export const b2c_pincodes = [
  {
    "Pincode": 561227,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562159,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561100,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561111,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689711,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690515,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625000,
    "City": "Madurai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 563333,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561222,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627353,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690504,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562128,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686603,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688527,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580000,
    "City": "Hubli",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627351,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575000,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643106,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573000,
    "City": "Hassan",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561110,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393130,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401000,
    "City": "Vasai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393135,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363440,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388520,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395000,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322023,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209308,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210121,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192301,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143511,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341025,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207120,
    "City": "Etah",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225202,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225205,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227812,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210128,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192302,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227814,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 247662,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272173,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272152,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224132,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272202,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335804,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341028,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246165,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326512,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788101,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781318,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781352,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486117,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486115,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494337,
    "City": "Charama",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492000,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483110,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 482000,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494336,
    "City": "Charama",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462000,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473335,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473446,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486009,
    "City": "Rewa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507208,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509337,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517234,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507114,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503125,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521180,
    "City": "Kanchikacherla",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523116,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509376,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502273,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523114,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524132,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521181,
    "City": "Kanchikacherla",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509209,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509228,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524134,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523281,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506221,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533006,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502280,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532460,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505303,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523113,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507305,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824217,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843105,
    "City": "Dholi",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758045,
    "City": "Turumunga",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843110,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824206,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766102,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721155,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765025,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843115,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811313,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758046,
    "City": "Turumunga",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811307,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732205,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766031,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824124,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843119,
    "City": "Dholi",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731202,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802351,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757039,
    "City": "Turumunga",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733202,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766100,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802154,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765033,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843114,
    "City": "Patepur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721140,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843121,
    "City": "Dholi",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765024,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721161,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766032,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841407,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802165,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843118,
    "City": "Gayghat",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731223,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804452,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841405,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732204,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734503,
    "City": "Jhalong",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731243,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802158,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802152,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804451,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 600121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602029,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602010,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603108,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602027,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602107,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602117,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 631402,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600000,
    "City": "Tiruvallur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602105,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601209,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603011,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602011,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600124,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 403503,
    "City": "Bicholim",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413701,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999112,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 711309,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722137,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 767026,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 577225,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577514,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586113,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586130,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577525,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133205,
    "City": "Dosarka",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 192303,
    "City": "Pulwama",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788726,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 788725,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 788724,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 461990,
    "City": "Pipariya",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 450332,
    "City": "Burhanpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 450221,
    "City": "Burhanpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799270,
    "City": "Kanchanpur",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581319,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 799271,
    "City": "Kanchanpur",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799269,
    "City": "Kanchanpur",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521344,
    "City": "KAIKALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521340,
    "City": "KAIKALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521227,
    "City": "GAMPALAGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521215,
    "City": "GAMPALAGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521190,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521111,
    "City": "NUZVID",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673639,
    "City": "MALLAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517588,
    "City": "SATYAVEDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673572,
    "City": "Thamarassery",
    "State": "KERALA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 517561,
    "City": "SBRPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673527,
    "City": "PERAMBRA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673526,
    "City": "PERAMBRA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673513,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 673508,
    "City": "PERAMBRA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673507,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 673029,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673027,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671545,
    "City": "RAJAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671323,
    "City": "MANJESHWAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388215,
    "City": "Umreth",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515164,
    "City": "C K PALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515101,
    "City": "C K PALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509401,
    "City": "ACHAMPETA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509375,
    "City": "ACHAMPETA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387355,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509336,
    "City": "KODANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509215,
    "City": "MAHABUBNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509203,
    "City": "MAHABUBNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507182,
    "City": "KHAMMAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507169,
    "City": "KHAMMAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507167,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507165,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507122,
    "City": "KHAMMAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506324,
    "City": "THORRUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506314,
    "City": "THORRUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505404,
    "City": "DUBBAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382465,
    "City": "DHANDUKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502302,
    "City": "Medak",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502281,
    "City": "Gajwel",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502270,
    "City": "Medak",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501508,
    "City": "IBRAHIMPATNAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501201,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365410,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364522,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638466,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638311,
    "City": "KESARIMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361210,
    "City": "DHROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636812,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 360540,
    "City": "KALAVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360110,
    "City": "DHROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344020,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344019,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342901,
    "City": "ASOP",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342705,
    "City": "BHOPALGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342306,
    "City": "TIWARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635116,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 335805,
    "City": "SURATGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335708,
    "City": "GHARSANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333338,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 496220,
    "City": "Pathalgaon",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496118,
    "City": "Pathalgaon",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327604,
    "City": "BAGIDORA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487881,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322704,
    "City": "Tonk",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322703,
    "City": "Tonk",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486123,
    "City": "Deotalab",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484116,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313802,
    "City": "KERWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627726,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627305,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470881,
    "City": "GARHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466661,
    "City": "KURAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466448,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304026,
    "City": "UNIYARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 304025,
    "City": "JHILLAI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303305,
    "City": "BASSI RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625540,
    "City": "THENI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752116,
    "City": "KONARK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752111,
    "City": "KONARK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461881,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461116,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458895,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458558,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458389,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301607,
    "City": "RAMGARH RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457885,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 283204,
    "City": "ETMADPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 283202,
    "City": "ETMADPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455227,
    "City": "CHAPADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455223,
    "City": "CHAPADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455221,
    "City": "CHAPADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454773,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275203,
    "City": "JEKHANIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275202,
    "City": "JEKHANIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450117,
    "City": "KHIRKIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273209,
    "City": "KHALILABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273203,
    "City": "CHAURI CHAURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273202,
    "City": "CHAURI CHAURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273201,
    "City": "CHAURI CHAURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272153,
    "City": "BANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263139,
    "City": "Haldwani",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263126,
    "City": "Haldwani",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 249137,
    "City": "Rishikesh",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246733,
    "City": "HALDAUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246726,
    "City": "HALDAUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742174,
    "City": "Hariharpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244922,
    "City": "RAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244413,
    "City": "BILLARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244411,
    "City": "BILLARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742136,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231216,
    "City": "ROBERTSGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736203,
    "City": "Barobisha",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736172,
    "City": "MATHABHANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736157,
    "City": "MATHABHANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227202,
    "City": "Itaunja",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226201,
    "City": "Itaunja",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735303,
    "City": "MATHABHANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425411,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735211,
    "City": "SonapurWB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734224,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423104,
    "City": "MANMAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423102,
    "City": "SATANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 900013,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 900012,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221311,
    "City": "RAJA TALAB",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221307,
    "City": "RAJA TALAB",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848132,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209206,
    "City": "GHATAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207122,
    "City": "ETAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841443,
    "City": "NAGRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841442,
    "City": "NAGRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841421,
    "City": "NAGRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591109,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835214,
    "City": "Mandar",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413324,
    "City": "MODNIMB",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413301,
    "City": "MODNIMB",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413248,
    "City": "MODNIMB",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712146,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581205,
    "City": "HAVERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412208,
    "City": "SHIKRAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825330,
    "City": "CHARHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577546,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577511,
    "City": "Hiriyur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577427,
    "City": "SHIKARIPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822112,
    "City": "NAGARUNTARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577413,
    "City": "SHIKARIPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577214,
    "City": "SHIKARIPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171208,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574225,
    "City": "MOODABIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573131,
    "City": "Channarayapatna",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152028,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151509,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571457,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799120,
    "City": "RSCUDAIPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799114,
    "City": "RSCUDAIPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799105,
    "City": "RSCUDAIPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151111,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151110,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151106,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799013,
    "City": "RSCUDAIPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151105,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151104,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151103,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148108,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795133,
    "City": "MOIRANG",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795126,
    "City": "MOIRANG",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795115,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795011,
    "City": "MOIRANG",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 563123,
    "City": "CHINTAMANI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563121,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 563113,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788723,
    "City": "KAYASTHAGRAM",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788719,
    "City": "KAYASTHAGRAM",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143305,
    "City": "CHABHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142058,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142032,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141123,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140602,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784173,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784111,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782427,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782426,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695564,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534451,
    "City": "Jangareddy Gudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123501,
    "City": "REWARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689695,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534004,
    "City": "Jangareddy Gudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689513,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689503,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689502,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396051,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688537,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686633,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686608,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686544,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532445,
    "City": "PALAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532443,
    "City": "PALAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532440,
    "City": "PALAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531061,
    "City": "ATCHUTHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531011,
    "City": "ATCHUTHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683546,
    "City": "KURUPPAMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683502,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757046,
    "City": "Rairangpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757045,
    "City": "Rairangpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757043,
    "City": "Rairangpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393041,
    "City": "DEDIAPADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523292,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523291,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523253,
    "City": "CHIMAKURTHY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754132,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 382340,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 123506,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 123505,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 122503,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122506,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500096,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500052,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 302029,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600027,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600002,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600129,
    "City": "Chengalpet",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600044,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600043,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600035,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577526,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 562123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500088,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 577557,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 673104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 641665,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600075,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600074,
    "City": "Chennai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600070,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600069,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600117,
    "City": "Chengalpet",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600097,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600091,
    "City": "Chengalpet",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641606,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560074,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500078,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 502319,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500014,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 500100,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500038,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500033,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 421101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 562106,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560046,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560045,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560032,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560024,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560006,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560005,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 562110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 388450,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 501401,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151101,
    "City": "RAMPURA PHUL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400086,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 643202,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643102,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643231,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 643105,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643101,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 682303,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 625022,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 573217,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 573128,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 573212,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 573118,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 573219,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 641000,
    "City": "Coimbatore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 111100,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 533016,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533002,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524320,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 400000,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605116,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 605118,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 605503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 607403,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 561000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 440000,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 517127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 517422,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 517007,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 111110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 411000,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 111000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 686594,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 678688,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 520000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 561226,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410000,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 511100,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 421111,
    "City": "Bhiwandi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502329,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 566666,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 561225,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999004,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229303,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 229128,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 682000,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638888,
    "City": "Tirupur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573120,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 284121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999002,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403114,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601207,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 111111,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 110000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 132000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 390000,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678709,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 678548,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 250104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 501301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 562162,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 403515,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 403109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 689595,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 688503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 688508,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 781000,
    "City": "Guwahati",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577133,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 403511,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 391350,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 391340,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 555555,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 608702,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 533462,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517132,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 124501,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 683556,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999001,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632520,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 990401,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 679539,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 682313,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 670703,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 670705,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 360000,
    "City": "Rajkot",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731127,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577777,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 992026,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 841211,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 421000,
    "City": "Bhiwandi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632510,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 737136,
    "City": "RANGPO",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609116,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 609113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 609115,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 643104,
    "City": "COONOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643213,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999025,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 752000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 711000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 831000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 734000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 845429,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 802123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 607301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 201204,
    "City": "Modinagar",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201206,
    "City": "Modinagar",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 600118,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 854108,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 480996,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 522615,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 524313,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 524239,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 508285,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 522663,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 783336,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 516107,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 587204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143303,
    "City": "CHABHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 134205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 134103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999005,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999006,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151210,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999007,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560000,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421311,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 713167,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 230137,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 508258,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 712305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999009,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999010,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999012,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 341504,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 230503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683105,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 273301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 151207,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560117,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560149,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 122105,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 453711,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 122504,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 754214,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 841411,
    "City": "NAGRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 203208,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 454774,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 160000,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 201103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 201101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 201015,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 413221,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 202121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 202145,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 281206,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 202124,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 222113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 210302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 243751,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 207125,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 283152,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 281123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 143302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 999011,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999013,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999014,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685514,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 785603,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 843130,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 788018,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 413310,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 335502,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 845302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 765056,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 133123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 192121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 273164,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 394440,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 123110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 192305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 421601,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 281403,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 414109,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414578,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201201,
    "City": "Modinagar",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250205,
    "City": "Modinagar",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788816,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 788815,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 782411,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 441501,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 989898,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 565656,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 578909,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 787878,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509325,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382425,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 689625,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460003,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 494347,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 494330,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 496109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 581212,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 524366,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683515,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 799999,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 999494,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999495,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999496,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999497,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999498,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999499,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999500,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999501,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999502,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 999503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 628906,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 628901,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 583220,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 766027,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 765021,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 765020,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 814157,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 829211,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713173,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713147,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754295,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 754033,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 764058,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 764057,
    "City": "BORIGUMMA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764056,
    "City": "BORIGUMMA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764055,
    "City": "BORIGUMMA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757042,
    "City": "Rairangpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764075,
    "City": "PAPADAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764072,
    "City": "PAPADAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764071,
    "City": "PAPADAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758016,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 815312,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 673524,
    "City": "PERAMBRA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591235,
    "City": "MUDALGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587330,
    "City": "Jamkhandi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673541,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581203,
    "City": "Ishwaramangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 477660,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476115,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 485774,
    "City": "AMARPATAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581213,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636117,
    "City": "VALAPPADI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581207,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570031,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570030,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590015,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683571,
    "City": "MANJAPARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782130,
    "City": "MORIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431154,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515123,
    "City": "PENUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502286,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 502271,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577533,
    "City": "HOSADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505416,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563125,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312204,
    "City": "BHOPALSAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795104,
    "City": "SENAPATI",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563159,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583224,
    "City": "HAGARIBOMMANAHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609103,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621729,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621718,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246425,
    "City": "RUDRAPRAYAG",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263621,
    "City": "CHAUKHUTIYA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497118,
    "City": "BAIKUNTHPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636202,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636142,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680533,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585307,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 685512,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685562,
    "City": "ADIMALI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683549,
    "City": "KURUPPAMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673542,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 524124,
    "City": "SULLURPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524121,
    "City": "SULLURPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454111,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246487,
    "City": "KARNAPRAYAG",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246474,
    "City": "KARNAPRAYAG",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246446,
    "City": "KARNAPRAYAG",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686601,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686602,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276405,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275306,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571247,
    "City": "MADIKERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142045,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507160,
    "City": "KHAMMAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462101,
    "City": "BERASIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764014,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585311,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581108,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670693,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585106,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584125,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 563130,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 563160,
    "City": "MALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765013,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 534426,
    "City": "DWARAKATIRUMALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509130,
    "City": "AMARCHINTHA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509131,
    "City": "AMARCHINTHA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509110,
    "City": "AMARCHINTHA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494115,
    "City": "TONGPAL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683501,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673521,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 577221,
    "City": "CHANNAGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795138,
    "City": "THOUBAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585367,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795149,
    "City": "THOUBAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795148,
    "City": "THOUBAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795002,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795008,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795005,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795003,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795004,
    "City": "IMPHAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532185,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795001,
    "City": "Imphal",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522438,
    "City": "PERECHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679328,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673613,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522529,
    "City": "PERECHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670701,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795130,
    "City": "LILONG",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521333,
    "City": "KAIKALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521345,
    "City": "KAIKALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457882,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507301,
    "City": "ASWARAOPETA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521343,
    "City": "KAIKALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641032,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641050,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686521,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284303,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 795010,
    "City": "IMPHAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795114,
    "City": "IMPHAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795134,
    "City": "NAMBOL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506163,
    "City": "THORRUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505467,
    "City": "SIDDIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682310,
    "City": "KOLENCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534456,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 535546,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795150,
    "City": "SENAPATI",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507201,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581120,
    "City": "Ishwaramangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581101,
    "City": "Ishwaramangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686512,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453001,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507159,
    "City": "KUSUMANCHI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507157,
    "City": "KUSUMANCHI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761105,
    "City": "BUGUDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795113,
    "City": "LANGJING",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643212,
    "City": "GUDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496224,
    "City": "BAGICHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643211,
    "City": "GUDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795009,
    "City": "IMPHAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795132,
    "City": "IMPHAL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643207,
    "City": "GUDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505476,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142059,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416507,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 416552,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577527,
    "City": "HOSADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670702,
    "City": "MATTANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518452,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 176321,
    "City": "CHAMBA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176316,
    "City": "CHAMBA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524126,
    "City": "NAIDUPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 581128,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518432,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 518405,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 192122,
    "City": "AWANTIPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192123,
    "City": "AWANTIPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532220,
    "City": "PUNDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284201,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497101,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143119,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 522401,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501506,
    "City": "IBRAHIMPATNAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502108,
    "City": "DUBBAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581110,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521301,
    "City": "GUDIVADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142038,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 601213,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601105,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752101,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700105,
    "City": "DHAPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141120,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190017,
    "City": "HMT",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190012,
    "City": "HMT",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517236,
    "City": "KALAKADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521323,
    "City": "GUDIVADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583132,
    "City": "KAMPLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148033,
    "City": "LEHRAGAGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 281306,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683550,
    "City": "MANJAPARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683563,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244712,
    "City": "JASPUR UK",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734312,
    "City": "RAMBI BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123021,
    "City": "NARNAUL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764073,
    "City": "UMERKOTE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764059,
    "City": "NABARANGPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272175,
    "City": "KHALILABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142060,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782446,
    "City": "LANKA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782448,
    "City": "LANKA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151208,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 151205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 515110,
    "City": "PENUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782126,
    "City": "MORIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765017,
    "City": "RAYAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722151,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722156,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722152,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141118,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141419,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534462,
    "City": "CHINTALAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141204,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142029,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141202,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141203,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141205,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144804,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 144622,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144621,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144624,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722160,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506105,
    "City": "MAHABUBABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522549,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522412,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495559,
    "City": "CHAMPA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585309,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 210305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 518463,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 822118,
    "City": "LESHLIGANJ",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673693,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695555,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483331,
    "City": "BAHORIBAND",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501111,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494222,
    "City": "BASTAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305021,
    "City": "SAMEJA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494221,
    "City": "BASTAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305026,
    "City": "PUSHKAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332742,
    "City": "DANTARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332604,
    "City": "KHANDELA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333308,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835211,
    "City": "KOLEBIRA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305203,
    "City": "MANGLIYAWAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508113,
    "City": "CHOUTUPPAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685612,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 561208,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 475671,
    "City": "BHANDER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624103,
    "City": "KODAIKANAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577519,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502125,
    "City": "MEDAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533213,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733207,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736167,
    "City": "SITALKUCHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833221,
    "City": "BARAJAMDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833222,
    "City": "BARAJAMDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833213,
    "City": "BARAJAMDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833223,
    "City": "BARAJAMDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833214,
    "City": "CHAIBASA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636810,
    "City": "DHARMAPURI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636813,
    "City": "DHARMAPURI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636451,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636458,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228133,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606301,
    "City": "KALLAKURUCHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613516,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613517,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844501,
    "City": "KHALISPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844507,
    "City": "KHALISPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630562,
    "City": "MADAGUPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631206,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624101,
    "City": "KODAIKANAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624210,
    "City": "KODAIKANAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767049,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457772,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 792120,
    "City": "CHANGLANG",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792131,
    "City": "LONGDING",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226303,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 147506,
    "City": "DERA BASSI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766118,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803307,
    "City": "SARMERA",
    "State": "BIHAR",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 582109,
    "City": "DHARWAD",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 612901,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 612902,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 612905,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 621803,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 608901,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 572213,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 797123,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171215,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577117,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143502,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670674,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670673,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 583229,
    "City": "KARATAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801308,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143504,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670651,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 391150,
    "City": "NASVADI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 471318,
    "City": "BUXWAHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470673,
    "City": "BUXWAHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175012,
    "City": "JOGINDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322711,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756088,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185102,
    "City": "POONCH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488058,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488051,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488050,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 791001,
    "City": "AALO",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783393,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 272129,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276402,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 792110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 802314,
    "City": "BARHARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485111,
    "City": "RAMPUR BAGHELAN MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485115,
    "City": "RAMPUR BAGHELAN MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333515,
    "City": "BUHANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586209,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328037,
    "City": "MANIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303506,
    "City": "PAPARDA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322203,
    "City": "SAPOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132044,
    "City": "ASSANDH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587112,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587115,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517124,
    "City": "PAKALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 737132,
    "City": "RANGPO",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458330,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458220,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458468,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458470,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844111,
    "City": "VAISHALI BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458474,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844113,
    "City": "VAISHALI BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193125,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193102,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171218,
    "City": "SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627118,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391152,
    "City": "NASVADI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391151,
    "City": "NASVADI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518346,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123028,
    "City": "Mahendergarh",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533431,
    "City": "YELESWARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 272206,
    "City": "NAUGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673574,
    "City": "THAMARASSERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491227,
    "City": "DHAMTARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 813102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 445209,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811102,
    "City": "SARMERA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803306,
    "City": "SARMERA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797114,
    "City": "PHEK",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799284,
    "City": "AMARPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 285125,
    "City": "ORAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 285130,
    "City": "ORAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797110,
    "City": "CHUMUKIDIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711408,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833215,
    "City": "CHAIBASA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761209,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192233,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 854325,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533560,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274703,
    "City": "BAHTPAR RANI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413608,
    "City": "LOHARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444726,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799100,
    "City": "BISHALGARH",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815311,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854327,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322212,
    "City": "BAMANWAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226501,
    "City": "FAIZABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415620,
    "City": "CHAFE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 205261,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 327034,
    "City": "ARTHUNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335025,
    "City": "GOLUWALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327606,
    "City": "BAGIDORA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327603,
    "City": "BAGIDORA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173221,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193223,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 303325,
    "City": "DAUSA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786156,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734105,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521230,
    "City": "MYLAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521229,
    "City": "MYLAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185234,
    "City": "RAJOURI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481778,
    "City": "DINDORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766106,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766108,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445210,
    "City": "DARWAH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766111,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587114,
    "City": "RON",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193403,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 835213,
    "City": "KURU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 875102,
    "City": "Tiptur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248016,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248145,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248143,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456441,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456550,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471203,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470339,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185135,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 723156,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848113,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582217,
    "City": "RON",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370485,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388545,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685509,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685551,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481885,
    "City": "SHAHPURA SER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152114,
    "City": "MALOUT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524321,
    "City": "MUTHUKURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225412,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524323,
    "City": "MUTHUKURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517152,
    "City": "PAKALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127026,
    "City": "BADHARA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683573,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365490,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 146110,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173222,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173217,
    "City": "SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303507,
    "City": "DAUSA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424214,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577118,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246431,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246428,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246486,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246440,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517112,
    "City": "PAKALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494556,
    "City": "BACHELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494449,
    "City": "DANTEWADA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494441,
    "City": "DANTEWADA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494553,
    "City": "BACHELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507209,
    "City": "TALLADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518220,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 342029,
    "City": "JODHPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491996,
    "City": "DHODHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494444,
    "City": "BIJAPUR CG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246735,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603312,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603401,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603313,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603304,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 571159,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 570150,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571150,
    "City": "NANJANAGUDU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571151,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571152,
    "City": "MALAVALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571154,
    "City": "SRIRANGAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144405,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144408,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604203,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263635,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683581,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683587,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 262904,
    "City": "KHIRI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325003,
    "City": "KOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327031,
    "City": "ARTHUNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326022,
    "City": "RATLAI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323616,
    "City": "UNIYARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185211,
    "City": "POONCH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152005,
    "City": "Ferozepur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175009,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311607,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331804,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303610,
    "City": "NAWA RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327608,
    "City": "Banswara",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191205,
    "City": "LAR GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172025,
    "City": "ANNI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201317,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201313,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201316,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201318,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583280,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523246,
    "City": "PODILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683543,
    "City": "PERUMBAVOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585437,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271124,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271129,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766117,
    "City": "SINDHEKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847213,
    "City": "MADHUBANI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686692,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572222,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 562161,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413109,
    "City": "NATEPUTE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584122,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 584115,
    "City": "LINGASUGUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673573,
    "City": "THAMARASSERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686691,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455459,
    "City": "CHAPADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686693,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509311,
    "City": "MAKTHAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577550,
    "City": "AJJAMPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262122,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 761135,
    "City": "POLASARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517505,
    "City": "CHANDRAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517102,
    "City": "CHANDRAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517101,
    "City": "CHANDRAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322214,
    "City": "BAMANWAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 493448,
    "City": "BAGBAHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683110,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507137,
    "City": "DUMMUGUDEM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423501,
    "City": "KALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423502,
    "City": "KALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585415,
    "City": "MANNAEKHELLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605502,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501203,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686673,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 533435,
    "City": "YELESWARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122103,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140109,
    "City": "MULLANPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453771,
    "City": "SANWER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500018,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507158,
    "City": "KHAMMAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683542,
    "City": "PERUMBAVOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500081,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501501,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683541,
    "City": "PERUMBAVOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683544,
    "City": "PERUMBAVOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686671,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686666,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683547,
    "City": "PERUMBAVOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 223226,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 682026,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682024,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682021,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577203,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577202,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673571,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673570,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670018,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670007,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670006,
    "City": "VALIYANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673028,
    "City": "BEYPORE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673015,
    "City": "BEYPORE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403404,
    "City": "MARCEL",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403115,
    "City": "MARCEL",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403107,
    "City": "MARCEL",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573115,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670017,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670003,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502278,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 502311,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 680501,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508254,
    "City": "NALGONDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522005,
    "City": "PERECHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636012,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505172,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505153,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524344,
    "City": "MUTHUKURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524311,
    "City": "MUTHUKURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625013,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577222,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502301,
    "City": "Gajwel",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524346,
    "City": "MUTHUKURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382220,
    "City": "BAVALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382213,
    "City": "BAVALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414005,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686661,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322230,
    "City": "Hindaun",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754138,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754137,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754140,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754160,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 322252,
    "City": "Hindaun",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522102,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574227,
    "City": "MOODABIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274402,
    "City": "KASYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517551,
    "City": "SBRPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523247,
    "City": "DARSI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442104,
    "City": "SELOO",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571442,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531082,
    "City": "ADDUROAD",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523240,
    "City": "PODILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522113,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522111,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523241,
    "City": "PODILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274403,
    "City": "KASYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801103,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801108,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422212,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754136,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 473660,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517571,
    "City": "SBRPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847211,
    "City": "MADHUBANI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361162,
    "City": "KALAVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361160,
    "City": "KALAVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784521,
    "City": "TANGLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786151,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786159,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786190,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786150,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786154,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786153,
    "City": "DOOMDOOMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682308,
    "City": "KOLENCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509338,
    "City": "KODANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680582,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523270,
    "City": "KANDUKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686541,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686540,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686515,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686543,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686542,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766012,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 766011,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 533444,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533446,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847212,
    "City": "MADHUBANI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765022,
    "City": "GUNUPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577229,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691554,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 762002,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 762001,
    "City": "PHULBANI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642109,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761012,
    "City": "DIGAPAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761014,
    "City": "DIGAPAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673317,
    "City": "KAKKODI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670592,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670603,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571423,
    "City": "KR PETE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 682503,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682505,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682509,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682511,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577547,
    "City": "AJJAMPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680590,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522310,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522317,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422213,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673577,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457777,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509208,
    "City": "MAKTHAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509353,
    "City": "MAKTHAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535463,
    "City": "CHINABHOGILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508114,
    "City": "CHOUTUPPAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385555,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385550,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385560,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505174,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515501,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799205,
    "City": "TELIAMURA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848125,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587202,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628902,
    "City": "VILATHIKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799203,
    "City": "TELIAMURA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453551,
    "City": "SANWER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523327,
    "City": "YERRAGONDAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523326,
    "City": "YERRAGONDAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462044,
    "City": "RATIBAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691553,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691556,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462039,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226401,
    "City": "SARIJANI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517257,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 795140,
    "City": "NAMBOL",
    "State": "MANIPUR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628907,
    "City": "VILATHIKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628904,
    "City": "VILATHIKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 795116,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 800111,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784526,
    "City": "TANGLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682508,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682510,
    "City": "VYPIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274206,
    "City": "KASYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274203,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754034,
    "City": "ATHAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531270,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307032,
    "City": "MOUNT ABU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756046,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303606,
    "City": "KALWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754154,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754260,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 765062,
    "City": "GUNUPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765032,
    "City": "GUNUPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 851223,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844532,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801118,
    "City": "BIHTA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571111,
    "City": "GUNDLUPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577549,
    "City": "AJJAMPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465550,
    "City": "ALOT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754245,
    "City": "RAJNAGAR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754225,
    "City": "RAJNAGAR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754246,
    "City": "RAJNAGAR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754248,
    "City": "RAJNAGAR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274408,
    "City": "KASYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 737111,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 523330,
    "City": "YERRAGONDAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273304,
    "City": "NICHLAUL",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573101,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573215,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531081,
    "City": "ADDUROAD",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531083,
    "City": "ADDUROAD",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 430006,
    "City": "MARCEL",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509350,
    "City": "KODANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273408,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344502,
    "City": "RAMSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517526,
    "City": "SBRPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517580,
    "City": "SBRPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673309,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 523252,
    "City": "DARSI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628905,
    "City": "VILATHIKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533455,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533456,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515532,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 515531,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 385533,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385531,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385551,
    "City": "SHIHORITH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442111,
    "City": "SELOO",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442105,
    "City": "SELOO",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754163,
    "City": "AMBERI",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 759022,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458334,
    "City": "JAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486335,
    "City": "MAUGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344501,
    "City": "RAMSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496107,
    "City": "RAIGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496111,
    "City": "RAIGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686555,
    "City": "KOTTIYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502312,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 768036,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 761042,
    "City": "DIGAPAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761054,
    "City": "DIGAPAHANDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755005,
    "City": "KAMPAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284203,
    "City": "GURUSARAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497111,
    "City": "SITAPUR CG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497114,
    "City": "SITAPUR CG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 394111,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 496108,
    "City": "RAIGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274207,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767025,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 767048,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 767041,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 140110,
    "City": "MULLANPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 192231,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784528,
    "City": "TANGLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585229,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585228,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585225,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824236,
    "City": "TEKARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284202,
    "City": "GURUSARAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523271,
    "City": "KANDUKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 791120,
    "City": "ZIRO",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670675,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577145,
    "City": "AJJAMPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686502,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686516,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486333,
    "City": "MAUGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322211,
    "City": "BAMANWAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768049,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 392170,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441102,
    "City": "GODHNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441111,
    "City": "GODHNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441123,
    "City": "GODHNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495674,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678545,
    "City": "ALATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312205,
    "City": "BHOPALSAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571474,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 172027,
    "City": "KUMARSAIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152124,
    "City": "FAZILKA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 145028,
    "City": "JUGIYAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 145030,
    "City": "JUGIYAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523263,
    "City": "CHIMAKURTHY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788831,
    "City": "MAIBONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523264,
    "City": "CHIMAKURTHY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673575,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509128,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 246736,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222204,
    "City": "MUNGRA BADSHAHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 131103,
    "City": "KHARKHODA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273413,
    "City": "KAURIRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273403,
    "City": "KAURIRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413103,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535534,
    "City": "KURUPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835324,
    "City": "SISAI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471101,
    "City": "KHAJURAO MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393115,
    "City": "RAJPARDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393110,
    "City": "RAJPARDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393120,
    "City": "RAJPARDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393017,
    "City": "RAJPARDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365650,
    "City": "KHAMBHA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393040,
    "City": "DEDIAPADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175046,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177219,
    "City": "GONDPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203090,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 261403,
    "City": "NAIMISHARANYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627453,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785613,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 464995,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464990,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483330,
    "City": "RITHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491888,
    "City": "DHODHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 744206,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314804,
    "City": "BICHHIWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606751,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606901,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503111,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503213,
    "City": "NIZAMABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503212,
    "City": "NIZAMABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503123,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503101,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503110,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503102,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524315,
    "City": "ALLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524318,
    "City": "ALLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792121,
    "City": "JAIRAMPUR",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321626,
    "City": "KAMAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321625,
    "City": "KAMAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631205,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630101,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630104,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496116,
    "City": "DHARAMJAIGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394190,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394517,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 471313,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472010,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174026,
    "City": "KUTHERA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227518,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174024,
    "City": "KUTHERA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 223712,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 176601,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 267159,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 141022,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141122,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 184210,
    "City": "KATHUA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 297851,
    "City": "PALIA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323802,
    "City": "NAINWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327032,
    "City": "ARTHUNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301712,
    "City": "BIBIRANI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792123,
    "City": "JAIRAMPUR",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606904,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606908,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606906,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140702,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 147023,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224854,
    "City": "PALIA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175017,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224171,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224161,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262521,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262520,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181151,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 354458,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327601,
    "City": "BAGIDORA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175011,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175010,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171018,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402202,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402203,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394716,
    "City": "AHWA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394720,
    "City": "AHWA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587302,
    "City": "Jamkhandi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516216,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 516218,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713375,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370490,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781120,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365550,
    "City": "KHAMBHA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456664,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518308,
    "City": "ADONI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797107,
    "City": "PHEK",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 212212,
    "City": "BHARWARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262540,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262530,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517619,
    "City": "SRIKALAHASTI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342012,
    "City": "BORANADA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501510,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500043,
    "City": "MEDCHAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829111,
    "City": "GUMIA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829112,
    "City": "GUMIA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516130,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532214,
    "City": "PATHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360430,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524411,
    "City": "KOTA AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524413,
    "City": "KOTA AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829128,
    "City": "GUMIA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523105,
    "City": "KANDUKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274204,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274205,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263001,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 263002,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 523186,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673505,
    "City": "NADAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518166,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829149,
    "City": "GUMIA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414113,
    "City": "SHIRURKASAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533430,
    "City": "YELESWARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494223,
    "City": "BASTAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517645,
    "City": "SRIKALAHASTI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518225,
    "City": "DHONE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743385,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743338,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140508,
    "City": "DERA BASSI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140504,
    "City": "DERA BASSI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523109,
    "City": "KANDUKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414205,
    "City": "PATODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431522,
    "City": "KINGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273411,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631202,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631207,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631208,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460002,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246001,
    "City": "PAURI GARHWAL",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361170,
    "City": "LALPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562121,
    "City": "HAROHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 768107,
    "City": "KUCHINDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364120,
    "City": "TALAJA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621208,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637021,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637013,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363510,
    "City": "SAYLA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686545,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533339,
    "City": "RAMPACHODAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625102,
    "City": "MELUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625527,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625528,
    "City": "BODINAYAKANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573147,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625201,
    "City": "THIRUPPUVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210423,
    "City": "MAHOBA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225401,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262403,
    "City": "PANTNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263147,
    "City": "PANTNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171204,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171301,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142054,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144633,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146114,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 796181,
    "City": "SERCHIP",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797108,
    "City": "PHEK",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246194,
    "City": "PAURI GARHWAL",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263642,
    "City": "BAGESHWAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263641,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 182125,
    "City": "UDHAMPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303110,
    "City": "RAJNOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263156,
    "City": "NAINITAL",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263136,
    "City": "NAINITAL",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585212,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585213,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585214,
    "City": "YADGIR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621714,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621710,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621806,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690558,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690107,
    "City": "MAVELIKERA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243501,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 205121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332312,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224172,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314801,
    "City": "BICHHIWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172026,
    "City": "ANNI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185212,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 585301,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577537,
    "City": "Challakere",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584134,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796701,
    "City": "LUNGLEI",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638060,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623503,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413229,
    "City": "PATODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533295,
    "City": "RAMPACHODAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516129,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515833,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370620,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365545,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365456,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515872,
    "City": "RAMPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583112,
    "City": "RAMPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585322,
    "City": "JEWARGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471311,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471301,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471405,
    "City": "BADAMALHERA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471111,
    "City": "HARPALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793104,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794103,
    "City": "TURA",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 192126,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142022,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305207,
    "City": "PISAGAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313330,
    "City": "AMET",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323801,
    "City": "NAINWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495685,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495448,
    "City": "KATGORA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313804,
    "City": "KERWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581209,
    "City": "DHARWAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582120,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581107,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560109,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562122,
    "City": "HOSKOTE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786103,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262524,
    "City": "LOHAGHAT",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263601,
    "City": "ALMORA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263643,
    "City": "ALMORA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577230,
    "City": "HONNALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577521,
    "City": "JAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263645,
    "City": "RANIKHET",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246167,
    "City": "SATPULI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246172,
    "City": "SATPULI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585419,
    "City": "SHIVAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263656,
    "City": "CHAUKHUTIYA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263653,
    "City": "CHAUKHUTIYA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262501,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262502,
    "City": "PITHORAGARH",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263637,
    "City": "SOMESHWAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263638,
    "City": "SOMESHWAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263639,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 262523,
    "City": "CHAMPAWAT",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587344,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627452,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624219,
    "City": "DINDUGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563128,
    "City": "HOSKOTE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734123,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535220,
    "City": "CHEEPURUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507303,
    "City": "SATHUPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801303,
    "City": "HILSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507164,
    "City": "SATHUPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175030,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587113,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303805,
    "City": "CHONP",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591114,
    "City": "RAMDURG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175018,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202394,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721166,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243727,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 591316,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803114,
    "City": "HILSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591107,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582117,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182161,
    "City": "CHENANI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181114,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141413,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141415,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 791104,
    "City": "PASHIGHAT",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622403,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422208,
    "City": "PEINT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364145,
    "City": "BAGDANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364295,
    "City": "BAGDANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387335,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387305,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387330,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387340,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387430,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402401,
    "City": "MURUD JANJIRA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476332,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 843127,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801306,
    "City": "HILSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686632,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627652,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768222,
    "City": "KUCHINDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768227,
    "City": "KUCHINDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768228,
    "City": "KUCHINDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768214,
    "City": "KUCHINDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581208,
    "City": "Ranebennur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577219,
    "City": "MALEBENNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613204,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517214,
    "City": "Piler",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502110,
    "City": "MEDAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517113,
    "City": "ROMPICHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416509,
    "City": "NESARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416504,
    "City": "NESARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531025,
    "City": "WADDADI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517123,
    "City": "Piler",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 530052,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496553,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503145,
    "City": "SADASHIV NAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503144,
    "City": "SADASHIV NAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503114,
    "City": "SADASHIV NAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585325,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416508,
    "City": "NESARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202390,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 496554,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502130,
    "City": "MEDAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182128,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 191203,
    "City": "GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191204,
    "City": "LAR GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176223,
    "City": "SHAHPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148018,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142028,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 328031,
    "City": "SIRMATHURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331811,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136030,
    "City": "PEHWA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127307,
    "City": "ATELA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323021,
    "City": "KESHOVRAIPATAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327026,
    "City": "BANSWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314030,
    "City": "SIMAL WARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314035,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 678573,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678671,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571436,
    "City": "KIKKERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676317,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 676319,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 621314,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630309,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585321,
    "City": "YADGIR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331301,
    "City": "CHURU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625708,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623135,
    "City": "MUTHUKULATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621111,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609608,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609201,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581211,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628952,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628716,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628714,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627359,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261405,
    "City": "SITAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313803,
    "City": "KERWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393030,
    "City": "HANSOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393020,
    "City": "HANSOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394810,
    "City": "HANSOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788830,
    "City": "MAIBONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 841210,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 841236,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 841509,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 825411,
    "City": "PADMA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686564,
    "City": "PALLIKKATHODU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686584,
    "City": "PALLIKKATHODU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686503,
    "City": "PALLIKKATHODU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413406,
    "City": "VAIRAG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 463106,
    "City": "BERASIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586216,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591229,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425135,
    "City": "PALDHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425126,
    "City": "PALDHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425132,
    "City": "PALDHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587206,
    "City": "BADAMI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587155,
    "City": "BADAMI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605106,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 999111,
    "City": "GURGAON",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573113,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587205,
    "City": "BADAMI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587104,
    "City": "BADAMI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577536,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577543,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 796321,
    "City": "CHAMPHAI",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506355,
    "City": "CHERIYAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415108,
    "City": "GHOGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416525,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685561,
    "City": "ADIMALI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331505,
    "City": "PARIHARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173025,
    "City": "PAONTA SAHIB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173021,
    "City": "PAONTA SAHIB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173028,
    "City": "PAONTA SAHIB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523180,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518442,
    "City": "KURNOOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688562,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688561,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688564,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517520,
    "City": "MARKAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203132,
    "City": "KHURJA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412216,
    "City": "PERNE PHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 425102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 786160,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786152,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518385,
    "City": "PATTIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 768205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 518467,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688563,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731254,
    "City": "MOHAMMAD BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671320,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743440,
    "City": "HABRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768232,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 532242,
    "City": "MANDASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689602,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689597,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425327,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786174,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384246,
    "City": "HARIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384245,
    "City": "HARIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 900011,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 791122,
    "City": "DAPORIJO",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786008,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781402,
    "City": "Sonapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781345,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781112,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781131,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781111,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796016,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793013,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202130,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 572228,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585417,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585443,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635710,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635701,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635702,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768101,
    "City": "ATTABIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757031,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757093,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 828144,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825308,
    "City": "BARKAGAON",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825223,
    "City": "BARAKATHA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743302,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743414,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743413,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741318,
    "City": "KRISHNANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835304,
    "City": "LOHARDAGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835305,
    "City": "LOHARDAGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814165,
    "City": "Mahagama",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721103,
    "City": "MIDNAPORE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764062,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835022,
    "City": "MURI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761127,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721138,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766109,
    "City": "BHAWANIPATNA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766021,
    "City": "BHAWANIPATNA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 834016,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 831110,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 831109,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 826116,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754238,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754235,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829412,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829317,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752010,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752006,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700162,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721259,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721258,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721123,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829150,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829109,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757072,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 742157,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768117,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843335,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843325,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721315,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721170,
    "City": "SONAKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731146,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731139,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759106,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835234,
    "City": "TORPA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711324,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670583,
    "City": "NADUVIL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671544,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670314,
    "City": "PARAPPA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670144,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628209,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686546,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673525,
    "City": "PERAMBRA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143512,
    "City": "DERA BABA NANAK",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577529,
    "City": "MOLAKALMURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848209,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193301,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494224,
    "City": "BASTAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691515,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442916,
    "City": "GADCHANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683576,
    "City": "KARUKUTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683577,
    "City": "KARUKUTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683589,
    "City": "KARUKUTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610201,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 610203,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736204,
    "City": "SONAPURWB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591220,
    "City": "KUDCHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802122,
    "City": "BUXAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683111,
    "City": "KARUKUTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683575,
    "City": "KARUKUTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505102,
    "City": "KAMALAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505101,
    "City": "KAMALAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572117,
    "City": "GUBBI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143604,
    "City": "DERA BABA NANAK",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572116,
    "City": "PAVAGADA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572141,
    "City": "PAVAGADA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591224,
    "City": "MUDALGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814150,
    "City": "SARWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505528,
    "City": "BEJJANKI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505473,
    "City": "BEJJANKI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506175,
    "City": "JANGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591115,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591112,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591118,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591106,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788737,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 203390,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 203394,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 523261,
    "City": "MEDARAMETLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144053,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144052,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144051,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144050,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144049,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144048,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144047,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144046,
    "City": "Nakodar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721131,
    "City": "DEBRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721136,
    "City": "PANSKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591153,
    "City": "KITTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792122,
    "City": "MIAO",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505530,
    "City": "BEJJANKI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175029,
    "City": "MANDI CITY",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175124,
    "City": "MANDI CITY",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171212,
    "City": "THEOG",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171222,
    "City": "THEOG",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144703,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 424308,
    "City": "BORVIHIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574323,
    "City": "BELTHANGADY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577114,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 610102,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 610204,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611103,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614707,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571254,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609802,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609811,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 792129,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 679564,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591311,
    "City": "KUDCHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577535,
    "City": "MOLAKALMURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591310,
    "City": "MUDALGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364130,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364081,
    "City": "TALAJA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391775,
    "City": "SAVLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391780,
    "City": "SAVLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785631,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785632,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584104,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585124,
    "City": "YADGIR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277213,
    "City": "MANIAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277124,
    "City": "GARWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277214,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 277403,
    "City": "SAHTAWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212213,
    "City": "BHARWARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142035,
    "City": "Jagraon",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124406,
    "City": "KHARKHODA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 796901,
    "City": "SAIHA",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796891,
    "City": "LAWNGTLAI",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743399,
    "City": "RAIDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713367,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 851216,
    "City": "NAGARPARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722503,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623708,
    "City": "MUTHUKULATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622202,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625704,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625529,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635653,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574114,
    "City": "NARAVI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713402,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713171,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732216,
    "City": "KALIACHAK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732212,
    "City": "KALIACHAK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835235,
    "City": "SIMDEGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835228,
    "City": "SIMDEGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415704,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415705,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415729,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759040,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581346,
    "City": "MUNDGOD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581358,
    "City": "MUNDGOD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422308,
    "City": "NIPHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509326,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388260,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360460,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517589,
    "City": "PUTTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517591,
    "City": "PUTTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517586,
    "City": "PUTTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577220,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577216,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243505,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313332,
    "City": "AMET",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523260,
    "City": "MEDARAMETLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609803,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609810,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572226,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572217,
    "City": "Tiptur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210507,
    "City": "HAMIRPUR UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210504,
    "City": "HAMIRPUR UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272130,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272181,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262901,
    "City": "PALIA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212622,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 212655,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 285126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 285127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 854340,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721168,
    "City": "PANSKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841232,
    "City": "SIWAN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802128,
    "City": "BUXAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 813113,
    "City": "AKBARNAGAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481224,
    "City": "AMANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585416,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721000,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403406,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403105,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606102,
    "City": "ULUNDURPETTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522235,
    "City": "PRATHIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522112,
    "City": "PRATHIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 477116,
    "City": "GOHAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630552,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630553,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630557,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363430,
    "City": "SAYLA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523106,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607204,
    "City": "ULUNDURPETTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431007,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502107,
    "City": "SIDDIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502310,
    "City": "SIDDIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502267,
    "City": "SIDDIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623603,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171202,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141414,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637410,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637403,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626114,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635120,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788733,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506349,
    "City": "MULUGU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144040,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 574143,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509103,
    "City": "WANAPARTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144702,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 144701,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 144041,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 816101,
    "City": "BARHARWA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816110,
    "City": "BARHARWA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 783120,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 303301,
    "City": "BASSI RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572221,
    "City": "TURVEKERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801302,
    "City": "HILSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801301,
    "City": "HILSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509381,
    "City": "WANAPARTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673504,
    "City": "NADAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494333,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829123,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470120,
    "City": "RAHATGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803108,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731219,
    "City": "MURARAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731221,
    "City": "MURARAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731222,
    "City": "MURARAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731238,
    "City": "MURARAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585313,
    "City": "JEWARGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 801307,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582209,
    "City": "RON",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712410,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582119,
    "City": "RON",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582111,
    "City": "RON",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 584129,
    "City": "RAICHUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573121,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573216,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713520,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171201,
    "City": "THEOG",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171209,
    "City": "THEOG",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413523,
    "City": "KINGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488220,
    "City": "CHANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766107,
    "City": "KHARIAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723130,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 723151,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 723148,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 722173,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803113,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803120,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803204,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743349,
    "City": "RAIDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743383,
    "City": "RAIDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402116,
    "City": "NAGOTHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470125,
    "City": "RAHATGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484552,
    "City": "UMARIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711304,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721427,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744107,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756033,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311806,
    "City": "GANGAPUR BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311805,
    "City": "GANGAPUR BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305201,
    "City": "PISAGAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301028,
    "City": "CHIKANI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123101,
    "City": "Rewari",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575029,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609204,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609202,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609205,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612106,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638703,
    "City": "TIRUPPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638702,
    "City": "TIRUPPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638706,
    "City": "TIRUPPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586203,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586122,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586208,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783133,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782101,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835221,
    "City": "HARDAG",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713373,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765015,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 854336,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 821108,
    "City": "KUDRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821104,
    "City": "KUDRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394710,
    "City": "AHWA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396150,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585316,
    "City": "JEWARGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642123,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642101,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793109,
    "City": "JOWAI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793161,
    "City": "JOWAI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792124,
    "City": "MIAO",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136021,
    "City": "KAITHAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136020,
    "City": "KAITHAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276125,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276129,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 276131,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 303908,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 311803,
    "City": "RAIPUR BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311804,
    "City": "RAIPUR BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425328,
    "City": "YAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396375,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396385,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363650,
    "City": "TANKARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496005,
    "City": "RAIGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680515,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533126,
    "City": "RAJAHMUNDRY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533125,
    "City": "RAJAHMUNDRY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613602,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613601,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613105,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613104,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574231,
    "City": "KALLADKA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574222,
    "City": "KALLADKA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680516,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680518,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783369,
    "City": "BASUGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122502,
    "City": "Rewari",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517408,
    "City": "BANGARUPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532212,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423213,
    "City": "NAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635122,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445106,
    "City": "ARNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203131,
    "City": "KHURJA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507306,
    "City": "ASWARAOPETA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624704,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422303,
    "City": "NIPHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574223,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 333517,
    "City": "KHETRIRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532218,
    "City": "PUNDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333516,
    "City": "KHETRIRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532211,
    "City": "PUNDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624712,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609301,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613705,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 610107,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612604,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612603,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613102,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613101,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 172024,
    "City": "KUMARSAIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517291,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505470,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224189,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224182,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 829130,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829131,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360060,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422214,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389170,
    "City": "JHALOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389180,
    "City": "JHALOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389190,
    "City": "JHALOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301413,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782450,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782486,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 203129,
    "City": "KHURJA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635108,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624306,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639203,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614103,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614714,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571610,
    "City": "K R NAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571446,
    "City": "KOPPAMANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131402,
    "City": "KHARKHODA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764020,
    "City": "KORAPUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764021,
    "City": "KORAPUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713407,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743439,
    "City": "HINGALGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743446,
    "City": "HINGALGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360025,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385310,
    "City": "DHANERA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385545,
    "City": "DHANERA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627151,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531023,
    "City": "Chodavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531036,
    "City": "Chodavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 768203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 516214,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176319,
    "City": "SUNDLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535521,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423402,
    "City": "Yeola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534124,
    "City": "PENUMANTRA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151301,
    "City": "Talwandi",
    "State": "PUNJAB",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 606204,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274405,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486771,
    "City": "RAMPUR MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642110,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814154,
    "City": "Mahagama",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176308,
    "City": "SUNDLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176317,
    "City": "SUNDLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176320,
    "City": "SUNDLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176312,
    "City": "SUNDLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571812,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445306,
    "City": "PANDHARKAWDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523190,
    "City": "INKOLLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524345,
    "City": "PODALAKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524342,
    "City": "PODALAKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431803,
    "City": "HIMAYATNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262309,
    "City": "TANAKPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431703,
    "City": "SENGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 251315,
    "City": "KHATAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534230,
    "City": "PENUMANTRA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591223,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591315,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413222,
    "City": "VAIRAG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140502,
    "City": "DERA BASSI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531281,
    "City": "Chodavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531135,
    "City": "Chodavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516215,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495695,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811104,
    "City": "SARMERA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391145,
    "City": "BODELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385130,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 385135,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 233301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 311202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 323602,
    "City": "KESHOVRAIPATAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323803,
    "City": "KAPREN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312614,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327027,
    "City": "BANSWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689505,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689585,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585436,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591231,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328026,
    "City": "SIRMATHURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761121,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 827301,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535525,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581145,
    "City": "Ranebennur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638106,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609501,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560110,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416707,
    "City": "PAWAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394240,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496101,
    "City": "DABHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466113,
    "City": "ICHHAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466118,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466125,
    "City": "POLAY KALAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473662,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473990,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473115,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473118,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575150,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835227,
    "City": "TORPA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835202,
    "City": "BERO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835301,
    "City": "BERO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835203,
    "City": "BERO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814155,
    "City": "Mahagama",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571807,
    "City": "SRIRANGAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508244,
    "City": "NALGONDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508255,
    "City": "NALGONDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 608306,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313903,
    "City": "SARARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313905,
    "City": "SARARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313904,
    "City": "SARARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321611,
    "City": "BALAJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301035,
    "City": "LAXMANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534247,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534245,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534244,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534243,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733209,
    "City": "DALKHOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585287,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788819,
    "City": "HAFLONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532409,
    "City": "RANASTHALAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532407,
    "City": "RANASTHALAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533406,
    "City": "ANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533408,
    "City": "ANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825412,
    "City": "TANDWA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581121,
    "City": "RAMNAGAR KAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 787051,
    "City": "LAKHIMPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533297,
    "City": "RAJANAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533291,
    "City": "RAJANAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533292,
    "City": "RAJANAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143103,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586124,
    "City": "MUDDEBIHAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413249,
    "City": "SHIRURKASAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176098,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176055,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274004,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431510,
    "City": "JINTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765018,
    "City": "RAYAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 304023,
    "City": "UNIYARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342309,
    "City": "PEELWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305402,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825407,
    "City": "JAINAGAR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388510,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393155,
    "City": "KEVADIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387120,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388239,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391125,
    "City": "BODELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389160,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322218,
    "City": "SAPOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322025,
    "City": "KHANDAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305204,
    "City": "PISAGAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400070,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400024,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533296,
    "City": "RAJANAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533294,
    "City": "RAJANAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577531,
    "City": "CHANNAGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571617,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571219,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140507,
    "City": "DERA BASSI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577233,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 689624,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691307,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583279,
    "City": "KUSHTAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614202,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613203,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531030,
    "City": "K KOTAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431204,
    "City": "AMBAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431209,
    "City": "AMBAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212203,
    "City": "BHARWARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845455,
    "City": "NARKATIAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494335,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621107,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621708,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621113,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495335,
    "City": "SARGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488059,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464114,
    "City": "MAKSUDANGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531034,
    "City": "K KOTAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531022,
    "City": "K KOTAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274702,
    "City": "BAHTPAR RANI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488446,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531075,
    "City": "K KOTAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487114,
    "City": "GOTEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274704,
    "City": "BAHTPAR RANI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604204,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606801,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604503,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604502,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604501,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604208,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606902,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606807,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614612,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815257,
    "City": "KARON",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614403,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 611102,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614019,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303105,
    "City": "PATAN RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334808,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 333041,
    "City": "BADA GANV",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341516,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341517,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333504,
    "City": "KHETRIRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613103,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613703,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621805,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639103,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506201,
    "City": "JANGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734222,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744112,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767065,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688504,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 689590,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695583,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585226,
    "City": "MANNAEKHELLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506167,
    "City": "JANGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502285,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502295,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581115,
    "City": "Ranebennur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531020,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403001,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620009,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403004,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534239,
    "City": "BHIMAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534208,
    "City": "BHIMAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689584,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 999003,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574219,
    "City": "BC ROAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574211,
    "City": "BC ROAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577548,
    "City": "BIRUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587311,
    "City": "BANHATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587315,
    "City": "BANHATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587314,
    "City": "BANHATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673641,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577215,
    "City": "CHANNAGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341506,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630107,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630202,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517299,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574217,
    "City": "MADANTHYAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574214,
    "City": "MADANTHYAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521459,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522403,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813210,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670593,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518347,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673647,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522408,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845424,
    "City": "SAHEBGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522402,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445103,
    "City": "ARNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535523,
    "City": "KURUPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516227,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673637,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843320,
    "City": "PUPRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843333,
    "City": "PUPRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843326,
    "City": "PUPRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445105,
    "City": "ARNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445109,
    "City": "ARNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497229,
    "City": "BISHRAMPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 574324,
    "City": "BC ROAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424204,
    "City": "SHENDURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491661,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 507133,
    "City": "CHERLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507140,
    "City": "CHERLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630108,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577426,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382721,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630105,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225124,
    "City": "HAIDERGARH UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630103,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630102,
    "City": "CHETTINADU TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623403,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623409,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500457,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493449,
    "City": "BAGBAHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493559,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225126,
    "City": "HAIDERGARH UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225125,
    "City": "HAIDERGARH UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382731,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382743,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609405,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574265,
    "City": "BC ROAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521175,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521178,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521457,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623406,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577223,
    "City": "HONNALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445308,
    "City": "RALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521176,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521197,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521196,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521438,
    "City": "JAGGAIAHPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609403,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628101,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628103,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847302,
    "City": "PUPRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686509,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686510,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522407,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522406,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522405,
    "City": "SATTENAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382725,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248118,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 500111,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516228,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516502,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516501,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516233,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332718,
    "City": "PATAN RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332708,
    "City": "KAWAT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332719,
    "City": "KAWAT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571427,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 639110,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758128,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573130,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622103,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670015,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673312,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333021,
    "City": "BADA GANV",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486220,
    "City": "SOHAGI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486226,
    "City": "SOHAGI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481884,
    "City": "KARANJIA MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 488443,
    "City": "RITHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416210,
    "City": "KADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416223,
    "City": "KADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754240,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766016,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 766103,
    "City": "DHARAMGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845423,
    "City": "SAHEBGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622104,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622501,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507211,
    "City": "MAHABUBABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518101,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518102,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172029,
    "City": "KUMARSAIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193302,
    "City": "KUPWARA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506345,
    "City": "MULUGU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431746,
    "City": "NANDED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212208,
    "City": "BHARWARI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689115,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 611003,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585326,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585421,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571601,
    "City": "KR PETE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508221,
    "City": "SURYAPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508280,
    "City": "SURYAPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845103,
    "City": "NARKATIAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638660,
    "City": "TIRUPPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641604,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500006,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641664,
    "City": "PALLADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641608,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400705,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522019,
    "City": "PRATHIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522017,
    "City": "PRATHIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523226,
    "City": "CHIMAKURTHY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400051,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500032,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673502,
    "City": "NADAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531021,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689548,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641667,
    "City": "PALLADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505184,
    "City": "GODAVARI KHANI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673503,
    "City": "NADAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391107,
    "City": "DABHOI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402109,
    "City": "NAGOTHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631102,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 524152,
    "City": "ALLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835303,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500264,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503120,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503112,
    "City": "Kamareddy",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631301,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631302,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631303,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631304,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143304,
    "City": "PATTI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 600052,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413402,
    "City": "VAIRAG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689106,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413412,
    "City": "VAIRAG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413403,
    "City": "VAIRAG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391770,
    "City": "SAVLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500090,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403521,
    "City": "PORVORIM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502032,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500085,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508213,
    "City": "SURYAPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500084,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600005,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682041,
    "City": "CHERANALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682037,
    "City": "KAKKANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682030,
    "City": "KAKKANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682022,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683503,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683104,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682042,
    "City": "KAKKANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508243,
    "City": "MALLEPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508248,
    "City": "MALLEPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523101,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506381,
    "City": "DORNAKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507210,
    "City": "DORNAKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509339,
    "City": "KODANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678589,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523104,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523121,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523125,
    "City": "SINGARAYAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224158,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533239,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533241,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533218,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201008,
    "City": "Delhi NCR",
    "State": "DELHI",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224127,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224284,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224228,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 223224,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 416436,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560016,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403806,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403726,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603105,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700081,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700079,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700065,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700158,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700134,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700133,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700131,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700130,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700039,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620023,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620021,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620020,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620018,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620017,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620008,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620007,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620006,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620005,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620003,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620002,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620001,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781125,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781122,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781017,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781015,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560083,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382481,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382480,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382470,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380019,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380005,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201011,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201007,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201006,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201005,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201004,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492008,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492003,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781033,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781012,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781011,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781010,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781009,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122413,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636016,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636015,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636010,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636009,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636008,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636007,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636006,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636005,
    "City": "MAMANGAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636004,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636003,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636002,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636001,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627401,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627414,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627413,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482011,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482010,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482009,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482005,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482004,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627601,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627851,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416416,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416415,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416406,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560071,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560038,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560017,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560008,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560007,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 560036,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560067,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560066,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560068,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638116,
    "City": "ODDANCHATRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743123,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743165,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743129,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743128,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743126,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743125,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600095,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600037,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600120,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600103,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600068,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600051,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700150,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221105,
    "City": "Varanasi",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221007,
    "City": "Varanasi",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221003,
    "City": "Varanasi",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201306,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603308,
    "City": "MADURANTAKAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691581,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691003,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394518,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395017,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395007,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395001,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395023,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394210,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394230,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394221,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415004,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415002,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470004,
    "City": "MAKRONIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470003,
    "City": "MAKRONIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741247,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741202,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741201,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673024,
    "City": "VELLIMADUKUNNU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673012,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673008,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581402,
    "City": "SIRSI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581401,
    "City": "SIRSI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638057,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638055,
    "City": "VIJAYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638053,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638052,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508207,
    "City": "MIRYALAGUDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673633,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673632,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673631,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673635,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144601,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127201,
    "City": "LOHARU",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834012,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834011,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834001,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682507,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682029,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682010,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682009,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682008,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682007,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682006,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682005,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682004,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682003,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682002,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682001,
    "City": "THOPPUMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678501,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678559,
    "City": "POLPULLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804404,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 831002,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613205,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613202,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613201,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695020,
    "City": "Nemom",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711313,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711302,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400043,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400087,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400076,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400072,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400059,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560065,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560064,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641670,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641668,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641659,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641655,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670011,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670010,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670009,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670008,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670014,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670013,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670004,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670002,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670001,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641016,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301001,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600093,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600083,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600033,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600026,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600017,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411036,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411013,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679513,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678762,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678597,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678595,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600085,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600032,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600025,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600022,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600018,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600016,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600015,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601301,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678702,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 600094,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600034,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600030,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600029,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600024,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600112,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600084,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600023,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600012,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600010,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600007,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600003,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600086,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600031,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600014,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600008,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600006,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641669,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641663,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641658,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641401,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642207,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642132,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642122,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642112,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136130,
    "City": "PEHWA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136129,
    "City": "PEHWA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136128,
    "City": "PEHWA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136132,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642103,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642005,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642004,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143101,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145101,
    "City": "PATHANKOT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221107,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221106,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143106,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400022,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400017,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577111,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613504,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613503,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613502,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635205,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221008,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273015,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273007,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605655,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605652,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605601,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605501,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605108,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605105,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605103,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641047,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641031,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641022,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641020,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641019,
    "City": "ANNUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384315,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208021,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208007,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208004,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678601,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678583,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171005,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411006,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628007,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628003,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628002,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628001,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628005,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642134,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642114,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642104,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642007,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689122,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560105,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641049,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641035,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641027,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641006,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403715,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403701,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403602,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501359,
    "City": "MAHESWARAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400083,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400079,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641407,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641406,
    "City": "KINATHUKADAVU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641062,
    "City": "KINATHUKADAVU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560103,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560070,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560069,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560041,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560011,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560004,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606115,
    "City": "ULUNDURPETTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606107,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122101,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122000,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733123,
    "City": "RUPAHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686022,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686020,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686015,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686014,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 122053,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122052,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122051,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122050,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562125,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122055,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600066,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686501,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686021,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686019,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686018,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686011,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686010,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382210,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380055,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140406,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 273412,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174102,
    "City": "BADDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641666,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141015,
    "City": "LUDHIANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141014,
    "City": "LUDHIANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141010,
    "City": "LUDHIANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600028,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600004,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160104,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140604,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140603,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411021,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145024,
    "City": "PATHANKOT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145023,
    "City": "PATHANKOT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140103,
    "City": "KURALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140101,
    "City": "KURALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243006,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243005,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243003,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243002,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141017,
    "City": "LUDHIANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534301,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751020,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751002,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641065,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641040,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641039,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641026,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641010,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641002,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641001,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560030,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560029,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401203,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401305,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401304,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401303,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388540,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442403,
    "City": "CHANDRAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442402,
    "City": "CHANDRAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147201,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147203,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560100,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800009,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800008,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800007,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560102,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786184,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786183,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786102,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690517,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 638103,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638812,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605110,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605107,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605102,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603127,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562107,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571404,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571403,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571402,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571401,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560104,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560040,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560023,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600126,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600100,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834009,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832109,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831014,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831013,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638002,
    "City": "ODDANCHATRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575009,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575007,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575001,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575002,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673101,
    "City": "VADAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110019,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110034,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403505,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506004,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506003,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753006,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753004,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753003,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422010,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600109,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600071,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600062,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600054,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590017,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590016,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590010,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411043,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411042,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411037,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411009,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400037,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400019,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462032,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462030,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600115,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600096,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560053,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560051,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560042,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560027,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560025,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560009,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560002,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560001,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678687,
    "City": "ALATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678686,
    "City": "ALATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560098,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560026,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462038,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600055,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641048,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641014,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638115,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638112,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638101,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680587,
    "City": "ALATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700156,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831006,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440034,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602024,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600072,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700157,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700136,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422007,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560096,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560086,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560058,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753014,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753008,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753002,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148028,
    "City": "SUNAM",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110067,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110016,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 148106,
    "City": "SUNAM",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562114,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560021,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560010,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560003,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834010,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834004,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711101,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517507,
    "City": "TIRUPATI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517506,
    "City": "TIRUPATI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517502,
    "City": "TIRUPATI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276001,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226027,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226024,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226007,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226006,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226001,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302021,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302012,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522008,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522007,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522006,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522004,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600130,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600127,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600082,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600039,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600011,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400009,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400003,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400002,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500008,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560076,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560078,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621005,
    "City": "MANACHANALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621112,
    "City": "MANACHANALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609102,
    "City": "SIRKAZHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534432,
    "City": "DENDULURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534005,
    "City": "DENDULURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638107,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638102,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638005,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638004,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638003,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638001,
    "City": "ODDANCHATRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412109,
    "City": "Lonavala",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800027,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800026,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800016,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442404,
    "City": "DURGAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673304,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673307,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673306,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560090,
    "City": "Nelamangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560089,
    "City": "Nelamangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560088,
    "City": "Nelamangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560057,
    "City": "Nelamangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411047,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110094,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110090,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110032,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500060,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834003,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500047,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500040,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400097,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400065,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400063,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400601,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411033,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500098,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500092,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621601,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500094,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500087,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500195,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500095,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500001,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625021,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625020,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625019,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625018,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625016,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625012,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625011,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625010,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625009,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625004,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625003,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625001,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248001,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248102,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508211,
    "City": "SURYAPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635809,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632602,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700106,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700098,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700097,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700091,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700064,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700046,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768020,
    "City": "GOSHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768019,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768018,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768017,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768016,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768003,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768001,
    "City": "SAMBALPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641042,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641024,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641023,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641021,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641008,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641044,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641037,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641012,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641009,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641004,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500054,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500037,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181133,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 400098,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400055,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400029,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500048,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500031,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500030,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395005,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394516,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391440,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160066,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160065,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416112,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400084,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580032,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580029,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580023,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395003,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395002,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226030,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226029,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226025,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474012,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474010,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474008,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474004,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474003,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734008,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734003,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700102,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700101,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631503,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631501,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700083,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700051,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700049,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679336,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679335,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679303,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201311,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144208,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641046,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641043,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641041,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641038,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641025,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641013,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641011,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641007,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641003,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416129,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416116,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637215,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637214,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637210,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637304,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143519,
    "City": "GURDASPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831019,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831017,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831016,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831010,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831009,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831007,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831004,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831003,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533124,
    "City": "RAJAHMUNDRY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533107,
    "City": "RAJAHMUNDRY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625106,
    "City": "MELUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614904,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 522213,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522212,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636302,
    "City": "MAMANGAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636030,
    "City": "MAMANGAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382315,
    "City": "DEHGAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641402,
    "City": "KINATHUKADAVU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641103,
    "City": "KINATHUKADAVU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160052,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160051,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160050,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160049,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160048,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160047,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160046,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160045,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160044,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160043,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160042,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160041,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160040,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160039,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160033,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160032,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160031,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160030,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201102,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181207,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 181206,
    "City": "MARH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391740,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534350,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391750,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604601,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391345,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391320,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391310,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390024,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390022,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390018,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390017,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390016,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390008,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390006,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390003,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390002,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390001,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390023,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390021,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390020,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390015,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390012,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 390011,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390010,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390007,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390005,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523227,
    "City": "KANIGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 314001,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382449,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382445,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382443,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382440,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382405,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110096,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110091,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751018,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751014,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751009,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751006,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751001,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201302,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201017,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201016,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201013,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201003,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201002,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201001,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502103,
    "City": "Siddipet",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678015,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678014,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678013,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678012,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678011,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678010,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678009,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678008,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678007,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678006,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678005,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678004,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678003,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678002,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678001,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324010,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324009,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324008,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324007,
    "City": "KOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324006,
    "City": "KOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324005,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324004,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324003,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324002,
    "City": "KOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 324001,
    "City": "KOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250005,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250002,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152004,
    "City": "Ferozepur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152003,
    "City": "Ferozepur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152002,
    "City": "Ferozepur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152001,
    "City": "Ferozepur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143528,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146024,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146023,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146022,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146021,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146002,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146001,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143521,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143520,
    "City": "GURDASPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143531,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143530,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334004,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334003,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334002,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334001,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 311802,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 311013,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334006,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151005,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151004,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151003,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151002,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151001,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 311001,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577006,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577005,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577004,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577003,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577002,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577001,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247001,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361150,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576108,
    "City": "MANIPAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576107,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576106,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576105,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576104,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576102,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576101,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678594,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678020,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678019,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678018,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678017,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145002,
    "City": "Pathankot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145001,
    "City": "Pathankot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678623,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678621,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361140,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361120,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361012,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361009,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361008,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361007,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361006,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361005,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361004,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361003,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361002,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361001,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678733,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678732,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678731,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678707,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678701,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606911,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606718,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606307,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606120,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605755,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605701,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605605,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605604,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605602,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605402,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605401,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605302,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605301,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605203,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605202,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680022,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680021,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680020,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680018,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680017,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680016,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680015,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680013,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680012,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680011,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680010,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680009,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680008,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680007,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680006,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680005,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680004,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680003,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680002,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680001,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788009,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788008,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788007,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788006,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788005,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788004,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788003,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788002,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788001,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607209,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607207,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607203,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607116,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607107,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680027,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680323,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110066,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110057,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110050,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110029,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110023,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110022,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110021,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110003,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110074,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110068,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110047,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110030,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396210,
    "City": "Daman",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411039,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411026,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411018,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382421,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382424,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411060,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411040,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411022,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110097,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110070,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110061,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110038,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110037,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673032,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673018,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673017,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673016,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673013,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673007,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673006,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673004,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673003,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673002,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673001,
    "City": "Calicut",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302039,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302032,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302023,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302016,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302014,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302013,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302000,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600092,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600089,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600087,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600078,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826001,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202002,
    "City": "ALIGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202001,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812007,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812006,
    "City": "AKBARNAGAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812005,
    "City": "AKBARNAGAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812004,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812003,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812002,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812001,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602104,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 602101,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 600125,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600116,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826005,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826004,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826003,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828101,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826015,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828116,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828112,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828111,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828110,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828106,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828105,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828104,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721306,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721305,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721304,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721303,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721302,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721301,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828302,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828301,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828135,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828132,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828130,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828129,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828127,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828126,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828121,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828119,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827006,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721145,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827004,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827003,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827002,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827001,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396195,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396191,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396185,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781039,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781035,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781034,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781031,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781025,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781014,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781013,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411058,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411038,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411029,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530049,
    "City": "GAJUWAKA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530046,
    "City": "GAJUWAKA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530032,
    "City": "GAJUWAKA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530031,
    "City": "GAJUWAKA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530012,
    "City": "GAJUWAKA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613501,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613403,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613401,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613010,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613009,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613008,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613007,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613006,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613005,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613004,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613003,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613002,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613001,
    "City": "Thanjavur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517004,
    "City": "Chittoor",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517003,
    "City": "Chittoor",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517002,
    "City": "Chittoor",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517001,
    "City": "Chittoor",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502205,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502002,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502001,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247667,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577205,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577204,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577201,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711409,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711405,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431604,
    "City": "NANDED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243502,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243202,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243123,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243122,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243004,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243001,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560062,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500080,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500029,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500020,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600064,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600063,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600047,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600045,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581106,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 678598,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678593,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678591,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678582,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832108,
    "City": "GAMHARIAJH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388421,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387002,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387001,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140133,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140123,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248008,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248005,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140118,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140117,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140116,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140115,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140114,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577522,
    "City": "Challakere",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411111,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110058,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110046,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110045,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110010,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695032,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695014,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695012,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695006,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695002,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641045,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641036,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641028,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641018,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641015,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502324,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500046,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600113,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600090,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600041,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600036,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600020,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400606,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400604,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400603,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400602,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400028,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400016,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600081,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600057,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600019,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600114,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600088,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600061,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600042,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638012,
    "City": "ODDANCHATRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638011,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638009,
    "City": "ODDANCHATRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411041,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751030,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751019,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625017,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625014,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625007,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625002,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560082,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600077,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600058,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600101,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600080,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600076,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600049,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600038,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530043,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530040,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530017,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600098,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600053,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600050,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700114,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700113,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700112,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700111,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700110,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700109,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400605,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560095,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560047,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560034,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560077,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560049,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560080,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560054,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560031,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560022,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560015,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560014,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560013,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560012,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560050,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560019,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560018,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641687,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641605,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641601,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781019,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781018,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800028,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800023,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800022,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800021,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800017,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800015,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800001,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570021,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570019,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570015,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570007,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572107,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572106,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411020,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411007,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411003,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680306,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680014,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131101,
    "City": "SONIPAT",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524314,
    "City": "NELLORE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524002,
    "City": "NELLORE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524001,
    "City": "NELLORE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642206,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642201,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642126,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560099,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560081,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132102,
    "City": "SAMALKHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713325,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713304,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 562101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 678596,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678632,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678631,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678613,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403801,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403711,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700104,
    "City": "AMTALA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700063,
    "City": "AMTALA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 642203,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642154,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642128,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642113,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642111,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500071,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500061,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500025,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403804,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403803,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403802,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834005,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786192,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786185,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786170,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786148,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786147,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786146,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786145,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786126,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786125,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796025,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796017,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796014,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796009,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796004,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736123,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736122,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736121,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416520,
    "City": "VENGURLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521101,
    "City": "GANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520011,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520010,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520004,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520003,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520002,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712258,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711204,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711203,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711202,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711201,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711227,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711107,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711106,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521156,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500079,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500070,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440023,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573211,
    "City": "HOLENARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690544,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800020,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800019,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800006,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800005,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800004,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800003,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248197,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248007,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140301,
    "City": "KHARAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131029,
    "City": "Kundli",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171006,
    "City": "SANJAULI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221002,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523183,
    "City": "MEDARAMETLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226010,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600128,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600122,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600056,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403526,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403502,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688523,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 535101,
    "City": "CHEEPURUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535128,
    "City": "CHEEPURUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680509,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411046,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562157,
    "City": "BAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562149,
    "City": "BAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560063,
    "City": "BAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507120,
    "City": "ENKOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503217,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 828124,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828123,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828122,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500042,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500021,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500011,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500009,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562111,
    "City": "DABASPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501218,
    "City": "KATTEDAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390019,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390014,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390013,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390009,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390004,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575017,
    "City": "ULLALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152107,
    "City": "MALOUT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152101,
    "City": "MALOUT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141101,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793021,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793019,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793018,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793014,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793011,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793010,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793007,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793006,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793003,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141106,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141108,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403401,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577555,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577524,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577517,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403103,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577502,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577501,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412308,
    "City": "MANJARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412307,
    "City": "MANJARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785686,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785685,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505185,
    "City": "SULTANABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403529,
    "City": "BICHOLIM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403504,
    "City": "BICHOLIM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110054,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110007,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577146,
    "City": "JAVAGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181152,
    "City": "BATHINDI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713347,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250004,
    "City": "MEERUT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627117,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627501,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638007,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638006,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638183,
    "City": "LAKSHMINAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638010,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638701,
    "City": "KANGEYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638051,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 562105,
    "City": "SHIDLAGHATTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535558,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535573,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638459,
    "City": "NAMBIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521136,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680673,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403523,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403508,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403507,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562130,
    "City": "TAVAREKERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412207,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394370,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695582,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562109,
    "City": "BIDADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122021,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122017,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122016,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122015,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122007,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122006,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122005,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136135,
    "City": "SHAHABAD",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211003,
    "City": "KADGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637211,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637209,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637205,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635101,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635002,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635001,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403402,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403204,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403203,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403110,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403108,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403104,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635115,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506012,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506008,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506007,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506006,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506005,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506002,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 211019,
    "City": "SAHSON",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415003,
    "City": "SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415001,
    "City": "SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342007,
    "City": "MANDOR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530051,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530047,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530029,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530028,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530027,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741501,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637303,
    "City": "SANKAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637302,
    "City": "SANKAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637301,
    "City": "SANKAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534427,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534425,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400054,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400052,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400050,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500067,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500010,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500015,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530044,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530026,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530015,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530014,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530011,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530005,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603314,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603303,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603107,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560079,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560072,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560055,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560044,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560020,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400067,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400082,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400081,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400080,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400089,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400077,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400075,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411048,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440030,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440029,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 440026,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440017,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440014,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440004,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832110,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831018,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831012,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560085,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560028,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636502,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174301,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121002,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560093,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560075,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560061,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560091,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560073,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533238,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474020,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282004,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282003,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689542,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110028,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110015,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110012,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680503,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455001,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641653,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 641107,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143107,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 673026,
    "City": "PANDEERANKAVU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673019,
    "City": "PANDEERANKAVU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673014,
    "City": "PANDEERANKAVU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302028,
    "City": "ACHROL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636140,
    "City": "AYOTHIYAPATINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636122,
    "City": "AYOTHIYAPATINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636106,
    "City": "AYOTHIYAPATINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636014,
    "City": "AYOTHIYAPATINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620012,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174302,
    "City": "BARUHI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587103,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587102,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587101,
    "City": "NAVNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421202,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637505,
    "City": "MOREPALAYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637501,
    "City": "MOREPALAYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509152,
    "City": "ALAMPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133207,
    "City": "DOSARKA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133103,
    "City": "DOSARKA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713305,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713303,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713302,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713301,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641652,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641607,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145029,
    "City": "JUGIYAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146106,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146105,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146102,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400046,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400044,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226009,
    "City": "SARIJANI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226008,
    "City": "SARIJANI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248198,
    "City": "HARBERTPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641114,
    "City": "Thondamuthur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641109,
    "City": "Thondamuthur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641101,
    "City": "Thondamuthur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124112,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124111,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110064,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110027,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400105,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400099,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400096,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400093,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400069,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400057,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400061,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400058,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400056,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400053,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400049,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530035,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530024,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530020,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530016,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530004,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530001,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421304,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421301,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782003,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782002,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782001,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734007,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734006,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734004,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734001,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500097,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500069,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500058,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700155,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700132,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700129,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700128,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700127,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700126,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700125,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700124,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 737102,
    "City": "GANGTOK",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 737101,
    "City": "GANGTOK",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673020,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673011,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673010,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673009,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673005,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431606,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431605,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431603,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431602,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431601,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603211,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603210,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603202,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421005,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421004,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421003,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421002,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421001,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495009,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495006,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495005,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495004,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495003,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495002,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495001,
    "City": "Bilaspur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700072,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734010,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734009,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734005,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734002,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305008,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305007,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600048,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305023,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532410,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532408,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532404,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532403,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532402,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532401,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532005,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532001,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305005,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305004,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305003,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305002,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305001,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572105,
    "City": "Tumkur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572104,
    "City": "Tumkur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572103,
    "City": "Tumkur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572102,
    "City": "Tumkur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572101,
    "City": "Tumkur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444605,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444604,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444603,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444602,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444601,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760010,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760009,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760008,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760006,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760005,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760004,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760003,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760002,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760001,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743177,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743176,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743120,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827014,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827013,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827012,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827011,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827010,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827009,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743155,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743144,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743133,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743127,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743122,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743101,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700123,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700122,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700121,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700120,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700119,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700118,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700117,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700116,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700115,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532484,
    "City": "SRIKAKULAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846006,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846005,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846004,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846003,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846002,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846001,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743188,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786005,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786004,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786003,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786002,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786001,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700021,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700014,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700013,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700012,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700009,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627251,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627201,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627358,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627357,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627356,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627352,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416008,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416007,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416006,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416005,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416004,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416003,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416002,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416001,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413255,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490011,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490009,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490008,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490007,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490006,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490005,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490004,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490003,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490002,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490001,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416234,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413007,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413006,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503230,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503175,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503174,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503003,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503002,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503001,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140401,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140601,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140417,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535215,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535214,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535006,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535005,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535004,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535003,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535002,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535001,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490026,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490023,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490020,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413224,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416013,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416012,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627015,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627013,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627012,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627011,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627010,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627009,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627008,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627007,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627006,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627005,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627004,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627003,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627002,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627001,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535218,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416122,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416119,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533007,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533005,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533004,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533003,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533001,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506370,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506015,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506011,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506010,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506009,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506001,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422003,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422002,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422001,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524004,
    "City": "Nellore",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524003,
    "City": "Nellore",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505527,
    "City": "Karimnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505469,
    "City": "Karimnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211017,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211014,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211006,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211005,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 211001,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121206,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 121015,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 121013,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121012,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121008,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121007,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121003,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500089,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201309,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201307,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201303,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201301,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313016,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753021,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753013,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753012,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753011,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753010,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753009,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753007,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753005,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753001,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313024,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431006,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431005,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431004,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431003,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431002,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431001,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474015,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474011,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474006,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474005,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474002,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388365,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388360,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388355,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388345,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388340,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388335,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388330,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388325,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388306,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388320,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388315,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388121,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388120,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388110,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388210,
    "City": "UMRETH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388001,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387375,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462027,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387310,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462010,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462008,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462004,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462001,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505002,
    "City": "Karimnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505001,
    "City": "Karimnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422101,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422011,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422006,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131027,
    "City": "Sonipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131021,
    "City": "Sonipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131001,
    "City": "Sonipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124412,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124021,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124010,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124009,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124008,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124007,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124006,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124005,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124004,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124003,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124002,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124001,
    "City": "Rohtak",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244001,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244103,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244102,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248160,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248146,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248126,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248115,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248010,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248006,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248003,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492007,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492006,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492005,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492004,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412113,
    "City": "Telegaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412106,
    "City": "Telegaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412101,
    "City": "Telegaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410507,
    "City": "Telegaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410506,
    "City": "Telegaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421203,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421201,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132117,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132116,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132111,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132001,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125044,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132041,
    "City": "KARNAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132037,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147005,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147004,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147003,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147002,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147001,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147103,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122018,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122013,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122012,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122011,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523286,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523262,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523225,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523211,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523182,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523002,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523001,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500082,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500041,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500026,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500016,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500003,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201019,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201014,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201012,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201010,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201000,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400094,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400088,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400085,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400074,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125006,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125005,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125004,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125003,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125002,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125001,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482020,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482008,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482007,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482006,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482001,
    "City": "Jabalpur",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401107,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401106,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401105,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401104,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401101,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400071,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122233,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122232,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122231,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122230,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122219,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122218,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122217,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122216,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122215,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122214,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122213,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122211,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122210,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122209,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122206,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122203,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110089,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110082,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110042,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110040,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110039,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799010,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799008,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799004,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799001,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431210,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431108,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411057,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411054,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768006,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768004,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768002,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784105,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784104,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784028,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784001,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784010,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444005,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444004,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444003,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444002,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444001,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201305,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201304,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201300,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784506,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784501,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784154,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842005,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842004,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842003,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842002,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842001,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823005,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823004,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823003,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823002,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823001,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786010,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800029,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800025,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800024,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800018,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800014,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800013,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800012,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800011,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800010,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623318,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614728,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609124,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 608801,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607404,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607402,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607401,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607307,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607113,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607111,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607109,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607108,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607106,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607105,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607102,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607051,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607006,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607005,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607004,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607003,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607002,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607001,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606914,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518503,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518502,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518501,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110403,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110402,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110055,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110006,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110002,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400102,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400060,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442102,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442001,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380026,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380025,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380024,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380023,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380021,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380018,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380000,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110092,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691021,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691020,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691019,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688014,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688013,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688012,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688011,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688009,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688008,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688007,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688006,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688005,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688004,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688003,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688002,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688001,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180020,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180015,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180012,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180011,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180010,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180006,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180004,
    "City": "Jammu",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125053,
    "City": "FATEHABAD HR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125052,
    "City": "AGROHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125051,
    "City": "BHUNA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125050,
    "City": "FATEHABAD HR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691013,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691012,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691011,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691010,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691009,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691008,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691007,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691006,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691004,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691002,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691001,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440027,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440024,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440018,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440012,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440003,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122207,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122204,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440013,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440011,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440010,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440007,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440006,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440001,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580031,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580030,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580028,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580026,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580025,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580024,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580022,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580021,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580020,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603112,
    "City": "Chennai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600119,
    "City": "Chennai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249411,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249410,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249408,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249407,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249406,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249405,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249404,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249402,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249401,
    "City": "Haridwar",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110079,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110078,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110077,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110075,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403731,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403601,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403713,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403712,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403709,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403707,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403604,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403718,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403720,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403725,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227106,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226020,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226019,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226016,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226015,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226013,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110401,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110088,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110087,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110063,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110056,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412105,
    "City": "CHAKAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122001,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410501,
    "City": "CHAKAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403729,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171009,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171004,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171003,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171002,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171001,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411014,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500055,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600105,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382418,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382415,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382410,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382352,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382350,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382346,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382325,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686104,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686102,
    "City": "KUTTANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686101,
    "City": "KUTTANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690551,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689581,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689110,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689107,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689105,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689103,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689102,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689101,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686548,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125047,
    "City": "AGROHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382475,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382442,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413512,
    "City": "Latur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670012,
    "City": "VALIYANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670005,
    "City": "VALIYANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690502,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690110,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690108,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690106,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690104,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690103,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690102,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690101,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127114,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127111,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127031,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127021,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127020,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521225,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521212,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520016,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520015,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520012,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520009,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520001,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160071,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160070,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160069,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160068,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160067,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160064,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160063,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160062,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160061,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160060,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160059,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160058,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160057,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160056,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160055,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160054,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160053,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140308,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452014,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452013,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452012,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452009,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503311,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503307,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503225,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503224,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249201,
    "City": "Rishikesh",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695040,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695039,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695036,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695035,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695034,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695031,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695029,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695027,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695026,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695024,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695023,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695022,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695021,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695019,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695018,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695011,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695009,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695008,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695007,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695001,
    "City": "Kazhakkoottam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400104,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741245,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741235,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741234,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180018,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180016,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180005,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180002,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180001,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445001,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136027,
    "City": "Kaithal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400090,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400062,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 345001,
    "City": "JAISALMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518401,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333031,
    "City": "Pilani",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335513,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335512,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431401,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743166,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743145,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743136,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743135,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743134,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741251,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741250,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741248,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741222,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636705,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636704,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636702,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636701,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700108,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700090,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700076,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700058,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700057,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700056,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700050,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700037,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700036,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700035,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700005,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700004,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700003,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700002,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411011,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411002,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411001,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333001,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123401,
    "City": "Rewari",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303303,
    "City": "DAUSA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322001,
    "City": "Sawaimadhopur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413411,
    "City": "Barshi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413401,
    "City": "Barshi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416115,
    "City": "Ichalkaranji",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148001,
    "City": "SANGRUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174103,
    "City": "Baddi",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173205,
    "City": "Baddi",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444505,
    "City": "Washim",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495671,
    "City": "Champa",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480661,
    "City": "Seoni",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470002,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470001,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494001,
    "City": "Jagdalpur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735221,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532222,
    "City": "Palasa",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532221,
    "City": "Palasa",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523166,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523157,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523156,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523155,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515671,
    "City": "Dharmavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522202,
    "City": "Tenali",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522201,
    "City": "Tenali",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456010,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415110,
    "City": "Karad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583104,
    "City": "Bellary",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583103,
    "City": "Bellary",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583102,
    "City": "Bellary",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583101,
    "City": "Bellary",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584101,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 561203,
    "City": "Doddaballapura",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515201,
    "City": "HINDUPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572201,
    "City": "Tiptur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151212,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505302,
    "City": "Sircilla",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505301,
    "City": "Sircilla",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151204,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151203,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485001,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322201,
    "City": "Gangapur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631701,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631601,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631561,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631552,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631551,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631502,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580011,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580009,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580008,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580007,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580006,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580005,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580004,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580003,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580002,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 580001,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673106,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673103,
    "City": "VADAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673102,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521366,
    "City": "Machilipatnam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521003,
    "City": "Machilipatnam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521002,
    "City": "Machilipatnam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521001,
    "City": "Machilipatnam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516269,
    "City": "Rayachoty",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400039,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400038,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400032,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400023,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400021,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400020,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400005,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400001,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480002,
    "City": "Chindwara",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480001,
    "City": "Chindwara",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335501,
    "City": "Bhadra",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335523,
    "City": "Nohar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134119,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134117,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134116,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134115,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134114,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134113,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134112,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134109,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134108,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 126102,
    "City": "JIND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680602,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680514,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680512,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733101,
    "City": "BALURGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733134,
    "City": "RAIGUNJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733130,
    "City": "RAIGUNJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736182,
    "City": "Jaigaon",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735208,
    "City": "Jaigaon",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680102,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680101,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679562,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680502,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680104,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680508,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680507,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680506,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680505,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483501,
    "City": "KATNI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683574,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683572,
    "City": "ALUVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683108,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683106,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683102,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683101,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786602,
    "City": "Duliajan",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673501,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673308,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 327001,
    "City": "Banswara",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335511,
    "City": "Bhadra",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416510,
    "City": "Sawantwadi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400031,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400015,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400014,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400012,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442405,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442401,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456006,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456003,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456001,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678546,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678542,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678541,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678510,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678508,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678706,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678705,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678704,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678703,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678689,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678685,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678684,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678683,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678682,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678681,
    "City": "Vadakkenchery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411028,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335001,
    "City": "Sriganganagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496001,
    "City": "Raigarh",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495677,
    "City": "Korba",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534260,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534250,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533253,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533242,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 328001,
    "City": "Dholpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331304,
    "City": "Taranagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534281,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534280,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534275,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209801,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303702,
    "City": "Chomu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323001,
    "City": "Bundi",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306401,
    "City": "Pali",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209862,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507118,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507115,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507101,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500073,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500045,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500034,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148026,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125056,
    "City": "Sirsa",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125055,
    "City": "Sirsa",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608102,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608002,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608001,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586105,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586104,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586103,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586102,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586101,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125110,
    "City": "Sirsa",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462016,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462024,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608602,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608601,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608502,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608501,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462011,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678633,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 508004,
    "City": "Nalgonda",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508002,
    "City": "Nalgonda",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508001,
    "City": "Nalgonda",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462043,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462026,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679101,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678642,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679103,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679105,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732206,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679523,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679522,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679521,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679514,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 679512,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679501,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679302,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679122,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679121,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 173213,
    "City": "Solan",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173212,
    "City": "Solan",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173211,
    "City": "Solan",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141005,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141004,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141001,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577102,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577101,
    "City": "CHIKKAMAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176215,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176213,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176057,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176052,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173229,
    "City": "Solan",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176219,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176218,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176217,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500059,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500036,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500024,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500023,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500002,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732103,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732102,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732101,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601295,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601214,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680588,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508206,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 678622,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382345,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380016,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380004,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380003,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380002,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380001,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678552,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678551,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678102,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678101,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678531,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678503,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678104,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226031,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226026,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226022,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226021,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505460,
    "City": "Jagtial",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505453,
    "City": "Jagtial",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505452,
    "City": "Jagtial",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505327,
    "City": "Jagtial",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400103,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400092,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400091,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400068,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125034,
    "City": "HANSI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125033,
    "City": "HANSI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143505,
    "City": "Batala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143406,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143401,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143117,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143115,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 122234,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122229,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122228,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122227,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122226,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122225,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122224,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122223,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122220,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122208,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122022,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122010,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122009,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122008,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122003,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122002,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304001,
    "City": "Tonk",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531031,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531002,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531001,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413307,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388888,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110084,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110036,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110033,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110009,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284128,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284127,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284120,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284003,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284002,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284001,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284135,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211004,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211002,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509301,
    "City": "JADCHERLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416236,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413115,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 521151,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781037,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781036,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781029,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781027,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781026,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781023,
    "City": "Sonapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781022,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781006,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533450,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533440,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533437,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411052,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411051,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411030,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695605,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211016,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211015,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211012,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211011,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492010,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492009,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492001,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413304,
    "City": "PANDHARPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500050,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500019,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244713,
    "City": "KASHIPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584128,
    "City": "SINDHANUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413305,
    "City": "MANGALVEDHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583211,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583203,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583202,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583201,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232101,
    "City": "MUGHAL SARAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500062,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500056,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534320,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575018,
    "City": "BANTWAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208019,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208018,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208017,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208009,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208005,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208003,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208002,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462047,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462046,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416410,
    "City": "MIRAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110059,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395004,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394107,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394105,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394101,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395009,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679309,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713219,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713218,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713217,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713216,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713215,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713214,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713213,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713212,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713211,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713210,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713209,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713208,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713207,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713206,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713205,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713204,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713203,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713202,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713201,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452017,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452015,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452007,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452006,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452005,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452004,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452003,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452002,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282005,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282001,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302025,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302017,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302011,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302024,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302019,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302006,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302005,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632406,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632405,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632404,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632403,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632402,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632401,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413501,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632503,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632509,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534123,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534122,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632515,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632513,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573202,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573201,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800002,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713149,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713124,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713104,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713103,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713102,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713101,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801501,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627759,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828109,
    "City": "GOVINDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147101,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121101,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121006,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121004,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301701,
    "City": "NEEMRANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413520,
    "City": "AUSA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683511,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632311,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632301,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682309,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682506,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700151,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700149,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700148,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700147,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700146,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700145,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700144,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700103,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400078,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400042,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400706,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400614,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491441,
    "City": "RAJNANDGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673522,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682306,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682305,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682301,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531084,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531113,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531117,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531116,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534211,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751025,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751022,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751010,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751007,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751004,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751031,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751026,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751024,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751023,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751021,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751016,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751005,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412114,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411062,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411044,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411035,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411019,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462042,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360024,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360004,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360002,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799015,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799014,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799012,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799009,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799007,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799006,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799005,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799003,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799002,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504208,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504303,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504302,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504301,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421503,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421502,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421501,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273016,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273010,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273009,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273008,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273005,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462041,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462021,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607308,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462023,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201310,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 201308,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127306,
    "City": "ATELA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422009,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422008,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422005,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421506,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421505,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452016,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452010,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700159,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700089,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700080,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700077,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700074,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700059,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700055,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700052,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700048,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700030,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700028,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582103,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582102,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582101,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160103,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160038,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160037,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160036,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160035,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160025,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160024,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160023,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160017,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160016,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160015,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160014,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160012,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160011,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160010,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160006,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160005,
    "City": "CHANDIGARH",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563115,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563114,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563122,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563120,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603405,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603111,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603109,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603106,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603101,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603003,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603002,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603001,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121010,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121009,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121005,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121001,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631606,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110049,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110017,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535592,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535591,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535579,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110080,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110062,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110020,
    "City": "OKHLA",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221108,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221011,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221005,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221004,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410218,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410210,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410209,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384004,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384003,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384002,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384001,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440035,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440032,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440009,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440008,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440002,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522503,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522502,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522501,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712235,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712233,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712232,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122004,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721101,
    "City": "MIDNAPORE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683578,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530048,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530045,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530042,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530041,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631051,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828114,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828113,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509216,
    "City": "SHADNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571325,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571311,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571304,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570301,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570026,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570025,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570023,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570022,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570014,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570009,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570008,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570024,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570011,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570010,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570005,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570004,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570001,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249403,
    "City": "HARIDWAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301705,
    "City": "SHAHJHAPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560060,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560059,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560056,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560039,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688538,
    "City": "PATHIRAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688525,
    "City": "CHERTHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688522,
    "City": "PATHIRAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688521,
    "City": "PATHIRAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638316,
    "City": "LAKSHMINAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638301,
    "City": "LAKSHMINAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403006,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403005,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403206,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403202,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403201,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110018,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577541,
    "City": "SIRIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577520,
    "City": "SIRIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638104,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211013,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 400036,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400035,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400034,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400026,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400008,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400007,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400006,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400004,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614206,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614303,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614201,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531019,
    "City": "LANKELAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403002,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500093,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500091,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500086,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500075,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641603,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641602,
    "City": "TIRUPUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413534,
    "City": "BHOOM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500065,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500064,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500053,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131028,
    "City": "Kundli",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638056,
    "City": "VIJAYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638752,
    "City": "VIJAYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421306,
    "City": "TISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226003,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393001,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393010,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393006,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403721,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403716,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403708,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393003,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133206,
    "City": "SADHAURA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 250110,
    "City": "MODIPURAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562102,
    "City": "VIJAYAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201009,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416101,
    "City": "JAYSINGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282010,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282007,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380014,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380013,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380009,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380027,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380063,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380061,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380052,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605113,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605051,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632014,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632013,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632012,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632011,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632010,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632009,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632008,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632007,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632006,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632005,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632004,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632003,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632002,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632001,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533106,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533105,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533104,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533103,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533102,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533101,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605111,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605104,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605101,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605014,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605013,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605012,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605011,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605010,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605009,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605008,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605007,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605006,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605005,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605004,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605003,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605002,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605001,
    "City": "PONDICHERRY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517504,
    "City": "Tirupati",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517503,
    "City": "Tirupati",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517501,
    "City": "Tirupati",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144027,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144021,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143501,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132103,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132106,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132140,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132110,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342011,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342010,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342009,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342008,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342006,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342005,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342004,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342003,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342002,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342001,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360021,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360009,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360008,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360007,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360006,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360005,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360003,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360001,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313013,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313011,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313004,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313003,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313002,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313001,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400710,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400709,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400703,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400700,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632114,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632113,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632110,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632109,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632108,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632107,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632105,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632104,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632103,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632102,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632101,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632059,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632058,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632057,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632055,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632054,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632053,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632211,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571130,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570027,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570020,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570018,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570017,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570016,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570013,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570012,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570006,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570003,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570002,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400101,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400066,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110609,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110608,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110607,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110606,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110605,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110604,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110603,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110602,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110601,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110512,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110511,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110510,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110505,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110504,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110503,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110502,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110501,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110302,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110301,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110125,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110124,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110122,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110121,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110120,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110119,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110118,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110117,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110116,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110115,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110114,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110113,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110112,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110110,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110109,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110108,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110107,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110106,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110105,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110104,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110103,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110102,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110101,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110098,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110069,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110060,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110008,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110005,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560106,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560084,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560043,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560033,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110509,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110508,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110507,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110506,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110076,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110044,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110025,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144012,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144011,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144009,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144008,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144007,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144004,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144002,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143022,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143021,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143105,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143104,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143006,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143005,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143004,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143003,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143002,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143001,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143008,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571186,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831011,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831005,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831001,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695041,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695038,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695037,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695033,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500066,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500063,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500022,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500012,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500004,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571606,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600107,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600106,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600102,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600040,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695566,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695099,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686637,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686631,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686630,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686562,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686561,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686560,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686008,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635126,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635119,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635114,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635110,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635109,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635103,
    "City": "Hosur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302033,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302022,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110085,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636204,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636203,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636201,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502307,
    "City": "ISNAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502305,
    "City": "ISNAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515661,
    "City": "DHARMAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620019,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620014,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620013,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620011,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620010,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620004,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401210,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401209,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401208,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401207,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401205,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401202,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401201,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221109,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221103,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221102,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221010,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221001,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834007,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834002,
    "City": "Ranchi",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141011,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141009,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141008,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141007,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141000,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575016,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575015,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575013,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575012,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575011,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575010,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575008,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575006,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575005,
    "City": "Mangalore",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575004,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575003,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695025,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695017,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695016,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695015,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695013,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695010,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695005,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695004,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695003,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110011,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110004,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110001,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110052,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110035,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110026,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560037,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144211,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208014,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208012,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603209,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603204,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603203,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208027,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208022,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208006,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695028,
    "City": "Venjaramoodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560035,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500005,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211010,
    "City": "NAINI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 211009,
    "City": "NAINI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211008,
    "City": "NAINI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 211007,
    "City": "NAINI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227105,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226028,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144023,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144005,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248171,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248110,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248002,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781038,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781028,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600073,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600059,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600046,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834008,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 834006,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560048,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462003,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 462002,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743268,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743263,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743233,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743223,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743222,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560097,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560094,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560092,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400033,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400027,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400010,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743272,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627354,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571302,
    "City": "NANJANAGUDU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571301,
    "City": "NANJANAGUDU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576103,
    "City": "MANIPAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641654,
    "City": "RAYARKOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110093,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273014,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273013,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273012,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273006,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273004,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273003,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302003,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302002,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302001,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500039,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134107,
    "City": "PANCHKULA",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226018,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226004,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641034,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641030,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641029,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641017,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585403,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585402,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585401,
    "City": "BIDAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560087,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500068,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743248,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743234,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743221,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636013,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636011,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 560052,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 208026,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208025,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208024,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208020,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208016,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208015,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208013,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208010,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208008,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637504,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637502,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636308,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636307,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606302,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410206,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824231,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403527,
    "City": "PERNEM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403524,
    "City": "PERNEM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403517,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403516,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403510,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403509,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413517,
    "City": "UDGIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734014,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734013,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734012,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734011,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400095,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400064,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250003,
    "City": "MEERUT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110051,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110031,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751017,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700006,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501505,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181101,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180009,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180003,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474009,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474007,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474001,
    "City": "GWALIOR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110086,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110083,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110081,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110041,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110073,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110072,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110071,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110043,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226017,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226011,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226005,
    "City": "LUCKNOW",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312021,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312001,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585328,
    "City": "BHALKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 482003,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 482002,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123001,
    "City": "NARNAUL",
    "State": "HARYANA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 535216,
    "City": "BOGAPPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 600110,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600099,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600060,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333026,
    "City": "CHIRAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505187,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380060,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380059,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380058,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380054,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600108,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600104,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600079,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600021,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600013,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600009,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600001,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577599,
    "City": "HIRIYUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577598,
    "City": "HIRIYUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585105,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585104,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585103,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585102,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585101,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144006,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144003,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144001,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144024,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144022,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785701,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785640,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785665,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380050,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380028,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380022,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380008,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500044,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500027,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500013,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673601,
    "City": "MUKKAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518301,
    "City": "ADONI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761020,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761002,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760007,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735121,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735102,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735101,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736101,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534235,
    "City": "AKIVIDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534199,
    "City": "AKIVIDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534237,
    "City": "AKIVIDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403722,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403710,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403703,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301019,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736179,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736156,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515775,
    "City": "PAMIDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515774,
    "City": "PAMIDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515731,
    "City": "PAMIDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641033,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641005,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534112,
    "City": "NALLAJERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680553,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680555,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431136,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431133,
    "City": "WALUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625015,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625008,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625006,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625005,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534134,
    "City": "ATTILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392002,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392001,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562158,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500074,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500035,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509002,
    "City": "Mahabubnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509001,
    "City": "Mahabubnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380007,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380006,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380051,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380015,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392160,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392240,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392012,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392011,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392020,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392015,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711110,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711109,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711108,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711105,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711104,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711103,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711102,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440033,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440028,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440025,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440022,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440021,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440020,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440019,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440016,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440015,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440005,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400708,
    "City": "Navi Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400701,
    "City": "Navi Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400608,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400607,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711114,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711113,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711112,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140111,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140108,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140001,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562145,
    "City": "HOSUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686041,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686039,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686037,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686017,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686016,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686013,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686012,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686007,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686006,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686005,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686004,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686003,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686002,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686001,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690572,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534207,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534102,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534101,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682034,
    "City": "CHERANALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682033,
    "City": "ERNAKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682027,
    "City": "CHERANALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796015,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796012,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796008,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796007,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796005,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796001,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534198,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534197,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590018,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590012,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590011,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590009,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590008,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590007,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590006,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590005,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590004,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590003,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590002,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590001,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686533,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686531,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140113,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500057,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500028,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690559,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690537,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690532,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690516,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690514,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690513,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690512,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690511,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690510,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690509,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690508,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690507,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690506,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413005,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413004,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413003,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413002,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413001,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534204,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534203,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534202,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534201,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534186,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515722,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515721,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515701,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515005,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515004,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515003,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515002,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515001,
    "City": "ANANTAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769032,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 769016,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769015,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769014,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769013,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769012,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769011,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769010,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769009,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769008,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769007,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769006,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769005,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769004,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769003,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769002,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769001,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518007,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518006,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518005,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518004,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518003,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518002,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518001,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769043,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769042,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603408,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603122,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 642107,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642106,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642120,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642008,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642006,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642003,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642002,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642001,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602114,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602112,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602111,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602031,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602030,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602025,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602023,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602022,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 602021,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602004,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602003,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602002,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 602001,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601304,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601303,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 601302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603120,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603119,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603117,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 603115,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 533202,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533201,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642205,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160102,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160101,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160034,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160029,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160028,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160027,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160026,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160022,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160021,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160020,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160019,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160018,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160013,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160009,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160008,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160007,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160004,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160003,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160002,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160001,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522509,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226023,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226014,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226012,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226002,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751029,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751027,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751015,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751013,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751012,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751011,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751008,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751003,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 166031,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160106,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133006,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133005,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133004,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134003,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134002,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682036,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682035,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682032,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682031,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682028,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682025,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682023,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682020,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682019,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682018,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682017,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682016,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682015,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682014,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682013,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682012,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682011,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400030,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400025,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400018,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400013,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400011,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110095,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110053,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302020,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302018,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302010,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302009,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302008,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302007,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133001,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134007,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134005,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522013,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522010,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522003,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522002,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522001,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521139,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521137,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521134,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521108,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521104,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521103,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521102,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520014,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520013,
    "City": "VIJAYAWADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520008,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520007,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520006,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 520005,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530039,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530038,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530037,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530036,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530034,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530033,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530030,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530025,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530023,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530022,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530021,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530019,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530013,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530010,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530006,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530003,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530002,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500072,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411034,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411027,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411017,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411012,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411045,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411010,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411008,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411100,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411032,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411031,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411015,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411061,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395010,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395008,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395006,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522034,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522015,
    "City": "PRATHIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643005,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643004,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643003,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643002,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643001,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643215,
    "City": "Ooty",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793022,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793017,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793016,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793012,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793009,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793008,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793005,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793004,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793002,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793001,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135103,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 135102,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135101,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135004,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135003,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135002,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135001,
    "City": "YAMUNANAGAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282006,
    "City": "Agra",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 282002,
    "City": "Agra",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136156,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136131,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136119,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136118,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534007,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534006,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534003,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534002,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534001,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132118,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534475,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534437,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521105,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516293,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516162,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516004,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516003,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516002,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516001,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500076,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500017,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500007,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208023,
    "City": "Kanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208011,
    "City": "Kanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 208001,
    "City": "Kanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781032,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781024,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781021,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781020,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781016,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781008,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781007,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781005,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781004,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781003,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781001,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452018,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452011,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452008,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452001,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503322,
    "City": "Nizamabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505212,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505211,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505210,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505209,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505208,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507004,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507003,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507002,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507001,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505215,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142001,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147301,
    "City": "Mandigovindgarh",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141402,
    "City": "Mandigovindgarh",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141401,
    "City": "Mandigovindgarh",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 242306,
    "City": "Shahijahanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 242226,
    "City": "Shahijahanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 242001,
    "City": "Shahijahanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332001,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321001,
    "City": "Bharatpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177005,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177001,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177401,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700088,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700060,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700053,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700043,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700038,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700034,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700027,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700023,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700022,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700008,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637003,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637002,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637001,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711403,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400615,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400610,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680594,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637409,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637405,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637019,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425001,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142043,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384170,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384160,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144402,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144401,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144409,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184104,
    "City": "Kathua",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184103,
    "City": "Kathua",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184102,
    "City": "Kathua",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184101,
    "City": "Kathua",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142027,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142021,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142013,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141116,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141013,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141012,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141006,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141003,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141002,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414001,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500083,
    "City": "Hyderabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500051,
    "City": "Hyderabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502325,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500049,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182101,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182121,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184152,
    "City": "Kathua",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184151,
    "City": "KATHUA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332311,
    "City": "Laxmangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331001,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 325205,
    "City": "Baran",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181201,
    "City": "Akhnoor",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516360,
    "City": "Proddatur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229001,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181221,
    "City": "Nagrota",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180019,
    "City": "Nagrota",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134203,
    "City": "Naraingarh",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134202,
    "City": "Naraingarh",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134201,
    "City": "Naraingarh",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123029,
    "City": "Mahendergarh",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302015,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302004,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174315,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174306,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174303,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140126,
    "City": "ROOPNAGAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140125,
    "City": "ROOPNAGAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140124,
    "City": "ROOPNAGAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411053,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411016,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411005,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411004,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583121,
    "City": "Sirguppa",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700085,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700067,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700054,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700015,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700011,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700010,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229405,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229316,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229302,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229130,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229010,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601212,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601211,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601210,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601208,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601206,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601204,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601203,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600067,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441601,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441614,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110065,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110048,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110024,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110014,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110013,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638312,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524101,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481001,
    "City": "BUDBUDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145027,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145026,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145025,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143534,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 126115,
    "City": "NARWANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181205,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181124,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181123,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181121,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180013,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 180007,
    "City": "JAMMU",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522508,
    "City": "TADIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522236,
    "City": "TADIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530018,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530009,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530008,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530007,
    "City": "VISAKHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 751028,
    "City": "JATNI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752054,
    "City": "JATNI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752050,
    "City": "JATNI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 383001,
    "City": "HIMMAT NAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743235,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416216,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 389001,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363002,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363001,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711322,
    "City": "FULESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392150,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190019,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190013,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190010,
    "City": "LAL CHOWK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190001,
    "City": "LAL CHOWK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190016,
    "City": "DARGAH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190006,
    "City": "DARGAH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389350,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700100,
    "City": "DHAPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835222,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124507,
    "City": "BAHADURGARH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835103,
    "City": "TATISILWAY",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396001,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190009,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190008,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190004,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190003,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190002,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636455,
    "City": "OMALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190015,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629004,
    "City": "NAGERCOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629003,
    "City": "NAGERCOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629002,
    "City": "NAGERCOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629001,
    "City": "NAGERCOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629501,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629901,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629801,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629201,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629005,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629704,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629701,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416516,
    "City": "PINGULI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732121,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732142,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416602,
    "City": "KANKAWALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583238,
    "City": "KOPPAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583231,
    "City": "KOPPAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583227,
    "City": "GANGAVATHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732128,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732122,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591237,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416601,
    "City": "KANKAWALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587301,
    "City": "Jamkhandi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416606,
    "City": "MALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625513,
    "City": "BODINAYAKANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625601,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625515,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625512,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625531,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626101,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626005,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626004,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626003,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626002,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626001,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612101,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612002,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612001,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612703,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612702,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612602,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612501,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612401,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612303,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612302,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612204,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612103,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190020,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444102,
    "City": "DAHIHANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401301,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 753015,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506142,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502294,
    "City": "Patancheru",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394327,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396230,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190018,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422401,
    "City": "DEOLALI CAMP",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422013,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413531,
    "City": "Latur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431009,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422004,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754005,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 753025,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416010,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142026,
    "City": "Jagraon",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391240,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414003,
    "City": "AHMEDNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414002,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444606,
    "City": "BADNERA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754027,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754022,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754071,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621216,
    "City": "Trichy",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754002,
    "City": "ADASPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754018,
    "City": "ADASPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635601,
    "City": "NATRAMPALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635751,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635802,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635810,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303108,
    "City": "KOTPUTLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143007,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190011,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244235,
    "City": "GAJROLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244221,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262001,
    "City": "Pilibhit",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244412,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244302,
    "City": "Shambhal",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 203001,
    "City": "BULANDSHAHR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209724,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209625,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209602,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209601,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209749,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394515,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394510,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571428,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679306,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679337,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 679502,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679106,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679301,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679123,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679511,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679504,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 679531,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679516,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679515,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679104,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679102,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678512,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678506,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678505,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678504,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678502,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680302,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680301,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680562,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680561,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680596,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680554,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680581,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680541,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680121,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680125,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679322,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679357,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680504,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680511,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678103,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678534,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678533,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678532,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678555,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678554,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678553,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678557,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678721,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678572,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678571,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679326,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680586,
    "City": "ALATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680618,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680611,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680313,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678624,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678611,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678612,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678592,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678641,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680751,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680656,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680651,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680546,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680612,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680620,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680519,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532322,
    "City": "KAVITI AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532312,
    "City": "KAVITI AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532290,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532284,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184143,
    "City": "KATHUA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184142,
    "City": "Hira Nagar",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505325,
    "City": "METPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505326,
    "City": "KATHALAPOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121105,
    "City": "PALWAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 121102,
    "City": "PALWAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752002,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248140,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175019,
    "City": "SUNDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175002,
    "City": "SUNDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174402,
    "City": "SUNDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174401,
    "City": "SUNDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175021,
    "City": "Mandi",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175008,
    "City": "Mandi",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175001,
    "City": "Mandi",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457001,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680521,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680517,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680524,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680513,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680510,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680616,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680601,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680584,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680604,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680544,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680543,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680542,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680523,
    "City": "KUNNAMKULAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680522,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680520,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679563,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680103,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679561,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626117,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630006,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630005,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630004,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630003,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630002,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630001,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638657,
    "City": "DHARAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638656,
    "City": "DHARAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638673,
    "City": "DHARAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678556,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571426,
    "City": "KR PETE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573116,
    "City": "CHANNARAYAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581123,
    "City": "HARIHAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577601,
    "City": "HARIHAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606601,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606604,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606603,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441110,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505468,
    "City": "HUZURABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505122,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509210,
    "City": "NARAYANPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224123,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224001,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143605,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143603,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143602,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143513,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 281004,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 204101,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480441,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713359,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713372,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713334,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713370,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713341,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713340,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713326,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713371,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743194,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743124,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743294,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846009,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801503,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801505,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801507,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801105,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385535,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380075,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382330,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382002,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531173,
    "City": "PENDURTHY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506013,
    "City": "WARANGAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 500409,
    "City": "KATTEDAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521456,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521241,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521228,
    "City": "Vijayawada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500873,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505214,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574326,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 574241,
    "City": "KALLERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574203,
    "City": "Uppinangady",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574202,
    "City": "Uppinangady",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574201,
    "City": "Uppinangady",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517325,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515761,
    "City": "Kalyanadurgam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516115,
    "City": "Rajampeta",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518345,
    "City": "KURNOOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518360,
    "City": "Yemmiganur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504203,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504106,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522101,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522413,
    "City": "PIDUGURALLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522616,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603102,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608302,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608301,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608401,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608305,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609110,
    "City": "SIRKAZHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609108,
    "City": "SIRKAZHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635803,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635851,
    "City": "NATRAMPALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635808,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635807,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635805,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635804,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635801,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635754,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635814,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635813,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635812,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635811,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635752,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563101,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563157,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 563133,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632517,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632052,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632519,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631553,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604410,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631605,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631603,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631702,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733102,
    "City": "BALURGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786610,
    "City": "NAHARKATIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766692,
    "City": "Duliajan",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786601,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142036,
    "City": "Jagraon",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587201,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587203,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385001,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591201,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591313,
    "City": "SANKESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583277,
    "City": "KUSHTAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560108,
    "City": "BANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332301,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281006,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281005,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281002,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281001,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281003,
    "City": "VRINDAWAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123106,
    "City": "DHARUHERA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174101,
    "City": "BADDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301030,
    "City": "ALWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171011,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171010,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301002,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144504,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144407,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342013,
    "City": "JODHPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342015,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171013,
    "City": "Shimla",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416321,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415311,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614208,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612102,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431513,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612601,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517640,
    "City": "SRIKALAHASTI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516108,
    "City": "RAILWAY KODUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516105,
    "City": "RAILWAY KODUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516101,
    "City": "RAILWAY KODUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518222,
    "City": "DHONE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518221,
    "City": "DHONE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518216,
    "City": "DHONE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517590,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517583,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504001,
    "City": "Adilabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501158,
    "City": "Tandur",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501143,
    "City": "Tandur",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501142,
    "City": "Tandur",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402201,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402108,
    "City": "Pen",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402107,
    "City": "Pen",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402209,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402208,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382003,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382012,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382011,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382010,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382009,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382008,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382007,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382006,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382017,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382041,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382030,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382029,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382028,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382027,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382026,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382025,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382024,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382023,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382022,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382021,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382020,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382423,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382355,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382823,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382620,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396020,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396002,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396220,
    "City": "Daman",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396215,
    "City": "Daman",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 281121,
    "City": "VRINDAWAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521356,
    "City": "GUDIVADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209861,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276404,
    "City": "Azamgarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322202,
    "City": "Gangapur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276406,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276128,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518412,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518411,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585223,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585330,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585327,
    "City": "SHIVAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516150,
    "City": "NANDALURE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518302,
    "City": "ADONI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516126,
    "City": "Rajampeta",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517581,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531033,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531032,
    "City": "ANAKAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522211,
    "City": "Tenali",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532216,
    "City": "PATHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532213,
    "City": "PATHAPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583212,
    "City": "HAGARIBOMMANAHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442306,
    "City": "PULGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442303,
    "City": "PULGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442302,
    "City": "PULGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442101,
    "City": "PULGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410302,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410403,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410401,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443001,
    "City": "BULDHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431122,
    "City": "Beed",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384265,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396445,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421204,
    "City": "DESAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414110,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431517,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453441,
    "City": "PITHAMPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423601,
    "City": "KOPARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431520,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431515,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431131,
    "City": "Majalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423401,
    "City": "Yeola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522237,
    "City": "AMARAVATHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516247,
    "City": "VONITIMITTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507166,
    "City": "ENKOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535217,
    "City": "Vizianagaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522303,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533433,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533344,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535204,
    "City": "BOGAPPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516312,
    "City": "Proddatur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507318,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533214,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224201,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333029,
    "City": "SURAJGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125042,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144039,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143518,
    "City": "GURDASPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522510,
    "City": "TADIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144033,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144042,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125122,
    "City": "BARWALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132022,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131039,
    "City": "Sonipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273017,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142053,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142042,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142048,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147202,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334005,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190007,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190005,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190014,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190021,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125120,
    "City": "Tohana",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125133,
    "City": "Tohana",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743193,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741246,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736165,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736159,
    "City": "BAKSHIRHAT WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736146,
    "City": "MATHABHANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123024,
    "City": "LOHARU",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127310,
    "City": "BADHARA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127308,
    "City": "BADHARA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127030,
    "City": "BADHARA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127043,
    "City": "LOHARU",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127040,
    "City": "SIWANI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582114,
    "City": "Gajendragad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204102,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 204216,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204213,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221009,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305022,
    "City": "PUSHKAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334401,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313022,
    "City": "DABOK",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 247670,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247661,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247656,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620024,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627417,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627603,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627602,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627435,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627428,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627426,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627416,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627423,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627430,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627434,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627433,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627901,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625533,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625582,
    "City": "BODINAYAKANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625603,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625602,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625604,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625572,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625547,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625536,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625534,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625523,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625516,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638314,
    "City": "KESARIMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638008,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570029,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 570028,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586109,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560107,
    "City": "Nelamangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584102,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591108,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591103,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591124,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591113,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629702,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581301,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581302,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581303,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581305,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581306,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581308,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636102,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636105,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636109,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636121,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636113,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636112,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636115,
    "City": "VALAPPADI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636108,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636119,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577540,
    "City": "RAMPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584123,
    "City": "Manvi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441904,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441104,
    "City": "KANHAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441906,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441802,
    "City": "SAKOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441915,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441913,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441912,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441909,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444303,
    "City": "KHAMGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413801,
    "City": "DAUND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413802,
    "City": "PATAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412219,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412203,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741315,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741102,
    "City": "KRISHNANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741103,
    "City": "KRISHNANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741101,
    "City": "KRISHNANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741139,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741313,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741401,
    "City": "KRISHNANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506101,
    "City": "MAHABUBABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506169,
    "City": "BHUPALAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506168,
    "City": "BHUPALAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531163,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531162,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400107,
    "City": "MIRA BHAYANDER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400613,
    "City": "NAVI MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400047,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421103,
    "City": "TITWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421102,
    "City": "TITWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380053,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382016,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382042,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382428,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382610,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394220,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393002,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396155,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390025,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444607,
    "City": "BADNERA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431010,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403724,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403706,
    "City": "CURCHOREM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403705,
    "City": "CURCHOREM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403704,
    "City": "CURCHOREM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425309,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425003,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425002,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422012,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441204,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441001,
    "City": "KANHAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441002,
    "City": "KANHAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440037,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 440036,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415124,
    "City": "Karad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396235,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396193,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396240,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394270,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442004,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370205,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370201,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370110,
    "City": "ANJAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125121,
    "City": "BARWALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141418,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132101,
    "City": "SAMALKHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132108,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132105,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125076,
    "City": "Tibbi",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125060,
    "City": "Sirsa",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143422,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735203,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735202,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735220,
    "City": "MADARIHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735207,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735233,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735212,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735204,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735232,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735228,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735216,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504231,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504251,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331403,
    "City": "Sardarshahar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225001,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225003,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225301,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225204,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148023,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148024,
    "City": "Dhuri",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441804,
    "City": "SAKOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518422,
    "City": "Atmakur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462045,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462020,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462012,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462037,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462031,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462013,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462022,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680309,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680308,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680307,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431213,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 431203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 571313,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571115,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571127,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571342,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584124,
    "City": "LINGASUGUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326001,
    "City": "JHALAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416502,
    "City": "Gadhingalaj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416501,
    "City": "Gadhingalaj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415712,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411025,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411024,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411023,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425405,
    "City": "Shirpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 488333,
    "City": "Panna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488101,
    "City": "Panna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488001,
    "City": "Panna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413208,
    "City": "MAHISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413209,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413211,
    "City": "TEMBURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574105,
    "City": "KATAPADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574118,
    "City": "MANIPAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576115,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584103,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639109,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639206,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639106,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639116,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639136,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639117,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639207,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639111,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639003,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639114,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639006,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639008,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639007,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639108,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639118,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639205,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639113,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639004,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639002,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639001,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639005,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303806,
    "City": "CHOMU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303801,
    "City": "Chomu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303712,
    "City": "Chomu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303704,
    "City": "Chomu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262401,
    "City": "BAZPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244222,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244231,
    "City": "GAJROLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244241,
    "City": "GAJROLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244301,
    "City": "Shambhal",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244410,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202410,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722146,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722102,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722101,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722107,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722203,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722180,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722136,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722133,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722155,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637105,
    "City": "Edappadi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637102,
    "City": "Edappadi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637101,
    "City": "Edappadi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683562,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 686668,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685582,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685584,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685587,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685586,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685608,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685597,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124505,
    "City": "BAHADURGARH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124508,
    "City": "BAHADURGARH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 285001,
    "City": "ORAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134102,
    "City": "PINJORE",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134151,
    "City": "PANCHKULA",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125075,
    "City": "Tibbi",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125102,
    "City": "Tibbi",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125101,
    "City": "SANGARIA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335063,
    "City": "SANGARIA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 211018,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785001,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785004,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785008,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785007,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785006,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785005,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785011,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785010,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785013,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785704,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785015,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785014,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785101,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785002,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785009,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785700,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785616,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623526,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623531,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623530,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623566,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623807,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623806,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623537,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623512,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623520,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623516,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623514,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623523,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623522,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623536,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623527,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623524,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623532,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623501,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623515,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623517,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623504,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623502,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623534,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624006,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624051,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624303,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624307,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624304,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624007,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624009,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624314,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624312,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624305,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624010,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624052,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624622,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624302,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624301,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624707,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624708,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624710,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624709,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624008,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624054,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624053,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624702,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624802,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624801,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743351,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743332,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743331,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743375,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743613,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743610,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743330,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743387,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412202,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412201,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 450001,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607801,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607803,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607802,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607807,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607303,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607804,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606001,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606003,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609601,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609604,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609603,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609602,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609703,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609609,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609607,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609606,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609605,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609307,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 611002,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522265,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521201,
    "City": "NUZVID",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535160,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535148,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535145,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535161,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531026,
    "City": "WADDADI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444506,
    "City": "Risod",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396030,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396007,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148101,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148105,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148107,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676517,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679324,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676541,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676509,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676122,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676121,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673642,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676521,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676505,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676507,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676506,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676519,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676522,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676514,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676123,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676126,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622003,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622002,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622001,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622005,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622004,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829122,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829117,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825316,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829103,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829102,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829106,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825101,
    "City": "GOLA JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783370,
    "City": "KOKRAJHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783381,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783380,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783385,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140506,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743412,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743411,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743422,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743401,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743437,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743289,
    "City": "HABRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743287,
    "City": "THAKURNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743271,
    "City": "HABRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743252,
    "City": "HABRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741302,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721426,
    "City": "DANTAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721436,
    "City": "BAITA BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756032,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767002,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767001,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424001,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424002,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424005,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425301,
    "City": "YAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445204,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712249,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712250,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421302,
    "City": "KALHER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493221,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492013,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507119,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363035,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321205,
    "City": "BHARATPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321606,
    "City": "KHERLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321605,
    "City": "KHERLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321602,
    "City": "NADBAI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495684,
    "City": "Korba",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331022,
    "City": "RATANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577213,
    "City": "CHANNAGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481661,
    "City": "MANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421605,
    "City": "TITWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444803,
    "City": "DARYAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476111,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476001,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 477001,
    "City": "BHIND",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444906,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829116,
    "City": "BOKARO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829113,
    "City": "BOKARO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829107,
    "City": "BOKARO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509302,
    "City": "JADCHERLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515411,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515415,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533262,
    "City": "DRAKSHARAMAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533346,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533261,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533255,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535501,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503306,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503187,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503122,
    "City": "YELLAREDDY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503310,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741126,
    "City": "BETHUDAHARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741137,
    "City": "BETHUDAHARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741138,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741154,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734301,
    "City": "KALIMPONG",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734314,
    "City": "KALIMPONG",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734317,
    "City": "KALIMPONG",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734311,
    "City": "KALIMPONG",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734316,
    "City": "KALIMPONG",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384151,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384140,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384215,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384325,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384355,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382711,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382825,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382865,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505425,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413101,
    "City": "AKLUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413118,
    "City": "AKLUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413108,
    "City": "AKLUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140112,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301707,
    "City": "TAPUKARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312601,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312620,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312617,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331024,
    "City": "BISSAU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331021,
    "City": "BISSAU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127028,
    "City": "LOHARU",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127046,
    "City": "SIWANI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125201,
    "City": "ODHAN",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335062,
    "City": "SANGARIA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331411,
    "City": "Sardarshahar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331402,
    "City": "Sardarshahar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322021,
    "City": "Sawaimadhopur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271001,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271002,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271003,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444701,
    "City": "BADNERA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401302,
    "City": "VIRAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492002,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492012,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571234,
    "City": "KUSHALNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571232,
    "City": "KUSHALNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571104,
    "City": "KUSHALNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695504,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695125,
    "City": "NEYYATTINKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695505,
    "City": "NEYYATTINKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695571,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695573,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695572,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695512,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695575,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695574,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680122,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680565,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680568,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680567,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680711,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680687,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680702,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680701,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680685,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680686,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690518,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690526,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690525,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690524,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690523,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690539,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690528,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690546,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690542,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690573,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690547,
    "City": "KARUNAGAPPALLY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691583,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690519,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466001,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415709,
    "City": "KHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415722,
    "City": "KHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384110,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384220,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190023,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224188,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224135,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712602,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712601,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712612,
    "City": "ARAMBAGH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712122,
    "City": "ARAMBAGH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721242,
    "City": "ARAMBAGH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754120,
    "City": "PARADEEP",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754142,
    "City": "PARADEEP",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754141,
    "City": "PARADEEP",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754145,
    "City": "PARADEEP",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754143,
    "City": "PARADEEP",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759107,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759101,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759100,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752055,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735304,
    "City": "MEKHLIGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734015,
    "City": "FULBARI HAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444805,
    "City": "PARATWADDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503206,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503202,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503201,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503188,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503185,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522124,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522311,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522020,
    "City": "AMARAVATHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522016,
    "City": "AMARAVATHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415612,
    "City": "RATNAGIRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415523,
    "City": "PHALTAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425409,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425413,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425422,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331023,
    "City": "Rajgarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334803,
    "City": "NOKHA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431704,
    "City": "ARDHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431801,
    "City": "BHOKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431809,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464001,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464661,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575022,
    "City": "BANTWAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575023,
    "City": "BANTWAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574153,
    "City": "BELTHANGADY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574199,
    "City": "BANTWAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480551,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480449,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480447,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243601,
    "City": "BADAYUN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251002,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251001,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251301,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251003,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251306,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277001,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344001,
    "City": "BARMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680664,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680663,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680123,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680670,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680661,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680662,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680733,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680732,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680682,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151505,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151502,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533308,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533234,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533233,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533342,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533341,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788152,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788151,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788163,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788150,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788155,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788802,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851117,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851112,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851101,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851118,
    "City": "BARAUNI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797112,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797117,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797116,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344024,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344022,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 344032,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142044,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142047,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142050,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322241,
    "City": "KARAULI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 245101,
    "City": "HAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 245201,
    "City": "HAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152116,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152123,
    "City": "FAZILKA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131301,
    "City": "GOHANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131303,
    "City": "GOHANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 131305,
    "City": "GOHANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125103,
    "City": "ODHAN",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306902,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 307027,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583219,
    "City": "HUVINAHAGALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582118,
    "City": "MUNDARGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571448,
    "City": "BG NAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571418,
    "City": "BG NAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577217,
    "City": "HONNALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682312,
    "City": "COCHIN",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304021,
    "City": "CHAKSHU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304022,
    "City": "CHAKSHU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140501,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276121,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124142,
    "City": "ATELA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125106,
    "City": "BHUNA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 126001,
    "City": "Tohana",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712136,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712101,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712102,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712103,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712105,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712139,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370020,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370001,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396125,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305901,
    "City": "BEAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305623,
    "City": "MASUDA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341303,
    "City": "DIDWANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230001,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230131,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275101,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209726,
    "City": "KANNAUJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209725,
    "City": "KANNAUJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209722,
    "City": "KANNAUJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209732,
    "City": "KANNAUJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209727,
    "City": "KANNAUJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 212621,
    "City": "FATEHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 212601,
    "City": "FATEHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 212635,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425201,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425203,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425308,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425305,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425307,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415605,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577116,
    "City": "BIRUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577401,
    "City": "SAGARCITY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577435,
    "City": "SAGARCITY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577421,
    "City": "SAGARCITY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824102,
    "City": "AURANGABAD BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824121,
    "City": "AURANGABAD BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534447,
    "City": "JANGAREDDY GUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534449,
    "City": "JANGAREDDY GUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534312,
    "City": "JANGAREDDY GUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581400,
    "City": "KARWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581396,
    "City": "KARWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581307,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581352,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581345,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515401,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515402,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518124,
    "City": "BANAGANAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721137,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721134,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721171,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721151,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721636,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721641,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721154,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721130,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721158,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 249202,
    "City": "Rishikesh",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 249204,
    "City": "Rishikesh",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 249203,
    "City": "Rishikesh",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125077,
    "City": "ODHAN",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304804,
    "City": "DEOLI RAJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182301,
    "City": "KATRA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303503,
    "City": "LALSOT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207002,
    "City": "ETAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207001,
    "City": "ETAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207003,
    "City": "ETAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206001,
    "City": "ETAWAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206002,
    "City": "ETAWAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331507,
    "City": "SUJANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416113,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416114,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764004,
    "City": "JEYPORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764003,
    "City": "JEYPORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764002,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 764001,
    "City": "JEYPORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764005,
    "City": "JEYPORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855107,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855108,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402104,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402103,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402301,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402309,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402302,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402303,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402115,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402304,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402106,
    "City": "NAGOTHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402125,
    "City": "NAGOTHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181122,
    "City": "AKHNOOR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362001,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362002,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362004,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362015,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362037,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362205,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362263,
    "City": "MENDRADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454775,
    "City": "PITHAMPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456335,
    "City": "NAGDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691304,
    "City": "CHATHANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691574,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691572,
    "City": "CHATHANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691302,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691578,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695142,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695141,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695102,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695101,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695146,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695145,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695144,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695143,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695318,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695312,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695311,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695306,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695611,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695603,
    "City": "EDAVA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673529,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673612,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734101,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734104,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734102,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734103,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734124,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508252,
    "City": "CHOUTUPPAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461111,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461122,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461115,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461114,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471201,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423702,
    "City": "LASUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413709,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425412,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431112,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422608,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422605,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385510,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385520,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802207,
    "City": "PIRO",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802311,
    "City": "BARHARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802302,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802301,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516330,
    "City": "VEMPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516329,
    "City": "VEMPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516396,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125113,
    "City": "BARWALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341001,
    "City": "NAGAUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341021,
    "City": "NAGAUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181132,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181102,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181111,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181112,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181104,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490022,
    "City": "BHILAI JAMUL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491001,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 431109,
    "City": "GANGGAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401404,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401502,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401501,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401506,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370465,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370460,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370455,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370435,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473001,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473287,
    "City": "MAKSUDANGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473112,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473111,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473110,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465333,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125111,
    "City": "BHUNA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151507,
    "City": "Talwandi",
    "State": "PUNJAB",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 272002,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 272001,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814112,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814113,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814116,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814122,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814142,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683513,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683512,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683514,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683517,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683516,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683519,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683518,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683520,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683522,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683521,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683579,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683594,
    "City": "Aluva",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691503,
    "City": "KOLLAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691501,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691506,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691505,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691531,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691512,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691508,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691322,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691557,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691507,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638751,
    "City": "VIJAYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691306,
    "City": "CHITHARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691311,
    "City": "CHITHARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691516,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691534,
    "City": "NILAMEL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691535,
    "City": "NILAMEL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691537,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691536,
    "City": "Kilimanoor",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691333,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606202,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606201,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582207,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582208,
    "City": "DHARWAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736168,
    "City": "Bamanhat",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736135,
    "City": "DINHATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736134,
    "City": "DINHATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736169,
    "City": "DINHATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736166,
    "City": "Bamanhat",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736176,
    "City": "DINHATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736175,
    "City": "Bamanhat",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712123,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712104,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712138,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425302,
    "City": "YAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 245304,
    "City": "HAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151504,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243639,
    "City": "BADAYUN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 311201,
    "City": "DEOLI RAJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415601,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415603,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415602,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415606,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415604,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506170,
    "City": "BHUPALAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480886,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480667,
    "City": "Seoni",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 370421,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370405,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370410,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370415,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493661,
    "City": "ABHANPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335503,
    "City": "Bhadra",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246701,
    "City": "BIJNOR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335504,
    "City": "Bhadra",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335701,
    "City": "ANOOPGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493885,
    "City": "ABHANPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608303,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591307,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493773,
    "City": "DHAMTARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 485771,
    "City": "MAIHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 485773,
    "City": "MAIHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485772,
    "City": "MAIHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485775,
    "City": "AMARPATAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422113,
    "City": "SINNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422112,
    "City": "SINNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422502,
    "City": "DEOLALI CAMP",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422207,
    "City": "OZHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422206,
    "City": "OZHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626102,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626122,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626188,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416312,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416310,
    "City": "PALUS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416308,
    "City": "PALUS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416118,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695507,
    "City": "Kallikkad",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574144,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574145,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630301,
    "City": "AMARAVATHIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630302,
    "City": "AMARAVATHIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630106,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533221,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533240,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533247,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533249,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533223,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533229,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627855,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627765,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627751,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627803,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627852,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627811,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627818,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627805,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627804,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627802,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627817,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627809,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627813,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627859,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627858,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627808,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627806,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627814,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627415,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627424,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627412,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627425,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627418,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627551,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125048,
    "City": "FATEHABAD HR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181202,
    "City": "Akhnoor",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515202,
    "City": "HINDUPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637018,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637408,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637401,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624001,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624002,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624004,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624003,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635210,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635104,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276403,
    "City": "Azamgarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225121,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225122,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124141,
    "City": "ATELA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303807,
    "City": "CHOMU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248011,
    "City": "DEHRADUN",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143507,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504296,
    "City": "KAGAZNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504205,
    "City": "DANDEPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441911,
    "City": "TIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735206,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735209,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735223,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713519,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713513,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 741319,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741316,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140407,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 140412,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 148104,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141109,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140307,
    "City": "KHARAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140413,
    "City": "KHARAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151508,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733121,
    "City": "GANGARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733127,
    "City": "TAPAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733124,
    "City": "GANGARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732123,
    "City": "CHACHAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732139,
    "City": "CHACHAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733215,
    "City": "KARANDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733201,
    "City": "DALKHOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733143,
    "City": "ITAHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733128,
    "City": "ITAHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733125,
    "City": "ITAHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733129,
    "City": "KALIAGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733132,
    "City": "KALIAGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736158,
    "City": "MATHABHANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143202,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144216,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144221,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144306,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147102,
    "City": "PATRAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 147105,
    "City": "PATRAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148035,
    "City": "PATRAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221303,
    "City": "GOPIGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221301,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221401,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221304,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221409,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221310,
    "City": "HANDIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210427,
    "City": "MAHOBA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342602,
    "City": "BILARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306302,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274304,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441991,
    "City": "GONDIA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843301,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843302,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828205,
    "City": "NIRSA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828201,
    "City": "GOVINDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516434,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515801,
    "City": "GUNTAKAL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522647,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504311,
    "City": "UTNOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504346,
    "City": "UTNOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507111,
    "City": "BHADRACHALAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507128,
    "City": "BHADRACHALAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142037,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431105,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 383315,
    "City": "MODASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506223,
    "City": "CHERIYAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444203,
    "City": "SHEGAON MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443404,
    "City": "NANDURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444806,
    "City": "PARATWADDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521126,
    "City": "CHALLAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506102,
    "City": "MAHABUBABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 281010,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 282008,
    "City": "AGRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148031,
    "City": "LEHRAGAGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244715,
    "City": "RAMNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152031,
    "City": "MUKTSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152026,
    "City": "MUKTSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152032,
    "City": "MUKTSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176041,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176040,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174311,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176049,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176042,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174305,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177501,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174505,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333307,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333022,
    "City": "GUDHA GORJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332709,
    "City": "Khandela",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333303,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333302,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531035,
    "City": "SABBAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246149,
    "City": "KOTDWAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680310,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680683,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680681,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680688,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680671,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680666,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680665,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680668,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680697,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680741,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680734,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387810,
    "City": "BAVALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382430,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382427,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388307,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394115,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392030,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392210,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370230,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370240,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361141,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394550,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363020,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363030,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396045,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396105,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535250,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535281,
    "City": "K KOTAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788806,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788805,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410505,
    "City": "RAJGURUNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444105,
    "City": "KARANJALAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444403,
    "City": "MANGLURPIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444402,
    "City": "MANGLURPIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444110,
    "City": "KAMARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680722,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680316,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680311,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816116,
    "City": "TINPAHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460001,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460449,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460447,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504002,
    "City": "Adilabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508202,
    "City": "HALIYA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518134,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517582,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517370,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335703,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 335704,
    "City": "SHRI VIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335707,
    "City": "GHARSANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207401,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 134101,
    "City": "PANCHKULA",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132046,
    "City": "ASSANDH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132039,
    "City": "ASSANDH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583135,
    "City": "KUDLIGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583134,
    "City": "KOTTUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577530,
    "City": "MALEBENNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614211,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635112,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637406,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636807,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 620015,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628004,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628006,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628008,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641105,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641108,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562135,
    "City": "VIJAYAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582116,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591309,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 723102,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723101,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723104,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722122,
    "City": "BISHNUPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722147,
    "City": "BISHNUPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722144,
    "City": "BISHNUPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722207,
    "City": "SONAMUKHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722140,
    "City": "KHATRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722135,
    "City": "KHATRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641301,
    "City": "ANNUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641305,
    "City": "ANNUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641302,
    "City": "ANNUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641104,
    "City": "ANNUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614014,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614001,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614102,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614101,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614015,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614016,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614601,
    "City": "Adirampattinam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614903,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614713,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614602,
    "City": "Adirampattinam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614715,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614708,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614717,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614018,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614906,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614905,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 614901,
    "City": "Adirampattinam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341508,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341505,
    "City": "MAKRANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341502,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307501,
    "City": "MOUNT ABU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307022,
    "City": "PINDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335524,
    "City": "HANUMANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263145,
    "City": "PANTNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263148,
    "City": "PANTNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788710,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788712,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788711,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370140,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632521,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632531,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632512,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632506,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632507,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632504,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632508,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632501,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816109,
    "City": "SAHIBGANJ",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816108,
    "City": "TINPAHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685605,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685581,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685585,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607104,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607101,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607110,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607103,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607205,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523357,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523368,
    "City": "GOWTHAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523333,
    "City": "CUMBUM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523373,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523356,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523369,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761201,
    "City": "GAJAPATI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761200,
    "City": "GAJAPATI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534327,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534326,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535524,
    "City": "KURUPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535526,
    "City": "KURUPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362720,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362725,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362715,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362710,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360370,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360311,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628501,
    "City": "KOVILPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628502,
    "City": "KOVILPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628503,
    "City": "KOVILPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481102,
    "City": "BUDBUDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481331,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481441,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609111,
    "City": "SIRKAZHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 524406,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524405,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523316,
    "City": "MARKAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603406,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604409,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632518,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632511,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176304,
    "City": "DALHOUSIE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176303,
    "City": "DALHOUSIE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176305,
    "City": "DALHOUSIE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176310,
    "City": "CHAMBA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132114,
    "City": "GHARAUNDA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 126101,
    "City": "JULANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124514,
    "City": "JULANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 126116,
    "City": "NARWANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143533,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143525,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341510,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341511,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574240,
    "City": "BELTHANGADY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574224,
    "City": "MADANTHYAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574216,
    "City": "BELTHANGADY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638501,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638502,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673602,
    "City": "MUKKAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695541,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695542,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695561,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782429,
    "City": "HOJAI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782435,
    "City": "HOJAI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782440,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782445,
    "City": "HOJAI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176314,
    "City": "CHAMBA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176318,
    "City": "CHAMBA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670305,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670307,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670309,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670327,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670308,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670501,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670502,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671121,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671345,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670521,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670504,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670503,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671311,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671313,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671314,
    "City": "PARAPPA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671312,
    "City": "PADANNA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782442,
    "City": "HOJAI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504105,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504310,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534338,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534265,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466115,
    "City": "ICHHAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466116,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465223,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466651,
    "City": "KURAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504101,
    "City": "BASAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504107,
    "City": "BASAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533401,
    "City": "TUNI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531126,
    "City": "TUNI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518543,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518553,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671123,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671124,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671316,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671317,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671321,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671322,
    "City": "UPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671324,
    "City": "UPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671348,
    "City": "UPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671531,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671541,
    "City": "RAJAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671533,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671543,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 671542,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 671552,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671551,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676101,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676103,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676102,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676105,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676106,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676107,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676108,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676109,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676301,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676320,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676307,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676302,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676510,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676501,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676502,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676561,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676562,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679571,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759102,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759116,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759104,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759103,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759148,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759146,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523201,
    "City": "ADDANKI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686571,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686575,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686574,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686573,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686572,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686579,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686578,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686577,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686635,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686583,
    "City": "PALLIKKATHODU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686508,
    "City": "EDAKUNNAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686122,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686587,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686653,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686651,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636116,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636101,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636141,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673305,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673620,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673315,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587125,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587154,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587118,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576111,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574104,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574110,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576117,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630551,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571120,
    "City": "TNARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571124,
    "City": "TNARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571110,
    "City": "TNARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456224,
    "City": "NAGDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456331,
    "City": "NAGDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442103,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123027,
    "City": "DAHINA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123302,
    "City": "KOSLI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326519,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326530,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494334,
    "City": "KANKER",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442301,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442305,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442307,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363641,
    "City": "MORBI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521202,
    "City": "NUZVID",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534401,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577429,
    "City": "SORAB",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577432,
    "City": "THIRTHAHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591126,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424201,
    "City": "BHADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362220,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362227,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362266,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362265,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362267,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362275,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362255,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362150,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362140,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362135,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450331,
    "City": "BURHANPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413216,
    "City": "AKKALKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413220,
    "City": "AKKALKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413217,
    "City": "AKKALKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490024,
    "City": "BHILAI JAMUL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490036,
    "City": "BHILAI JAMUL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 787059,
    "City": "Dhemaji",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 791125,
    "City": "Dhemaji",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786181,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786188,
    "City": "JAGUN",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786182,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786171,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786179,
    "City": "JAGUN",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466445,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461001,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313301,
    "City": "NATHDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 313324,
    "City": "NATHDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305801,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458001,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458002,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691571,
    "City": "CHATHANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691573,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691540,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691016,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628202,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628203,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628205,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628204,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628206,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628213,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628215,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628216,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628217,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626130,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626141,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626127,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626124,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626189,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626138,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626103,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626201,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626131,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626123,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626140,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626128,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413308,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413309,
    "City": "JAWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444705,
    "City": "ANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444706,
    "City": "DARYAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444802,
    "City": "DARYAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679340,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679323,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679307,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679325,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679321,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676504,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679341,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431402,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522259,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522268,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518464,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518466,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518462,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458441,
    "City": "NEEMUCH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571440,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571443,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416425,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441107,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360490,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360470,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636107,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621110,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493332,
    "City": "BALODABAZAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493331,
    "City": "BHATAPURA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493118,
    "City": "BHATAPURA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413201,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413205,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413204,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470113,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470124,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522330,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523167,
    "City": "INKOLLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523301,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523214,
    "City": "INKOLLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365601,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522302,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522309,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276207,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221306,
    "City": "GOPIGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221406,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144214,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144205,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151506,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148030,
    "City": "SUNAM",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151501,
    "City": "LEHRAGAGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151206,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 143201,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143204,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152022,
    "City": "RUPANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148027,
    "City": "PATRAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143416,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 248012,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248013,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248014,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143113,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 122019,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122100,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122102,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176216,
    "City": "Dharmshala",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 122014,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 121000,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146113,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143529,
    "City": "GURDASPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143526,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177029,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142041,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284129,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284419,
    "City": "JHANSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144013,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144028,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144020,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144805,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209217,
    "City": "KANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247669,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247002,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182104,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160073,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160074,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160079,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143532,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144026,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497001,
    "City": "AMBIKAPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412805,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416505,
    "City": "UTTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636401,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636402,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636403,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636453,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636501,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636454,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636457,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670691,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670611,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670622,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670741,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670612,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670613,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670692,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670101,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670104,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670642,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670694,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670643,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531055,
    "City": "YELLAMANCHILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531060,
    "City": "YELLAMANCHILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486001,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486002,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486003,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486010,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486006,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486004,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486007,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486450,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486008,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785634,
    "City": "MARIANI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785630,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321617,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312000,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271801,
    "City": "BEHRAICH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271865,
    "City": "BEHRAICH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413302,
    "City": "BHOSE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413315,
    "City": "BHOSE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492015,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491111,
    "City": "UTAIPATAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492099,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 590000,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193101,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193122,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193103,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193198,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193201,
    "City": "SOPORE",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193202,
    "City": "SOPORE",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833201,
    "City": "CHAIBASA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 833202,
    "City": "CHAIBASA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805104,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805110,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805111,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848101,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848102,
    "City": "KASHICHAK",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502276,
    "City": "SIDDIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502277,
    "City": "Siddipet",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522435,
    "City": "PIDUGURALLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522316,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522315,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532294,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441101,
    "City": "BUTTIBORI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441113,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441112,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441109,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441105,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480108,
    "City": "SAUSAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480106,
    "City": "SAUSAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441502,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325202,
    "City": "ANTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521245,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521260,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521153,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505415,
    "City": "Choppadandi",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535183,
    "City": "KOTHAVALASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425502,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844101,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844502,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844503,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841101,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533203,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533210,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533215,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533217,
    "City": "AMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533232,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533264,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533305,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533271,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533307,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533345,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533340,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535102,
    "City": "CHEEPURUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535125,
    "City": "CHEEPURUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533434,
    "City": "SAMALKOT",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425503,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441902,
    "City": "AMANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415307,
    "City": "KHANAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415304,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415301,
    "City": "ATPADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415409,
    "City": "KASEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815301,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815302,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385540,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385505,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385506,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229206,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413705,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413706,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413722,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464770,
    "City": "UDAIPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464776,
    "City": "UDAIPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464668,
    "City": "BARELI MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464671,
    "City": "UDAIPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487551,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382870,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473440,
    "City": "ASHOKNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473331,
    "City": "ASHOKNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473330,
    "City": "ASHOKNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383430,
    "City": "Idar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509126,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335803,
    "City": "PILIBANGA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231001,
    "City": "MIRZAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321401,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 233001,
    "City": "GHAZIPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 233002,
    "City": "GHAZIPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321022,
    "City": "KAMAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321203,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271201,
    "City": "BALRAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271609,
    "City": "UTRAULA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271604,
    "City": "UTRAULA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274305,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414502,
    "City": "SHEVGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413133,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 451001,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454552,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451660,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451228,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451224,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441203,
    "City": "UMRED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441201,
    "City": "BHIWAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444905,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444707,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444103,
    "City": "TELHARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444810,
    "City": "CHANDURBAZAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444720,
    "City": "CHANDURBAZAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444704,
    "City": "CHANDURBAZAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444809,
    "City": "PARATWADDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524410,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680669,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680712,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680566,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402112,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402111,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402117,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402120,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431806,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603306,
    "City": "MADURANTAKAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425304,
    "City": "YAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423603,
    "City": "KOPARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431519,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431523,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422601,
    "City": "AKOLE MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262406,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262407,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577218,
    "City": "SANTHEBENNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206130,
    "City": "ETAWAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132115,
    "City": "SAMALKHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246763,
    "City": "NAJIBABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312604,
    "City": "CHOTI SADRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333042,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333304,
    "City": "DADIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 610001,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610004,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610003,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610002,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613704,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610101,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610109,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610105,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610104,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 610103,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609502,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609701,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609504,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609503,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 611104,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 611101,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612610,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613701,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 613702,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515591,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515581,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515521,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515511,
    "City": "MUDDIGUBA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515551,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515571,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515865,
    "City": "RAYADURGAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522414,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 273157,
    "City": "KOLHUI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176125,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176115,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 241001,
    "City": "HARDOI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176048,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176044,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175004,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177024,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177601,
    "City": "AWAH DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174028,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176045,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303103,
    "City": "SAHPURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303104,
    "City": "MANOHARPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182122,
    "City": "RAMNAGAR UDHAMPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124022,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124513,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424101,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424105,
    "City": "BHADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425109,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425111,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425105,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412214,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412215,
    "City": "UrliKanchan",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464551,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464881,
    "City": "GAIRATGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464884,
    "City": "GAIRATGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460661,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480334,
    "City": "PANDHURNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443201,
    "City": "CHIKHLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441302,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441301,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441303,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441304,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441305,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441306,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441103,
    "City": "KATOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444101,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444108,
    "City": "TELHARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581355,
    "City": "SIDDAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583236,
    "City": "YELBURAGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583232,
    "City": "YELBURAGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638315,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686605,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686606,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686141,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686143,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686146,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679573,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679575,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679576,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679577,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679580,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679582,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679583,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679584,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679585,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679586,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679587,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679588,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679551,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679552,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679553,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574117,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574106,
    "City": "KATAPADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581320,
    "City": "BHATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444901,
    "City": "WALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444717,
    "City": "PARATWADDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444808,
    "City": "ANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621212,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621220,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621109,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621108,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621101,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621116,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621115,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621219,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621104,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621704,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416109,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416011,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431745,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413008,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413215,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414111,
    "City": "AHMEDNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312202,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312605,
    "City": "PRATAPGARHRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441806,
    "City": "SADAK ARJUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441807,
    "City": "SADAK ARJUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781346,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781333,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781372,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781371,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781325,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781326,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781355,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781343,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781349,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843113,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844120,
    "City": "FAKULI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844127,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843146,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851204,
    "City": "KHAGARIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851205,
    "City": "KHAGARIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851214,
    "City": "KHAGARIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824202,
    "City": "AURANGABAD BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804408,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804417,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360575,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360578,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360577,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144010,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785667,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451111,
    "City": "BEDIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451115,
    "City": "BEDIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410502,
    "City": "JUNNAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410504,
    "City": "JUNNAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133302,
    "City": "PINJORE",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134104,
    "City": "PINJORE",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184121,
    "City": "SAMBA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184120,
    "City": "VIJAYPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284403,
    "City": "LALITPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148021,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141119,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148022,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175027,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175006,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307026,
    "City": "MOUNT ABU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140119,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148102,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148025,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148103,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148109,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148110,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148111,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148113,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148112,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148114,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148115,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148116,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148117,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152033,
    "City": "RUPANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140104,
    "City": "KHARAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 123034,
    "City": "DAHINA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123303,
    "City": "DAHINA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176306,
    "City": "DALHOUSIE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144204,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 126103,
    "City": "JIND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 126104,
    "City": "JIND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143112,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143205,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424004,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424006,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424311,
    "City": "BORVIHIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425508,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425504,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425505,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425506,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425507,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414102,
    "City": "PATHARDI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461331,
    "City": "HARDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461228,
    "City": "HARDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461775,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461771,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461772,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534225,
    "City": "ATTILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523230,
    "City": "KANIGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523224,
    "City": "KANIGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515134,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515144,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515133,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534342,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534302,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360050,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360040,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363310,
    "City": "DHARANGATHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364270,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364505,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364510,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362226,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362225,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362250,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362230,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413105,
    "City": "BHIGWAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413106,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413130,
    "City": "BHIGWAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413132,
    "City": "NIMGAON KETKI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413120,
    "City": "NIMGAON KETKI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413114,
    "City": "NIMGAON KETKI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416404,
    "City": "MADGYAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415102,
    "City": "MAYANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415506,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415508,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401103,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401601,
    "City": "DAHANU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401602,
    "City": "DAHANU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415501,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415503,
    "City": "PUSEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415502,
    "City": "PUSEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443101,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443103,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443102,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442605,
    "City": "GADCHIROLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441217,
    "City": "ARMORI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442606,
    "City": "GADCHIROLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441208,
    "City": "ARMORI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441225,
    "City": "SAOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442603,
    "City": "CHAMORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441215,
    "City": "SAOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441206,
    "City": "WADSA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441207,
    "City": "WADSA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465674,
    "City": "BIAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465683,
    "City": "PACHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475661,
    "City": "DATIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475335,
    "City": "BHANDER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484334,
    "City": "KOTMA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484336,
    "City": "KOTMA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484444,
    "City": "KOTMA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484224,
    "City": "ANUPPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484440,
    "City": "KOTMA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484446,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148019,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148020,
    "City": "MALERKOTLA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181143,
    "City": "VIJAYPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606701,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606702,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606705,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606706,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606707,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606709,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606752,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606753,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606754,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606755,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606802,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606804,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606806,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606611,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606704,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606805,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606602,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606808,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606811,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 208039,
    "City": "Kanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132023,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132054,
    "City": "KARNAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144533,
    "City": "Roopnagar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152103,
    "City": "MALOUT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142003,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244002,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 896109,
    "City": "SAHIBGANJ",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816115,
    "City": "SAHIBGANJ",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813208,
    "City": "BORIO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733246,
    "City": "GANGARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733145,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 833102,
    "City": "CHAKRADHARPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835302,
    "City": "LOHARDAGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 343001,
    "City": "JALORE",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151302,
    "City": "Talwandi",
    "State": "PUNJAB",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 424206,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424205,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425401,
    "City": "AMALNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425420,
    "City": "AMALNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425107,
    "City": "CHOPDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410405,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803101,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803216,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803115,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803119,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803118,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387411,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387380,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387130,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517644,
    "City": "SRIKALAHASTI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 210205,
    "City": "KARWI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 210204,
    "City": "KARWI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534416,
    "City": "ELURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534406,
    "City": "ELURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534411,
    "City": "ELURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785688,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785691,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785690,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785689,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785692,
    "City": "SAPEKHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 573134,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573127,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638476,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638456,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638455,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638110,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638453,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638452,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441108,
    "City": "BUTTIBORI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441122,
    "City": "BUTTIBORI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442905,
    "City": "RAJURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442701,
    "City": "DURGAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442901,
    "City": "DURGAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473551,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473770,
    "City": "BADRAWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473995,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811201,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811214,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811211,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508208,
    "City": "MIRYALAGUDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124102,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124103,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124104,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144602,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144802,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144801,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673636,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673302,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673301,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676312,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673314,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673655,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627713,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627753,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627719,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627755,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627757,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627756,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627760,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627761,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627857,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627953,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627754,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627862,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627758,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628721,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628552,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627951,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637202,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637201,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384345,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382840,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384320,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382860,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384335,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384305,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788160,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788164,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788168,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788801,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788709,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470661,
    "City": "DAMOH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495450,
    "City": "JAMNIPALI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788701,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788803,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781360,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781367,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364710,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364720,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364750,
    "City": "GATHDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382450,
    "City": "BARWALAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364765,
    "City": "GATHDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382245,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445203,
    "City": "DIGRAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444404,
    "City": "MANORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 487001,
    "City": "NARSINGPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487221,
    "City": "KARELI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413601,
    "City": "TULJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517129,
    "City": "KANIPAKAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421006,
    "City": "AMBARNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421504,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421507,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382001,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380010,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382360,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382316,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380038,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380020,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380057,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380062,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396006,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380045,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365610,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387325,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388560,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388370,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388140,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388305,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388440,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455003,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382005,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382015,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382014,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382013,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382019,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382018,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382004,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382045,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382044,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370203,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403714,
    "City": "CURCHOREM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403603,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403717,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403719,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403723,
    "City": "MARGAO",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403730,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671122,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671318,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671319,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671128,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671125,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691005,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691017,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691018,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691588,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686009,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518015,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518019,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518020,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518023,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387320,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387370,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387345,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387315,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387360,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518508,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518512,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518593,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385002,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492011,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444907,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444908,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485005,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333027,
    "City": "SULTANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518135,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518155,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518157,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325209,
    "City": "ANTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325208,
    "City": "SULTANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470117,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 494010,
    "City": "BASTAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360380,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333024,
    "City": "BAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321023,
    "City": "KAMAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303504,
    "City": "LALSOT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517326,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384012,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396421,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396427,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396424,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396450,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396415,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396433,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396463,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396475,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301020,
    "City": "NEEMRANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364245,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384229,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384255,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384275,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333035,
    "City": "CHIRAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516390,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517584,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441202,
    "City": "UMRED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441801,
    "City": "GONDIA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441905,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331506,
    "City": "SALASAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341306,
    "City": "LADNUN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331302,
    "City": "Taranagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360440,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360450,
    "City": "UPLETA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395012,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394185,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395013,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394211,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394325,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 395011,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394212,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363040,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400609,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676104,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680026,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380012,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391410,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391346,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396035,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401200,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401214,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401204,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401206,
    "City": "VASAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303106,
    "City": "PAOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303121,
    "City": "SAHPURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695030,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516441,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516439,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516433,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516431,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516412,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516411,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370040,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443002,
    "City": "BULDHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531027,
    "City": "MADUGULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531028,
    "City": "MADUGULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532201,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532195,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814101,
    "City": "DUMKA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814103,
    "City": "SHIKARIPARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814110,
    "City": "DUMKA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814118,
    "City": "JARMUNDI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814141,
    "City": "JARMUNDI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721401,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721441,
    "City": "DIGHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721428,
    "City": "DIGHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721463,
    "City": "DIGHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306104,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306103,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331803,
    "City": "SHRI DUNGARGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342301,
    "City": "PHALODI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231219,
    "City": "OBRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193222,
    "City": "Kupwara",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425408,
    "City": "DONDAICHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425406,
    "City": "DONDAICHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425418,
    "City": "NAVAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425426,
    "City": "NAVAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423301,
    "City": "SATANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423204,
    "City": "SATANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425306,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425310,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422103,
    "City": "SINNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422501,
    "City": "DEOLALI CAMP",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451551,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451556,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457887,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457990,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431127,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431212,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410201,
    "City": "KARJAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410101,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410203,
    "City": "Khopoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410204,
    "City": "Khopoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472336,
    "City": "PRITHVIPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472111,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472115,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 472442,
    "City": "NIWARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413606,
    "City": "OMERGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413605,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493111,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360410,
    "City": "DHORAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360421,
    "City": "DHORAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360375,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396360,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362560,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362565,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362510,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362550,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362530,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362520,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362570,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362540,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464221,
    "City": "GANJ BASODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410503,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425428,
    "City": "Shirpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495668,
    "City": "Champa",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484001,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484110,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484117,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484114,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591312,
    "City": "MUDALGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571430,
    "City": "MALAVALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571422,
    "City": "MALAVALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576214,
    "City": "BYNDOOR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576213,
    "City": "BRAHMAVAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572127,
    "City": "MADHUGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572132,
    "City": "MADHUGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572133,
    "City": "MADHUGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403702,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403728,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403727,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504218,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504272,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504292,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504220,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534442,
    "City": "DENDULURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504201,
    "City": "CHENNUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504204,
    "City": "CHENNUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521135,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521131,
    "City": "CHALLAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521125,
    "City": "CHALLAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303012,
    "City": "KANOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457226,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364001,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799210,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423105,
    "City": "MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423201,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423202,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423203,
    "City": "MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811212,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576232,
    "City": "BYNDOOR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577132,
    "City": "MUDIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480105,
    "City": "SAUSAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480109,
    "City": "SAUSAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670102,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670105,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670103,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670107,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670106,
    "City": "PINARAYI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670641,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670672,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670671,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606110,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607302,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 607805,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606002,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332715,
    "City": "SHRI MADHOPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443302,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443301,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443303,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534216,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534217,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504295,
    "City": "ASIFABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504273,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504299,
    "City": "KAGAZNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793150,
    "City": "JOWAI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 791110,
    "City": "NAHARLAGUN",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 791109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 791112,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 576201,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576225,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576257,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576231,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576230,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576211,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576235,
    "City": "Shiroor",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576247,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576216,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576224,
    "City": "Shiroor",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576254,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576226,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731237,
    "City": "LOHAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731224,
    "City": "LOHAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731220,
    "City": "LOHAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742184,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742225,
    "City": "JANGIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742235,
    "City": "SAGARDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742226,
    "City": "SAGARDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670649,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670662,
    "City": "PINARAYI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682307,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 604504,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606803,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606903,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606905,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606907,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632312,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632313,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632314,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632315,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632316,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632317,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632318,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632319,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632326,
    "City": "ARANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691602,
    "City": "KOLLAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691015,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691014,
    "City": "Kollam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507138,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507154,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507103,
    "City": "Kothagudem",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576217,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576222,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576221,
    "City": "KUNDAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508377,
    "City": "HALIYA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752001,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752003,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721602,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721607,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721635,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721604,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721657,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756001,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756002,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756003,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756019,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756056,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504209,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534111,
    "City": "NALLAJERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630561,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721102,
    "City": "MIDNAPORE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721129,
    "City": "MIDNAPORE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625518,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625521,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625522,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625524,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625525,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625526,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625530,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625556,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731101,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731102,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731103,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731104,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731126,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731129,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671351,
    "City": "PADANNA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671310,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671315,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431512,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490042,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490021,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490025,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497442,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497446,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497447,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412803,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691577,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691576,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691504,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758001,
    "City": "KEONJHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758002,
    "City": "KEONJHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758013,
    "City": "KEONJHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758014,
    "City": "KEONJHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403808,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712201,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712202,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712203,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712204,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712222,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712223,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712234,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712245,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712246,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712248,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 833220,
    "City": "SARAIKELAKHARSWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 833219,
    "City": "SARAIKELAKHARSWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825409,
    "City": "JHUMRITILEIYA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825410,
    "City": "JHUMRITILEIYA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742212,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742236,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742202,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732210,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732215,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712611,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784125,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784146,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784529,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784145,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825301,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825302,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825402,
    "City": "ICHAK",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 561202,
    "City": "PAVAGADA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636306,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534227,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534328,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742101,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742102,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742103,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742134,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742149,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742160,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742165,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742187,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742302,
    "City": "KALADANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742407,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742408,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841301,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841302,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841311,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841414,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841316,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822101,
    "City": "SHAHPUR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822102,
    "City": "SHAHPUR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822110,
    "City": "DALTONGANJ",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523334,
    "City": "CUMBUM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523370,
    "City": "CUMBUM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518533,
    "City": "Atmakur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741404,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741402,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741317,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741161,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741121,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756026,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756034,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756084,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741301,
    "City": "NABAWADIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756100,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756101,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756135,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756181,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415521,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412102,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415306,
    "City": "ATPADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494226,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491995,
    "City": "KAVARDA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491226,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412210,
    "City": "SHIRUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412211,
    "City": "NHAWARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442908,
    "City": "GADCHANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442917,
    "City": "GADCHANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444503,
    "City": "JAHANGIRMALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444504,
    "City": "JAHANGIRMALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535522,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535527,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535502,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206129,
    "City": "AURAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206121,
    "City": "AURAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206122,
    "City": "AURAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206244,
    "City": "AURAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275102,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263153,
    "City": "RUDRAPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263160,
    "City": "RUDRAPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761026,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761045,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604001,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604101,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604102,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604154,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604206,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604207,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604301,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604302,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604304,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604305,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604306,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604307,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176047,
    "City": "NAGROTA BAGWAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176209,
    "City": "GAGAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176001,
    "City": "GAGAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574116,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574111,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445205,
    "City": "MAHAGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431721,
    "City": "MAHAGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361335,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361345,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361347,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361350,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370615,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370665,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370670,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364290,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454446,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454335,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454331,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454660,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454221,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518380,
    "City": "PATTIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518390,
    "City": "PATTIKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673638,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676305,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422209,
    "City": "OZHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631001,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631002,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631003,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631004,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631006,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631151,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631209,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631211,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631213,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577301,
    "City": "BHADRAVATHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521121,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521120,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587313,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 854301,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854302,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854303,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854304,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854305,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854326,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854330,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841428,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841440,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841427,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841505,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465001,
    "City": "SHAJAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465106,
    "City": "SHAJAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465110,
    "City": "SHAJAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465697,
    "City": "SARANGPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486885,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 486886,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 486890,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782447,
    "City": "LUMDING",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606205,
    "City": "RISHIVANDHIYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606203,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606207,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606206,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606401,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636404,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636406,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636452,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636456,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626139,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626125,
    "City": "SRIVILLIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626121,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626111,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577113,
    "City": "MUDIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581304,
    "City": "Karwar",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571122,
    "City": "TNARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571101,
    "City": "TNARASIPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504103,
    "City": "BHAINSA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504102,
    "City": "BHAINSA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504109,
    "City": "GAJULA NARSAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534313,
    "City": "DEVARAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534316,
    "City": "DEVARAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516309,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516289,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441901,
    "City": "SADAK ARJUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441701,
    "City": "ARJUNI MORGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413515,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413203,
    "City": "KARMALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454001,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454116,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454441,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454010,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413510,
    "City": "SONARI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413511,
    "City": "LATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413508,
    "City": "SONARI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413509,
    "City": "SONARI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416408,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416303,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416309,
    "City": "PALUS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695103,
    "City": "KOTTIYOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695607,
    "City": "KOTTIYOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695618,
    "City": "Kilimanoor",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695317,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695308,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695612,
    "City": "Kilimanoor",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695104,
    "City": "KOTTIYOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695304,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695606,
    "City": "KOTTIYOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743245,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743262,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743405,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743701,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743130,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562112,
    "City": "HAROHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 303107,
    "City": "PAOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425501,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679304,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534236,
    "City": "AKIVIDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441908,
    "City": "PAUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441910,
    "City": "PAUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441903,
    "City": "PAUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786006,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785664,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785662,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786007,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 601016,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828403,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828404,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828306,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828401,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828307,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828305,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828125,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828128,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828402,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829114,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829144,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825102,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829132,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829127,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828202,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828204,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828203,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828206,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828207,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 222001,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222002,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274001,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274182,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 274201,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274501,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 283203,
    "City": "FIROZABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583283,
    "City": "KANAKGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125007,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125011,
    "City": "HISSAR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691582,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534146,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534156,
    "City": "ATTILI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534196,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534166,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783124,
    "City": "DUDHNOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783122,
    "City": "DUDHNOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783134,
    "City": "DUDHNOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641201,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641111,
    "City": "MALUMICHAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176061,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176083,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176067,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176102,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176103,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176062,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829121,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827302,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781124,
    "City": "CHAYGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781141,
    "City": "CHAYGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781137,
    "City": "CHAYGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782123,
    "City": "DHING",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782122,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486661,
    "City": "SIDHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465331,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465335,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465227,
    "City": "SARANGPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465116,
    "City": "POLAY KALAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534460,
    "City": "CHINTALAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534467,
    "City": "CHINTALAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415315,
    "City": "ATPADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444728,
    "City": "ANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680547,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680551,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680552,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680545,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680603,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680623,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695562,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 791102,
    "City": "PASHIGHAT",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465691,
    "City": "ZIRAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465679,
    "City": "ZIRAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465447,
    "City": "Nalkheda",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561207,
    "City": "BAGEPALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783129,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 783132,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 783330,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 570000,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742159,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742147,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731244,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742138,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742137,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742168,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741221,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741225,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741249,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741252,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741257,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752106,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752105,
    "City": "PIPILI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752114,
    "City": "PIPILI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752113,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752110,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712514,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712501,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712515,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712503,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712513,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712504,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815352,
    "City": "KARON",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815353,
    "City": "MADHUPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768225,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768218,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768217,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768211,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796081,
    "City": "KOLASIB",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796082,
    "City": "KOLASIB",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 791113,
    "City": "ITANAGAR",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 791111,
    "City": "ITANAGAR",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 272150,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711224,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711205,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711323,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572123,
    "City": "KOPPAMANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572120,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572142,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562127,
    "City": "MAGADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533287,
    "City": "KATERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533286,
    "City": "KOTHAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533289,
    "City": "KOTHAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583115,
    "City": "KUDATHINI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507117,
    "City": "MANUGURU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507116,
    "City": "MANUGURU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507125,
    "City": "MANUGURU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752104,
    "City": "PIPILI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752100,
    "City": "Bhubaneswar",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752046,
    "City": "DELANG",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815354,
    "City": "JAMTARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815351,
    "City": "JAMTARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521158,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521157,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829206,
    "City": "LATEHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829207,
    "City": "LATEHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144201,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852131,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852138,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475110,
    "City": "DABRA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 475115,
    "City": "DABRA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475220,
    "City": "DABRA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533464,
    "City": "YANAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533463,
    "City": "YANAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533461,
    "City": "YANAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422102,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695121,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695122,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695501,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695503,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695042,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413507,
    "City": "KALLAMB",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176201,
    "City": "JASSUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176202,
    "City": "JASSUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176204,
    "City": "RAJATALAB HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176200,
    "City": "JASSUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176022,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176023,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176051,
    "City": "RAJATALAB HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176021,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712149,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712147,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712135,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712134,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712148,
    "City": "PANDUA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743513,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743368,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712310,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712311,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712702,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712708,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712124,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712502,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712137,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712121,
    "City": "DUMURDAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754029,
    "City": "ATHAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 790115,
    "City": "BOMDILA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 790003,
    "City": "BOMDILA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 790001,
    "City": "BOMDILA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713146,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713422,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713408,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713154,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713151,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713166,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431215,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431202,
    "City": "JALNA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481665,
    "City": "MANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481771,
    "City": "MANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481998,
    "City": "BICHIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370475,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415511,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480884,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480882,
    "City": "Seoni",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451666,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 225414,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125125,
    "City": "Tohana",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431511,
    "City": "PURNA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742213,
    "City": "JANGIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742223,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742201,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733252,
    "City": "KALIAGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733150,
    "City": "KALIAGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413521,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732126,
    "City": "CHACHAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413502,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431505,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431506,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431516,
    "City": "SONPETH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431541,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431501,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431207,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441401,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441106,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832402,
    "City": "GAMHARIAJH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361305,
    "City": "KHAMBHALIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361310,
    "City": "KHAMBHALIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361306,
    "City": "KHAMBHALIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361315,
    "City": "BHATIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534330,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533274,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464993,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441222,
    "City": "SINDEWAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441224,
    "City": "SINDEWAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441223,
    "City": "SINDEWAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441221,
    "City": "SINDEWAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573102,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573142,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 798601,
    "City": "MOKOKCHUNG",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798627,
    "City": "MOKOKCHUNG",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797001,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783348,
    "City": "BILASHIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783345,
    "City": "BILASHIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783349,
    "City": "BILASHIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695602,
    "City": "NILAMEL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695604,
    "City": "NILAMEL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695614,
    "City": "Kilimanoor",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695601,
    "City": "Kilimanoor",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765001,
    "City": "RAYAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815316,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847215,
    "City": "JAYNAGAR BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847214,
    "City": "JAYNAGAR BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742237,
    "City": "SAGARDIGHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712614,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722161,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712616,
    "City": "GOGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721449,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721444,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721433,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515541,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518323,
    "City": "Yemmiganur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534218,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431503,
    "City": "SELU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431502,
    "City": "SELU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333705,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333702,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444301,
    "City": "CHIKHLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444307,
    "City": "KHAMGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848130,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363421,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363422,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363420,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743329,
    "City": "CANNING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743376,
    "City": "CANNING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742161,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742301,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742227,
    "City": "JANGIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713501,
    "City": "GUPTIPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841238,
    "City": "MAHARAJGANJ BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841233,
    "City": "MAHARAJGANJ BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852201,
    "City": "SAHARSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852202,
    "City": "SAHARSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845401,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845450,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845438,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851134,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854105,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854106,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854103,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246174,
    "City": "SRINAGAR GARHWAL",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465661,
    "City": "BIAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465669,
    "City": "KURAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465677,
    "City": "MAKSUDANGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412205,
    "City": "Khandala",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412801,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412802,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443204,
    "City": "DEULGAON RAJA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443203,
    "City": "DEULGAON RAJA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797103,
    "City": "CHUMUKIDIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643214,
    "City": "KOTIGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422402,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422403,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413503,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572130,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521235,
    "City": "TIRUVURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721430,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721425,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721432,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 452020,
    "City": "LIMBODI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453556,
    "City": "LIMBODI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453331,
    "City": "LIMBODI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394680,
    "City": "SONGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425416,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394375,
    "City": "UCHCHHAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394670,
    "City": "SONGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425417,
    "City": "NAVAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425452,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425419,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394380,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761027,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761052,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761003,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761025,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835217,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835125,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846008,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 846007,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841226,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844102,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781132,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413714,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413737,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413736,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441205,
    "City": "BHIWAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442903,
    "City": "CHIMUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757001,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757002,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757003,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145022,
    "City": "BASOLI JK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416103,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416102,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416106,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445206,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445207,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442201,
    "City": "ARVI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442204,
    "City": "ARVI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442202,
    "City": "KARANJAGHADGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144532,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144527,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144526,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144525,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144524,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144518,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144523,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144516,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144517,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144515,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144514,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144513,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144512,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144511,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144509,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144510,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144506,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144505,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144508,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144507,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144422,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144421,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144417,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144029,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444708,
    "City": "NANDGAON KHANDESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445102,
    "City": "DARWAH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457779,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457993,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141115,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141114,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141121,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141125,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431701,
    "City": "Akhadabalapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413213,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457661,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176206,
    "City": "SHAHPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176205,
    "City": "SHAHPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176208,
    "City": "SHAHPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412408,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416203,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416202,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754025,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754021,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754028,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754100,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835225,
    "City": "Tamar",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412410,
    "City": "ALEPHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412412,
    "City": "OTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412411,
    "City": "ALEPHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832303,
    "City": "GHATSHILA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832104,
    "City": "GHATSHILA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832103,
    "City": "GHATSHILA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832302,
    "City": "DHALBHUMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783390,
    "City": "CHIRANG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176401,
    "City": "INDORA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176402,
    "City": "INDORA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176403,
    "City": "INDORA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176404,
    "City": "INDORA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521286,
    "City": "GANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534311,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534318,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185153,
    "City": "SUNDERBANI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 185151,
    "City": "NOWSHERA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 174021,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174023,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174031,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174929,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174027,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412401,
    "City": "OTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412409,
    "City": "OTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522659,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481116,
    "City": "UKWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825314,
    "City": "GHATOTAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829134,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825326,
    "City": "CHARHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825325,
    "City": "GHATOTAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754250,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754211,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754289,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743347,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743371,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721121,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721253,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721147,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766014,
    "City": "JUNAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766002,
    "City": "BHAWANIPATNA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766015,
    "City": "DHARAMGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845305,
    "City": "RAXUAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845433,
    "City": "RAXUAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360530,
    "City": "JAMJODHPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505454,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505526,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302031,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321408,
    "City": "HALENA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523345,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515803,
    "City": "GUNTAKAL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835207,
    "City": "GUMLA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802101,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 770001,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 770002,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342027,
    "City": "BANAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534315,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181141,
    "City": "VIJAYPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181134,
    "City": "VIJAYPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460551,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460553,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425415,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246749,
    "City": "NAJIBABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503302,
    "City": "YELLAREDDY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503108,
    "City": "YELLAREDDY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503124,
    "City": "YELLAREDDY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690521,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690520,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691502,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691500,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691584,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691590,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756024,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756023,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756081,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756083,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 185131,
    "City": "RAJOURI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185132,
    "City": "RAJOURI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629152,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629153,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629163,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629170,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629172,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629176,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629151,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629161,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629166,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629167,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629177,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629179,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629191,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614702,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614706,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614711,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614712,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614716,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 477117,
    "City": "GOHAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713152,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174307,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174308,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177031,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306116,
    "City": "FALNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484661,
    "City": "UMARIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 744101,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744102,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744103,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744104,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744105,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756035,
    "City": "CHANDANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756036,
    "City": "CHANDANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756085,
    "City": "CHANDANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721446,
    "City": "CHANDANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574146,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575019,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575014,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574154,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574150,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575030,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 575025,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574103,
    "City": "SURATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670301,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670302,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670304,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670303,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670358,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670325,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326502,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326513,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284301,
    "City": "CHIRGAON",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284305,
    "City": "CHIRGAON",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284306,
    "City": "POONCH UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284304,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 284302,
    "City": "POONCH UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244901,
    "City": "RAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676304,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676306,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676309,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676503,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676508,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670141,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670142,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670561,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670564,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670563,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670562,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670565,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670567,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451449,
    "City": "JULWANIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416208,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416219,
    "City": "MUDHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416212,
    "City": "MUDHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416209,
    "City": "MUDHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 483770,
    "City": "BARHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733103,
    "City": "BALURGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733133,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733153,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 733155,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 733158,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733154,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461005,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466331,
    "City": "NASRULLAGUNJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691305,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691331,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382305,
    "City": "DEHGAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382308,
    "City": "DEHGAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497451,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497553,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431717,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431718,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416612,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416613,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721423,
    "City": "DIGHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761029,
    "City": "KESHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761030,
    "City": "KESHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761028,
    "City": "KESHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414506,
    "City": "SHEVGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414507,
    "City": "SHEVGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414508,
    "City": "SHEVGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413506,
    "City": "BEMBLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413525,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413528,
    "City": "SONARI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370150,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362268,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362269,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431126,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413526,
    "City": "BHOOM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782124,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782125,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782142,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782141,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782102,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713409,
    "City": "GUPTIPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784115,
    "City": "Mongoldoi",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784116,
    "City": "Mongoldoi",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476221,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626203,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626204,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522611,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522619,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522233,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532455,
    "City": "KOTTURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532456,
    "City": "KOTTURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532459,
    "City": "KOTTURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532457,
    "City": "KOTTURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799250,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799253,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799251,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 732144,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415408,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415412,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761102,
    "City": "HINJILICUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761146,
    "City": "HINJILICUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785614,
    "City": "DERGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785618,
    "City": "DERGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623701,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623707,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623706,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630709,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630702,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533436,
    "City": "KOYYURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531087,
    "City": "KOYYURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642019,
    "City": "KINATHUKADAVU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731233,
    "City": "RAMPURHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731216,
    "City": "RAMPURHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424304,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424306,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424305,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766001,
    "City": "BHAWANIPATNA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425114,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 184201,
    "City": "BASOLI JK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174029,
    "City": "SHAH TALAI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174004,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176301,
    "City": "DALHOUSIE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176026,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331303,
    "City": "Rajgarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331028,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516311,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522256,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522257,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 831015,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571438,
    "City": "SRIRANGAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721626,
    "City": "BHAGABANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721655,
    "City": "BHAGABANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721633,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721632,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721625,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721659,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722154,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722141,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722138,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721146,
    "City": "SONAKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721148,
    "City": "SONAKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721211,
    "City": "SONAKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305601,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332402,
    "City": "SIKAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759122,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759145,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759128,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759123,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759143,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786623,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786622,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786621,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786614,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785104,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785106,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577302,
    "City": "BHADRAVATHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175015,
    "City": "JOGINDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176081,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176077,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176012,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175032,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173210,
    "City": "DHARAMPUR SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173209,
    "City": "DHARAMPUR SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173220,
    "City": "PARWANOO",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173204,
    "City": "DHARAMPUR SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173202,
    "City": "DHARAMPUR SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786187,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481051,
    "City": "UKWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481111,
    "City": "UKWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410511,
    "City": "OTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415513,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415515,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384205,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384225,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384120,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629165,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629158,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629171,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 762014,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843109,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691526,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691523,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691529,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284123,
    "City": "LALITPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365540,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365560,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365541,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416220,
    "City": "UTTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416218,
    "City": "Gadhingalaj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415404,
    "City": "KASEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415406,
    "City": "KASEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431142,
    "City": "Majalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755019,
    "City": "JAJPUR ROAD",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755020,
    "City": "JAJPUR ROAD",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755015,
    "City": "JAJPUR ROAD",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755026,
    "City": "JAJPUR ROAD",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572137,
    "City": "Sira",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572125,
    "City": "Sira",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572115,
    "City": "Sira",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572139,
    "City": "Sira",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768028,
    "City": "BARGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768040,
    "City": "BARGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767017,
    "City": "SONEPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767016,
    "City": "TARBHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423701,
    "City": "VAIJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423703,
    "City": "VAIJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364515,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503180,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365630,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365430,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365535,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614401,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614404,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614625,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 176053,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176025,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176211,
    "City": "CHOWARI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176063,
    "City": "PALAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176120,
    "City": "JOGINDER NAGAR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176128,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176020,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175033,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342304,
    "City": "MANDOR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306702,
    "City": "DESURI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413712,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413713,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413738,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734209,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173030,
    "City": "KALA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173001,
    "City": "SIRMAUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416512,
    "City": "DODAMARG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416549,
    "City": "DODAMARG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481445,
    "City": "KATANGI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481449,
    "City": "KATANGI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481776,
    "City": "NAINPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676542,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679329,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679330,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679334,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679331,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679339,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361320,
    "City": "BHATIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735214,
    "City": "KALCHINI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735215,
    "City": "KALCHINI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825421,
    "City": "JHUMRITILEIYA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731204,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731235,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516267,
    "City": "GALIVEEDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364760,
    "City": "GATHDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573165,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573214,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736207,
    "City": "BAROBISHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736206,
    "City": "HATIPOTHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736202,
    "City": "BAROBISHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761144,
    "City": "SHERAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414602,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414105,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414606,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414607,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673611,
    "City": "KAKKODI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673616,
    "City": "KAKKODI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 822111,
    "City": "BARWADIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125039,
    "City": "NARNAUND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394601,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394620,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824211,
    "City": "SHERGHATI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484220,
    "City": "ANUPPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484330,
    "City": "ANUPPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425303,
    "City": "CHOPDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415528,
    "City": "PHALTAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673582,
    "City": "KODANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572112,
    "City": "MADHUGIRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415401,
    "City": "KAMERI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415403,
    "City": "KAMERI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415407,
    "City": "KAMERI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575020,
    "City": "ULLALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695544,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413112,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363410,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425311,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394650,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394651,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743246,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743251,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451447,
    "City": "JULWANIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841506,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845449,
    "City": "NARKATIAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802119,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802116,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829119,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829108,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829126,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829125,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835215,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712512,
    "City": "GUPTIPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735213,
    "City": "MADARIHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752012,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752014,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756111,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756127,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756116,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731234,
    "City": "SURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497449,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781128,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676528,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676311,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504206,
    "City": "DANDEPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504215,
    "City": "DANDEPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574243,
    "City": "VITLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413012,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413013,
    "City": "JAWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415101,
    "City": "BAMBAVADE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416213,
    "City": "BAMBAVADE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416215,
    "City": "BAMBAVADE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742229,
    "City": "JANGIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742139,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742171,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742140,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521328,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742224,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742231,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742232,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517247,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534341,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521403,
    "City": "GAMPALAGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516310,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741159,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741127,
    "City": "SANTIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518599,
    "City": "BETHAMCHERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518206,
    "City": "BETHAMCHERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516172,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516193,
    "City": "PORUMAMILLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509127,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509135,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141421,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141412,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141416,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688524,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 572227,
    "City": "TURVEKERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576228,
    "City": "Shiroor",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423107,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423109,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413719,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691510,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573125,
    "City": "JAVAGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174321,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394345,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394310,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394250,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394350,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394355,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382460,
    "City": "DHANDUKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515767,
    "City": "Kalyanadurgam",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531114,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531115,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531085,
    "City": "NARSIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689510,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689520,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689508,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689121,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689126,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690562,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690574,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690575,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691586,
    "City": "THODIYOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690536,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670594,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670591,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670601,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670602,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670604,
    "City": "TALIPARAMBA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670331,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670621,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 673661,
    "City": "MUKKAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680617,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680613,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680614,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680569,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680619,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680703,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680691,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680564,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680571,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523212,
    "City": "MEDARAMETLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523213,
    "City": "MEDARAMETLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627105,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629301,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629305,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629304,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629303,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629302,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627109,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444801,
    "City": "WALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484886,
    "City": "RAJENDRAGRAM",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785670,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785675,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785669,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 576215,
    "City": "Cherkady",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799280,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799279,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799277,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799282,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799281,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799211,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799212,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416804,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148034,
    "City": "Dhuri",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743357,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743373,
    "City": "GANGASAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148029,
    "City": "LEHRAGAGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141113,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141126,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141124,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326520,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326517,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833216,
    "City": "SARAIKELAKHARSWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207123,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 562120,
    "City": "MAGADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176302,
    "City": "CHOWARI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505445,
    "City": "GANGADHARA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505531,
    "City": "GANGADHARA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505524,
    "City": "GANGADHARA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505501,
    "City": "GANGADHARA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638401,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638402,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638503,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638451,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638454,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799289,
    "City": "AMBASA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799204,
    "City": "AMBASA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799278,
    "City": "AMBASA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509153,
    "City": "ALAMPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835216,
    "City": "KHUNTI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835210,
    "City": "KHUNTI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507124,
    "City": "YELLANDU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507123,
    "City": "YELLANDU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493889,
    "City": "GARIYABANDH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441209,
    "City": "KURKHUDE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470226,
    "City": "DEORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629703,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629403,
    "City": "ANJUGRAMAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629402,
    "City": "ANJUGRAMAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629401,
    "City": "ANJUGRAMAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627127,
    "City": "ANJUGRAMAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627116,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627114,
    "City": "ANJUGRAMAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627106,
    "City": "KOODANKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695547,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504293,
    "City": "ASIFABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504202,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628207,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628601,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628211,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628613,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628612,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628617,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628616,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627657,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628704,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797106,
    "City": "CHUMUKIDIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814133,
    "City": "GODDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814147,
    "City": "GODDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848127,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518510,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518598,
    "City": "BETHAMCHERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518217,
    "City": "BETHAMCHERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797115,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360480,
    "City": "JAMJODHPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360520,
    "City": "JAMJODHPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413214,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480994,
    "City": "NAINPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481668,
    "City": "NAINPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382150,
    "City": "VIRAMGAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533237,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534331,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431113,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431114,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464886,
    "City": "SILWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784101,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782136,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785612,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 785609,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785615,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799155,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799159,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799156,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799144,
    "City": "SABROOM",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781328,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781329,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 851213,
    "City": "KHAGARIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811311,
    "City": "LAKHISARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460443,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754032,
    "City": "NARASINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754037,
    "City": "NARASINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844125,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844114,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844117,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844119,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131023,
    "City": "Kundli",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801305,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205001,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205264,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205247,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 205265,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175024,
    "City": "SARKAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175042,
    "City": "SARKAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175037,
    "City": "SARKAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175007,
    "City": "SARKAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175040,
    "City": "DHARAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177201,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177206,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177207,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177208,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177202,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177203,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853204,
    "City": "NAUGACHIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761018,
    "City": "PURSHOTAMPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761019,
    "City": "PURSHOTAMPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761043,
    "City": "PURSHOTAMPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755044,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754296,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754024,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755024,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767033,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767035,
    "City": "SINDHEKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767032,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767037,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507168,
    "City": "ENKOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522614,
    "City": "PIDUGURALLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522437,
    "City": "PIDUGURALLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796002,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796003,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 796006,
    "City": "AIZAWL",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 573135,
    "City": "KIKKERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451881,
    "City": "Borad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425432,
    "City": "BORAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425424,
    "City": "BORAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425442,
    "City": "Borad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396321,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396380,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396325,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210001,
    "City": "BANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176027,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176024,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629851,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629854,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629852,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629902,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629855,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466114,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416405,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416418,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572214,
    "City": "CHIKKANAYAKANAHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572114,
    "City": "CHIKKANAYAKANAHALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493114,
    "City": "TILDA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495555,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497226,
    "City": "BISHRAMPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441805,
    "City": "PALANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458883,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782460,
    "City": "DIPHU",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782462,
    "City": "DIPHU",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231217,
    "City": "RENUKOOT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231225,
    "City": "RENUKOOT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695521,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695522,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695523,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695527,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767020,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 487118,
    "City": "GOTEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 483113,
    "City": "TENDUKHEDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 762013,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 762015,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 762024,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415713,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415714,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470223,
    "City": "DEORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732127,
    "City": "DHULIAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229120,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458664,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521132,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521138,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521390,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363610,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363415,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382255,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509144,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532203,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799022,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534461,
    "City": "CHINTALAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788103,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788106,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788126,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788107,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788098,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788104,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788102,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828120,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828107,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782105,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782104,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782412,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825320,
    "City": "BAGODAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825322,
    "City": "BAGODAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503218,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503219,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342037,
    "City": "JODHPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721132,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721128,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721127,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523165,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306701,
    "City": "FALNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136026,
    "City": "KAITHAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136042,
    "City": "KAITHAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136117,
    "City": "NARWANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 185152,
    "City": "NOWSHERA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 127045,
    "City": "SIWANI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431705,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493996,
    "City": "GARIYABANDH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492109,
    "City": "MAHASAMUND",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431702,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412404,
    "City": "RAJGURUNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743710,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741223,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743290,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743456,
    "City": "BOKCHORA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743425,
    "City": "BOKCHORA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743502,
    "City": "BHANGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743442,
    "City": "BOKCHORA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207241,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 207242,
    "City": "PATIYALI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207243,
    "City": "PATIYALI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207244,
    "City": "JAITHRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207246,
    "City": "PATIYALI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207247,
    "City": "JAITHRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207248,
    "City": "PATIYALI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629601,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629602,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414701,
    "City": "KASHTI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497335,
    "City": "BAIKUNTHPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444302,
    "City": "BALAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412209,
    "City": "RANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412220,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458667,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473226,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413513,
    "City": "Chakur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486889,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 486888,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 486887,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 516356,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516362,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516203,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363250,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363530,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493554,
    "City": "BASNA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465445,
    "City": "Nalkheda",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431123,
    "City": "Kaij",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431124,
    "City": "Kaij",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735224,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 735301,
    "City": "MEKHLIGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754202,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754207,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754209,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676552,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679338,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676553,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676551,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574235,
    "City": "VITLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 787001,
    "City": "LAKHIMPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 787031,
    "City": "LAKHIMPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 766023,
    "City": "JUNAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480888,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480999,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480887,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416121,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752108,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761100,
    "City": "HINJILICUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761101,
    "City": "HINJILICUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535213,
    "City": "BOGAPPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470229,
    "City": "Garhakota",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305624,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144529,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721212,
    "City": "SONAKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515405,
    "City": "PAMIDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515611,
    "City": "PAMIDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517131,
    "City": "KANIPAKAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262701,
    "City": "LAKHIMPUR KHIRI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577228,
    "City": "TARIKERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577128,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577115,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577144,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577451,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785635,
    "City": "MARIANI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785683,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680589,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680585,
    "City": "TRICHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385320,
    "City": "Bhabhar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385330,
    "City": "Bhabhar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363520,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413710,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743249,
    "City": "HABRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722121,
    "City": "KHATRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722171,
    "City": "KHATRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423602,
    "City": "KOPARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741256,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741232,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741255,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741254,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415639,
    "City": "RATNAGIRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848129,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722206,
    "City": "SONAMUKHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484555,
    "City": "UMARIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484551,
    "City": "UMARIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458990,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604202,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495454,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495452,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495117,
    "City": "Pendra",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495119,
    "City": "Pendra",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458339,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781101,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785110,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785102,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785105,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742181,
    "City": "NAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 345021,
    "City": "JAISALMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445304,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442503,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416812,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 277208,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 277216,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 277205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 210431,
    "City": "RATH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274509,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274808,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274506,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431804,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177101,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177103,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177114,
    "City": "RANITAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177104,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177108,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221506,
    "City": "SAHSON",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673506,
    "City": "KALLACHI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262405,
    "City": "SITARGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262308,
    "City": "KHATIMA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670143,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484776,
    "City": "BEOHARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484774,
    "City": "BEOHARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177033,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177118,
    "City": "JAHU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177042,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177048,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177046,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176064,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176084,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176101,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176060,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176085,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143111,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143203,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506164,
    "City": "PARKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506319,
    "City": "PARKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788010,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788015,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788014,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788011,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635111,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784144,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152102,
    "City": "MALOUT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691579,
    "City": "CHATHANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518165,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518196,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673122,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673121,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673123,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535221,
    "City": "S KOTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133201,
    "City": "DOSARKA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754030,
    "City": "ATHAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571117,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532291,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311021,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311022,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627111,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627113,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627131,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629180,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629807,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629809,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629810,
    "City": "KADUKKARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534206,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571441,
    "City": "KOLLEGALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571605,
    "City": "KR PETE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629707,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629708,
    "City": "PARAKKAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576219,
    "City": "Shiroor",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788166,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788734,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788736,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574109,
    "City": "NARAVI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574239,
    "City": "SULLIA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574115,
    "City": "KATAPADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571102,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571107,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785112,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785682,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785107,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788025,
    "City": "SRIKONA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788012,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788013,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788119,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518186,
    "City": "BANAGANAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392110,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362240,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754203,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754293,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731214,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731201,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676303,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394690,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394340,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721451,
    "City": "DANTAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721435,
    "City": "DANTAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721457,
    "City": "DANTAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484770,
    "City": "JAISINGHNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484771,
    "City": "JAISINGHNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363330,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335711,
    "City": "GHARSANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335771,
    "City": "GHARSANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712401,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712413,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712415,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712615,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799115,
    "City": "SONAMURA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504307,
    "City": "ICHODA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444904,
    "City": "CHANDUR RAILWAY",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444709,
    "City": "CHANDUR RAILWAY",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444107,
    "City": "KAMARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741140,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741123,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741125,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535270,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535580,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535260,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535273,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 832404,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 832401,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 581354,
    "City": "MURDESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581421,
    "City": "MURDESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581350,
    "City": "MURDESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571201,
    "City": "MADIKERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571237,
    "City": "SHUNTIKOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571252,
    "City": "MURNAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759111,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828115,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828131,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688555,
    "City": "CHERTHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688582,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 688526,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688541,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522234,
    "City": "CHILAKALURIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781040,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741238,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741253,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743270,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363351,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177301,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414106,
    "City": "PATHARDI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522203,
    "City": "Tenali",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679572,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679308,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486331,
    "City": "MAUGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486341,
    "City": "DEOTALAB",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735217,
    "City": "KALCHINI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735227,
    "City": "KALCHINI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497557,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497773,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497555,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721424,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721445,
    "City": "PASCHIM MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721133,
    "City": "PASCHIM MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629251,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629252,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629253,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629202,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629203,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629204,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629802,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629805,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629502,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523187,
    "City": "VETAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523184,
    "City": "VETAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523181,
    "City": "INKOLLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625514,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625532,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735222,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 190025,
    "City": "DARGAH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797113,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 212207,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493881,
    "City": "ABHANPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444126,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627120,
    "City": "KOODANKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497339,
    "City": "BAIKUNTHPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497331,
    "City": "BAIKUNTHPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444109,
    "City": "BALAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444501,
    "City": "PATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444502,
    "City": "BALAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493558,
    "City": "BASNA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484120,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442904,
    "City": "CHIMUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176031,
    "City": "JAWALA JI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493663,
    "City": "DHAMTARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425510,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146101,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144528,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441916,
    "City": "AMANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383230,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 383010,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 383110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 483504,
    "City": "KATNI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444306,
    "City": "KHAMGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444312,
    "City": "KHAMGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415621,
    "City": "KHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415710,
    "City": "KHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494237,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494228,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441702,
    "City": "KURKHUDE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495455,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495447,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497448,
    "City": "MANENDRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443306,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443305,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443307,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422222,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458553,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464651,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485446,
    "City": "NAGOD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458880,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458888,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630556,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630606,
    "City": "SIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493101,
    "City": "TILDA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493116,
    "City": "TILDA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445211,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441210,
    "City": "UMRED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442907,
    "City": "WARORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416204,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416205,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735210,
    "City": "DHUPGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732124,
    "City": "GAZOLE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731123,
    "City": "DUBRAJPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731124,
    "City": "DUBRAJPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731301,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731302,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731303,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731215,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731240,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470227,
    "City": "REHLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516380,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516444,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516401,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455336,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261001,
    "City": "SITAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793200,
    "City": "KHLIEHRIAT",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793160,
    "City": "KHLIEHRIAT",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384330,
    "City": "SATLASANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384340,
    "City": "SATLASANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384360,
    "City": "SATLASANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363621,
    "City": "WANKANER",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 283135,
    "City": "SHIKOHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221712,
    "City": "RASRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226101,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 226102,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695301,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695302,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695316,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695584,
    "City": "Venjaramoodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695585,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535547,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508284,
    "City": "POCHAMPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638458,
    "City": "NAMBIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638457,
    "City": "NAMBIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638460,
    "City": "NAMBIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721422,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721429,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721443,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 185101,
    "City": "POONCH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182144,
    "City": "RAMBAN",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493555,
    "City": "BASNA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444117,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736208,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425402,
    "City": "AMALNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695123,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695131,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695126,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522021,
    "City": "AMARAVATHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522436,
    "City": "AMARAVATHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754035,
    "City": "ATHAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456771,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742123,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756025,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756020,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 563116,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503321,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743428,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799142,
    "City": "SABROOM",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799150,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 490012,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503207,
    "City": "BODHAN",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 401504,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425444,
    "City": "BORAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425410,
    "City": "Borad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756038,
    "City": "GAZIPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756039,
    "City": "CHANDANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231207,
    "City": "CHOPAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736133,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736160,
    "City": "BAKSHIRHAT WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144202,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321206,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321201,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177117,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177107,
    "City": "DEHRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670334,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670581,
    "City": "NADUVIL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761013,
    "City": "SHERAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425110,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151213,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470666,
    "City": "Garhakota",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505188,
    "City": "Godavari Khani",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360312,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505498,
    "City": "HUZURABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505129,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505455,
    "City": "Jagtial",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425004,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735132,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431125,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788781,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362248,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507170,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507163,
    "City": "Khammam",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341509,
    "City": "NAWA RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680615,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680641,
    "City": "MANALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504207,
    "City": "MANCHERIAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 735219,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713401,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508217,
    "City": "MIRYALAGUDA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176036,
    "City": "JAWALA JI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177044,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177045,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574107,
    "City": "NARAVI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574122,
    "City": "NARAVI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466446,
    "City": "NASRULLAGUNJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752109,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754004,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761041,
    "City": "PURSHOTAMPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473222,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741167,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741502,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741505,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741151,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690538,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788156,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799125,
    "City": "SABROOM",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799104,
    "City": "AMARPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362256,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695525,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695526,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442914,
    "City": "WARORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442501,
    "City": "CHANDRAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152021,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521133,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695545,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695551,
    "City": "ARYANADU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522325,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522341,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522258,
    "City": "CHERUKUPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535240,
    "City": "KOTHAVALASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531219,
    "City": "KOTHAVALASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415405,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 509351,
    "City": "MARIKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509409,
    "City": "MARIKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509205,
    "City": "MARIKAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522410,
    "City": "KROSUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522409,
    "City": "KROSUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 123301,
    "City": "KOSLI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621717,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284205,
    "City": "MAURANIPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 284204,
    "City": "MAURANIPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627102,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627103,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627108,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627119,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630611,
    "City": "THIRUPPUVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630210,
    "City": "THIRUPPATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630211,
    "City": "THIRUPPATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799264,
    "City": "KUMARGHAT",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799290,
    "City": "KUMARGHAT",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799288,
    "City": "KUMARGHAT",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533429,
    "City": "YELESWARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533432,
    "City": "YELESWARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689645,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689646,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412204,
    "City": "SUPE MORGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412304,
    "City": "SUPE MORGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 382115,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 493196,
    "City": "BALODABAZAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493228,
    "City": "BALODABAZAR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495334,
    "City": "MUNGELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495330,
    "City": "TAKHATPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495115,
    "City": "LORMI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402110,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 402105,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402403,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581325,
    "City": "DANDELI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581329,
    "City": "HALIYAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581453,
    "City": "RAMNAGAR KAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591301,
    "City": "RAMNAGAR KAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 737134,
    "City": "SINGTAM",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735225,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752069,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752068,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752080,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752070,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752078,
    "City": "KANTILO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752077,
    "City": "KANTILO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752063,
    "City": "KANTILO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752065,
    "City": "RAJSUNAKHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752066,
    "City": "RAJSUNAKHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752064,
    "City": "RAJSUNAKHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752030,
    "City": "BALUGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752031,
    "City": "BALUGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752035,
    "City": "BALUGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629156,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629157,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629159,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629174,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629175,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629803,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629804,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788115,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788121,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788124,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788116,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713172,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731236,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581343,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581327,
    "City": "KAVALAKKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581330,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581440,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581332,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581333,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825167,
    "City": "DHAWATAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825106,
    "City": "DHAWATAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784168,
    "City": "GOHPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784169,
    "City": "GOHPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784178,
    "City": "GOHPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207403,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713332,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713336,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713337,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713339,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713360,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713362,
    "City": "HARIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756018,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756017,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743363,
    "City": "CANNING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845459,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827015,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802102,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802114,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802103,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736170,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814114,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814115,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814143,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713363,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721605,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721628,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721645,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721670,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825317,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825335,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735133,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854109,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721437,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721135,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732141,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732143,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801506,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829101,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769041,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822212,
    "City": "SAHARSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852124,
    "City": "SAHARSA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492101,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691521,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444104,
    "City": "Akola",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444125,
    "City": "DAHIHANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444608,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244245,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388350,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388122,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466221,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466117,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174304,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174309,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174312,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174320,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177039,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177041,
    "City": "GALORE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177119,
    "City": "BANGANA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722132,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503301,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483880,
    "City": "BARHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483775,
    "City": "BARHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492112,
    "City": "BASNA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143508,
    "City": "Batala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392162,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392163,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392221,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392013,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392014,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364002,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364004,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364003,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364006,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364005,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364055,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364061,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364071,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490027,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246721,
    "City": "BIJNOR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246764,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785699,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 202414,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444710,
    "City": "CHANDUR RAILWAY",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689511,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689123,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689124,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689506,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689623,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505525,
    "City": "Choppadandi",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470672,
    "City": "DAMOH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470675,
    "City": "DAMOH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470771,
    "City": "DAMOH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425202,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416805,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799254,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799260,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799261,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 799262,
    "City": "Dharmanagar",
    "State": "TRIPURA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 424301,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148017,
    "City": "Dhuri",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786172,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562163,
    "City": "Doddaballapura",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248202,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794112,
    "City": "DEKACHANG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491002,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491003,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491004,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680570,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680563,
    "City": "OLLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206003,
    "City": "ETAWAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206126,
    "City": "BAKEWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370202,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382043,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 737135,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 470232,
    "City": "Garhakota",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473249,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143537,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177027,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202139,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204105,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680305,
    "City": "IRINJALAKUDA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680694,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680698,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461446,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461445,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461551,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176043,
    "City": "BHOTA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756086,
    "City": "JALESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587119,
    "City": "Jamkhandi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361142,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815357,
    "City": "KARON",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457339,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326023,
    "City": "JHALAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362003,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742132,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392220,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722148,
    "City": "KHATRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431811,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305802,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481105,
    "City": "UKWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562131,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572126,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562201,
    "City": "MAGADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151510,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124127,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231312,
    "City": "MIRZAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142011,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142002,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444902,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444903,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221012,
    "City": "MUGHAL SARAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177038,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387230,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387350,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387510,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387570,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387421,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638462,
    "City": "NAMBIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591241,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591215,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591211,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591263,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176059,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176087,
    "City": "DAROH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441803,
    "City": "PALANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385515,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385010,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385210,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385521,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385011,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143535,
    "City": "Pathankot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145003,
    "City": "PATHANKOT",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147006,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147021,
    "City": "PATIALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 147007,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522125,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522318,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360576,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230002,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442107,
    "City": "PULGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516259,
    "City": "VEMPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723103,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445215,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445216,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496004,
    "City": "Raigarh",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140402,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470228,
    "City": "REHLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143413,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143402,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143423,
    "City": "TARN TARAN SAHIB",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456004,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456005,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263149,
    "City": "PANTNAGAR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263150,
    "City": "BAZPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 263146,
    "City": "RUDRAPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263152,
    "City": "RUDRAPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424310,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148008,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148002,
    "City": "SANGRUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148048,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148000,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148004,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148005,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148006,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148007,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175025,
    "City": "SARKAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485002,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485003,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485004,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495550,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173214,
    "City": "Solan",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517641,
    "City": "SRIKALAHASTI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444202,
    "City": "TELHARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785680,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785681,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785636,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784025,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784150,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784153,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784002,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784004,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799103,
    "City": "BISHALGARH",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484660,
    "City": "UMARIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384290,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384130,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384175,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732150,
    "City": "ITAHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759129,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802304,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853201,
    "City": "NAGARPARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 805103,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743292,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743427,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743429,
    "City": "BASIRHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464226,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445307,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442003,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442005,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442002,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416528,
    "City": "VENGURLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423704,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423705,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 449202,
    "City": "WARORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461110,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821111,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445302,
    "City": "PANDHARKAWDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821115,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821305,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491107,
    "City": "UTAIPATAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391243,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829301,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504304,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504323,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503245,
    "City": "NAVIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503235,
    "City": "NAVIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503186,
    "City": "NAVIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461223,
    "City": "SEONI MALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 461221,
    "City": "SEONI MALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484887,
    "City": "RAJENDRAGRAM",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413197,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493551,
    "City": "PITHORA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460004,
    "City": "BETUL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414004,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414112,
    "City": "BHINGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464224,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464240,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497235,
    "City": "BHATAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 497116,
    "City": "LAKHANPUR CH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425423,
    "City": "BORAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424106,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444909,
    "City": "CHANDUR RAILWAY",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480555,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475005,
    "City": "DABRA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401608,
    "City": "DAHANU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416511,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425104,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461661,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444407,
    "City": "BARSHITAKLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444106,
    "City": "KAMARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415539,
    "City": "Karad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415122,
    "City": "Karad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415104,
    "City": "GHOGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431805,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362276,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416022,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416123,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423208,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362235,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384011,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384435,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384206,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384207,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481551,
    "City": "PARASWADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472445,
    "City": "NIWARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416534,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471606,
    "City": "KHAJURAO MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 488441,
    "City": "PANNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384266,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516361,
    "City": "Proddatur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464573,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425509,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425523,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425524,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425513,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425516,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425511,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425519,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425522,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470119,
    "City": "RAHATGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410062,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591236,
    "City": "SANKESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591340,
    "City": "SANKESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495220,
    "City": "SARGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495222,
    "City": "SARGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413024,
    "City": "AKLUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413025,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413026,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413010,
    "City": "TEMBURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413011,
    "City": "TEMBURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457778,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493225,
    "City": "KHARORA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456007,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362571,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384273,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491223,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416015,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416125,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416304,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362551,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464220,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415514,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412804,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425326,
    "City": "YAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631005,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631152,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305625,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577566,
    "City": "HARIHAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676110,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679581,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679574,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679589,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679592,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679594,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333704,
    "City": "MANDAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631602,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631703,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312025,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609303,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609306,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609311,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609313,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609312,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686029,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686024,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686034,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686028,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686036,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686023,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686033,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686038,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686040,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533309,
    "City": "MANDAPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603307,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604406,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676111,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676310,
    "City": "PUTHANATHANI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676313,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676556,
    "City": "TIRUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632106,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632202,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442006,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844122,
    "City": "MAHUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844112,
    "City": "FAKULI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591110,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485441,
    "City": "NAGOD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458336,
    "City": "JIRAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752107,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752119,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752118,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733140,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733141,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733142,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722142,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722183,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722202,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722143,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243301,
    "City": "AONLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243403,
    "City": "AONLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342603,
    "City": "BHOPALGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243720,
    "City": "BISAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243726,
    "City": "BISAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175101,
    "City": "BHUNTER",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175126,
    "City": "BHUNTER",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175125,
    "City": "BHUNTER",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175128,
    "City": "PATLIKUHAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 241204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 232329,
    "City": "ZAMANIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493890,
    "City": "DEVBHOG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493891,
    "City": "DEVBHOG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457773,
    "City": "PETLAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782401,
    "City": "Sonapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782402,
    "City": "Sonapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782403,
    "City": "KHETRI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782410,
    "City": "KHETRI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445202,
    "City": "DARWAH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481995,
    "City": "BICHIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504104,
    "City": "GAJULA NARSAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504306,
    "City": "GAJULA NARSAPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362650,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360570,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231224,
    "City": "SHAKTI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231218,
    "City": "SHAKTI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231222,
    "City": "SHAKTI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758021,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758020,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758015,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758043,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843106,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843126,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843143,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136044,
    "City": "ASSANDH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301404,
    "City": "KHERTAL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301405,
    "City": "KISHANGARH BAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505503,
    "City": "KATARAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505504,
    "City": "KATARAM",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524322,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524304,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524302,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524303,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175129,
    "City": "PATLIKUHAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175131,
    "City": "MANALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175103,
    "City": "MANALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175136,
    "City": "PATLIKUHAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175143,
    "City": "MANALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458110,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458118,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458116,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458556,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491445,
    "City": "DONGARGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342601,
    "City": "PIPARCITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342604,
    "City": "PIPARCITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788030,
    "City": "UDARBOND",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788031,
    "City": "UDARBOND",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788108,
    "City": "UDARBOND",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788109,
    "City": "UDARBOND",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342902,
    "City": "GOTAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673591,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673592,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673593,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673595,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 241301,
    "City": "BILGRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 241302,
    "City": "BILGRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 241303,
    "City": "BILGRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799035,
    "City": "JIRANIA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799045,
    "City": "JIRANIA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799046,
    "City": "JIRANIA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303102,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636808,
    "City": "ULAGANAHALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636809,
    "City": "ULAGANAHALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636805,
    "City": "ULAGANAHALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636806,
    "City": "ULAGANAHALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631210,
    "City": "ARAKKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785622,
    "City": "DERGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848131,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848121,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848122,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843104,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574221,
    "City": "KADABA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574238,
    "City": "KADABA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591129,
    "City": "YARGATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335051,
    "City": "RAISINGHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638054,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638313,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516217,
    "City": "PORUMAMILLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517192,
    "City": "ROMPICHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517193,
    "City": "ROMPICHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517194,
    "City": "ROMPICHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517172,
    "City": "ROMPICHARLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314025,
    "City": "SAGWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505490,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523135,
    "City": "VETAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524308,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534266,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534267,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534269,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534324,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534126,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516290,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516291,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481335,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441212,
    "City": "SINDEWAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784110,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784112,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784117,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629169,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629193,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629178,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629173,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781335,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781337,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781353,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781351,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781334,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781341,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781340,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493211,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492014,
    "City": "Raipur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816118,
    "City": "SHIKARIPARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816119,
    "City": "DUMKA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785673,
    "City": "MORAN",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785676,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 636351,
    "City": "KADAYAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636309,
    "City": "OMALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636305,
    "City": "OMALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141107,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141105,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141104,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388254,
    "City": "ANJAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 304640,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394602,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415410,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415402,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382451,
    "City": "BARWALAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627112,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627115,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470235,
    "City": "KESLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587124,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587145,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431200,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360510,
    "City": "BHANVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360360,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451442,
    "City": "JULWANIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424003,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424312,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481332,
    "City": "BUDBUDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362005,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450661,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450112,
    "City": "MUNDI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450119,
    "City": "MUNDI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629155,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629162,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629164,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629168,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629189,
    "City": "SAMIYARMADAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629195,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629197,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629808,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629154,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491337,
    "City": "MUNGELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396425,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413592,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413593,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413594,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364526,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691517,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414104,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470880,
    "City": "TENDUKHEDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382170,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 422615,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402114,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402113,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 430007,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451221,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456002,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456008,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456009,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504313,
    "City": "UTNOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799201,
    "City": "KHOWAI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799202,
    "City": "KHOWAI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799207,
    "City": "KHOWAI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144102,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144106,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144103,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144104,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144030,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442707,
    "City": "GONDPIPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442702,
    "City": "GONDPIPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442918,
    "City": "GONDPIPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441228,
    "City": "SAOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176107,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176082,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176110,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176108,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171102,
    "City": "DARLAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173208,
    "City": "DARLAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799141,
    "City": "SABROOM",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844121,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182103,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532243,
    "City": "MANDASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532219,
    "City": "Palasa",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305404,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799275,
    "City": "MANOGHAT",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799273,
    "City": "MANOGHAT",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798618,
    "City": "TULI",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798623,
    "City": "TULI",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301411,
    "City": "TIJARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788112,
    "City": "SILCOORIE",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788113,
    "City": "SILCOORIE",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788118,
    "City": "SILCOORIE",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783372,
    "City": "BASUGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783373,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152024,
    "City": "RUPANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480337,
    "City": "PANDHURNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480338,
    "City": "PANDHURNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458775,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752084,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752083,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752091,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752085,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691301,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691334,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695310,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713130,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713129,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713140,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713502,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832102,
    "City": "JADUGORA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832106,
    "City": "JADUGORA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832111,
    "City": "JADUGORA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832304,
    "City": "JADUGORA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470775,
    "City": "HATTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470772,
    "City": "HATTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522313,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522261,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522324,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522301,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713321,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713358,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713338,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713346,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713323,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713427,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713424,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713423,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713421,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581314,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581316,
    "City": "ANKOLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581357,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581353,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581321,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445402,
    "City": "RALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332404,
    "City": "RINGUS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431712,
    "City": "HADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431713,
    "City": "HADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224147,
    "City": "JAHANGIRGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224137,
    "City": "JAHANGIRGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224176,
    "City": "JAHANGIRGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224181,
    "City": "JAHANGIRGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799286,
    "City": "HALAHALI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799285,
    "City": "HALAHALI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835101,
    "City": "MURI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835102,
    "City": "MURI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585412,
    "City": "CHITGUPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585329,
    "City": "CHITGUPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585331,
    "City": "CHITGUPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585418,
    "City": "CHITGUPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585353,
    "City": "CHITGUPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277207,
    "City": "MANIAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277302,
    "City": "MANIAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505331,
    "City": "METPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505450,
    "City": "METPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503308,
    "City": "METPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505330,
    "City": "METPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465441,
    "City": "AGARMALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416301,
    "City": "ASHTA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416305,
    "City": "ASHTA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416315,
    "City": "ASHTA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522304,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522307,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522308,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444111,
    "City": "DAHIHANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505403,
    "City": "CHANDURTHI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505307,
    "City": "CHANDURTHI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209202,
    "City": "BILHAUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209210,
    "City": "BILHAUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277303,
    "City": "MANIAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277304,
    "City": "GARWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 794108,
    "City": "DEKACHANG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413202,
    "City": "JEUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785696,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785674,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785687,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785698,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401102,
    "City": "SAPHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401401,
    "City": "SAPHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401403,
    "City": "SAPHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 431714,
    "City": "KANDHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431707,
    "City": "KANDHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412303,
    "City": "JEJURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412301,
    "City": "SASWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735134,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735135,
    "City": "FULBARI HAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802133,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624612,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624614,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624616,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624619,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624705,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734429,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734427,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734426,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431802,
    "City": "HIMAYATNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757025,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757026,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757028,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516175,
    "City": "DUVVUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516173,
    "City": "DUVVUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516359,
    "City": "DUVVUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635301,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635305,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635303,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635302,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 770073,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 770020,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503164,
    "City": "INDALWAI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503165,
    "City": "INDALWAI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713343,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713333,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713331,
    "City": "KULTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505186,
    "City": "SULTANABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505162,
    "City": "SULTANABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505152,
    "City": "SULTANABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844118,
    "City": "FAKULI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844131,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323603,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481880,
    "City": "DINDORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481882,
    "City": "KARANJIA MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799102,
    "City": "BISHALGARH",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799130,
    "City": "AGARTALA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794111,
    "City": "WILLIAM NAGAR",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781354,
    "City": "RANGIYA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781365,
    "City": "RANGIYA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781382,
    "City": "RANGIYA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781376,
    "City": "RANGIYA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334801,
    "City": "DESNOK",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332701,
    "City": "AJEETGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332713,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248142,
    "City": "HARBERTPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248125,
    "City": "HARBERTPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144036,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144043,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144044,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144630,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755007,
    "City": "KUAKHIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755001,
    "City": "KUAKHIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 755004,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636903,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636904,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636905,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636902,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413252,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413250,
    "City": "MAHISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743504,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723202,
    "City": "JHALDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723213,
    "City": "JHALDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723212,
    "City": "JHALDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712409,
    "City": "SINGUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712407,
    "City": "SINGUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712411,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712405,
    "City": "SINGUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816107,
    "City": "PAKUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505532,
    "City": "PEDDAPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535126,
    "City": "BALIJIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535557,
    "City": "BALIJIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535559,
    "City": "BALIJIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630314,
    "City": "AMARAVATHIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572216,
    "City": "GUBBI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572223,
    "City": "GUBBI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516364,
    "City": "KAMALAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673603,
    "City": "KODANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508204,
    "City": "HUZURNAGAR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143410,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143417,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143409,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143415,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 143412,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209721,
    "City": "CHIBRAMAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227405,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 227413,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713145,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205304,
    "City": "KUSMARA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625110,
    "City": "MELUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625122,
    "City": "MELUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625301,
    "City": "MELUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679532,
    "City": "DHEASHMANGALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679535,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680583,
    "City": "DHEASHMANGALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174001,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174034,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793120,
    "City": "MAIRANGBAH",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380030,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 380011,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380056,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380029,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 828103,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828308,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828133,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828108,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306102,
    "City": "BEAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441924,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441923,
    "City": "TIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140901,
    "City": "MULLANPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442406,
    "City": "PADOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442502,
    "City": "PADOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442505,
    "City": "PADOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442507,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471202,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577503,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562129,
    "City": "VIJAYAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636803,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636804,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382630,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382640,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431143,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461441,
    "City": "KHIRKIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461335,
    "City": "KHIRKIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431214,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423120,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 343002,
    "City": "JALORE",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829104,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828102,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754222,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754223,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415708,
    "City": "KHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362620,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480997,
    "City": "LAKHNADON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423115,
    "City": "LASUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431119,
    "City": "LASUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141112,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141127,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387220,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387710,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456221,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637015,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637212,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637014,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637017,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637020,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 472246,
    "City": "PRITHVIPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431530,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144403,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144632,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144031,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144311,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144501,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144411,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144418,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423112,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761151,
    "City": "SURADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360579,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360023,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360020,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360022,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765002,
    "City": "RAYAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171012,
    "City": "SANJAULI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451669,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824219,
    "City": "SHERGHATI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171008,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843327,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 630313,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630554,
    "City": "SHIVAGANGAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394010,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394150,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394305,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394326,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394520,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394241,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395210,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472005,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491221,
    "City": "UTAIPATAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396196,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416417,
    "City": "ASHTA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382850,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382830,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382810,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382815,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382820,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445305,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442902,
    "City": "CHANDRAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515455,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841241,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841212,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 785633,
    "City": "MARIANI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828117,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828304,
    "City": "DHANBAD",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828309,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783135,
    "City": "HATSINGIMARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783131,
    "City": "HATSINGIMARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783128,
    "City": "HATSINGIMARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 794104,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 460554,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177210,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177205,
    "City": "AMB",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788127,
    "City": "SRIKONA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788110,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788111,
    "City": "SILCHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788702,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206243,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 521401,
    "City": "GAMPALAGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507203,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829210,
    "City": "KHALARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829208,
    "City": "KHALARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829205,
    "City": "KHALARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829209,
    "City": "KHALARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829201,
    "City": "KHALARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614902,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626110,
    "City": "SRIVILLIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457114,
    "City": "ALOT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522214,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522210,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522216,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491881,
    "City": "KHAIRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491444,
    "City": "KHAIRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491885,
    "City": "KHAIRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505462,
    "City": "KATHALAPOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505306,
    "City": "KATHALAPOOR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491335,
    "City": "BEMETARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577418,
    "City": "HOSANAGARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577425,
    "City": "HOSANAGARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577452,
    "City": "HOSANAGARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577412,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483225,
    "City": "SIHORA MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 483222,
    "City": "SIHORA MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 483332,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496445,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413107,
    "City": "MALASHIRAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413111,
    "City": "MALASHIRAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756045,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756126,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756134,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756047,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382715,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382705,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364740,
    "City": "DHASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365220,
    "City": "DHASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752056,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752057,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754215,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754217,
    "City": "RAJNAGAR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754216,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754220,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754218,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854318,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 854328,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636304,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 563102,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576112,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743286,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743273,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743247,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743438,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585222,
    "City": "SEDAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585292,
    "City": "SEDAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585317,
    "City": "SEDAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767070,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767029,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767030,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262802,
    "City": "GOLA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797111,
    "City": "WOKHA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451770,
    "City": "PANSEMAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799101,
    "City": "AMARPUR",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754103,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754102,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754104,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754106,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754108,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382729,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798612,
    "City": "TUENSANG",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454665,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454648,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781301,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781316,
    "City": "HOWLY",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781309,
    "City": "BHELLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781311,
    "City": "BHELLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783371,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 783389,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 132024,
    "City": "NISSING",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460666,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460665,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504110,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431743,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689501,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691530,
    "City": "ADOOR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457991,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431216,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759132,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466119,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752037,
    "City": "BALUGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451661,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451552,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451553,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450554,
    "City": "BEDIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441914,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458770,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458778,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458771,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462033,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462036,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471411,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431208,
    "City": "DEULGAON RAJA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416806,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416807,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741164,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441602,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413196,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413198,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414203,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414206,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414207,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312623,
    "City": "JIRAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733144,
    "City": "KALIAGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441404,
    "City": "KANHAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413275,
    "City": "KARMALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455332,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415524,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572131,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413287,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413288,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495678,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431139,
    "City": "Majalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431140,
    "City": "Majalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443112,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713168,
    "City": "MEMARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 465449,
    "City": "Nalkheda",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465448,
    "City": "Nalkheda",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465446,
    "City": "Nalkheda",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412425,
    "City": "OTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431217,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431219,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829302,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457775,
    "City": "PETLAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622301,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622203,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413704,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752062,
    "City": "RAJSUNAKHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752026,
    "City": "RAJSUNAKHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445401,
    "City": "RALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741504,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690540,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451667,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412237,
    "City": "SHIRUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413715,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413720,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402404,
    "City": "SHRIVARDHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495223,
    "City": "SARGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495552,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825324,
    "City": "TANDWA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444201,
    "City": "TELHARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695509,
    "City": "VIZHINJAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781134,
    "City": "CHAYGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444304,
    "City": "NANDURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444308,
    "City": "SHEGAON MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781368,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470005,
    "City": "MAKRONIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591213,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591239,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591242,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591244,
    "City": "ANKALI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274601,
    "City": "BARHAJ UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274603,
    "City": "BARHAJ UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783335,
    "City": "AGOMONI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443401,
    "City": "NANDURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443405,
    "City": "NANDURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 798620,
    "City": "ZUNHEBOTO",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230204,
    "City": "KUNDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230202,
    "City": "KUNDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231223,
    "City": "RIHAND NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262310,
    "City": "TANAKPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782103,
    "City": "RAHA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523372,
    "City": "GOWTHAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523336,
    "City": "GOWTHAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518122,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781306,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781303,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781338,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781375,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 781312,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 781304,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781370,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781348,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781374,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577422,
    "City": "KONANDURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577125,
    "City": "KONANDURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577232,
    "City": "KONANDURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577436,
    "City": "KONANDURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577414,
    "City": "KONANDURU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691585,
    "City": "NEENDAKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533254,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533248,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533250,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533252,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533244,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679332,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 676525,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603309,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603319,
    "City": "MADURANTAKAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603301,
    "City": "MADURANTAKAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365640,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365440,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416414,
    "City": "MIRAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176033,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176028,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431145,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423113,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177020,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413137,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413138,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413347,
    "City": "PANDHARPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413348,
    "City": "PANDHARPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413578,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413579,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175130,
    "City": "PATLIKUHAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844128,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843123,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231220,
    "City": "SHAKTI NAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413210,
    "City": "TEMBURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413276,
    "City": "TEMBURNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515413,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 503223,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504312,
    "City": "Adilabad",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522649,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721201,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713324,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713153,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731121,
    "City": "BOLPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713148,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444401,
    "City": "BARSHITAKLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394640,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493113,
    "City": "BHATAPURA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462034,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638661,
    "City": "DHARAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638672,
    "City": "DHARAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413551,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416051,
    "City": "Gadhingalaj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571251,
    "City": "SHUNTIKOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574285,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574232,
    "City": "KADABA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574230,
    "City": "KADABA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629160,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574151,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415051,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415035,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574212,
    "City": "SULLIA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574248,
    "City": "SULLIA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574314,
    "City": "SULLIA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574327,
    "City": "SULLIA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415045,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394360,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394655,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624005,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624308,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624401,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624402,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624403,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624803,
    "City": "DINDIGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624706,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276305,
    "City": "SARAIMEER",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276304,
    "City": "SARAIMEER",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303007,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481222,
    "City": "LANJI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481115,
    "City": "LANJI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481556,
    "City": "PARASWADA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335041,
    "City": "CHUNAWADH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136034,
    "City": "CHIKA HR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 136035,
    "City": "CHIKA HR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799131,
    "City": "SONAMURA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799132,
    "City": "SONAMURA",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797003,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797004,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636104,
    "City": "VALAPPADI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495557,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493344,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493338,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444804,
    "City": "WALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443402,
    "City": "JALGAON JAMOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443403,
    "City": "JALGAON JAMOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444204,
    "City": "JALGAON JAMOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711401,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711303,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711414,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516349,
    "City": "VEMPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516350,
    "City": "VEMPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534176,
    "City": "NALLAJERLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506315,
    "City": "MARIPEDA BANGLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743337,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743372,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743354,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756027,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756028,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756029,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582205,
    "City": "ANNIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582201,
    "City": "ANNIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759018,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759023,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759028,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759039,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759020,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591306,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591308,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493778,
    "City": "NAGARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516151,
    "City": "NANDALURE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416232,
    "City": "SIDDANERLI KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416207,
    "City": "SIDDANERLI KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524307,
    "City": "A S PETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756037,
    "City": "GAZIPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754031,
    "City": "KANPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206124,
    "City": "BAKEWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274802,
    "City": "KHADDA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144626,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144606,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144628,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144620,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574142,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574509,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574108,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635203,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635201,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635206,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626116,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626126,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626132,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626133,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626190,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261303,
    "City": "SIDHAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506343,
    "City": "MULUGU",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306101,
    "City": "RAS RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176056,
    "City": "NAGROTA BAGWAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176002,
    "City": "NAGROTA BAGWAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182127,
    "City": "MANWAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422306,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422210,
    "City": "SAIKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422301,
    "City": "SAIKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422302,
    "City": "SAIKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517258,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457118,
    "City": "ALOT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614616,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622201,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587117,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587116,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673323,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413711,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413524,
    "City": "Chakur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445002,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413279,
    "City": "JEUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413280,
    "City": "JEUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458335,
    "City": "JIRAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392310,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391210,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415021,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415525,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413297,
    "City": "MAHISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413298,
    "City": "MAHISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458338,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622303,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585201,
    "City": "YADGIR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585202,
    "City": "YADGIR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630502,
    "City": "THIRUPPATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441907,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627104,
    "City": "KOODANKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627107,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627651,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627654,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445301,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325203,
    "City": "SULTANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535578,
    "City": "BALIJIPETA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784149,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131307,
    "City": "GOHANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 131309,
    "City": "GOHANA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784172,
    "City": "GOHPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799287,
    "City": "HALAHALI",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332602,
    "City": "RINGUS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788117,
    "City": "SILCOORIE",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322219,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784161,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784164,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784165,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 781380,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781381,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781121,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781364,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781030,
    "City": "BAIHATA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781102,
    "City": "HAJO",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781104,
    "City": "HAJO",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174036,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174013,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174012,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144519,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146103,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146109,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 799263,
    "City": "PENCHARTHAL",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 799266,
    "City": "PENCHARTHAL",
    "State": "TRIPURA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721232,
    "City": "GHATAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721222,
    "City": "GHATAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742148,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742151,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742135,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571236,
    "City": "SOMARPET KA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177213,
    "City": "GONDPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177109,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177212,
    "City": "GONDPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177204,
    "City": "GONDPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825109,
    "City": "JAINAGAR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825413,
    "City": "JHUMRITILEIYA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825318,
    "City": "JAINAGAR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622507,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506252,
    "City": "PALAKURTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 506316,
    "City": "PALAKURTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814149,
    "City": "SARATH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814146,
    "City": "SARATH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622407,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622412,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622409,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622401,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622402,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630405,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630410,
    "City": "PUDUKKOTTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624201,
    "City": "DINDUGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624202,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624206,
    "City": "DINDUGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624208,
    "City": "DINDUGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624220,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571105,
    "City": "HUNSUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176092,
    "City": "DAROH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176097,
    "City": "JASINGPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176093,
    "City": "DAROH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176096,
    "City": "JASINGPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176094,
    "City": "DAROH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756115,
    "City": "BONTH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756144,
    "City": "BONTH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758078,
    "City": "BONTH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177026,
    "City": "GALORE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177006,
    "City": "GALORE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177040,
    "City": "GALORE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412115,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412108,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756129,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412406,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412405,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410512,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410516,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410515,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571218,
    "City": "MURNAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571211,
    "City": "MURNAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571213,
    "City": "PONNAMPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431509,
    "City": "JINTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431508,
    "City": "JINTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752024,
    "City": "CHANDPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752023,
    "City": "CHANDPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752025,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415616,
    "City": "PAWAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415626,
    "City": "PAWAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509324,
    "City": "KALWAKURTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 392180,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391421,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503305,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503309,
    "City": "BANSWADA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301408,
    "City": "RAJGARH RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781347,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781339,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722168,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636111,
    "City": "VALAPPADI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636139,
    "City": "VALAPPADI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505451,
    "City": "Karimnagar",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505401,
    "City": "SIRCILLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572224,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574148,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679533,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679534,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606208,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 608402,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606213,
    "City": "KARUNAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332706,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587312,
    "City": "MUDALGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621010,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621011,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621002,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621118,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621206,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591173,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574260,
    "City": "VITLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574279,
    "City": "VITLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394160,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394335,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783388,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782144,
    "City": "RAHA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600111,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517587,
    "City": "PUTTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423302,
    "City": "NAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423303,
    "City": "NAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 151102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 151107,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332025,
    "City": "LOSAL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783382,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783383,
    "City": "BONGAIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788026,
    "City": "SRIKONA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695132,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695502,
    "City": "PARASSALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695513,
    "City": "PARASSALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695128,
    "City": "PARASSALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524408,
    "City": "RAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481337,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571248,
    "City": "SHUNTIKOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442703,
    "City": "ALLAPALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442705,
    "City": "ALLAPALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442704,
    "City": "ALLAPALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465685,
    "City": "PACHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 475687,
    "City": "PACHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571602,
    "City": "K R NAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571603,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571604,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275304,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275105,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723131,
    "City": "MANBAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723129,
    "City": "MANBAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723128,
    "City": "MANBAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176214,
    "City": "GAGAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583233,
    "City": "HULIGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583234,
    "City": "HULIGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483440,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483334,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460440,
    "City": "SHAHPUR BHOURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464228,
    "City": "SIRONJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581104,
    "City": "Ishwaramangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581102,
    "City": "Ishwaramangala",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670645,
    "City": "MANANTHAVADY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670644,
    "City": "MANANTHAVADY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670731,
    "City": "MANANTHAVADY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574220,
    "City": "VITLA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133204,
    "City": "SADHAURA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583116,
    "City": "KURUGODU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125112,
    "City": "BARWALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464118,
    "City": "SIRONJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571811,
    "City": "BG NAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485226,
    "City": "BIRSHINGHPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485221,
    "City": "BIRSHINGHPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735218,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736131,
    "City": "BAKSHIRHAT WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721126,
    "City": "DEBRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721139,
    "City": "PANSKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721124,
    "City": "DEBRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713381,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713378,
    "City": "HARIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713385,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756133,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756132,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756131,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756130,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756163,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756123,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756125,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756124,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756168,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756162,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802135,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581334,
    "City": "HONNAVARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721420,
    "City": "BAITA BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721447,
    "City": "BAITA BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721448,
    "City": "BAITA BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680573,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680574,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680575,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680325,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680303,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 575028,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621306,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621315,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621307,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621316,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621313,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785693,
    "City": "SAPEKHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172102,
    "City": "JEORI HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172101,
    "City": "JEORI HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 172034,
    "City": "JEORI HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 172201,
    "City": "RAMPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473885,
    "City": "BADRAWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 473113,
    "City": "BADRAWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465667,
    "City": "KURAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465680,
    "City": "PACHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171207,
    "City": "ROHRU",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829110,
    "City": "GOLA JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 487770,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493445,
    "City": "MAHASAMUND",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 483105,
    "City": "GARHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422304,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422305,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637104,
    "City": "LAKSHMINAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689516,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690529,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689521,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689509,
    "City": "PANDALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583119,
    "City": "Sandur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583118,
    "City": "Sandur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302037,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301022,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323615,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206167,
    "City": "BAKEWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732202,
    "City": "AMRITY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784103,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784505,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516213,
    "City": "VONITIMITTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516237,
    "City": "VONITIMITTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686513,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782481,
    "City": "HOWRAGHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782482,
    "City": "BAKALIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 172001,
    "City": "RAMPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143102,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328021,
    "City": "SIRMATHURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173207,
    "City": "KUNIHAR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173206,
    "City": "SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224122,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301026,
    "City": "RAMGARH RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695305,
    "City": "KOTTIYOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695313,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695307,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332712,
    "City": "AJEETGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303601,
    "City": "AJEETGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785671,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785672,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785661,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785697,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785666,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184144,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 181224,
    "City": "JHAJJAR KOTLI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182117,
    "City": "MANWAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332714,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332716,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303602,
    "City": "RINGUS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332603,
    "City": "RINGUS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522264,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522267,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680731,
    "City": "THRISSUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822126,
    "City": "LATEHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673634,
    "City": "CALICUT",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841244,
    "City": "MAHARAJGANJ BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680657,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680655,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244223,
    "City": "GAJROLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244225,
    "City": "GAJROLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360531,
    "City": "JAMJODHPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788735,
    "City": "SHIVBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491228,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757105,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757107,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756113,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826006,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743202,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743203,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743204,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700160,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700161,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828142,
    "City": "GOVINDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826007,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826002,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825319,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825303,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825304,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831008,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 831035,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721122,
    "City": "MIDNAPORE",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829129,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768011,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673303,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673021,
    "City": "ELATHUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303901,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784176,
    "City": "BISWANATH CHARIALI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784177,
    "City": "BISWANATH CHARIALI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680322,
    "City": "OLLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680319,
    "City": "OLLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680317,
    "City": "OLLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680312,
    "City": "OLLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695608,
    "City": "KALLARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695609,
    "City": "KALLARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695610,
    "City": "KALLARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695568,
    "City": "KALLARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758025,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756030,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756080,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756022,
    "City": "BASTA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757021,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757083,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757100,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743291,
    "City": "BOKCHORA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752093,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786012,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786101,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802136,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781071,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781171,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754107,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754114,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785016,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785018,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743395,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743345,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743336,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743391,
    "City": "JOYNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752094,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752081,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752090,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752079,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752087,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782010,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754208,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785684,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785663,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743299,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784502,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784027,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784151,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784003,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571235,
    "City": "SOMARPET KA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670310,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695043,
    "City": "KALLAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695044,
    "City": "KALLAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695045,
    "City": "KALLAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695588,
    "City": "Venjaramoodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690522,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690501,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690505,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690503,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690530,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690561,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341512,
    "City": "PARBATSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334023,
    "City": "KHAJUVALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 334603,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 713310,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742150,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743250,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743269,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743201,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743212,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743274,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743288,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743296,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743700,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743704,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743706,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743708,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743275,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743276,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743102,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743121,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743170,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743175,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743178,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743179,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743180,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743186,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743187,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743358,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743705,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743244,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743511,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743517,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712106,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712154,
    "City": "MOGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736180,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741135,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741166,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741124,
    "City": "DHUBULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713308,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713317,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713387,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722177,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722158,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722166,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721658,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721654,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711111,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711411,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500105,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500890,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500107,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500660,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500253,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500265,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500659,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500361,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500380,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500123,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500178,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500605,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501507,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500103,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500594,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500762,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500104,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500267,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500110,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500482,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743393,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743348,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743374,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742170,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742172,
    "City": "KANDI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713516,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713514,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713512,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713150,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721149,
    "City": "KHARAGPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735234,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721653,
    "City": "MECHEDA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 713377,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713160,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731133,
    "City": "PANDAVESWAR  WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829133,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829135,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835240,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835238,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741405,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712205,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712304,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712306,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712331,
    "City": "HOOGHLY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712701,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712703,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712704,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712705,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734403,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734405,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734422,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734402,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734421,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712707,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 152117,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152118,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144404,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144406,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305206,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305025,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305012,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305024,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305009,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 211021,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202150,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301025,
    "City": "RAMGARH RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143009,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143066,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143010,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143026,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143036,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143000,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143044,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143093,
    "City": "AMRITSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244236,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244251,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243401,
    "City": "AONLA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441703,
    "City": "ARJUNI MORGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302026,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302042,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151006,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151007,
    "City": "Bhatinda",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847308,
    "City": "BASOPATTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490028,
    "City": "BHILAI JAMUL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490015,
    "City": "BHILAI JAMUL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311011,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403102,
    "City": "BICHOLIM",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246728,
    "City": "BIJNOR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246700,
    "City": "BIJNOR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334403,
    "City": "Bikaner",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174005,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396521,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244414,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244415,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202412,
    "City": "CHANDAUSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680314,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680320,
    "City": "CHERPU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480003,
    "City": "Chindwara",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480004,
    "City": "Chindwara",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176086,
    "City": "DAROH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144206,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144304,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 361336,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431739,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431740,
    "City": "ARDHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141206,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141020,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 248147,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248201,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248004,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248018,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416623,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365421,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364730,
    "City": "DHASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679536,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248161,
    "City": "DOIWALA",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 314012,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491009,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425116,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425117,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 283103,
    "City": "FIROZABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144520,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144531,
    "City": "GARHSHANKAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144503,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493888,
    "City": "MAINPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389347,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389325,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391510,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389348,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389351,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389352,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202146,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177021,
    "City": "AWAH DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177025,
    "City": "AWAH DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825418,
    "City": "JAINAGAR JH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306303,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306709,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755014,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431534,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176203,
    "City": "CHOWARI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413206,
    "City": "JEUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124106,
    "City": "KHAPARWAS",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124504,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144101,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144014,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144000,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695586,
    "City": "KANIYAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144603,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144806,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263401,
    "City": "BAZPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184148,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 184147,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 305405,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305415,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305403,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305408,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 251203,
    "City": "KHATAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305817,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261506,
    "City": "LAKHIMPUR KHIRI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262726,
    "City": "LAKHIMPUR KHIRI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141016,
    "City": "LUDHIANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141021,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141027,
    "City": "Ludhiana",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146104,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144530,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146107,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146108,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226104,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 226103,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412407,
    "City": "MANCHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 181008,
    "City": "MARH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342303,
    "City": "OSIAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250103,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250106,
    "City": "MEERUT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250403,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250013,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250007,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250102,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250006,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181113,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181105,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142040,
    "City": "MOGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244249,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460663,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 251311,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176210,
    "City": "NAGROTA SURIYAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246731,
    "City": "NAJIBABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246732,
    "City": "NAJIBABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333801,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312611,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431150,
    "City": "BHADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425115,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431532,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431533,
    "City": "PARTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 147111,
    "City": "Patiala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415522,
    "City": "PHALTAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307031,
    "City": "PINDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307019,
    "City": "PINDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635204,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335040,
    "City": "CHUNAWADH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335073,
    "City": "CHUNAWADH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335024,
    "City": "RAISINGHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 140701,
    "City": "RAJPURA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326518,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326529,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326510,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486550,
    "City": "REWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 247666,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 247664,
    "City": "Roorkee",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441808,
    "City": "SADAK ARJUNI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314026,
    "City": "SAGWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 247672,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184141,
    "City": "SAMBA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 241127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 401402,
    "City": "SAPHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176225,
    "City": "SHAHPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811101,
    "City": "BARBIGHA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 283141,
    "City": "SHIKOHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483220,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332031,
    "City": "DADIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464672,
    "City": "SILWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261131,
    "City": "KHAIRABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190024,
    "City": "DARGAH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 190022,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176073,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176075,
    "City": "SUJANPUR TIRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630610,
    "City": "THIRUPPUVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301702,
    "City": "TIJARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313014,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413533,
    "City": "UDGIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627101,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627110,
    "City": "VALLIYOOR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362258,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695587,
    "City": "Venjaramoodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695134,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445108,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142051,
    "City": "ZIRA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518563,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518158,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516163,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516164,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516165,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743304,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 535581,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532580,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722169,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523335,
    "City": "GOWTHAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741233,
    "City": "GOPALNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713517,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 715513,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 508233,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 532502,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532522,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532527,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532501,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532521,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741403,
    "City": "RANAGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734401,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734404,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734406,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734407,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781319,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783391,
    "City": "CHIRANG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783392,
    "City": "Bongaigaon",
    "State": "ASSAM",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785703,
    "City": "DERGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 797118,
    "City": "DIMAPUR",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784171,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782441,
    "City": "HOWRAGHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782439,
    "City": "HOWRAGHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788716,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788790,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793101,
    "City": "Sonapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786613,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 786615,
    "City": "NAHARKATIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785694,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785695,
    "City": "SONARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781344,
    "City": "TAMULPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785113,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785637,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758027,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758028,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758029,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758030,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416514,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422202,
    "City": "DINDORI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422215,
    "City": "DINDORI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273402,
    "City": "BARHALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782137,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782139,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782138,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782143,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782120,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332703,
    "City": "DANTARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734313,
    "City": "RAMBI BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734320,
    "City": "RAMBI BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734321,
    "City": "RAMBI BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781103,
    "City": "HAJO",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824101,
    "City": "AURANGABAD BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767040,
    "City": "SINDHEKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533216,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533211,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533222,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533212,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471625,
    "City": "KHAJURAO MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 762017,
    "City": "TARBHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533306,
    "City": "DRAKSHARAMAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533263,
    "City": "DRAKSHARAMAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533468,
    "City": "DRAKSHARAMAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683112,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683561,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784162,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 781315,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781313,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781317,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209311,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 209303,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 181203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 181204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "Y"
  },
  {
    "Pincode": 181208,
    "City": "MARH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788145,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206127,
    "City": "BAKEWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783351,
    "City": "BILASHIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783344,
    "City": "BILASHIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243633,
    "City": "BISAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243723,
    "City": "BISAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827005,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144121,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 145021,
    "City": "Pathankot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784118,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784120,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143524,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143523,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788167,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788169,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184146,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 782485,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 144019,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124105,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832107,
    "City": "JAMSHEDPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785111,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274801,
    "City": "KHADDA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205301,
    "City": "KUSMARA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144109,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181103,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181131,
    "City": "MIRAN SAHIB",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 207249,
    "City": "JAITHRA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144318,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144316,
    "City": "PHAGWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781385,
    "City": "RANGIYA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191132,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784525,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143325,
    "City": "TARAGARH",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784026,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786124,
    "City": "TINSUKIA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 181223,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182123,
    "City": "Udhampur",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522332,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444309,
    "City": "BALAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485112,
    "City": "Satna",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 482050,
    "City": "GARHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442304,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457227,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444115,
    "City": "KAMARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483503,
    "City": "KATNI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305406,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413522,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591267,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591269,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591275,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591280,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591285,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591288,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591290,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591284,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754219,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444511,
    "City": "PATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495118,
    "City": "Pendra",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621008,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635304,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626143,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444510,
    "City": "Risod",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441810,
    "City": "SAKOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445212,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734424,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734431,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735205,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722170,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712152,
    "City": "MOGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712303,
    "City": "MOGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851115,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851114,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851116,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851135,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851210,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845461,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845462,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845463,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845466,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845476,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812008,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812009,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 812010,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803130,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841305,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841312,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841415,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847116,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847120,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847428,
    "City": "SIMRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847429,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823000,
    "City": "Gaya",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801304,
    "City": "KHUSRUPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842007,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842008,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843014,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843031,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843142,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805123,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801502,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848109,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848135,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413514,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785668,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 204106,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756048,
    "City": "KHAIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756059,
    "City": "KHAIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756049,
    "City": "KHAIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756182,
    "City": "KHAIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853203,
    "City": "NAGARPARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 813213,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813108,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821101,
    "City": "BHABUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821102,
    "City": "BHABUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821103,
    "City": "BHABUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362130,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362120,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362110,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365645,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721601,
    "City": "BHAGABANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721634,
    "City": "NIMTOURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721454,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721458,
    "City": "BHAGABANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722164,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722165,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722174,
    "City": "BANKADAHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515812,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515832,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515870,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515822,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515824,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515823,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515834,
    "City": "URVAKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700135,
    "City": "RAJARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713125,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713143,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713158,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713165,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362260,
    "City": "MENDRADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362229,
    "City": "MENDRADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505305,
    "City": "RACHARLA GOLLAPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505304,
    "City": "RACHARLA GOLLAPALLY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457331,
    "City": "PIPLODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457550,
    "City": "PIPLODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457222,
    "City": "PIPLODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423101,
    "City": "CHANDWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423117,
    "City": "CHANDWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416221,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416211,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273302,
    "City": "MAHARAJGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515301,
    "City": "PENUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415203,
    "City": "MADANGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415716,
    "City": "MADANGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484881,
    "City": "RAJENDRAGRAM",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623704,
    "City": "MUTHUKULATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623604,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623120,
    "City": "MUTHUKULATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623703,
    "City": "MUTHUKULATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639104,
    "City": "KULITHALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639112,
    "City": "KULITHALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621211,
    "City": "KULITHALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621215,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639107,
    "City": "KULITHALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621209,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621202,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721455,
    "City": "PICHABONI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721453,
    "City": "PICHABONI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261201,
    "City": "KHAIRABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261125,
    "City": "KHAIRABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246737,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246734,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246747,
    "City": "SHERKOT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246761,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246725,
    "City": "DHAMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 285204,
    "City": "KALPI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209112,
    "City": "PUKHRAYAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144410,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144416,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144419,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133202,
    "City": "DOSARKA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133203,
    "City": "DOSARKA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635207,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635307,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635306,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621117,
    "City": "THALAIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712406,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712613,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712417,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712412,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 816120,
    "City": "BORIO",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331025,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331026,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333025,
    "City": "MALSISAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396165,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396171,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396170,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362630,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481990,
    "City": "SHAHPURA SER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412206,
    "City": "BHOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460220,
    "City": "BHAINSDEHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431720,
    "City": "PALAM MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415206,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415207,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415209,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228001,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 228118,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262311,
    "City": "SITARGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 263151,
    "City": "SITARGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734214,
    "City": "MIRIK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734218,
    "City": "MIRIK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734227,
    "City": "MIRIK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414108,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404221,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404220,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404100,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404411,
    "City": "Ahmednagar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305014,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202171,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202119,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202118,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202117,
    "City": "Aligarh",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736181,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736136,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736118,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736102,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212165,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212141,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210012,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210011,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210009,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688560,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688042,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688029,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688028,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688022,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688020,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688017,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688016,
    "City": "Allapey",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301033,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301029,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134004,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134001,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444900,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444609,
    "City": "AMRAVATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365616,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365615,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364620,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364616,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364615,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364610,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364550,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364537,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364536,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364519,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364460,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364457,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364455,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364436,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364435,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364432,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364431,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364430,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364421,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364365,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388212,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388485,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388466,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388445,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388425,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388321,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388020,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388011,
    "City": "ANAND",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756077,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756076,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756075,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756074,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756073,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756072,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756071,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756057,
    "City": "BALASORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743295,
    "City": "BARASAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757015,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757012,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743211,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743200,
    "City": "Barrackpore",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743508,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743608,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743607,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743605,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743604,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743603,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743602,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743601,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 272183,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272102,
    "City": "BASTI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591702,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591338,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591331,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591198,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591197,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591182,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591181,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591176,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591175,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591157,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591146,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591135,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591134,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761053,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760034,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760033,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760032,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 760031,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854354,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845513,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845509,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845508,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845500,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845499,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845498,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845496,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845494,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845492,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845491,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845490,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845486,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845485,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845483,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845481,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845480,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845479,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845478,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845477,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845475,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845474,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845473,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845471,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845464,
    "City": "BETTIAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813233,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 823201,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813112,
    "City": "Bhagalpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396562,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393035,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393018,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392031,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392021,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364022,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364021,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490054,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534293,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534362,
    "City": "Bhimavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301425,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301423,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301422,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301421,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301420,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301419,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370635,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370611,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370429,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370061,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370002,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848159,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848157,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848156,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848154,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848153,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848152,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848151,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811108,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803129,
    "City": "BIHARSHARIF",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586137,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586135,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586107,
    "City": "BIJAPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174035,
    "City": "BILASPUR HP",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829138,
    "City": "JARIDIH BAZAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827008,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 827007,
    "City": "Bokaro",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743707,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743236,
    "City": "BONGAON",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848226,
    "City": "DUMRAON",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712428,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712155,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712153,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712112,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712150,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854360,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841339,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841318,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841317,
    "City": "CHHAPRA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761051,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761046,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761044,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761022,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761021,
    "City": "CHHATRAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604914,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736257,
    "City": "COOCH BEHAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626556,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626533,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626522,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626521,
    "City": "CUMBAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444113,
    "City": "DAHIHANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332702,
    "City": "DANTARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854367,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847119,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847118,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847117,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847113,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845524,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845523,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845520,
    "City": "DARBHANGA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583146,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577602,
    "City": "HARIHAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577594,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577580,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577579,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577573,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581183,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571493,
    "City": "DAVANGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 248116,
    "City": "Dehradun",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455224,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455114,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455113,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455225,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636751,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635855,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635823,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635821,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635659,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635611,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635140,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635139,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635138,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635137,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635136,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635134,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635132,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581215,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581175,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580116,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580115,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580113,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580110,
    "City": "Dharwad",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743382,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786054,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786053,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786052,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786009,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786186,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786180,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624628,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624627,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624623,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624318,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624317,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624315,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624311,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624310,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624235,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624234,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624232,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624231,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624230,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624227,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624223,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624222,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624221,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624217,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624214,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624213,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731212,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733302,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713382,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713379,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713366,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713345,
    "City": "DURGAPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679590,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679556,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676577,
    "City": "EDAPPAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370132,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370111,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382644,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382655,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382420,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382051,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382625,
    "City": "Gandhinagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841431,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841430,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841429,
    "City": "GOPALGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522014,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680609,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680111,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679633,
    "City": "Guruvayur",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841105,
    "City": "HAJIPUR TOWN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721669,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721665,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721662,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721661,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721638,
    "City": "HALDIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825414,
    "City": "HAZARIBAGH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 191141,
    "City": "HMT",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144116,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582216,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581158,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581233,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581231,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581228,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581124,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580216,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580215,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580214,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580212,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 580117,
    "City": "HUBLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303092,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302054,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302053,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302052,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302051,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302035,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141318,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141317,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141316,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141311,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141310,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141306,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141304,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141303,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 141302,
    "City": "Jalandhar",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425136,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425134,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425133,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425131,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425130,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425129,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425128,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425125,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425124,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425123,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425122,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425121,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425120,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425119,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425118,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785057,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785020,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785012,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364645,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364535,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362615,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362013,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362012,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360411,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585337,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585289,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585286,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585285,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585282,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585278,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585266,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585243,
    "City": "KALABURAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603118,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209261,
    "City": "Kanpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609705,
    "City": "KARAIKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132123,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132152,
    "City": "Karnal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244714,
    "City": "KASHIPUR",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 855124,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855122,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854127,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854126,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854125,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854124,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854123,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854111,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852154,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852145,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845120,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845119,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845117,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845116,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845115,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845113,
    "City": "KATIHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416228,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416227,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416217,
    "City": "KAGAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416127,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416034,
    "City": "KOLHAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 329090,
    "City": "Kota",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686646,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686627,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686529,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686035,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686032,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686031,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686027,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686026,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686025,
    "City": "Kottayam",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612505,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612205,
    "City": "Kumbakonam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518022,
    "City": "Kurnool",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132119,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 794109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 191123,
    "City": "LAL CHOWK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227207,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227119,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227116,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332030,
    "City": "LOSAL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732706,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732163,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732162,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732161,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732160,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732159,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732158,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732157,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732156,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732155,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732154,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732153,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732152,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732151,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732149,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732148,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732146,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732145,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732104,
    "City": "Malda",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571449,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571137,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522291,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522180,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522511,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402123,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402118,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576118,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576119,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576125,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 578105,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676518,
    "City": "VALANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676516,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676161,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676128,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676127,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676125,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676124,
    "City": "Manjery",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678761,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678605,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678752,
    "City": "MANNARKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403520,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690564,
    "City": "Mavelikera",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690549,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690541,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 250134,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 250020,
    "City": "Meerut",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244801,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244711,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244105,
    "City": "Moradabad",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476133,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476031,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476027,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476026,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476006,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 855456,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845518,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845515,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845514,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845504,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845497,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845488,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845421,
    "City": "MOTIHARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680321,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852151,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811269,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 251210,
    "City": "MUZAFFARNAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843148,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843140,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843138,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843131,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 842010,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842009,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 842006,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843160,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843159,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843158,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843156,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843155,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843154,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843151,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 852235,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845465,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843801,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843611,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843368,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843367,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843364,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843363,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843362,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843164,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843163,
    "City": "Muzaffarpur",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387531,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387385,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387382,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387331,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387140,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387135,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387113,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518202,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518675,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518669,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518596,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518363,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731246,
    "City": "MOHAMMAD BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682204,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 682044,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679518,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 679517,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679314,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679312,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679311,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679310,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678637,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678635,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678634,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686655,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686654,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686615,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686597,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686596,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686595,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686592,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686591,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686590,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686589,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686588,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686568,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686567,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686565,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686527,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686524,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686523,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686124,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385525,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385522,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385519,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385518,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385517,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385516,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385012,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132151,
    "City": "Panipat",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671350,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670522,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670338,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670322,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670321,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670319,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670318,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670317,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670316,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670313,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679351,
    "City": "MALAPPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403111,
    "City": "MERCES",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642146,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360583,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516114,
    "City": "Proddatur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516366,
    "City": "Proddatur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626182,
    "City": "Pudukkottai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752044,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752043,
    "City": "PURI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855315,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855276,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 855126,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854364,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854358,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854353,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854352,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854350,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854349,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854321,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854319,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854209,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854207,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854001,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853208,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853206,
    "City": "NAUGACHIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852233,
    "City": "PURNIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229315,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229003,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229002,
    "City": "RAEBARELI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623611,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623562,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623546,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623545,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623544,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623543,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829146,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632631,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632523,
    "City": "RANIPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231221,
    "City": "RENUKOOT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769019,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769018,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 769017,
    "City": "ROURKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247673,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247402,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247325,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247323,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 247239,
    "City": "SAHARANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274701,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768150,
    "City": "Sambalpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148051,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148050,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148049,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148047,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148046,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148045,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148044,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148043,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148042,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148041,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148040,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148039,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148009,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148036,
    "City": "Sangrur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577153,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577237,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577235,
    "City": "SHIVAMOGGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396250,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396245,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396236,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396228,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626184,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626180,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626176,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785659,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785658,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785657,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785656,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785655,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785654,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785653,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785652,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785651,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785650,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785647,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785646,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785645,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785642,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785639,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841512,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841256,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841255,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841254,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841253,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841250,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841247,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 841246,
    "City": "MAHARAJAGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413254,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413225,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413009,
    "City": "SOLAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335902,
    "City": "Sriganganagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335043,
    "City": "Sriganganagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335042,
    "City": "Sriganganagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335026,
    "City": "Sriganganagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852136,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363045,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363041,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363023,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363022,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534137,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534108,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534116,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534160,
    "City": "Tadepalligudem",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767184,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767091,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754070,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756087,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767071,
    "City": "TALCHER",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731218,
    "City": "MOHAMMAD BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627815,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613505,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626572,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626534,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626524,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624225,
    "City": "Theni",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686722,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686702,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686697,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686696,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685592,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606915,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604211,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628803,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628707,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628663,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628661,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628513,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628228,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628227,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628051,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627957,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627912,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627911,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627910,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627909,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627906,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627905,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627904,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627866,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627823,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627820,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627774,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627771,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627769,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627767,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627766,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627723,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627722,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627721,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627432,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627419,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627360,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627303,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627253,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627154,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627153,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627125,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627122,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603320,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 631105,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603316,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603315,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 631609,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603317,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 603114,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 689620,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689593,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689552,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686107,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 313613,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313612,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313610,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313609,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313608,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313607,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313606,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313033,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313032,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313030,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313025,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313023,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673558,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673557,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673555,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673112,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673111,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673110,
    "City": "Vadakara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396021,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 381520,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396175,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396209,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396202,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396200,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396197,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396106,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396223,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221111,
    "City": "VARANASI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632528,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631057,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631053,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632607,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632606,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632208,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632205,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605753,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606910,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606716,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606715,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606713,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606711,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605809,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632051,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632525,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 608803,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606810,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631061,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631060,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631118,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635819,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635756,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635753,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632206,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606912,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606909,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606809,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606717,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606714,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606712,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632526,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604002,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631404,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631104,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632530,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632529,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632524,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632522,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606223,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604412,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680593,
    "City": "DHEASHMANGALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680592,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442112,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442110,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442109,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442108,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441121,
    "City": "Wardha",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206249,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 274407,
    "City": "SEWRAHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232336,
    "City": "ZAMANIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232340,
    "City": "ZAMANIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 223227,
    "City": "SARAIMEER",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 223225,
    "City": "SARAIMEER",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171007,
    "City": "MASHOBRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 390915,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 381156,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380481,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380452,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380316,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380215,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380202,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380152,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380151,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380123,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431100,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742190,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742191,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742188,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742186,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742183,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742182,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742180,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742177,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742176,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742173,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742131,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742124,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742109,
    "City": "MURSHIDABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711127,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711212,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711206,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711328,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754015,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752201,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 851228,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851225,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851209,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851138,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851137,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851136,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851107,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 851026,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843339,
    "City": "BEGUSARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756136,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756110,
    "City": "BHADRAK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492103,
    "City": "Bhilai",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462035,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743507,
    "City": "BHANGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641106,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 682051,
    "City": "Cochin",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683564,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 814124,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828311,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 828147,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826124,
    "City": "BALIAPUR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 826009,
    "City": "Dhanbad",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 421206,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421205,
    "City": "KALYAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638130,
    "City": "ERODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 474040,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711247,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711321,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 501513,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 502304,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453777,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453576,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832113,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832012,
    "City": "Jamshedpur",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 672301,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 672212,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492042,
    "City": "BHILAI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614724,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614027,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571334,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571322,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571331,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571324,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571185,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571184,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571169,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571162,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571161,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571160,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571158,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571131,
    "City": "MYSORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637418,
    "City": "Namakkal",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637026,
    "City": "Namakkal",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637024,
    "City": "Namakkal",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422111,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422509,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422503,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422220,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422217,
    "City": "Nashik",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671341,
    "City": "PARAPPA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671328,
    "City": "PARAPPA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671357,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671353,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440038,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422216,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422108,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422107,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422106,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678614,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624611,
    "City": "PALANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803206,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803218,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848509,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 800108,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671349,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642153,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642149,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642148,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642145,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642144,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642143,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642142,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642141,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642140,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642137,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642136,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642135,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642131,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642124,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642118,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642116,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642115,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642055,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642054,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642053,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641120,
    "City": "Pollachi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492016,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492102,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712425,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453562,
    "City": "SANWER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734435,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743001,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734433,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734432,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734430,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734428,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734418,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734318,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734025,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734018,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734016,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733006,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732013,
    "City": "SILIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627762,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626212,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626211,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626179,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626173,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626171,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626166,
    "City": "SIVAKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400617,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643054,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627867,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623565,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695560,
    "City": "Tirupur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636318,
    "City": "Tirupur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695049,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 694582,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642139,
    "City": "Udumalaipettai",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642138,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639204,
    "City": "PALLIVASAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394632,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396156,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391443,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391442,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391441,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391773,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388217,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388216,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388213,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391742,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391447,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391411,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391380,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391354,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391353,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391352,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391351,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365730,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686614,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686307,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686145,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686142,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626160,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621221,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604155,
    "City": "VILLUPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626163,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626162,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626159,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626156,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626155,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626153,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626150,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626148,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626147,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626186,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626210,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626209,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626208,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626207,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626195,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626181,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626175,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626172,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626167,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626165,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626158,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626157,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626152,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626146,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626059,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626058,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626056,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626055,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626054,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626052,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626051,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626701,
    "City": "Virudhunagar",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680723,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 680315,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 680221,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 680203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 676314,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 561219,
    "City": "KOLAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623539,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623801,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609002,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 695616,
    "City": "Pangodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638204,
    "City": "UDUMALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635856,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 631027,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415201,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509136,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576220,
    "City": "Shiroor",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402100,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395163,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394664,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306117,
    "City": "FALNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394114,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393340,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392026,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431733,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431732,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431730,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431728,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627133,
    "City": "CHENBAGARAMANPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425106,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638404,
    "City": "SATHYAMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814132,
    "City": "GODDA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722179,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805137,
    "City": "LAKHISARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785109,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 843135,
    "City": "SARAI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396526,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396525,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396523,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396340,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396331,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396431,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396400,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396142,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396112,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410708,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410707,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410615,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767036,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767034,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767043,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411105,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411099,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144308,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144203,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144218,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852160,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852159,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852158,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852157,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852156,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852148,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364262,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364261,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364251,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364231,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364146,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534459,
    "City": "CHINTALAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244500,
    "City": "AMROHA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413313,
    "City": "ATPADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829115,
    "City": "PETARBAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415345,
    "City": "ATPADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413901,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140801,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521372,
    "City": "GANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521265,
    "City": "GANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521112,
    "City": "GANNAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141420,
    "City": "MACHIWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413246,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413245,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413244,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413243,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413242,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411412,
    "City": "ALEPHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721257,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721256,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721254,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754285,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754282,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754261,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754251,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754161,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721252,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721228,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721165,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743339,
    "City": "KAKDWIP",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736177,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736173,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736162,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362142,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736132,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736130,
    "City": "ALIPURDUAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362155,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362152,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362151,
    "City": "TALALA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756082,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756078,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690569,
    "City": "SASTHAMKOTTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442912,
    "City": "WARORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516292,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516166,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516103,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516392,
    "City": "MUDDANUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403514,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721421,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721466,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363333,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363332,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363331,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721459,
    "City": "DANTAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629503,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626566,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626537,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626535,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626532,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532525,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531273,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531242,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531221,
    "City": "GAJAPATHINAGARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431815,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431814,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431813,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143144,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673212,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673126,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673578,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635857,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635825,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635824,
    "City": "KRISHNAGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305414,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305410,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305409,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311406,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305416,
    "City": "KEKRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821006,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821005,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821003,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821001,
    "City": "SASARAM",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207441,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 629406,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761033,
    "City": "BALUGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441227,
    "City": "SAOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403408,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495337,
    "City": "MUNGELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735251,
    "City": "BANARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582220,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577568,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577567,
    "City": "CHITRADURGA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522299,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522298,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522297,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522295,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522294,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522293,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522292,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522290,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522266,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389355,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712420,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 325008,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325006,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323606,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323604,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 770025,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 502102,
    "City": "DUBBAK",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425521,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425520,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425518,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425517,
    "City": "MUKTAINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425204,
    "City": "BHUSAWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424111,
    "City": "BHADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416422,
    "City": "SANGALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416421,
    "City": "SANGALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431812,
    "City": "GANGAKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571178,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571170,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571165,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571156,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571155,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571145,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571142,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571141,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571337,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571333,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571328,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571327,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571323,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571319,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571318,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571499,
    "City": "CHAMARAJANAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743392,
    "City": "GANGASAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743390,
    "City": "GANGASAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425515,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425514,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425512,
    "City": "KARHALE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844141,
    "City": "MAHUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844139,
    "City": "MAHUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209293,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 441607,
    "City": "TIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441604,
    "City": "TIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533554,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533514,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533502,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533227,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533225,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416541,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416530,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743510,
    "City": "RAJARHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847254,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813228,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813227,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813223,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813114,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587153,
    "City": "NAVNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587143,
    "City": "NAVNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587215,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587214,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 587213,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582234,
    "City": "NAVNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581426,
    "City": "NAVNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144038,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629182,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628662,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628660,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628659,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628658,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628655,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623559,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623320,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623319,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623309,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623307,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623304,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712429,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712423,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712422,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362627,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362626,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680736,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680735,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680672,
    "City": "MALA KL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423116,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423114,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422307,
    "City": "LASALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391818,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391817,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391816,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391815,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391461,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391460,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396528,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396527,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396042,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396041,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412225,
    "City": "RANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412224,
    "City": "RANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412223,
    "City": "RANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334306,
    "City": "KHAJUVALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334304,
    "City": "KHAJUVALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334101,
    "City": "KHAJUVALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306310,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306309,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306710,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752021,
    "City": "NIRAKARPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752020,
    "City": "NIRAKARPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752019,
    "City": "NIRAKARPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742179,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742178,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261203,
    "City": "SIDHAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332717,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332704,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721667,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743369,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743360,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743322,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743316,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743381,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743380,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743397,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743394,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743389,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636915,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636908,
    "City": "HARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752022,
    "City": "CHANDPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689125,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362242,
    "City": "MANGROL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144207,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144210,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302036,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302027,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 688581,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688557,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637217,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736174,
    "City": "Bamanhat",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815356,
    "City": "JAMTARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742403,
    "City": "SALAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382739,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382737,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382736,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711413,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711412,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756053,
    "City": "BAHANAGA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711226,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 424313,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424026,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276225,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276103,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325224,
    "City": "KISHANGANJ RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678016,
    "City": "Palakkad",
    "State": "Kerala",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585375,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585273,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585247,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585233,
    "City": "Shahapur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413122,
    "City": "Beed",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413124,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413123,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413121,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360045,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360032,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360080,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320012,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320010,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320009,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320007,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320005,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320003,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 320000,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314806,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314803,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314802,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314407,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314405,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314013,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313806,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382724,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410205,
    "City": "NAGOTHANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431529,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431527,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394675,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393133,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393131,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396452,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396443,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396480,
    "City": "Navsari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331401,
    "City": "Sardarshahar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735350,
    "City": "Birpara",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584154,
    "City": "Manvi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584147,
    "City": "Manvi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584144,
    "City": "Manvi",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441922,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441921,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441930,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676318,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423103,
    "City": "MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425211,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424210,
    "City": "JAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423304,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382310,
    "City": "DEHGAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691325,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691314,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691317,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691329,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382706,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752059,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752058,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847249,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845335,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845318,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845145,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221605,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 854357,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 854351,
    "City": "FORBESGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382727,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382723,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382718,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382717,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384440,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382741,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756054,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756052,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756050,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757076,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754117,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754116,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754115,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754158,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754155,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754017,
    "City": "JAGATSINGHPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743402,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 747273,
    "City": "SWARUPNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767031,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767047,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 243643,
    "City": "KAIMGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243642,
    "City": "KAIMGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209701,
    "City": "KAIMGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713426,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713515,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305626,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305413,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305411,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496460,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522215,
    "City": "CHEBROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277514,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277011,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227115,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227107,
    "City": "MALIHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382742,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382726,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382722,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382720,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382719,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382707,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382704,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844134,
    "City": "FAKULI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331404,
    "City": "Sardarshahar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302041,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302040,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423209,
    "City": "MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522276,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522275,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522274,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522273,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522272,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522271,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522269,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624228,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624501,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624404,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623718,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623716,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 838325,
    "City": "LOHARDAGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 838303,
    "City": "LOHARDAGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522260,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573145,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577119,
    "City": "SAKLESHPUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322228,
    "City": "Hindaun",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322235,
    "City": "Hindaun",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322231,
    "City": "Hindaun",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326041,
    "City": "JHALAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326040,
    "City": "JHALAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326024,
    "City": "JHALAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411925,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411201,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411125,
    "City": "Dhayari",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342804,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342803,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342017,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412501,
    "City": "CHAKAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335528,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335527,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335515,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335514,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333709,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333706,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333040,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637109,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635133,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635131,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635141,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331321,
    "City": "Pilani",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331307,
    "City": "Pilani",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 345110,
    "City": "JAISALMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 345032,
    "City": "JAISALMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431539,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431407,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431406,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518517,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741243,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741236,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445003,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151215,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140106,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591511,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591507,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333048,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333047,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333046,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209800,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 208120,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331002,
    "City": "Taranagar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306118,
    "City": "Pali",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229313,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209822,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209808,
    "City": "Unnao",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411208,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134009,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327033,
    "City": "Banswara",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327030,
    "City": "Banswara",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445323,
    "City": "Yavatmal",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140630,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140202,
    "City": "Panchkula",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416544,
    "City": "Sawantwadi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416532,
    "City": "Sawantwadi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322217,
    "City": "Gangapur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322209,
    "City": "Gangapur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582215,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581214,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581172,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581152,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581133,
    "City": "Haveri",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515332,
    "City": "HINDUPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587133,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584160,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584110,
    "City": "Raichur",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583273,
    "City": "Bellary",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442506,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442910,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515446,
    "City": "Dharmavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515282,
    "City": "Dharmavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515102,
    "City": "Dharmavaram",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523195,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523194,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523188,
    "City": "Chirala",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735238,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735237,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735236,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470114,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470013,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470008,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470007,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470006,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494003,
    "City": "Jagdalpur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494002,
    "City": "Jagdalpur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494005,
    "City": "Jagdalpur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464016,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464010,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306108,
    "City": "BEAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306106,
    "City": "BEAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305928,
    "City": "BEAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518017,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515814,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515458,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515457,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 207023,
    "City": "ETAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322031,
    "City": "LALSOT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535231,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532534,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531091,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515466,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515422,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515421,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515419,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515416,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532526,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532524,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532523,
    "City": "PARVATHIPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306913,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306505,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271006,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388714,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388716,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389123,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389122,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389121,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515409,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571614,
    "City": "KUSHALNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571132,
    "City": "KUSHALNAGAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783374,
    "City": "KOKRAJHAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424113,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424027,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424317,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424316,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424315,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424314,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532617,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532599,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522655,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522282,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522281,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522280,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522279,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522278,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522277,
    "City": "Repalle",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587242,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587212,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587211,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587210,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587209,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587208,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587152,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587149,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587144,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587138,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587123,
    "City": "Badami",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522116,
    "City": "BAPATLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532323,
    "City": "KAVITI AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532293,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143510,
    "City": "Batala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385543,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385542,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385541,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 678558,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630209,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262505,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262412,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262411,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243406,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243215,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243208,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243206,
    "City": "NAWABGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382836,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382833,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382832,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382831,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382816,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382812,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382806,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382805,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382797,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582231,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582227,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582214,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582131,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582124,
    "City": "GADAG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335801,
    "City": "PILIBANGA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321211,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321210,
    "City": "BHARATPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321209,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321208,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321207,
    "City": "DEEG",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382861,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382857,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382856,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382851,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384318,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382875,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382871,
    "City": "VIJAPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515233,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515232,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306028,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306027,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306026,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306025,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306024,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532593,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532592,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532578,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532547,
    "City": "SALUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532209,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532208,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425425,
    "City": "NAVAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577171,
    "City": "MUDIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577151,
    "City": "MUDIGERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303115,
    "City": "KOTPUTLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683021,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683012,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683001,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 680692,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635707,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635706,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635705,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635704,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635662,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635661,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635660,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635656,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 635313,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625124,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625120,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625119,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625118,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625116,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784503,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360026,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673650,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591187,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591160,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591151,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587328,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587325,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587324,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587320,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587317,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587137,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587136,
    "City": "MUDHOL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521118,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 520050,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673338,
    "City": "KONDOTTY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384311,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384226,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384213,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384153,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384122,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222112,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 222110,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 854368,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843366,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843355,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843348,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843347,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843342,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843341,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843340,
    "City": "SITAMARHI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767006,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767004,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767064,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416319,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416317,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416132,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412903,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415532,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415531,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415529,
    "City": "PHALTAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685050,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 685025,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 685005,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683525,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 683593,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 281304,
    "City": "MATHURA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382734,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382733,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382709,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382708,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364561,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382868,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382867,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382863,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382862,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382826,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382713,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384178,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384176,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384174,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341539,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341537,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341536,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341528,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341527,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341526,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341525,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341523,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332743,
    "City": "KUCHAMAN CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788729,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413577,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413576,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413575,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413574,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413573,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413572,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413571,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413570,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413568,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413567,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413566,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413565,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788708,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788705,
    "City": "KARIMGANJ",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722209,
    "City": "SONAMUKHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722112,
    "City": "SONAMUKHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722110,
    "City": "SONAMUKHI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723167,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723164,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723163,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723162,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723160,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723159,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723158,
    "City": "PURULIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 148032,
    "City": "LEHRAGAGA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333315,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333314,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333313,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333312,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333311,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333309,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333301,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333111,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333052,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333016,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333015,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333014,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332740,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332739,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332738,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332737,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332736,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332735,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332734,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332733,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332732,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332731,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332730,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332729,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332728,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332727,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332726,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332725,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332724,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332723,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333306,
    "City": "UDAIPURWATI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 302043,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302030,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802231,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802230,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802174,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422621,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422619,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422617,
    "City": "SANGAMNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516260,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516238,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516118,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802366,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802317,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802315,
    "City": "ARRAH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516486,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341011,
    "City": "NAGAUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341002,
    "City": "NAGAUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209025,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209608,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209744,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370472,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370471,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 814117,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362021,
    "City": "JUNAGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733149,
    "City": "TAPAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733147,
    "City": "GANGARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733146,
    "City": "GANGARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277406,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516440,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516438,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516413,
    "City": "JAMMALAMADUGU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533551,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522668,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522630,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522296,
    "City": "VINUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515844,
    "City": "GUNTAKAL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370440,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370422,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370411,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370406,
    "City": "MUNDRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 736150,
    "City": "DINHATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582122,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582135,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582128,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691527,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591703,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591168,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591159,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591155,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 586246,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582229,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582228,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582221,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582212,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582238,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582237,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845495,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413318,
    "City": "PANDHARPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413316,
    "City": "PANDHARPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325210,
    "City": "ANTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312610,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312609,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312608,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312607,
    "City": "NIMBAHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591512,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591505,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591343,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591339,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591299,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591296,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591295,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591293,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591289,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584165,
    "City": "SINDHANUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584141,
    "City": "SINDHANUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670647,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591283,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591282,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591279,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591274,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591272,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636137,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497020,
    "City": "AMBIKAPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638328,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636604,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636463,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636462,
    "City": "Mettur",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321506,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321430,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321429,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321428,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321427,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321426,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321425,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321424,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321423,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321422,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321421,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321420,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321419,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321418,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321417,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321415,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321414,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321413,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321412,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321036,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321035,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321034,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321033,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321032,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321031,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321030,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321029,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321027,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413129,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413128,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413127,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413125,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 815201,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441213,
    "City": "UMRED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521370,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521231,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521177,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521155,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521128,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521116,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521114,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521113,
    "City": "KANKIPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848512,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848510,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848183,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848177,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848155,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848149,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848148,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848144,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848140,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848137,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848136,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848124,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848111,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848103,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815304,
    "City": "GIRIDIH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305805,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313303,
    "City": "NATHDWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341524,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305824,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305820,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305818,
    "City": "KISHANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585352,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585350,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585270,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585262,
    "City": "HUMNABAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413241,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413240,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413239,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413238,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413237,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413236,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413235,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413234,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413232,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413231,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413230,
    "City": "JAMKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362259,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362257,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362249,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362216,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362247,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362246,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362231,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362228,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445677,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442312,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442311,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442310,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442309,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442308,
    "City": "HINGANGHAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362224,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362223,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363669,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363668,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363667,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363666,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363665,
    "City": "VERAVAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224175,
    "City": "BURHANPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224138,
    "City": "BURHANPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362726,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325227,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325226,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326521,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326503,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362271,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362722,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362721,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362278,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362277,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362733,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413233,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365435,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585257,
    "City": "SHIVAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585354,
    "City": "SHIVAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585349,
    "City": "SHIVAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362624,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362623,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362621,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360650,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360572,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360571,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362653,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362652,
    "City": "KUTIYANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844522,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844521,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844520,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844519,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844518,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844517,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844516,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844515,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844514,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844513,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844512,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844511,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844510,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844140,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844138,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844137,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844136,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844135,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844133,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844132,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844130,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844129,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303118,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303117,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303116,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303114,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303113,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303112,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303111,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843100,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802318,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301426,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275120,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275107,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335074,
    "City": "RAISINGHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301414,
    "City": "RAJGARH RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393016,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754243,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756142,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756141,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756140,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 584131,
    "City": "KUSHTAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 584121,
    "City": "KUSHTAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483446,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483445,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483443,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587151,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587148,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587147,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587146,
    "City": "ILKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754252,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415210,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415120,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362634,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362633,
    "City": "MANAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228115,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313034,
    "City": "Udaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363636,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332401,
    "City": "MUKUNGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332024,
    "City": "DADIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403518,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673333,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515161,
    "City": "MUDDIGUBA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 230502,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333044,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332409,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332408,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332045,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332044,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332040,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332038,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332037,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332036,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332035,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332034,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332033,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332027,
    "City": "Sikar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 151511,
    "City": "MANSA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271828,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271214,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441612,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332315,
    "City": "Laxmangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331035,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331034,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331032,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331020,
    "City": "Churu",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759139,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759138,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759137,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759136,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759135,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759134,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759133,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759114,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759144,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759142,
    "City": "ANGUL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522123,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522222,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 522340,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522321,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 765028,
    "City": "KORAPUT",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680695,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344015,
    "City": "BARMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344014,
    "City": "BARMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344013,
    "City": "BARMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331305,
    "City": "Rajgarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425447,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425446,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425445,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425440,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425438,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425437,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425436,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425435,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425434,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425433,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425431,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425430,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464101,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464002,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464112,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344503,
    "City": "BARMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364440,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306128,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306127,
    "City": "SUMERPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152129,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152127,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360335,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360075,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 344029,
    "City": "BALOTRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 851126,
    "City": "BARAUNI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 245102,
    "City": "HAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322233,
    "City": "KARAULI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322327,
    "City": "KARAULI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322248,
    "City": "KARAULI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638511,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341547,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341546,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341545,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341544,
    "City": "MERTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638471,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638324,
    "City": "ANTHIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627714,
    "City": "KOVILPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523216,
    "City": "MARKAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524102,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227000,
    "City": "Lucknow",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534015,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412507,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412502,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412310,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415118,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144424,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144423,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311029,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431146,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311020,
    "City": "BIJAINAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391242,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391431,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363626,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363535,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363522,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754288,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754286,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754284,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754283,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754281,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754280,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754157,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754156,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733148,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 733131,
    "City": "FULBARI WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591802,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591504,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591196,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591183,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591162,
    "City": "SAUNDATTI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209407,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 220016,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 220009,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 220007,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 220006,
    "City": "BINDKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680595,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680771,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515566,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515866,
    "City": "PUTTAPARTHI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518550,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518555,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518607,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415721,
    "City": "RATNAGIRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415630,
    "City": "RATNAGIRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415627,
    "City": "RATNAGIRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424117,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424116,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424114,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424112,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424110,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522620,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 312033,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312026,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585430,
    "City": "BHALKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585356,
    "City": "BHALKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581185,
    "City": "BHATKAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533541,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364545,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362552,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362515,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362136,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362036,
    "City": "UNAGJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494227,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494225,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414307,
    "City": "SHIRUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577585,
    "City": "HIRIYUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572161,
    "City": "HIRIYUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410514,
    "City": "JUNNAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395620,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395375,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395345,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 726206,
    "City": "HATIPOTHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679343,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679342,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363416,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363412,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363411,
    "City": "RANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415005,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415016,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303705,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624406,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624405,
    "City": "NATHAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365665,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365632,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364660,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364481,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364458,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364445,
    "City": "Dhari",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679355,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782107,
    "City": "RAHA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 762025,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 204103,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384177,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384172,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384171,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384154,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384152,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416542,
    "City": "DODAMARG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416539,
    "City": "DODAMARG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673330,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364456,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364635,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364633,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364630,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364524,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364523,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364518,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364517,
    "City": "SAVARKUNDLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331033,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204209,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204208,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204207,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204206,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 204205,
    "City": "HATHRAS",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364714,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364713,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364712,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413616,
    "City": "TULJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413615,
    "City": "TULJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144619,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638675,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 210105,
    "City": "KARWI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416428,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 592236,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591517,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591516,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591513,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591509,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591508,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591506,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591342,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591337,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591333,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591292,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591276,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591251,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591250,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591178,
    "City": "GOKAK",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759035,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759034,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759033,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759032,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759031,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759030,
    "City": "KAMAKHYANAGAR ORRISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413416,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413413,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577586,
    "City": "Challakere",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743388,
    "City": "DIAMOND HARBOUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624502,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624503,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624504,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624505,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635208,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635209,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635212,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635308,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638059,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638117,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638207,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638319,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638406,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638469,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 639210,
    "City": "KATHIMEDU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362727,
    "City": "KODINAR",
    "State": "DAMAN AND DIU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673324,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 284407,
    "City": "LALITPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670121,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670543,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670605,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670614,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 670615,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670648,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632320,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621127,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627706,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627903,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302034,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333701,
    "City": "MANDAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332303,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332302,
    "City": "JHUNJHUNU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332305,
    "City": "MANDAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533447,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 533449,
    "City": "KATHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829143,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829118,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732138,
    "City": "PAKUAHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732147,
    "City": "PAKUAHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414202,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414208,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585227,
    "City": "MANNAEKHELLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 585414,
    "City": "MANNAEKHELLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515502,
    "City": "MUDDIGUBA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125049,
    "City": "NARNAUND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754011,
    "City": "ADASPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754010,
    "City": "ADASPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 122505,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768029,
    "City": "BARPALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767045,
    "City": "BARPALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754213,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754212,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754228,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754253,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413604,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792001,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577112,
    "City": "BALEHONNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577123,
    "City": "BALEHONNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577126,
    "City": "KOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577127,
    "City": "KOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577134,
    "City": "KOPPA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303903,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 181142,
    "City": "VIJAYPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182202,
    "City": "DODA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273303,
    "City": "MAHARAJGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273207,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 182222,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 571114,
    "City": "H D KOTE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571121,
    "City": "H D KOTE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 222201,
    "City": "MUNGRA BADSHAHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222202,
    "City": "MUNGRA BADSHAHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307001,
    "City": "SIROHI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302045,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302044,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302046,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302047,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302048,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302049,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 302050,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413564,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444112,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444114,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444116,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444118,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444119,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444120,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444121,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444122,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444123,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444124,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444129,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444718,
    "City": "AKOT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457112,
    "City": "ALOT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691303,
    "City": "CHATHANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691580,
    "City": "MUKATHALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431524,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431525,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380017,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380041,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380330,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382211,
    "City": "Ahmedabad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382324,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382327,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382333,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460008,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481015,
    "City": "BALAGHAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481022,
    "City": "BALAGHAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754009,
    "City": "KANTILO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441928,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458777,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470118,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470111,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754023,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 444713,
    "City": "ANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721152,
    "City": "NIMTOURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721160,
    "City": "DEBRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454020,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454051,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679538,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679537,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782480,
    "City": "BOKAJAN",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721452,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761211,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 460444,
    "City": "GHORADONGARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442700,
    "City": "GONDPIPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470662,
    "City": "HATTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470773,
    "City": "HATTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 733151,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 302038,
    "City": "Jaipur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400048,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754244,
    "City": "KENDRAPARA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691542,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 230201,
    "City": "LALGOPALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495445,
    "City": "KATGORA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425312,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425313,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425314,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425315,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425316,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425317,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425318,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425319,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425320,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425321,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425322,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425323,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425324,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425325,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425336,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443109,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443110,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443111,
    "City": "MALKAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416607,
    "City": "MALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384121,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384165,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591317,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591217,
    "City": "KUDCHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443406,
    "City": "JALGAON JAMOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443407,
    "City": "NANDURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713380,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713132,
    "City": "BHATAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416604,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416603,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422221,
    "City": "OZHAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431528,
    "City": "PATHARDI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144037,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144035,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678507,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 445208,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445214,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445217,
    "City": "PUSAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314031,
    "City": "SAGWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415020,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415015,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767046,
    "City": "SAINTALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460050,
    "City": "SHAHPUR BHOURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 813201,
    "City": "SULTANGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 770072,
    "City": "SUNDERGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848115,
    "City": "TAJPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394265,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394296,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395016,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395018,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395021,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680608,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680549,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442913,
    "City": "WARORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444508,
    "City": "Washim",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444509,
    "City": "Washim",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444910,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444911,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444912,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444913,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444914,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444915,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444921,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445213,
    "City": "UMARKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445303,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515741,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 515867,
    "City": "RAYADURGAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571216,
    "City": "PONNAMPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571249,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571215,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788727,
    "City": "BAZARICHERRA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788728,
    "City": "BAZARICHERRA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626202,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370630,
    "City": "DAYAPAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370625,
    "City": "DAYAPAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370655,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370645,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370640,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638505,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638506,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638512,
    "City": "KONDAYAMPALYA",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 476337,
    "City": "SHEOPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416702,
    "City": "RAJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416713,
    "City": "RAJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416705,
    "City": "RAJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274508,
    "City": "LAR TOWN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274505,
    "City": "LAR TOWN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 110099,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 110100,
    "City": "NEW DELHI",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160105,
    "City": "Chandigarh",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801504,
    "City": "Patna",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400040,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400041,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400045,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400073,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 400100,
    "City": "Mumbai",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395014,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395015,
    "City": "Surat",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700019,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700078,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700073,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700007,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700001,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700069,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700062,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411074,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411088,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411080,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411082,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411067,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411072,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411076,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411084,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411089,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411090,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411092,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411093,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411094,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411096,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411097,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411069,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411095,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411059,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411083,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411049,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411077,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411064,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411091,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411081,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411055,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411063,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411070,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411066,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411071,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411078,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411085,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411079,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411098,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411068,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411073,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411087,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411050,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411056,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411065,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411075,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 411086,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600065,
    "City": "TIRUVALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 500099,
    "City": "HYDERABAD",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 440031,
    "City": "Nagpur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457893,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221404,
    "City": "HANDIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410202,
    "City": "Khopoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413113,
    "City": "MALASHIRAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 794001,
    "City": "TURA",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637103,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637503,
    "City": "MOREPALAYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712403,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712404,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712706,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712408,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274406,
    "City": "SEWRAHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 671532,
    "City": "RAJAPURAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209205,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 209203,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721631,
    "City": "NANDIGRAM WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721656,
    "City": "NANDIGRAM WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721646,
    "City": "NANDIGRAM WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721650,
    "City": "PURBA MEDINIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844126,
    "City": "MAHUA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768027,
    "City": "ATTABIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768103,
    "City": "ATTABIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768111,
    "City": "ATTABIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516005,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516006,
    "City": "CUDDAPAH",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175049,
    "City": "DHARAMPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274301,
    "City": "KAPTANGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614701,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614704,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614613,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614723,
    "City": "ADIRAMAPATTINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586213,
    "City": "MUDDEBIHAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191201,
    "City": "LAR GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456443,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456440,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609001,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609801,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609309,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227817,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227809,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638504,
    "City": "KESARIMANGALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176207,
    "City": "CHOWARI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124404,
    "City": "KHARKHODA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134118,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 134204,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562160,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625706,
    "City": "THIRUPARANKUNDRAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691559,
    "City": "KOLLAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 794115,
    "City": "AMPATI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182141,
    "City": "CHENANI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182142,
    "City": "CHENANI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721439,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721440,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721456,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713315,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713368,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767023,
    "City": "BARPALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767021,
    "City": "BALANGIR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757086,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757032,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757030,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757051,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757081,
    "City": "JHARPOKHARIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 481667,
    "City": "NAINPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785103,
    "City": "MAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811210,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811209,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811208,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811207,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811206,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811205,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811204,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811203,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 813321,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451451,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457996,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457995,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457994,
    "City": "JOBAT",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451695,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622212,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622211,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495449,
    "City": "KASDOL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 454435,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454772,
    "City": "DHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788834,
    "City": "MAIBONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465221,
    "City": "SHAJAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461951,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461785,
    "City": "PIPARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454333,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454280,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454276,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454365,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454350,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454345,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415536,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 346005,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 346004,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461381,
    "City": "HARDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461338,
    "City": "HARDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413286,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413285,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413284,
    "City": "KURDUWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415516,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457337,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457426,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457223,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457332,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457330,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457228,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457335,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457334,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453831,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453643,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754016,
    "City": "BHUBANESWAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480104,
    "City": "SAUSAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457892,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457891,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457890,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457889,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451563,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451559,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451558,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451557,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457886,
    "City": "ALIRAJPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464996,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462048,
    "City": "MANDIDEEP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473229,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473101,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451435,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451411,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451381,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451376,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451341,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451338,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670695,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670679,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670677,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670110,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670022,
    "City": "Kuthuparamba",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686721,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686730,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686726,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686723,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686698,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686695,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686694,
    "City": "THODUPUZHA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 801119,
    "City": "PATNA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 582236,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582241,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582127,
    "City": "NARGUND",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691641,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691624,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691623,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691605,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691514,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461166,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461118,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456300,
    "City": "NAGDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722213,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712906,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712307,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712216,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712208,
    "City": "SHRIRAMPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461555,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461553,
    "City": "ITARSI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691335,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691320,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691300,
    "City": "KOTTARAKKARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364622,
    "City": "BARWALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636802,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635826,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635311,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635310,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635309,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635818,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635817,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635816,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635758,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635757,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635755,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635709,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635708,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635130,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635127,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636821,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636816,
    "City": "Dharmapuri",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413022,
    "City": "Barshi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413021,
    "City": "Barshi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591238,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591228,
    "City": "Nipani",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606123,
    "City": "Cuddalore",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 474055,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 474050,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 474041,
    "City": "Gwalior",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627768,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627727,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627718,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627717,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627716,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627123,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627132,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627126,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628309,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628307,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628306,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628305,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627822,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628223,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628222,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628220,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628214,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628155,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628154,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628654,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628652,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628631,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628630,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628629,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628628,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628627,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628626,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628625,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 760100,
    "City": "Berhampur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 442911,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483507,
    "City": "KATNI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 456662,
    "City": "Ujjain",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442909,
    "City": "Chandrapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444507,
    "City": "Washim",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480669,
    "City": "Seoni",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480664,
    "City": "Seoni",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623305,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623217,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623216,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623215,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623214,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623212,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623211,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623210,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623209,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623208,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623207,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623205,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623201,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623111,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623109,
    "City": "Karaikudi",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457769,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457553,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457440,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457111,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457003,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743164,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743146,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743143,
    "City": "CHANDANNAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632325,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632324,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632323,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632322,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606221,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816123,
    "City": "RANISHWAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495689,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495442,
    "City": "RATANPURPALI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495444,
    "City": "KATGORA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496661,
    "City": "KARSHIA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626178,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626170,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626168,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626164,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626151,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628308,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458272,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458265,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458254,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458232,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458224,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394631,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481991,
    "City": "SHAHPURA SER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473445,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464334,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460111,
    "City": "BHAINSDEHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460226,
    "City": "BHAINSDEHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621304,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621303,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444512,
    "City": "PATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470889,
    "City": "TENDUKHEDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470225,
    "City": "KESLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400209,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400201,
    "City": "THANE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788731,
    "City": "HAILAKANDI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 466644,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466011,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466003,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465977,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441608,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493002,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492107,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492106,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 492105,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492104,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492100,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492021,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492017,
    "City": "RAIPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425127,
    "City": "Jalgaon",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483050,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 482016,
    "City": "JABALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829167,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 460669,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460667,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460660,
    "City": "MULTAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464556,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686566,
    "City": "PALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442919,
    "City": "GADCHANDUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442706,
    "City": "RAJURA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735139,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721464,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721460,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457665,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457664,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457662,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 753052,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425451,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486894,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 470230,
    "City": "Garhakota",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461222,
    "City": "SEONI MALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 681315,
    "City": "WADAKKANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603404,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442203,
    "City": "KARANJAGHADGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624203,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629196,
    "City": "KANYAKUMARI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622316,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622311,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622310,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622213,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421051,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 419209,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 404502,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400713,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400547,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 400125,
    "City": "MUMBAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487885,
    "City": "GADARWARA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484447,
    "City": "KOTMA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734502,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712617,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721434,
    "City": "BHAGABANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 457551,
    "City": "PIPLODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 484885,
    "City": "RAJENDRAGRAM",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721461,
    "City": "PICHABONI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821122,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821121,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821119,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 855121,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 855120,
    "City": "KISHANGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621129,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621128,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455335,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416014,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416009,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457776,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457771,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457583,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457556,
    "City": "THANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425453,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425450,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425449,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425443,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425429,
    "City": "KHANDBARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455337,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455348,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455344,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455342,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486355,
    "City": "MAUGANJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625538,
    "City": "USILAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712365,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723144,
    "City": "RAGHUNATHPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465230,
    "City": "AGARMALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734425,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629664,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629509,
    "City": "MANAVALAKURICHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458772,
    "City": "BHANPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480339,
    "City": "PANDHURNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629188,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784152,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784119,
    "City": "DHEKIAJULI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303608,
    "City": "FALNA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629183,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629192,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629190,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629181,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412415,
    "City": "ALEPHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454730,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454729,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454669,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454668,
    "City": "BADNAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457880,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457780,
    "City": "JHABUA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 346003,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 346002,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 440531,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441125,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465418,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465336,
    "City": "SHUJALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465693,
    "City": "ZIRAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741224,
    "City": "KALYANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741203,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788130,
    "City": "FULERTAL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458982,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458976,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458886,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458688,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458676,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458668,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458393,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495682,
    "City": "KORBA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845472,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824212,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824000,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 458660,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458595,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458588,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458581,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458576,
    "City": "MANDSAUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413540,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413539,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413538,
    "City": "DHARMABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402406,
    "City": "SONARI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472338,
    "City": "PRITHVIPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464676,
    "City": "SILWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673322,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673319,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673300,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673051,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673037,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673035,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673031,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673022,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673000,
    "City": "ULLIYERI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491665,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 816124,
    "City": "TINPAHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495687,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825107,
    "City": "DHAWATAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431725,
    "City": "LATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721642,
    "City": "SHRIRAMPUR WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802313,
    "City": "BARHARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443108,
    "City": "JAFRABADMH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443107,
    "City": "JAFRABADMH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721465,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491225,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396025,
    "City": "Valsad",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441216,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431141,
    "City": "PATHARDI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483776,
    "City": "BARHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735152,
    "City": "BALURGHAT",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721462,
    "City": "DIGHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431727,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431726,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451877,
    "City": "PANSEMAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786616,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394244,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394243,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394242,
    "City": "Vapi",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627725,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623721,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623719,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623713,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623710,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623709,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624625,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624507,
    "City": "Dindigul",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735505,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735503,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722159,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722103,
    "City": "Bankura",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785003,
    "City": "JORHAT",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785629,
    "City": "MARIANI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785678,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785677,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 146112,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144105,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785638,
    "City": "TEOK",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 206252,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 206241,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332710,
    "City": "DANTARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304802,
    "City": "DEOLI RAJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 304803,
    "City": "DEOLI RAJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303905,
    "City": "JAIPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205303,
    "City": "KUSMARA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 322022,
    "City": "LALSOT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 334602,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 341026,
    "City": "NAGAUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144415,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144034,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306306,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303603,
    "City": "RENWAL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341507,
    "City": "NAWA RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 335802,
    "City": "GOLUWALA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431715,
    "City": "MUKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455115,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 455111,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246444,
    "City": "KARNAPRAYAG",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246429,
    "City": "KARNAPRAYAG",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261401,
    "City": "NAIMISHARANYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261402,
    "City": "NAIMISHARANYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 241304,
    "City": "NAIMISHARANYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742169,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735230,
    "City": "JALPAIGURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723201,
    "City": "DUMURDIH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321025,
    "City": "Bharatpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688528,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688539,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 711305,
    "City": "FULESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711310,
    "City": "FULESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 583225,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583221,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583215,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583239,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583276,
    "City": "HOSPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695528,
    "City": "Nemom",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626149,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124129,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695508,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695506,
    "City": "PARASSALA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 345023,
    "City": "JAISALMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174310,
    "City": "NAINA DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227808,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144629,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144625,
    "City": "SULTANPUR LODHI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 560112,
    "City": "TAVAREKERE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635602,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635652,
    "City": "UTHANGARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517592,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 504308,
    "City": "ICHODA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 203002,
    "City": "BULANDSHAHR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396310,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414204,
    "City": "PATODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742189,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742133,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742163,
    "City": "BELDANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 174314,
    "City": "BARUHI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174316,
    "City": "BARUHI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177211,
    "City": "BARUHI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509217,
    "City": "SHABAD TG",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416409,
    "City": "MAHISHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416401,
    "City": "MAHISHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431514,
    "City": "GANGAKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624204,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624211,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184202,
    "City": "MANPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 184203,
    "City": "MANPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 184204,
    "City": "MANPUR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 184205,
    "City": "KATHUA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176029,
    "City": "RANITAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176054,
    "City": "RANITAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176038,
    "City": "RANITAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273155,
    "City": "MAHARAJGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273161,
    "City": "MAHARAJGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416513,
    "City": "SHIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416517,
    "City": "SHIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416518,
    "City": "SHIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734221,
    "City": "SUKIAPOKHRI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734216,
    "City": "SUKIAPOKHRI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734215,
    "City": "MIRIK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244303,
    "City": "Shambhal",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431735,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363312,
    "City": "DHARANGATHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363315,
    "City": "DHARANGATHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132112,
    "City": "GHARAUNDA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363424,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363426,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364292,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364293,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364296,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364297,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364298,
    "City": "MAHUVA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132036,
    "City": "NISSING",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143149,
    "City": "BEAS",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453555,
    "City": "SANWER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495554,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495556,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394218,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384312,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384270,
    "City": "Unjha",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362111,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362132,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362133,
    "City": "VISHAVADAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394652,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 395650,
    "City": "VYARA GUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412217,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412302,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 766036,
    "City": "SINDHEKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756167,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756139,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794105,
    "City": "AMPATI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793151,
    "City": "JOWAI",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712414,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721506,
    "City": "GOPIBALLAVPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721517,
    "City": "GOPIBALLAVPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721503,
    "City": "GOPIBALLAVPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721143,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388580,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388550,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388610,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388570,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443304,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416016,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416017,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466666,
    "City": "ASHTA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624215,
    "City": "DINDUGUL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493526,
    "City": "KASDOL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451550,
    "City": "BARWANI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431750,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415415,
    "City": "BATTISSHIRALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370141,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370146,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370147,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493195,
    "City": "BHATAPURA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413535,
    "City": "BHOOM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413536,
    "City": "BHOOM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413537,
    "City": "BHOOM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 471501,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176313,
    "City": "CHOWARI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431724,
    "City": "MAHAGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455002,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455112,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490010,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143538,
    "City": "Fatehgarh Churiyan",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581326,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143536,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143517,
    "City": "Gurdaspur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431706,
    "City": "Nanded",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246746,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246745,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244501,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 244224,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244227,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723143,
    "City": "BALARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723127,
    "City": "BALARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723152,
    "City": "BALARAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 737113,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 721513,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721507,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721514,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721504,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176095,
    "City": "JASINGPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176091,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176088,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176076,
    "City": "JASINGPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176089,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743356,
    "City": "GANGASAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743606,
    "City": "GANGASAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713386,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713364,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713335,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713357,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144222,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144223,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144224,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144225,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 582210,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581202,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 191131,
    "City": "LAR GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686507,
    "City": "EDAKUNNAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686518,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303002,
    "City": "ACHROL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415012,
    "City": "MEDHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415022,
    "City": "MEDHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326038,
    "City": "KHANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143108,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 522631,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303511,
    "City": "LALSOT",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144803,
    "City": "KAPURTHALA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 450006,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454334,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450017,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455339,
    "City": "KHATEGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413569,
    "City": "KINWAT",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414671,
    "City": "KOPARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413716,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431164,
    "City": "RAHURI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431155,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431538,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431531,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431744,
    "City": "MUKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 432119,
    "City": "LASUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492559,
    "City": "MUNGELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493222,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414509,
    "City": "KOPARGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176037,
    "City": "RANITAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176065,
    "City": "PAROR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454341,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481669,
    "City": "MANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483336,
    "City": "SIHORA MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 483333,
    "City": "PAN UMARIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384201,
    "City": "MAURANIPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384131,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384132,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384133,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457552,
    "City": "PIPLODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 451010,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451555,
    "City": "THIKRI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815315,
    "City": "JAMUA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757040,
    "City": "KAPTIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757079,
    "City": "KAPTIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756079,
    "City": "BALIAPAL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712418,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757017,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757029,
    "City": "BETNOTI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731304,
    "City": "NANOOR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 848505,
    "City": "KHALISPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844505,
    "City": "KHALISPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 242307,
    "City": "TILHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 242301,
    "City": "TILHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518594,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532558,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504214,
    "City": "CHENNUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533260,
    "City": "DRAKSHARAMAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534000,
    "City": "Eluru",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515456,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515843,
    "City": "GUNTAKAL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522024,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522036,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522035,
    "City": "Guntur",
    "State": "Andhra Pradesh",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522050,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522608,
    "City": "GUNTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533008,
    "City": "Kakinada",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523223,
    "City": "KANIGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523254,
    "City": "KANIGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522238,
    "City": "MANGALAGIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523205,
    "City": "MARKAPUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518112,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518513,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518523,
    "City": "Nandyal",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523280,
    "City": "Ongole",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522354,
    "City": "PONNUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516391,
    "City": "PULIVENDULA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533293,
    "City": "Rajahmundry",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515863,
    "City": "RAYADURGAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515414,
    "City": "TADIPATRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533220,
    "City": "YANAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518343,
    "City": "Yemmiganur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518349,
    "City": "KURNOOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495224,
    "City": "SARGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414601,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 414201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 534222,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534240,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534268,
    "City": "PALAKOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331027,
    "City": "BISSAU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388620,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388630,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388430,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388640,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364320,
    "City": "DHOLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364330,
    "City": "DHOLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 232103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 832301,
    "City": "DHALBHUMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 832101,
    "City": "DHALBHUMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620025,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 620026,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202280,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202281,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232108,
    "City": "CHANDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431737,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301032,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133021,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134008,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 134011,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125021,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 127311,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325206,
    "City": "MANGROL RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277209,
    "City": "BAIRIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 518125,
    "City": "BANAGANAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518126,
    "City": "BANAGANAPALLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193303,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275303,
    "City": "BARHALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414510,
    "City": "Beed",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 452019,
    "City": "INDORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321603,
    "City": "Bharatpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311012,
    "City": "BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 133003,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133007,
    "City": "AMBALA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209728,
    "City": "CHIBRAMAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431738,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 275305,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275301,
    "City": "GHOSI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515404,
    "City": "GOOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182320,
    "City": "JHAJJAR KOTLI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124101,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413614,
    "City": "JINTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 342016,
    "City": "Jodhpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451448,
    "City": "JULWANIA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451665,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 132026,
    "City": "Kaithal",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410303,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124109,
    "City": "KOSLI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 452056,
    "City": "Indore",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413563,
    "City": "LATUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160081,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160082,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160083,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160084,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160085,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160086,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160087,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160088,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160089,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160090,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431168,
    "City": "Parli Vaijnath",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415211,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415205,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431157,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518391,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518381,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431734,
    "City": "PURNA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457002,
    "City": "Ratlam",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413612,
    "City": "TULJAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523185,
    "City": "INKOLLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412120,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481338,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481340,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752120,
    "City": "KAKATPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711410,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431144,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431149,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413613,
    "City": "HADGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364140,
    "City": "TALAJA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364150,
    "City": "TALAJA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364135,
    "City": "TALAJA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413580,
    "City": "BEMBLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 811103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 742304,
    "City": "KALADANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742303,
    "City": "KALADANGA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193401,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 193411,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 533230,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767039,
    "City": "KANTABANJI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767060,
    "City": "KANTABANJI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 767051,
    "City": "KANTABANJI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224120,
    "City": "RUDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224116,
    "City": "RUDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224118,
    "City": "RUDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306001,
    "City": "MARWAR JUNCTION",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306023,
    "City": "MARWAR JUNCTION",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686121,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686581,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686582,
    "City": "ERATTUPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577528,
    "City": "JAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577553,
    "City": "JAGALUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443206,
    "City": "DEULGAON MAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 443308,
    "City": "DEULGAON MAHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274502,
    "City": "LAR TOWN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689641,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689654,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689549,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643233,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643253,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643241,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643239,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643220,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643205,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518535,
    "City": "Atmakur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518424,
    "City": "Atmakur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518425,
    "City": "Atmakur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508238,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 394660,
    "City": "SONGADH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382241,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382270,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382276,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382155,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 382160,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 382166,
    "City": "KADI GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382121,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382845,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382740,
    "City": "KALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384111,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384112,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384222,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384227,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384228,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384256,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384257,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384278,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384280,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384319,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384321,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384323,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384327,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384328,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384329,
    "City": "VADNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384350,
    "City": "SATLASANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416437,
    "City": "MIRAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416423,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416435,
    "City": "MIRAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413541,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413542,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413543,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413119,
    "City": "MALASHIRAS",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416019,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410005,
    "City": "ARJUNWAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 430507,
    "City": "DODAMARG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416146,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416515,
    "City": "PINGULI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 430004,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431161,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414674,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431176,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481339,
    "City": "WARASEONI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470000,
    "City": "Sagar",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160091,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160092,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 160093,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160094,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160095,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160096,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160097,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160098,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 160099,
    "City": "MOHALI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464110,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464116,
    "City": "VIDISHA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141307,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141308,
    "City": "PHILLAUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 143408,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 301417,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301418,
    "City": "BHIWADI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301036,
    "City": "TIJARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713365,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713369,
    "City": "RUPNARAYANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713419,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713429,
    "City": "MANTESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 722163,
    "City": "RATANPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713361,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741162,
    "City": "BETHUDAHARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742185,
    "City": "LALGOLA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712419,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712424,
    "City": "HARIPAL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712426,
    "City": "MOGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721438,
    "City": "EGRA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713383,
    "City": "ASANSOL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 731213,
    "City": "MOHAMMAD BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 690534,
    "City": "CHARUMOOD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680684,
    "City": "TRIPRAYAR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794106,
    "City": "MAHENDRAGANJ",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274803,
    "City": "KHADDA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450114,
    "City": "MUNDI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517390,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517391,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517371,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517351,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517372,
    "City": "MULAKALACHERUVU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414503,
    "City": "PAITHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431107,
    "City": "PAITHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431148,
    "City": "PAITHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323305,
    "City": "RAWATBHATA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752038,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734423,
    "City": "NAXALBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364540,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383002,
    "City": "HIMMAT NAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410520,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 414702,
    "City": "DAUND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414709,
    "City": "DAUND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455116,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455118,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144819,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144631,
    "City": "DHILWAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363334,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364560,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360150,
    "City": "BHANVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364465,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412413,
    "City": "JUNNAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577124,
    "City": "KALASA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577181,
    "City": "KALASA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396330,
    "City": "Silvassa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322245,
    "City": "KARAULI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391230,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325601,
    "City": "KHANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321006,
    "City": "KHERLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431110,
    "City": "GANGGAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 485661,
    "City": "MAIHAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144502,
    "City": "NAWANSAHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413117,
    "City": "NIMGAON KETKI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415109,
    "City": "KARAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534215,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 430005,
    "City": "PINGULI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416529,
    "City": "PINGULI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412042,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412111,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403405,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403409,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312618,
    "City": "PRATAPGARHRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152025,
    "City": "MUKTSAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410065,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413545,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413546,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413547,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413548,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413549,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413550,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185156,
    "City": "SUNDERBANI",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416306,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416307,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416322,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416540,
    "City": "VEETA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416145,
    "City": "NANDANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431138,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581348,
    "City": "MURDESHWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581340,
    "City": "SIRSI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 591247,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591287,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591226,
    "City": "CHIKODI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691319,
    "City": "PARAVUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574325,
    "City": "MANI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 783394,
    "City": "BENGTOL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783375,
    "City": "BENGTOL",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768234,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574228,
    "City": "BELTHANGADY",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480442,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413404,
    "City": "PANGRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413409,
    "City": "PANGRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711315,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711316,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784102,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 787033,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785679,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741163,
    "City": "BETAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741160,
    "City": "BETAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741165,
    "City": "BETAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741152,
    "City": "BETAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 327022,
    "City": "PARTAPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781307,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781302,
    "City": "BHELLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781305,
    "City": "BHELLA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825401,
    "City": "CHATRA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571425,
    "City": "KOPPAMANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571419,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 530053,
    "City": "LANKELAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638111,
    "City": "VELLAKOVIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638105,
    "City": "VELLAKOVIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415611,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415801,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415804,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 683545,
    "City": "KURUPPAMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 683548,
    "City": "KURUPPAMPADI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261135,
    "City": "SITAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261136,
    "City": "SITAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261207,
    "City": "SITAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605752,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605756,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605757,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605759,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605766,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577552,
    "City": "SANTHEBENNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577544,
    "City": "SANTHEBENNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442604,
    "City": "CHAMORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185155,
    "City": "NOWSHERA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 193404,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 241126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 241201,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 241202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 754153,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754224,
    "City": "MARSHAGHAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415216,
    "City": "BAMBAVADE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413707,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431172,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431169,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422205,
    "City": "DINDORI MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445201,
    "City": "DARWAH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628218,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628219,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628201,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581118,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581126,
    "City": "LAKSHMESHWARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591123,
    "City": "RAMDURG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515765,
    "City": "KAMBADUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515766,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 515621,
    "City": "KAMBADUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515762,
    "City": "KAMBADUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576120,
    "City": "KATAPADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673580,
    "City": "KODANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673586,
    "City": "KODANCHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623518,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623519,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623521,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623529,
    "City": "RAMESHWARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786692,
    "City": "Duliajan",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786191,
    "City": "Duliajan",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786678,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786684,
    "City": "NAMRUP",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782461,
    "City": "DIPHU",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 689546,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689551,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689531,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638302,
    "City": "LAKSHMINAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451331,
    "City": "BHIKANGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451332,
    "City": "BHIKANGAON",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431709,
    "City": "NAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431722,
    "City": "NAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413553,
    "City": "Akhadabalapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413554,
    "City": "Akhadabalapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413555,
    "City": "Akhadabalapur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535292,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518413,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518402,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518406,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518404,
    "City": "NANDIKOTKUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533577,
    "City": "MUMMIDIVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193402,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 141201,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 328035,
    "City": "SIRMATHURA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 241403,
    "City": "BILGRAM",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262801,
    "City": "GOLA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721505,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621801,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621802,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621804,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612904,
    "City": "JEYAMKONDAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515425,
    "City": "NARPALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515426,
    "City": "NARPALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515427,
    "City": "NARPALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515435,
    "City": "NARPALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784167,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784179,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784166,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 784184,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 756117,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756118,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756119,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756120,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756112,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301706,
    "City": "SHAHJHAPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224190,
    "City": "TANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224145,
    "City": "TANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306307,
    "City": "RAIPUR MARWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306304,
    "City": "RAIPUR MARWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224149,
    "City": "JALALPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224159,
    "City": "JALALPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224183,
    "City": "JALALPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518479,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605754,
    "City": "RISHIVANDHIYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605801,
    "City": "RISHIVANDHIYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605751,
    "City": "RISHIVANDHIYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723147,
    "City": "DUMURDIH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723149,
    "City": "DUMURDIH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723154,
    "City": "DUMURDIH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231304,
    "City": "CHUNAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193221,
    "City": "HANDWARA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 131102,
    "City": "GANNAUR",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695563,
    "City": "TRIVANDRUM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784180,
    "City": "JAMUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784182,
    "City": "JAMUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784189,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784181,
    "City": "JAMUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784183,
    "City": "TEZPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784185,
    "City": "JAMUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192101,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 192125,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312027,
    "City": "MANDPHIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638181,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638182,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637204,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 637207,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637208,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637213,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581349,
    "City": "MUNDGOD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581411,
    "City": "MUNDGOD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713403,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713169,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384210,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384212,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384240,
    "City": "HARIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 384241,
    "City": "HARIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384230,
    "City": "HARIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 737126,
    "City": "NAMCHI",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385340,
    "City": "RADHANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757016,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757018,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757020,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757055,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757073,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 761104,
    "City": "PURSHOTAMPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 531127,
    "City": "KOTANANDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533410,
    "City": "KOTANANDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533407,
    "City": "KOTANANDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 233227,
    "City": "MOHAMMADABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224238,
    "City": "TANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224227,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202125,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 403010,
    "City": "BANDA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516365,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414703,
    "City": "DAUND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431747,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389002,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212401,
    "City": "HANDIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229801,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227811,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229309,
    "City": "AMETHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431173,
    "City": "KADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388555,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388556,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388585,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388600,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496665,
    "City": "KARSHIA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431177,
    "City": "LASUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413270,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413271,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413272,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413273,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413274,
    "City": "VELAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403519,
    "City": "MAPUSA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383350,
    "City": "MODASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383345,
    "City": "MODASA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383310,
    "City": "BAYAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387100,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387420,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387435,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403003,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403112,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403531,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384261,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384271,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384274,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384276,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384277,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384291,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384292,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411406,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411501,
    "City": "CHAKAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515862,
    "City": "RAYADURGAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431729,
    "City": "PURNA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431751,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495551,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431171,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414608,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431165,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786173,
    "City": "DIGBOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782106,
    "City": "MORIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754105,
    "City": "ADASPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 768219,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680642,
    "City": "EDAMUTTAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624624,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576121,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574113,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574112,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177023,
    "City": "HAMIRPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517298,
    "City": "KALAKADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517238,
    "City": "KALAKADA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711306,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711307,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711308,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711317,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752015,
    "City": "DELANG",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752016,
    "City": "DELANG",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516271,
    "City": "GALIVEEDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516273,
    "City": "GALIVEEDU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811308,
    "City": "JHAJHA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803302,
    "City": "MOKAMA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803303,
    "City": "MOKAMA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803301,
    "City": "MOKAMA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 233230,
    "City": "KASIMABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 275201,
    "City": "KASIMABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 233222,
    "City": "KASIMABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274306,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333053,
    "City": "GUDHA GORJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333012,
    "City": "GUDHA GORJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333305,
    "City": "GUDHA GORJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141309,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140120,
    "City": "Anandpur Sahib",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176039,
    "City": "SHAH TALAI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175023,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622101,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622102,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622504,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622502,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622515,
    "City": "KEERANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689547,
    "City": "TIRUVELLA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804427,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804418,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786611,
    "City": "TINGKHONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 786612,
    "City": "TINGKHONG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 143306,
    "City": "PATTI",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845418,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571212,
    "City": "MURNAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333502,
    "City": "BUHANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313027,
    "City": "SALUMBER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 344021,
    "City": "SAMDARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754130,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754131,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754134,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 323307,
    "City": "RAWATBHATA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146519,
    "City": "MAHALPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571217,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670582,
    "City": "NADUVIL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670571,
    "City": "NADUVIL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670631,
    "City": "NADUVIL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743426,
    "City": "HASNABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743435,
    "City": "HASNABAD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743503,
    "City": "AMTALA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743377,
    "City": "AMTALA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743398,
    "City": "AMTALA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532244,
    "City": "MANDASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532263,
    "City": "MANDASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203393,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203392,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203396,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203389,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 233305,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 509154,
    "City": "ALAMPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431526,
    "City": "AMBEJOGAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422603,
    "City": "AKOLE MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411220,
    "City": "RANJANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416420,
    "City": "SANGALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416311,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416314,
    "City": "TASGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415119,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415103,
    "City": "Karad",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141103,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603004,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 608201,
    "City": "CHIDAMBARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607112,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 600131,
    "City": "CHENNAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606710,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632516,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632204,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632115,
    "City": "VELLORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603318,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533288,
    "City": "RAMPACHODAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533622,
    "City": "RAMPACHODAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533644,
    "City": "RAMPACHODAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 798613,
    "City": "CHANGTONGYA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431710,
    "City": "BHILOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431711,
    "City": "BHILOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413559,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413560,
    "City": "BHILOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325220,
    "City": "CHABRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325218,
    "City": "ATRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325219,
    "City": "CHABRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325204,
    "City": "SULTANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325201,
    "City": "SULTANPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 326033,
    "City": "AKLERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518395,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518398,
    "City": "ASPARI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331802,
    "City": "RAJALDESAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 471515,
    "City": "LAVKUSHNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471516,
    "City": "LAVKUSHNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471510,
    "City": "LAVKUSHNAGAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574119,
    "City": "PADUBIDRI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788706,
    "City": "BADARPUR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784174,
    "City": "BISWANATH CHARIALI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516223,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516355,
    "City": "CHAPADU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425108,
    "City": "CHOPDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173201,
    "City": "DHARAMPUR SOLAN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364230,
    "City": "DHOLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360405,
    "City": "DHORAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362011,
    "City": "DHORAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781068,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629194,
    "City": "KALIAKKAVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450116,
    "City": "KHIRKIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629101,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 629102,
    "City": "KULASEKARAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206123,
    "City": "KUSMARA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413223,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413582,
    "City": "OSMANABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416137,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413281,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413282,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413617,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413618,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413619,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413620,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402305,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784147,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370511,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370660,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193501,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385360,
    "City": "RADHANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 516155,
    "City": "Rajampeta",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516132,
    "City": "Rajampeta",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516134,
    "City": "Rajampeta",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 829105,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829124,
    "City": "RAMGARH",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 829120,
    "City": "PATRATU",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533235,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533236,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533228,
    "City": "RAVULAPALEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444711,
    "City": "MANGRULDASTGIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 835219,
    "City": "RANCHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 629187,
    "City": "KARUNAGAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415610,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415608,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415609,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451664,
    "City": "SENDHWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788099,
    "City": "SONAIMUKH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144241,
    "City": "TALWARA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182124,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 182126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 388590,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388530,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412231,
    "City": "Khandala",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412232,
    "City": "Khandala",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412233,
    "City": "BHOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412234,
    "City": "BHOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412235,
    "City": "BHOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635854,
    "City": "NATRAMPALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635852,
    "City": "NATRAMPALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635815,
    "City": "NATRAMPALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383205,
    "City": "PRANTIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614203,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614204,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614205,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614210,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632122,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423207,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423210,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 423211,
    "City": "DABHADI MALEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325215,
    "City": "MANGROL RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273001,
    "City": "Gorakhpur",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412212,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412213,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 412236,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412238,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412239,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412241,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412240,
    "City": "NASRAPUR MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493335,
    "City": "KASDOL",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 272155,
    "City": "HARRAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 272127,
    "City": "HARRAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212502,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803213,
    "City": "BARH BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803221,
    "City": "BARH BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803212,
    "City": "KHUSRUPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229413,
    "City": "LALGOPALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 229411,
    "City": "LALGOPALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571434,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 571435,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 625705,
    "City": "ATHIPATI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625703,
    "City": "ATHIPATI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625702,
    "City": "ATHIPATI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625535,
    "City": "ATHIPATI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625091,
    "City": "ATHIPATI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413556,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413557,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413558,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413609,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413610,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413611,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761106,
    "City": "SHERAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761107,
    "City": "SURADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 796070,
    "City": "KAWNPUI",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 783338,
    "City": "AGOMONI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785648,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785649,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785643,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785644,
    "City": "SIMALUGURI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462018,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462025,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462015,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462006,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462014,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462029,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462019,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462005,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462017,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462028,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462100,
    "City": "Bhopal",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457774,
    "City": "PETLAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370675,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370627,
    "City": "DAYAPAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392115,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392105,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388308,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364565,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534129,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534372,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534127,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534125,
    "City": "ACHANTA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518318,
    "City": "ADONI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518317,
    "City": "ADONI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 380210,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382404,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393021,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431759,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370170,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 762026,
    "City": "BOUDH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752027,
    "City": "CHANDPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691321,
    "City": "CHERUVAKKAL",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425407,
    "City": "DONDAICHA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388715,
    "City": "GODHRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360034,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389322,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363039,
    "City": "HALVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176111,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391245,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391241,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848504,
    "City": "KHALISPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 680667,
    "City": "KODUNGALLUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 796075,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 363007,
    "City": "LIMDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670721,
    "City": "MANANTHAVADY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609401,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609402,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609808,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609203,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609003,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388131,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388201,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144032,
    "City": "NOORMAHAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625104,
    "City": "OTTHAKADAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625023,
    "City": "OTTHAKADAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385526,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385007,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754231,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754239,
    "City": "PATTAMUNDAI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360585,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306105,
    "City": "RAIPUR MARWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757014,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757084,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757022,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 205135,
    "City": "SHIKOHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431535,
    "City": "SONPETH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635202,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605758,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605803,
    "City": "THIRUKOVILUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361280,
    "City": "MOTIKHAVDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361010,
    "City": "MOTIKHAVDI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638151,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638109,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581341,
    "City": "KAVALAKKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581342,
    "City": "KAVALAKKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581361,
    "City": "HONNAVARA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 581335,
    "City": "KAVALAKKI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732208,
    "City": "AMRITY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732211,
    "City": "AMRITY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228151,
    "City": "KUREBHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 227806,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 573225,
    "City": "CHANNARAYAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562117,
    "City": "KANAKAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 562126,
    "City": "KANAKAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 562119,
    "City": "KANAKAPURA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531168,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531165,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 531172,
    "City": "BHEEMUNIPATNAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515159,
    "City": "MUDDIGUBA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515154,
    "City": "MUDDIGUBA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 641685,
    "City": "RAYARKOIL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 844506,
    "City": "MAHNAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626106,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625701,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626119,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626104,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626109,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625707,
    "City": "MUTHURAMANAPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673313,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673310,
    "City": "MAHE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673311,
    "City": "MAHE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 642612,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642616,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642619,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431748,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431749,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606104,
    "City": "ULUNDURPETTAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723155,
    "City": "PARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723121,
    "City": "RAGHUNATHPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723146,
    "City": "PARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723145,
    "City": "PARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723133,
    "City": "RAGHUNATHPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 410402,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410406,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 431816,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431818,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431819,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431820,
    "City": "MUDKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465444,
    "City": "AGARMALWA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781378,
    "City": "BARAMA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384231,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491338,
    "City": "BEMETARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691324,
    "City": "CHITHARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691519,
    "City": "CHITHARA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 445110,
    "City": "DARWAH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146416,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141117,
    "City": "DEHLON",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431536,
    "City": "GANGAKHED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781002,
    "City": "GUWAHATI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460445,
    "City": "GHORADONGARI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581356,
    "City": "HALIYAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581103,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 442106,
    "City": "KARANJAGHADGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533251,
    "City": "LAKKAVARM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532264,
    "City": "MANDASA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689541,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689582,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689583,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689543,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125041,
    "City": "MEHAM",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 731245,
    "City": "MOHAMMAD BAZAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798617,
    "City": "MOKOKCHUNG",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781336,
    "City": "NALBARI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305401,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 680631,
    "City": "KOZHIKODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691326,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691315,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441226,
    "City": "SAOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 404222,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404412,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 785660,
    "City": "SIVSAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494111,
    "City": "SUKMA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495113,
    "City": "RATANPURPALI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396120,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396130,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396135,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402601,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 404101,
    "City": "Waluj",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628151,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628229,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416071,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416072,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416070,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416074,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416065,
    "City": "HUPARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413323,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413325,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413326,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413327,
    "City": "SANGOLA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431754,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431755,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431752,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431753,
    "City": "HINGOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758083,
    "City": "HATADIHI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758023,
    "City": "HATADIHI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758022,
    "City": "HATADIHI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 402308,
    "City": "MANGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465113,
    "City": "POLAY KALAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465118,
    "City": "POLAY KALAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422613,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422623,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422624,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422625,
    "City": "TALEGAON DHIGE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721502,
    "City": "BINPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721515,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303005,
    "City": "PHAGI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303338,
    "City": "PHULERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303604,
    "City": "PHULERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277121,
    "City": "GARWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 277123,
    "City": "GARWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306115,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 192102,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175026,
    "City": "AWAH DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625203,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444405,
    "City": "BARSHITAKLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781130,
    "City": "BIJAYNAGAR",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535568,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364315,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481266,
    "City": "BUDBUDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431132,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495686,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444814,
    "City": "DARYAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202393,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 202392,
    "City": "DEBAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431723,
    "City": "DEGLOOR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491331,
    "City": "DHAMDHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491332,
    "City": "DHAMDHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491993,
    "City": "DHODHA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364470,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 524131,
    "City": "GUDUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515808,
    "City": "GUNTAKAL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803202,
    "City": "KHUSRUPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 175034,
    "City": "GHUMARWIN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364485,
    "City": "JETPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364490,
    "City": "RAJKOT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 244504,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246727,
    "City": "KANTH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713518,
    "City": "KATWA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 532313,
    "City": "KAVITI AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532314,
    "City": "KAVITI AP",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 523358,
    "City": "KOMAROLU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497559,
    "City": "CHIRIMIRI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444826,
    "City": "MORSHI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444807,
    "City": "PARATWADDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305814,
    "City": "PARBATSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 305819,
    "City": "PARBATSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626601,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 712427,
    "City": "PURSURAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 326501,
    "City": "RAMGANJ MANDI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441406,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466002,
    "City": "SEHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761103,
    "City": "ASKA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224230,
    "City": "TANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224210,
    "City": "TANDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301018,
    "City": "TAPUKARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 441920,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396194,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396201,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396211,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396217,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396192,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396238,
    "City": "UMBERGAON",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444721,
    "City": "WALGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311317,
    "City": "HOWRAH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621014,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 125043,
    "City": "BHUNA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246171,
    "City": "RUDRAPRAYAG",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246424,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174002,
    "City": "NAINA DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174015,
    "City": "NAINA DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174201,
    "City": "NAINA DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174011,
    "City": "NAINA DEVI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124201,
    "City": "BERI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124107,
    "City": "BERI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177220,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177209,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174317,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277211,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277401,
    "City": "SAHTAWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277402,
    "City": "SAHTAWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412112,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412116,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412117,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414118,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412119,
    "City": "Pirangut",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321404,
    "City": "RUPBAS",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321402,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721651,
    "City": "SHRIRAMPUR WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721647,
    "City": "NIMTOURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721629,
    "City": "SHRIRAMPUR WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 325217,
    "City": "SAMRANIA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 307510,
    "City": "MOUNT ABU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843329,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845403,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843334,
    "City": "SHEOHAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845422,
    "City": "ARERAJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845419,
    "City": "ARERAJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845411,
    "City": "ARERAJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713322,
    "City": "HARIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713384,
    "City": "HARIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713342,
    "City": "HARIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712247,
    "City": "DANKUNI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712221,
    "City": "BHADRESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712125,
    "City": "BHADRESWAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341023,
    "City": "JAYAL",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176030,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614804,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614803,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614628,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614802,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614624,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 534431,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534430,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534429,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534428,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534402,
    "City": "BHIMADOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741508,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741507,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741506,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741509,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743424,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743423,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743445,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 847106,
    "City": "SIMRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847104,
    "City": "SIMRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847123,
    "City": "SIMRI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221601,
    "City": "MADHUBAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221603,
    "City": "MADHUBAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276306,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577139,
    "City": "SRINGERI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782127,
    "City": "MORIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144301,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144302,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144303,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177043,
    "City": "JAWALA JI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415302,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415303,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415313,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415314,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415317,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415316,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416419,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416411,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416433,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416432,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416434,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416438,
    "City": "KAVATHEMAHANKAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416424,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416302,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415411,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415413,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416431,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416430,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415629,
    "City": "SHIRGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415615,
    "City": "SHIRGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415617,
    "City": "SHIRGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415614,
    "City": "SHIRGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413590,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413589,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413588,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413596,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413595,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413591,
    "City": "PARANDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 721450,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721442,
    "City": "CONTAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 735231,
    "City": "GORUBATHAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 306703,
    "City": "DESURI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 342018,
    "City": "MANDOR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271607,
    "City": "UTRAULA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416117,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416107,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416143,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416144,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509155,
    "City": "ALAMPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509151,
    "City": "ALAMPUR",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481101,
    "City": "BUDBUDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673596,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384211,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384235,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504305,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504330,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504327,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504325,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504324,
    "City": "BOATH",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480102,
    "City": "Chindwara",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231306,
    "City": "CHUNAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221305,
    "City": "CHUNAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486338,
    "City": "DEOTALAB",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455004,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756137,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 463662,
    "City": "DHAMTARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 490013,
    "City": "DURG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364741,
    "City": "GATHDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441609,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473002,
    "City": "GUNA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177007,
    "City": "Hamirpur",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494004,
    "City": "Jagdalpur",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457340,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457333,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124108,
    "City": "LUHARI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493738,
    "City": "SHIVARINARAYAN",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 450003,
    "City": "Khandwa",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518140,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518139,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518138,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518137,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518136,
    "City": "KOILAKUNTLA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522328,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522327,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522326,
    "City": "KOLLURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364640,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364632,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365422,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364641,
    "City": "LATHI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493441,
    "City": "MAHASAMUND",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456313,
    "City": "NAGDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457119,
    "City": "MAHIDPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 474042,
    "City": "MALANPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609806,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609314,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370515,
    "City": "NALIYA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364220,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364225,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364276,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364311,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364335,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364312,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364410,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364215,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364506,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364508,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364507,
    "City": "PALITANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312403,
    "City": "BADI SADRI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495691,
    "City": "SAKTI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441403,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441504,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441503,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496450,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496551,
    "City": "SARANGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473554,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495112,
    "City": "RATANPURPALI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522208,
    "City": "Tenali",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627659,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627653,
    "City": "THISAYANVILAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606305,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626113,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626118,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626105,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414610,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414611,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414612,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414613,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414615,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414614,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413562,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413561,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413587,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413586,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413585,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413584,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413583,
    "City": "TERKHEDA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413598,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413597,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413599,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413622,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413621,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413625,
    "City": "AUNDHA NAGNATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415535,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415553,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415552,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415551,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415534,
    "City": "LONAND",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847225,
    "City": "BASOPATTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847240,
    "City": "BASOPATTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604408,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603403,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495688,
    "City": "DABHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495692,
    "City": "DABHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574253,
    "City": "MANI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628402,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628304,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628722,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431218,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431222,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431223,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431224,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431226,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431225,
    "City": "Jalna",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415554,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415555,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415556,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415557,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415558,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415569,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415568,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415567,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415565,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415560,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335702,
    "City": "JAITSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313605,
    "City": "DARIYAWAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 304505,
    "City": "TODARAISINGH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262905,
    "City": "SINGAHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 262906,
    "City": "SINGAHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413131,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413136,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413135,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413134,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522632,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522629,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522628,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522627,
    "City": "MARTUR",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 533290,
    "City": "KATERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768102,
    "City": "GOSHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768026,
    "City": "GOSHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768025,
    "City": "GOSHALA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146006,
    "City": "ADAMPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382426,
    "City": "AHMEDABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392161,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393005,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393004,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503228,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503227,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503226,
    "City": "Armoor",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202282,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 202131,
    "City": "ATRAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274604,
    "City": "BARHAJ UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495663,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396301,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 455005,
    "City": "DEWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503222,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503221,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503220,
    "City": "DOODGAON",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515525,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515524,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515523,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515522,
    "City": "KADIRI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441611,
    "City": "GONDIA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227816,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 145105,
    "City": "JUGIYAL",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 492885,
    "City": "KHAIRAGARH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471105,
    "City": "KHAJURAO MP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388641,
    "City": "KHAMBAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451440,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 454448,
    "City": "KUKSHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458113,
    "City": "MANASA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534346,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534347,
    "City": "NIDADAVOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465687,
    "City": "PACHORE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457770,
    "City": "PETLAWAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484002,
    "City": "SHAHDOL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416313,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416801,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416810,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416098,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416095,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416092,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416091,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387620,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389260,
    "City": "SANTRAMPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723142,
    "City": "RAGHUNATHPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273309,
    "City": "KOLHUI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273162,
    "City": "KOLHUI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 273169,
    "City": "KOLHUI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333023,
    "City": "BAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 276142,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 223223,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 761108,
    "City": "SURADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761109,
    "City": "SURADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761126,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761110,
    "City": "ASKA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761111,
    "City": "ASKA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761114,
    "City": "ASKA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517350,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517330,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 173215,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173234,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173219,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431184,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797002,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797005,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797006,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797120,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797021,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797121,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797011,
    "City": "KOHIMA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388255,
    "City": "BALASINOR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388235,
    "City": "BALASINOR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431260,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431261,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431262,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431264,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431263,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431265,
    "City": "GEVRAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389230,
    "City": "LUNAVADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221715,
    "City": "BELTHARA ROAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 221602,
    "City": "BELTHARA ROAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581324,
    "City": "KARWAR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460552,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 460555,
    "City": "AMLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638152,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303122,
    "City": "BAGRU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491222,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385571,
    "City": "Bhabhar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441917,
    "City": "BHANDARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424119,
    "City": "CHALISGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495690,
    "City": "DABHARA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385326,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385554,
    "City": "Deesa",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493776,
    "City": "DHAMTARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328024,
    "City": "MANIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 583235,
    "City": "GANGAVATHI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464229,
    "City": "GANJ BASODA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493662,
    "City": "MAHASAMUND",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441919,
    "City": "TIRODA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441605,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441603,
    "City": "Gondia",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416130,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416025,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416027,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416026,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416028,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422219,
    "City": "KALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171219,
    "City": "KUNDAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491336,
    "City": "KAVARDA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416608,
    "City": "MALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416626,
    "City": "MALVAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689550,
    "City": "MARAMON",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689643,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 443209,
    "City": "MEHKAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491559,
    "City": "MUNGELI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425439,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425441,
    "City": "NANDURBAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487110,
    "City": "NARSINGPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504118,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504116,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504115,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504113,
    "City": "NIRMAL",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 385215,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625562,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625605,
    "City": "PERIYAKULAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383215,
    "City": "PRANTIJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441409,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441117,
    "City": "SAONER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422110,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 423305,
    "City": "SATANA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174030,
    "City": "SHAH TALAI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425448,
    "City": "SHAHADA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495661,
    "City": "CHAMPA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422109,
    "City": "SINNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422607,
    "City": "SINNER",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 464225,
    "City": "SIRONJ",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422404,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422405,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441918,
    "City": "Tumsar",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441114,
    "City": "NAGPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 486884,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 444812,
    "City": "WARUD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534314,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534319,
    "City": "DONDAPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534345,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534344,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534343,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534354,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534353,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534352,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534340,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534351,
    "City": "DOMMERU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624711,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 285205,
    "City": "KONCH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246762,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 246724,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 262902,
    "City": "PALIA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209881,
    "City": "MOHAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209841,
    "City": "MOHAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695303,
    "City": "CHIRAYINKEEZHU",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 821307,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821312,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821301,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821306,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 821308,
    "City": "ROHTAS",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670661,
    "City": "PINARAYI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 670663,
    "City": "PINARAYI",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673316,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670109,
    "City": "KANNUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571433,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571429,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571476,
    "City": "MADDUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 635528,
    "City": "BODINAYAKANUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621218,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621703,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621706,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621712,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621702,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 631505,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621006,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621009,
    "City": "MANACHANALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621213,
    "City": "MANACHANALLUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431121,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431205,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431247,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431249,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431248,
    "City": "PACHOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209111,
    "City": "PUKHRAYAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 574244,
    "City": "BAILOOR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574102,
    "City": "BAILOOR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591127,
    "City": "RAMDURG",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756043,
    "City": "BAHANAGA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756042,
    "City": "BAHANAGA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756044,
    "City": "BAHANAGA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756041,
    "City": "SAJANAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761119,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761131,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761124,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761120,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609101,
    "City": "SIRKAZHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 732209,
    "City": "AMRITY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442205,
    "City": "ARVI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624701,
    "City": "AYYAMPALYAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532562,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532560,
    "City": "BOBBILLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754227,
    "City": "CHANDBALI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415706,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415720,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756122,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 382455,
    "City": "DHANDUKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328025,
    "City": "MANIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 383220,
    "City": "HIMMAT NAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577179,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 518474,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518473,
    "City": "KODUMURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521160,
    "City": "KUCHIPUDI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 444408,
    "City": "MANORA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480991,
    "City": "NAINPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752082,
    "City": "NAYAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521159,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332029,
    "City": "SALASAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332318,
    "City": "SALASAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624714,
    "City": "SRI RAMPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 442915,
    "City": "WANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 232332,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 759021,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175138,
    "City": "PATLIKUHAL",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 133101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 133102,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 325216,
    "City": "KISHANGANJ RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473116,
    "City": "BADRAWAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470027,
    "City": "DEORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471028,
    "City": "DEORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176066,
    "City": "JAWALA JI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 458333,
    "City": "JIRAN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470025,
    "City": "KESLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212503,
    "City": "LALGOPALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451771,
    "City": "PANSEMAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451773,
    "City": "PANSEMAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517594,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517593,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517609,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517606,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517605,
    "City": "Puttur",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471003,
    "City": "REHLI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532207,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532206,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532205,
    "City": "TEKKALI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 470882,
    "City": "TENDUKHEDA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415414,
    "City": "WALWA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606101,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606105,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 606106,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606109,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606111,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614013,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612803,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612802,
    "City": "TIRUVARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574101,
    "City": "KARKALA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627152,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627515,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627502,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627355,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 788804,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788814,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788812,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 788808,
    "City": "KATIGORAH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142055,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 142049,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 230403,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 230127,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144025,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303313,
    "City": "BANDIKUI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224164,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224153,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224229,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332041,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332002,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 413277,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413278,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413296,
    "City": "KEM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571214,
    "City": "MURNAD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614703,
    "City": "PERAVURANI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685552,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685515,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685511,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 685508,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415634,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415703,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415724,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 385410,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431793,
    "City": "NAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431792,
    "City": "NAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431791,
    "City": "NAIGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415111,
    "City": "GHOGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413630,
    "City": "OMERGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413633,
    "City": "OMERGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413632,
    "City": "OMERGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413631,
    "City": "OMERGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431174,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431170,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431167,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431166,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431175,
    "City": "MANJARSUMBA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431409,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431408,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431411,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431410,
    "City": "MANWATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370030,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370427,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370105,
    "City": "GANDHIDHAM",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370430,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370007,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431560,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431554,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431562,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431561,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431563,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480880,
    "City": "KURAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480881,
    "City": "KURAI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754200,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754221,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754204,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754205,
    "City": "KAMPAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754206,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 462007,
    "City": "BHOPAL",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784114,
    "City": "ORANG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784507,
    "City": "ORANG",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332042,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606103,
    "City": "VIRUDHACHALAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636103,
    "City": "AYOTHIYAPATINAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621105,
    "City": "LALGUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614207,
    "City": "PAPANASAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625107,
    "City": "OTTHAKADAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 586212,
    "City": "MUDDEBIHAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802202,
    "City": "PIRO",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636504,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424302,
    "City": "DHULE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473559,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383325,
    "City": "BAYAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413169,
    "City": "JAWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413168,
    "City": "JAWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413167,
    "City": "JAWALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246722,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 413626,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413629,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413628,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413627,
    "City": "MURUM",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638119,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638121,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572134,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 132122,
    "City": "SAMALKHA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332317,
    "City": "Laxmangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325004,
    "City": "MANGROL RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323023,
    "City": "Bundi",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793102,
    "City": "NONGPOH",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394120,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 394315,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396580,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274807,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274806,
    "City": "DEORIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 792056,
    "City": "BORDUMSA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802223,
    "City": "PIRO",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742401,
    "City": "SALAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742402,
    "City": "SALAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713123,
    "City": "SALAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742404,
    "City": "SALAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643201,
    "City": "KOTIGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 643217,
    "City": "KOTIGIRI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370601,
    "City": "DAYAPAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370602,
    "City": "DAYAPAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453115,
    "City": "DEPALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453111,
    "City": "DEPALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 453220,
    "City": "DEPALPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 421602,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 745428,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845428,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 487225,
    "City": "KARELI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487330,
    "City": "KARELI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845458,
    "City": "ARERAJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690548,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 690556,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 678600,
    "City": "PALAKKAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781308,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758079,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758017,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758082,
    "City": "ANANDAPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756040,
    "City": "BAHANAGA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 493892,
    "City": "DEVBHOG",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306601,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 301403,
    "City": "AJARKA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757037,
    "City": "KARANJIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757035,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 757036,
    "City": "KARANJIA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761010,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761037,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761004,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761011,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722153,
    "City": "BANKURA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431542,
    "City": "SENGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 228125,
    "City": "KUREBHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606303,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621716,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621730,
    "City": "CUDDALORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606304,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764078,
    "City": "NABARANGPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152122,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152128,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 152132,
    "City": "ABOHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 148100,
    "City": "Barnala",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 151209,
    "City": "Faridkot",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491666,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 412306,
    "City": "BARAMATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422606,
    "City": "SINNAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700075,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 509132,
    "City": "IEEJA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534238,
    "City": "NAVUDURU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505405,
    "City": "Sircilla",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 590019,
    "City": "BELGAUM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224146,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224151,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224235,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224168,
    "City": "AKBARPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614801,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614805,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225002,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225203,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225123,
    "City": "Barabanki",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768226,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 770075,
    "City": "BELPAHAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741155,
    "City": "BETAI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360515,
    "City": "BHANVAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415305,
    "City": "BHAVANINAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 146115,
    "City": "BHOGPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721157,
    "City": "CHANDRAKONA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480553,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144212,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 453112,
    "City": "SANWER",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713344,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713330,
    "City": "DOMAHANI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 679333,
    "City": "NILAMBUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212645,
    "City": "FATEHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 758087,
    "City": "GHATAGAON",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 365001,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360320,
    "City": "GONDAL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222003,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222105,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222138,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410613,
    "City": "LONAVALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690550,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 261202,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 361101,
    "City": "KHAMBHALIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 285206,
    "City": "KONCH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415010,
    "City": "KOREGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 497117,
    "City": "LAKHANPUR CH",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229201,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229203,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229208,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229211,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229205,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844504,
    "City": "MAHNAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 844509,
    "City": "MAHNAR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370450,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370445,
    "City": "MANDVI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382710,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382732,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382730,
    "City": "MEHSANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384421,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 229881,
    "City": "MOHAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229841,
    "City": "MOHAN UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363631,
    "City": "MORBI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413530,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626112,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626134,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303119,
    "City": "PAOTA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 480557,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534329,
    "City": "PERAVALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 808022,
    "City": "PIRO",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209621,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754201,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754290,
    "City": "SALEPUR OR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465689,
    "City": "ZIRAPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 535594,
    "City": "KURUPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415641,
    "City": "CHIPLUN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845427,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845457,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 845303,
    "City": "MUZAFFARPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805121,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843108,
    "City": "KANTI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 803201,
    "City": "KHUSRUPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 523331,
    "City": "ONGOLE",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 274708,
    "City": "SALEMPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387630,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754292,
    "City": "KAMPAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755003,
    "City": "KAMPAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754001,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754003,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 756058,
    "City": "SAJANAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 841243,
    "City": "NAUTAN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752013,
    "City": "BRAHMAGIRI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752011,
    "City": "BRAHMAGIRI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735122,
    "City": "HALDIBARI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 737121,
    "City": "JORETHANG",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 737128,
    "City": "JORETHANG",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 182148,
    "City": "RAMBAN",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182143,
    "City": "RAMBAN",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 261302,
    "City": "SIDHAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673576,
    "City": "KALPETTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626115,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628618,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628623,
    "City": "NAZARETH",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 691332,
    "City": "PUNALUR",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628212,
    "City": "THIRUCHENDUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 495553,
    "City": "BILASPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 495660,
    "City": "CHAMPA",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 491668,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 576114,
    "City": "Manipal",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761115,
    "City": "ASKA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768104,
    "City": "ATTABIRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521122,
    "City": "AVANIGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574141,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574267,
    "City": "BAJPE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803214,
    "City": "BARH BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 803215,
    "City": "BARH BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383330,
    "City": "BAYAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383260,
    "City": "BAYAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743293,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743443,
    "City": "BERACHAMPA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 127027,
    "City": "Bhiwani",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754082,
    "City": "CHANDIKHOLE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 363031,
    "City": "Chotila",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752089,
    "City": "DASPALLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 144305,
    "City": "MUKERIAN",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362014,
    "City": "DHORAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 723215,
    "City": "DUMURDIH",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 425112,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 425113,
    "City": "ERANDOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735229,
    "City": "GORUBATHAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415701,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415726,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415719,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 383440,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 142030,
    "City": "Jagraon",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360011,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 735226,
    "City": "KALCHINI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 387365,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387650,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362221,
    "City": "KESHOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 494229,
    "City": "KONDAGAON",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 424303,
    "City": "SAKRI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389235,
    "City": "LUNAVADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389220,
    "City": "LUNAVADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 620022,
    "City": "MAATHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370006,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 370204,
    "City": "BHUJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382650,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382835,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362215,
    "City": "MENDRADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638153,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 638154,
    "City": "KODUMUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 370610,
    "City": "NAKHTRANA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754159,
    "City": "Nanpur",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 754210,
    "City": "Nischintakoili",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643240,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 384260,
    "City": "Patan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360545,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389240,
    "City": "SANTRAMPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571477,
    "City": "SRIRANGAPATNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767042,
    "City": "TITLAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 576122,
    "City": "KATAPADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136043,
    "City": "KAITHAL",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416551,
    "City": "GADHINGALAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416108,
    "City": "JAWAHARNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 465339,
    "City": "KURAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416231,
    "City": "MUDHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416521,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416628,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414504,
    "City": "PAITHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416526,
    "City": "UTTUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415517,
    "City": "WAI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 785619,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 785611,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 793103,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793105,
    "City": "SHILLONG",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811202,
    "City": "MUNGER",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577120,
    "City": "Thirthahalli",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577411,
    "City": "Thirthahalli",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577424,
    "City": "Thirthahalli",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327024,
    "City": "PARTAPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389151,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496225,
    "City": "KUNKARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 496227,
    "City": "KUNKARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 496334,
    "City": "KUNKARI",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413410,
    "City": "MOHOL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391135,
    "City": "BODELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391165,
    "City": "CHHOTAUDEPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 393145,
    "City": "RAJPIPLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414301,
    "City": "AHMEDNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 414302,
    "City": "AHMEDNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389130,
    "City": "PIPLOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 389380,
    "City": "PIPLOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301023,
    "City": "Alwar",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392003,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392004,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171103,
    "City": "DARLAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416230,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328022,
    "City": "BARI RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756165,
    "City": "BASUDEVPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321410,
    "City": "BAYANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321021,
    "City": "Bharatpur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 364110,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759014,
    "City": "BARADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 759016,
    "City": "BARADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331030,
    "City": "BISSAU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246401,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 246449,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246472,
    "City": "CHAMOLI",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756138,
    "City": "DHAMNAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798614,
    "City": "CHANGTONGYA",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391347,
    "City": "HALOL",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388105,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388132,
    "City": "JAMBUSAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361110,
    "City": "Jamnagar",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360012,
    "City": "JASDAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222133,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 222180,
    "City": "JAUNPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 509320,
    "City": "KALWAKURTHY",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431742,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382712,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388051,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388052,
    "City": "Karjan",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392005,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 392111,
    "City": "Bharuch",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341304,
    "City": "LADNUN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341316,
    "City": "LADNUN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416407,
    "City": "MAHISHAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312602,
    "City": "MANDPHIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636503,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332711,
    "City": "NEEM KA THANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431137,
    "City": "PAITHAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394317,
    "City": "BARDOLI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 643270,
    "City": "PANDALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480554,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327025,
    "City": "PARTAPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638058,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403106,
    "City": "PONDA GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209312,
    "City": "PUKHRAYAN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415504,
    "City": "PUSEGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 441408,
    "City": "RAMTEK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335065,
    "City": "Hanumangarh",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246421,
    "City": "RUDRAPRAYAG",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 246475,
    "City": "RUDRAPRAYAG",
    "State": "UTTARAKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314027,
    "City": "SAGWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313038,
    "City": "SALUMBER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403530,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322027,
    "City": "Sawaimadhopur",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431106,
    "City": "SELU",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415526,
    "City": "SHIRWAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431135,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333030,
    "City": "SURAJGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363110,
    "City": "SURENDRANAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413518,
    "City": "UDGIR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387515,
    "City": "VATADARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431128,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136038,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 603104,
    "City": "CHENGALPET",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768221,
    "City": "BAMRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768224,
    "City": "BAMRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768215,
    "City": "BAMRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 770022,
    "City": "BAMRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 770016,
    "City": "BAMRA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 786189,
    "City": "DIBRUGARH",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 321607,
    "City": "LAXMANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571253,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679327,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 676523,
    "City": "POOKKOTTUMPADAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755013,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755011,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755012,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761117,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391110,
    "City": "DABHOI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360590,
    "City": "BARWADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 362020,
    "City": "BHESHAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362030,
    "City": "BHESHAN",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365480,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517424,
    "City": "TIRUPATI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517401,
    "City": "TIRUPATI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176501,
    "City": "KANGRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176502,
    "City": "KANGRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176058,
    "City": "KANGRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 331701,
    "City": "RAJGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193502,
    "City": "SRINAGAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 285123,
    "City": "ORAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 285121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 285128,
    "City": "ORAI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144623,
    "City": "JALANDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314022,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314032,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 232120,
    "City": "CHANDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232109,
    "City": "CHANDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576218,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576210,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625221,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625218,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689691,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689698,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689663,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689661,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689666,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689692,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414107,
    "City": "AKOLE MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415570,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416222,
    "City": "BALINGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 792105,
    "City": "BORDUMSA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713144,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 713420,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761005,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761006,
    "City": "CHIKITI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431646,
    "City": "NANDED",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752060,
    "City": "KHORDHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 404404,
    "City": "KARMALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411107,
    "City": "PUNE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 411230,
    "City": "INDAPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412309,
    "City": "MANJARI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414405,
    "City": "RAHATA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414011,
    "City": "NEWASA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416813,
    "City": "TALERE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431153,
    "City": "WADWANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484664,
    "City": "MANPURMP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 484665,
    "City": "MANPURMP",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 364310,
    "City": "BHAVNAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 360550,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391168,
    "City": "KAWANT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626107,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626129,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626607,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312901,
    "City": "CHITTORGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 508210,
    "City": "SURYAPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632601,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632201,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 632603,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635806,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 517425,
    "City": "KUPPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517423,
    "City": "KUPPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517426,
    "City": "KUPPAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614615,
    "City": "Adirampattinam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614626,
    "City": "Adirampattinam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626142,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626108,
    "City": "Rajapalayam",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577160,
    "City": "BALEHONNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581344,
    "City": "GOKARNA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 759121,
    "City": "HINDOL",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621308,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621305,
    "City": "MANAPPARAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754112,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 636499,
    "City": "NANGAVALLI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623601,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 623608,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623705,
    "City": "PARAMAKUDI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 635123,
    "City": "ORATHANADU",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756060,
    "City": "SAJANAGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625715,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625716,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 203207,
    "City": "Delhi NCR",
    "State": "DELHI",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 305006,
    "City": "AJMER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333034,
    "City": "BUHANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 125038,
    "City": "NARNAUND",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732201,
    "City": "KALIACHAK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 732207,
    "City": "KALIACHAK",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 752017,
    "City": "DELANG",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 752018,
    "City": "NIRAKARPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 758041,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 758044,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 758047,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 758032,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 483990,
    "City": "RITHI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 641112,
    "City": "COIMBATORE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303006,
    "City": "PHAGI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 205262,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 232328,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224205,
    "City": "KUREBHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229412,
    "City": "LALGOPALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231307,
    "City": "MIRZAPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277202,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518583,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 518573,
    "City": "ALLAGADDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402307,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573133,
    "City": "ARAKALGUD",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416214,
    "City": "BAMBAVADE",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413116,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 370135,
    "City": "BHACHAU",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413529,
    "City": "Chakur",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576234,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 385421,
    "City": "PALANPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521332,
    "City": "PAMARRU",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 744106,
    "City": "PORT BLAIR",
    "State": "ANDAMAN AND NICOBAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416632,
    "City": "KANKAWALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487441,
    "City": "KARELI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382735,
    "City": "MANSA GJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431540,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431521,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431537,
    "City": "Parbhani",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 457336,
    "City": "JAORA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415011,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415530,
    "City": "SAIDAPURA SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 487661,
    "City": "UDAIPURA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416531,
    "City": "Sawantwadi",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412103,
    "City": "BARAMATI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412104,
    "City": "SUPE MORGAON",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 480559,
    "City": "PARASIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402207,
    "City": "ALIBAUG",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396590,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415711,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 402306,
    "City": "MAHAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388250,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388225,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611001,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611106,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611108,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611111,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627861,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627860,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627856,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627854,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627853,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 627812,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627807,
    "City": "TENKASI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627202,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 532292,
    "City": "ICHAPURAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804423,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804425,
    "City": "JEHANABAD",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 805124,
    "City": "NAWADA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 670646,
    "City": "MANANTHAVADY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413532,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431716,
    "City": "AHMEDPUR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416506,
    "City": "GADHINGALAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416503,
    "City": "GADHINGALAJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391445,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486114,
    "City": "DEOTALAB",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176090,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 176109,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177028,
    "City": "SANDHOLE",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140306,
    "City": "Manauli",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 784504,
    "City": "BALIPARA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630201,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630203,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630501,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622411,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387610,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387640,
    "City": "KAPADWANJ",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415702,
    "City": "GUHAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 493887,
    "City": "ABHANPUR",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391760,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476444,
    "City": "MORENA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 182204,
    "City": "KISHTWAR",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 736201,
    "City": "HATIPOTHA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816103,
    "City": "SHIKARIPARA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825329,
    "City": "DHAWATAND",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814120,
    "City": "SARWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391170,
    "City": "KAWANT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391175,
    "City": "KAWANT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 185111,
    "City": "POONCH",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227408,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 505402,
    "City": "SIRCILLA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394163,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394445,
    "City": "DEDIAPADA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394430,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243725,
    "City": "BISAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206247,
    "City": "AURAIYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206125,
    "City": "BAKEWAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277203,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277503,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277210,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277301,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277506,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 206250,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 206131,
    "City": "ETAWAH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413251,
    "City": "KARMALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 205119,
    "City": "MAINPURI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415023,
    "City": "DAHIWADI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 413607,
    "City": "NILANGA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210428,
    "City": "RATH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623528,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623533,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623712,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 623711,
    "City": "RAMANATHAPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 679313,
    "City": "OTTAPALAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627604,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 627451,
    "City": "TIRUNELVELI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689621,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689104,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689622,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689626,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690105,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 689109,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332405,
    "City": "SIKAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757106,
    "City": "KAPTIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741157,
    "City": "PLASSEY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741156,
    "City": "PLASSEY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741181,
    "City": "PLASSEY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814148,
    "City": "RANISHWAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814144,
    "City": "RANISHWAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416519,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 757013,
    "City": "BARIPADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389250,
    "City": "SANTRAMPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624715,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624716,
    "City": "VEDASANTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303323,
    "City": "BANDIKUI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 401405,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415717,
    "City": "Dapoli",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 361330,
    "City": "DWARKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 382463,
    "City": "DHANDUKA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 481879,
    "City": "DINDORI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 723126,
    "City": "PARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 741503,
    "City": "HANSKHALI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 383030,
    "City": "HIMMAT NAGAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466554,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 461668,
    "City": "HOSHANGABAD",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 362730,
    "City": "JAFRABAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312622,
    "City": "KAPASAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 451335,
    "City": "KHRAGONE",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 323614,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323613,
    "City": "LAKHERI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321633,
    "City": "LAXMANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396469,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391101,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 486111,
    "City": "DEOTALAB",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360560,
    "City": "PORBANDAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 456661,
    "City": "RAISEN",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415637,
    "City": "SANGMESHWAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 473880,
    "City": "SHIVPURI",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 756166,
    "City": "TIHIDI",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 303003,
    "City": "VIRATNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 790116,
    "City": "BOMDILA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 782108,
    "City": "MORIGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 782413,
    "City": "NAGAON",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 845425,
    "City": "ARERAJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721652,
    "City": "SHRIRAMPUR WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 712416,
    "City": "KHANAKUL",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516270,
    "City": "Rayachoty",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639105,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639102,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639120,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639119,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396060,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396510,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303328,
    "City": "JOBNER",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571250,
    "City": "VIRAJPET",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572111,
    "City": "KUNIGAL",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505502,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534145,
    "City": "TADEPALLIGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 534305,
    "City": "TADEPALLIGUDEM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847105,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 802163,
    "City": "BABURA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768033,
    "City": "SOHELLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768034,
    "City": "SOHELLA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781150,
    "City": "Chandrapur",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 124146,
    "City": "KOSLI",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 626205,
    "City": "VIRUDHUNAGAR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630403,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622408,
    "City": "PONNAMARAVATHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614623,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622302,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625402,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626135,
    "City": "SRIVILLIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 626137,
    "City": "SRIVILLIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577136,
    "City": "BALEHONNUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833101,
    "City": "SARAIKELAKHARSWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 231211,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 222164,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 133301,
    "City": "PANCHKULA",
    "State": "CHANDIGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 571406,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 192211,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192210,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 624703,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 624620,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637206,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637203,
    "City": "TIRUCHENGODE",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 404001,
    "City": "KARMALA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 811213,
    "City": "KHARAGPUR BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 210426,
    "City": "MAHOBA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192232,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 365450,
    "City": "AMRELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396126,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396050,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396040,
    "City": "VALSAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574328,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581198,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 416316,
    "City": "ASHTA MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416110,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416111,
    "City": "ICHALKARANJI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431211,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431507,
    "City": "BASMATH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 792130,
    "City": "KHONSA",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636118,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 794101,
    "City": "TURA",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 793119,
    "City": "NONGSTOIN",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781327,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781330,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314021,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323306,
    "City": "RAWATBHATA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326039,
    "City": "AKLERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341533,
    "City": "NAWA RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306704,
    "City": "DESURI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306705,
    "City": "DESURI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306901,
    "City": "",
    "State": "",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303803,
    "City": "CHOMU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303804,
    "City": "CHOMU",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314024,
    "City": "SAGWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 312616,
    "City": "PRATAPGARHRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 325221,
    "City": "CHABRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333032,
    "City": "GUDHA GORJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577224,
    "City": "HONNALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577243,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577551,
    "City": "HONNALLI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572138,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572128,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572168,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572140,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 572118,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 614617,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614619,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614618,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614629,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614622,
    "City": "ARANTHANGI TN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614621,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614620,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614630,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227406,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227303,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321406,
    "City": "HALENA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321407,
    "City": "HALENA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394180,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193109,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192201,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609804,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612105,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612104,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609807,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612605,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612502,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612202,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612402,
    "City": "KUMBAKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 391156,
    "City": "CHHOTAUDEPUR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 403506,
    "City": "GOA",
    "State": "GOA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412311,
    "City": "Nira",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412312,
    "City": "Nira",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431206,
    "City": "JAFRABADMH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 391155,
    "City": "BODELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389390,
    "City": "BODELI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225409,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225416,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225413,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274149,
    "City": "KAPTANGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271502,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 335522,
    "City": "HANUMANGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327023,
    "City": "BANSWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209871,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 431117,
    "City": "SILLOD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415520,
    "City": "KARAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 638108,
    "City": "ARACHALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 761133,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700087,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700071,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700017,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700016,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764085,
    "City": "NABARANGPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 764070,
    "City": "NABARANGPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784113,
    "City": "Mongoldoi",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 791101,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 621012,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621001,
    "City": "THURAIYUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621214,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574210,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 574259,
    "City": "MANGALORE",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 673579,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 503246,
    "City": "NAVIPET",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505505,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695127,
    "City": "Neyyattinkara",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 671534,
    "City": "KASARGODE",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 201314,
    "City": "Delhi NCR",
    "State": "DELHI",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 306308,
    "City": "JAITARAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306305,
    "City": "RAIPUR MARWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228159,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221402,
    "City": "BHADOHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274602,
    "City": "LAR TOWN",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 175036,
    "City": "MANDI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757034,
    "City": "JASHIPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757091,
    "City": "JASHIPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 757033,
    "City": "JASHIPUR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761140,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761125,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 422203,
    "City": "NASHIK",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416611,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416610,
    "City": "DEVGAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124524,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 394130,
    "City": "SURAT",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 341519,
    "City": "DANTARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303326,
    "City": "BANDIKUI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 505475,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396540,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713155,
    "City": "BUDBUD",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 756055,
    "City": "SORO",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 853205,
    "City": "NAUGACHIA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 620101,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639101,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639115,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621133,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621103,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621102,
    "City": "PERAMBALUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686103,
    "City": "KUTTANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686106,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686532,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 686535,
    "City": "KUTTANAD",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686537,
    "City": "Tiruvella",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686538,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 585211,
    "City": "SEDAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332403,
    "City": "SIKAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321642,
    "City": "HALENA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321614,
    "City": "KHERLI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327605,
    "City": "DUNGARPUR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 341501,
    "City": "PARBATSAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 505474,
    "City": "JAMMIKUNTA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 604205,
    "City": "TINDIVANAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271319,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271303,
    "City": "GONDA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 230141,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 230143,
    "City": "PRATAPGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 321601,
    "City": "HALENA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192221,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192202,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229304,
    "City": "AMETHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229307,
    "City": "AMETHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271302,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 271308,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 233229,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 825311,
    "City": "BARKAGAON",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333028,
    "City": "SULTANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755010,
    "City": "KAMPAGADA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 410513,
    "City": "RAJGURUNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 412402,
    "City": "RAJGURUNAGAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177111,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177110,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177112,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177113,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 763002,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 763001,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 763003,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802316,
    "City": "BARHARA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755027,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 755036,
    "City": "JAJPUR ORISSA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825323,
    "City": "BARAKATHA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743312,
    "City": "BASANTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743611,
    "City": "BASANTI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 768038,
    "City": "BARGARH",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 765019,
    "City": "BISHAMA KATEK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 825336,
    "City": "CHARHI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 815313,
    "City": "GAWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 815317,
    "City": "GAWAN",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 814152,
    "City": "DEOGHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721644,
    "City": "NIMTOURI",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843112,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843120,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711312,
    "City": "SHYAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711301,
    "City": "SHYAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 711326,
    "City": "SHYAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 711314,
    "City": "SHYAMPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393150,
    "City": "RAJPIPLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393140,
    "City": "RAJPIPLA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415106,
    "City": "KARAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415115,
    "City": "KARAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415527,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415510,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 414212,
    "City": "PATAN MH",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396560,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396570,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 685553,
    "City": "KATTAPANA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573210,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 573136,
    "City": "HASSAN",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276301,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276303,
    "City": "LALGANJ",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212206,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301604,
    "City": "GOVINDGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305630,
    "City": "MASUDA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 798621,
    "City": "MON",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798602,
    "City": "TIZIT",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 798625,
    "City": "LONGLENG",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 797109,
    "City": "TSEMINYU",
    "State": "NAGALAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 533448,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 333011,
    "City": "DHANURI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313205,
    "City": "FATEHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 507304,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 507204,
    "City": "MADHIRA",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313329,
    "City": "RAILMAGRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824118,
    "City": "TEKARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824207,
    "City": "TEKARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 802208,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 801110,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804401,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804402,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804428,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804426,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804419,
    "City": "ARWAL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476229,
    "City": "KAILARAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 476224,
    "City": "KAILARAS",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 415619,
    "City": "LANJA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415803,
    "City": "LANJA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 273165,
    "City": "GORAKHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301760,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621705,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621653,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621715,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621713,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621707,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612804,
    "City": "KUMBAKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612801,
    "City": "KUMBAKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612701,
    "City": "KUMBAKONAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 136039,
    "City": "ASSANDH",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571407,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 472001,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 122054,
    "City": "DELHI NCR",
    "State": "DELHI",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416609,
    "City": "KANKAWALI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607201,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 607202,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637407,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 637412,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686563,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 401503,
    "City": "BOISAR",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 388220,
    "City": "UMRETH",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415116,
    "City": "SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415107,
    "City": "SATARA",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 561198,
    "City": "BYADAGI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228121,
    "City": "KUREBHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 228142,
    "City": "KUREBHAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262903,
    "City": "SINGAHI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224225,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224121,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 221706,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221705,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225403,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225404,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191202,
    "City": "GANDERBAL",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277219,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301406,
    "City": "MALAKHERA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221713,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 171205,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 171206,
    "City": "SHIMLA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303327,
    "City": "BANDIKUI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700152,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700044,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700024,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700093,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700082,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700068,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700045,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700042,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700095,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700041,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700040,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700033,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700032,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700031,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700029,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700026,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700025,
    "City": "Kolkata",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700094,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700092,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700086,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743516,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743515,
    "City": "BURDWAN",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743506,
    "City": "BARUIPUR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 700154,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700153,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700084,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700107,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700099,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743318,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743384,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700138,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700137,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743301,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700143,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700142,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700141,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700140,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700139,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700066,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700061,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700018,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743612,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743352,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743319,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 743313,
    "City": "BATANAGAR",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700020,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700096,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700070,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 700047,
    "City": "KOLKATA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764036,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 763004,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 763008,
    "City": "SUNABEDA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 754026,
    "City": "CUTTACK",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721501,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 722162,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848302,
    "City": "KASHICHAK",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 847301,
    "City": "KASHICHAK",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848301,
    "City": "KASHICHAK",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 571129,
    "City": "NANJANAGUDU",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 796101,
    "City": "KOLASIB",
    "State": "MIZORAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 501141,
    "City": "Tandur",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572219,
    "City": "GUBBI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639201,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 639202,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691601,
    "City": "KOLLAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 691511,
    "City": "KOLLAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621203,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621207,
    "City": "KARUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 695615,
    "City": "Venjaramoodu",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277204,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 182221,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 177105,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 177106,
    "City": "UNA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192212,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 227304,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 191121,
    "City": "LAL CHOWK",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 431008,
    "City": "AURANGABAD",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396430,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 396350,
    "City": "NAVSARI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 477445,
    "City": "BHIND",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 331029,
    "City": "DUDHWA KHARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 193224,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 332316,
    "City": "MUKUNGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 335022,
    "City": "CHUNAWADH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262201,
    "City": "PILIBHIT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262202,
    "City": "PILIBHIT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276206,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 276208,
    "City": "AZAMGARH",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303109,
    "City": "JAMWARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303001,
    "City": "JAMWARAMGARH",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209307,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 209304,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 175016,
    "City": "BAIJNATH",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 741150,
    "City": "PLASSEY",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721431,
    "City": "NANDIGRAM WB",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 743711,
    "City": "NADIA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 515631,
    "City": "DHARMAVARAM",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 625205,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625214,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625207,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224302,
    "City": "Shambhal",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394116,
    "City": "ANKLESHWAR",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394630,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363622,
    "City": "WANKANER",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 387560,
    "City": "NADIAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 389172,
    "City": "JHALOD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 688632,
    "City": "KOTTAYAM",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581359,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 581337,
    "City": "KUMTA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303509,
    "City": "BALAJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314406,
    "City": "SIMAL WARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314402,
    "City": "SIMAL WARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 314403,
    "City": "SIMAL WARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 576244,
    "City": "BYNDOOR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630303,
    "City": "AMARAVATHIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824220,
    "City": "SHERGHATI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824205,
    "City": "SHERGHATI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824125,
    "City": "RAFIGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824103,
    "City": "RAFIGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 603402,
    "City": "UTHIRAMERUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 250001,
    "City": "MEERUT",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 142056,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 142057,
    "City": "BAGHA PURANA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221718,
    "City": "BELTHARA ROAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 572212,
    "City": "TUMKUR",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 620102,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 561201,
    "City": "MAGADI",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 790101,
    "City": "DIRANG",
    "State": "ARUNACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 577211,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 577416,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 571455,
    "City": "MANDYA",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 713434,
    "City": "GUPTIPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 764006,
    "City": "JEYPORE",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 734501,
    "City": "Malbazar",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224804,
    "City": "KHADDA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144213,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146116,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 140405,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 152121,
    "City": "FAZILKA",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 141422,
    "City": "SUDHAR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 184145,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 761118,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761123,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761132,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 761141,
    "City": "BHANJANAGAR",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742122,
    "City": "AZIMGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 742104,
    "City": "AZIMGANJ",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621217,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621204,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621205,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622304,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516257,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 516504,
    "City": "RAYACHOTY",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 816129,
    "City": "TINPAHAR",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 621851,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621722,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621651,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621652,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 621711,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628401,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 622505,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 622506,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843122,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 843125,
    "City": "SAHEBGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 312024,
    "City": "MANDPHIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 613301,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614614,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 135133,
    "City": "KURUKSHETRA",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 301407,
    "City": "MUNDAWAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 303302,
    "City": "TUNGA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177022,
    "City": "HAMIRPUR",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 326034,
    "City": "PIRAWA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 762030,
    "City": "TARBHA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852110,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 852130,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 852108,
    "City": "SUPAUL",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 363642,
    "City": "MORBI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742166,
    "City": "HARIHARPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742121,
    "City": "HARIHARPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 742162,
    "City": "HARIHARPARA",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 825408,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 822128,
    "City": "NAGARUNTARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 822121,
    "City": "NAGARUNTARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 822129,
    "City": "NAGARUNTARI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225415,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 173235,
    "City": "DARLAGHAT",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313203,
    "City": "FATEHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 327021,
    "City": "BANSWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174503,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 176032,
    "City": "JAWALI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 177034,
    "City": "NADAUN",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212105,
    "City": "NAINI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313207,
    "City": "RAILMAGRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313211,
    "City": "RAILMAGRA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 221716,
    "City": "MAU",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 636352,
    "City": "KADAYAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636354,
    "City": "KADAYAMPATTI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 673581,
    "City": "BATHERY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630603,
    "City": "AMARAVATHIPUTHUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277501,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 277504,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 224141,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 224157,
    "City": "AYODHYA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193123,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301401,
    "City": "AJARKA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322701,
    "City": "CHOUTH KA BARWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222302,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222304,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 222303,
    "City": "SULTANPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 587120,
    "City": "BAGALKOT",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 577227,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 614402,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 243503,
    "City": "BAREILLY",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 784190,
    "City": "MONGOLDOI",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824122,
    "City": "RAFIGANJ",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804421,
    "City": "TEKARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471525,
    "City": "CHANDLA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 422105,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 415538,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415512,
    "City": "VADUJ",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 393151,
    "City": "KEVADIA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 636301,
    "City": "NAMAKKAL",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690533,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690560,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690531,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690527,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 690535,
    "City": "ALLAPEY",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333503,
    "City": "KHETRIRJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 192129,
    "City": "ANANTNAG",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 322702,
    "City": "CHOUTH KA BARWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 274308,
    "City": "KUSHINAGAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 229135,
    "City": "JAGDISHPUR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 515321,
    "City": "PENUKONDA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 504219,
    "City": "BELLAMPALLI",
    "State": "TELANGANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 522306,
    "City": "DUGGIRALA",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 517319,
    "City": "MADANAPALLI",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 804453,
    "City": "PUNPUN",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396110,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 396370,
    "City": "BILIMORA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388245,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 793108,
    "City": "Cherrapunji",
    "State": "MEGHALAYA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 360055,
    "City": "BOTAD",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 506356,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 506366,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 518313,
    "City": "KURNOOL",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313206,
    "City": "FATEHNAGAR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 848134,
    "City": "SAMASTIPUR",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 843101,
    "City": "SARAIYA",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 231311,
    "City": "CHUNAR",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394248,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833212,
    "City": "DANGUAPOSI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 833203,
    "City": "DANGUAPOSI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 833217,
    "City": "DANGUAPOSI",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 835223,
    "City": "SIMDEGA",
    "State": "JHARKHAND",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 804403,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 803110,
    "City": "CHANDI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 632209,
    "City": "AMBUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 737139,
    "City": "RAVANGLA",
    "State": "SIKKIM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614806,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614808,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614809,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 614810,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611110,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 611112,
    "City": "TRICHY",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 630305,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 734226,
    "City": "DARJEELING",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 686511,
    "City": "PATHANAMTHITTA",
    "State": "KERALA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 209870,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 241503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 612201,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 612203,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612504,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 612503,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 642129,
    "City": "POLLACHI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 824111,
    "City": "AURANGABAD BH",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824237,
    "City": "SHERGHATI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 824235,
    "City": "TEKARI",
    "State": "BIHAR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604407,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604402,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604401,
    "City": "KANCHIPURAM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604404,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604405,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 604505,
    "City": "TIRUVANNAMALAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 416522,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416523,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 416524,
    "City": "KUDAL",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415312,
    "City": "MAYANI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193124,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 277502,
    "City": "BALLIA",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 271503,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 271126,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 171015,
    "City": "MASHOBRA",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 262805,
    "City": "GOLA UP",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 146111,
    "City": "Hoshiarpur",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332721,
    "City": "Khandela",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332722,
    "City": "Khandela",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193108,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 271301,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 303508,
    "City": "BALAJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224117,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 225405,
    "City": "BARABANKI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224126,
    "City": "RUDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 224119,
    "City": "RUDAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301714,
    "City": "SHAHJHAPUR RJ",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 306114,
    "City": "SOJAT CITY",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193209,
    "City": "SOPORE",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193211,
    "City": "SOPORE",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193212,
    "City": "SOPORE",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 333514,
    "City": "SULTANA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 193504,
    "City": "BARAMULLA",
    "State": "JAMMU AND KASHMIR",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 174507,
    "City": "HAROLI",
    "State": "HIMACHAL PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 466111,
    "City": "ICHHAWAR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 470112,
    "City": "MAKRONIYA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 464337,
    "City": "BINA",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 477335,
    "City": "BHIND",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 477441,
    "City": "BHIND",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 491232,
    "City": "BALOD",
    "State": "CHHATTISGARH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 415537,
    "City": "PHALTAN",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 388230,
    "City": "THASRA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 389150,
    "City": "VADODARA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 251201,
    "City": "KHATAULI",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 209622,
    "City": "FARRUKHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305621,
    "City": "NASIRABAD",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311801,
    "City": "GANGAPUR BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 304024,
    "City": "UNIYARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 305407,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 321610,
    "City": "BALAJI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332411,
    "City": "KHANDELA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 311024,
    "City": "RAILA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 212214,
    "City": "ALLAHABAD",
    "State": "UTTAR PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 311404,
    "City": "SAHPURA BHILWARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332026,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332028,
    "City": "SEVAD BARI",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 301021,
    "City": "",
    "State": "",
    "COD Delivery": "N",
    "Prepaid Delivery": "N",
    "Pickup": "N"
  },
  {
    "Pincode": 325001,
    "City": "KAITHUN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 332021,
    "City": "KHOOR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 332023,
    "City": "KHOOR",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323601,
    "City": "KESHOVRAIPATAN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 323301,
    "City": "KAPREN",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 313902,
    "City": "SARARA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 767066,
    "City": "SINDHEKELA",
    "State": "ORISSA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 394635,
    "City": "VANSDA",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 591302,
    "City": "BELGUAM",
    "State": "KARNATAKA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 144209,
    "City": "HOSHIARPUR",
    "State": "PUNJAB",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 124202,
    "City": "KHAPARWAS",
    "State": "HARYANA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 609118,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609805,
    "City": "MAYILADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 609404,
    "City": "THANJAVUR",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 521207,
    "City": "NUZVID",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 521211,
    "City": "NUZVID",
    "State": "ANDHRA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472221,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472118,
    "City": "TIKAMGARH",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606108,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625501,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 625503,
    "City": "MADURAI",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 628102,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 628251,
    "City": "TUTICORIN",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 605702,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606402,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 606708,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 605802,
    "City": "SALEM",
    "State": "TAMIL NADU",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472337,
    "City": "PRITHVIPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 472331,
    "City": "PRITHVIPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 438412,
    "City": "BECHARAJI",
    "State": "GUJARAT",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431736,
    "City": "BHILOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 431731,
    "City": "BHILOLI",
    "State": "MAHARASHTRA",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721159,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 721125,
    "City": "JHARGRAM",
    "State": "WEST BENGAL",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 471001,
    "City": "CHATARPUR",
    "State": "MADHYA PRADESH",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 781373,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 781377,
    "City": "BARPETA",
    "State": "ASSAM",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "N"
  },
  {
    "Pincode": 328029,
    "City": "MANIYA",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  },
  {
    "Pincode": 333201,
    "City": "BADA GANV",
    "State": "RAJASTHAN",
    "COD Delivery": "Y",
    "Prepaid Delivery": "Y",
    "Pickup": "Y"
  }
]