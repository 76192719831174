import React, { useEffect, useState } from 'react'
import { getCookie } from '../../helpers/cookies';
import { useCreateWareHouseMutation, useGetWareHouseQuery, useUpdateWareHouseMutation } from '../../store/index';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Button from '../common/Button';

const WareHouse = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const id = getCookie("delivaryUserId");
    const { data: wareHouse, error: FetchError } = useGetWareHouseQuery(id);
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const [createWareHouse, createResults] = useCreateWareHouseMutation();
    const [updateWareHouse, updateResults] = useUpdateWareHouseMutation();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [pincode, setPincode] = useState("");
    const [error, setError] = useState();
    
    useEffect(() => {
        if(!FetchError && wareHouse){
            setAddress(wareHouse.address);
            setCity(wareHouse.city);
            setPincode(wareHouse.pincode);
            setState(wareHouse.state);
        }
    }, [wareHouse, FetchError]);

    const handleUpdate = async () => {
        const data = {
            address,
            city,
            state,
            pincode,
        };
        if(!wareHouse){
            setProgress(50);
            await createWareHouse({ data, jwt }).unwrap().then((res) => {
                console.log(res);
                setProgress(100);
            }).catch((err) => {
                try{
                    setError(err.data.message);
                }
                catch{
                    setError("Request Timed Out! try again");
                }
                setProgress(100);
            });
        }
        else {
            setProgress(50);
            await updateWareHouse({ data, jwt }).unwrap().then((res) => {
                console.log(res);
                setProgress(100);
            }).catch((err) => {
                try{
                    setError(err.data.message);
                }
                catch{
                    setError("Request Timed Out! try again");
                }
                setProgress(100);
            });
        }
    }

    useEffect(() => {
        enable(true);
    }, [parent, enable]);

    return (
        <div className='signup' ref={parent}>
            <div className='heading'>
                <h1>WareHouse Details</h1>
            </div>
            <div className='inputs'>
                <input
                    type='text'
                    name='address'
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value);
                    }}
                    placeholder='address'
                />
                <input
                    type='text'
                    name='city'
                    value={city}
                    onChange={(e) => {
                        setCity(e.target.value);
                    }}
                    placeholder='city'
                />
                <input
                    type='text'
                    name='state'
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value);
                    }}
                    placeholder='state'
                />
                <input
                    type='number'
                    name='pincode'
                    value={pincode}
                    onChange={(e) => {
                        setPincode(e.target.value);
                    }}
                    placeholder='pincode'
                />
            </div>
            {error && (
                <div className='error'>
                    {error}
                </div>
            )}
            <div className='buttons'>
                <Button loading={createResults.isLoading || updateResults.isLoading} onClick={handleUpdate}>
                    Update
                </Button>
            </div>
        </div>
    )
}

export default WareHouse