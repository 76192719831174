import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const PaymentApi = createApi({
    reducerPath: "Payment",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/payment"
    }),
    endpoints(builders){
        return {
            createPayment: builders.mutation({
                query: (data) => {
                    return {
                        url: "/create",
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data
                    }
                }
            }),
            validatePayment: builders.mutation({
                query: (data) => {
                    return {
                        url: "/validate",
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data
                    }
                }
            })
        }
    }
});

export const { useCreatePaymentMutation, useValidatePaymentMutation } = PaymentApi;
export { PaymentApi };