import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import { useGetAllCouponsQuery, useUpdateSingleCouponMutation } from '../store/index'
import Button from '../components/common/Button'
import { GoSync } from 'react-icons/go'
import { getCookie } from '../helpers/cookies'

const Coupons = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const { data: coupons, isFetching } = useGetAllCouponsQuery();
    const [updateCoupon, updateResults] = useUpdateSingleCouponMutation();
    const [content, setContent] = useState()
    const [noProd, setNoProd] = useState();

    const handleStatusChange = async ({ id, status }) => {
        setProgress(50);
        const data = {
            status
        };
        console.log(data);
        await updateCoupon({ data, id, jwt }).unwrap().then((res) => {
            console.log(res);
            setProgress(100);
        }).catch((err) => {
            console.log(err);
            setProgress(100);
        })
    }

    useEffect(() => {
        if(coupons) {
            if(coupons.length === 0) {
                setContent();
                setNoProd(
                    <div className='no-prod'>
                        No Coupons available yet...
                    </div>
                );
            }
            else {
                setNoProd()
                setContent(coupons.map((c) => {
                    const status = c.status === "active" ? "inactive" : "active";
                    const handleStatus = async () => {
                        const data = {
                            id: c._id,
                            status,
                        }
                        await handleStatusChange(data);
                    }
                    return <tr>
                        <td>
                            {c._id}
                        </td>
                        <td>
                            {c.discount}
                        </td>
                        <td>
                            {c.status}
                        </td>
                        <td>
                            {c.usage}
                        </td>
                        <td>
                            {c.limit}
                        </td>
                        <td>
                            {c.used}
                        </td>
                        <td>
                            {new Date(c.expiry_date).getDate()}-{new Date(c.expiry_date).getMonth()}-{new Date(c.expiry_date).getFullYear()}
                        </td>
                        <td>
                            {c.customer_id}
                        </td>
                        <td>
                            <NavLink to={`/admin/edit-coupon/${c._id}`}>
                                <Button>
                                    Edit
                                </Button>
                            </NavLink>
                            <br />
                            <br />
                            <Button loading={updateResults.isLoading} onClick={handleStatus}>
                                {status}
                            </Button>
                        </td>
                    </tr>
                }));
            }
        }
        else if(isFetching){
            setContent();
            setNoProd(
                <div className='no-prod'>
                    <GoSync className='animate-spin' />
                    <p>Loading Coupons</p>
                </div>
            )
        }
        else {
            setContent();
            setNoProd(
                <div className='no-prod'>
                    reload!! to try again
                </div>
            )
        }
    }, [coupons, isFetching]);
    
    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Orders</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='orders'>
                <div className='top'>
                    <input type='text' name='search' placeholder='search' />
                    <NavLink to="/admin/create-coupon">
                        <button>+ Create Coupon</button>
                    </NavLink>
                </div>
                <div className='order-table'>
                    <div className='heading'>
                        <h1>Coupons</h1>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>Coupon ID</th>
                            <th>Discount</th>
                            <th>Status</th>
                            <th>Usage</th>
                            <th>Order Limit (gms)</th>
                            <th>Used</th>
                            <th>Expiry Date</th>
                            <th>Assigned To</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>{content}</tbody>
                    </table>
                    {noProd && (
                        <div className='no-ord'>
                            {noProd}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Coupons