import React, { useState } from 'react'
import { getCookie } from '../helpers/cookies';
import { Helmet } from 'react-helmet-async';
import Button from '../components/common/Button';
import { useCreateEscalationsMutation } from '../store/index';
import { useNavigate } from 'react-router-dom';

const CreateEscalation = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const [orderId, setOrderId] = useState();
    const [subject, setSubject] = useState();
    const [description, setDescription] = useState();
    const [err, setErr] = useState();
    const [createEscalation, createResults] = useCreateEscalationsMutation();
    const navigate = useNavigate();

    const handleEscalationCreate = async () => {
        if(!orderId){
            setErr("orderId is required");
        }
        if(!subject){
            setErr("subject is required");
        }
        if(!description){
            setErr("description is required");
        }
        const data = {
            orderId,
            subject,
            description
        };
        setProgress(50);
        await createEscalation({ data, jwt }).unwrap().then((res) => {
            navigate("/customer/support");
            setProgress(100);
        }).catch((err) => {
            console.log(err);
            setProgress(100);
        });
    };
    
    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - New Escalation</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='signup'>
                <div className='heading'>
                    <h1>New Escalation</h1>
                </div>
                <div className='inputs'>
                    <input
                        type='text'
                        name='orderId'
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        placeholder='order Id'
                    />
                    <input
                        type='text'
                        name='subject'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder='subject'
                    />
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='description'
                    />
                </div>
                {err && (
                    <div className='error'>
                        {err}
                    </div>
                )}
                <div className='buttons'>
                    <Button loading={createResults.isLoading} onClick={handleEscalationCreate}>
                        Create
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CreateEscalation