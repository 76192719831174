import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='contacts'>
        <h1>Edaylivary</h1>
        <p>edaylivary@gmail.com</p>
        <p>+91 90381 76999</p>
        <p>10/40 Netaji Nagar, Regent Park Kolkata- 700040</p>
      </div>
      <div className='conditions'>
        <h1>Terms And conditions</h1>
        <div className='list'>
          <ul>
            <li>I have read and understood the shipping rates of company and their sub services and understand that the shipping rates are subject to change once a year. Above Prices are valid till 31st December 2022 & general hike from Jan-23 is expected as per industry standard.</li>
            <li>I understand that sending of prohibited items like hazardous or flammable substances, arms & ammunitions, etc. is not permissible and I shall abide by those restrictions.</li>
            <li>I understand that I should use only shipping labels (AWB'S) that are generated from the dashboard and not manual abels; use of manual labels will result in a fine of Rs. 500/- + taxes.</li>
            <li>I understand that there is a 5% chance that pick-up services may face issues due to operational concerns of thecourier company especially in case of new pickup locations.</li>
            <li>I understand that sometimes the volumetric weight of my package could be higher than physical weight and agree to billed for whichever is higher.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer