import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getCookie, setCookie } from '../helpers/cookies'
import { Helmet } from 'react-helmet-async';
import { useGetAllB2BOrdersQuery, useGetCustomerB2BOrdersQuery, useUpdateSingleB2BOrderMutation, useUpdateUserMutation } from '../store/index';
import Button from '../components/common/Button';
import { exportToXL } from '../helpers/export';
import Verify from '../components/orders/Verify';
import { pickup, codDelivery, prepaidDelivery } from '../helpers/Pincodes';

const B2BOrders = ({ setProgress }) => {
  const role = getCookie("delivaryUserRole");
  const id = getCookie("delivaryUserId");
  const jwt = getCookie("delivaryUserJWT");
  const wallet = getCookie("delivaryUserWallet");
  const no_of_orders = getCookie("delivaryUserOrders");
  const { data: allOrders, isFetching: FetchAll} = useGetAllB2BOrdersQuery();
  const { data: customerOrders, isFetching: FetchCustomers } = useGetCustomerB2BOrdersQuery(id);
  const [UpdateOrder, updateResults] = useUpdateSingleB2BOrderMutation();
  const [updateUser, updationResults] = useUpdateUserMutation();
  const [content, setContent] = useState();
  const [noProd, setNoProd] = useState();
  const [download, setDownload] = useState();

  useEffect(() => {
    if(role === "admin"){
      if(allOrders){
        if(allOrders.length !== 0){
          setDownload(allOrders);
          setContent(allOrders.map((c) => {
            const handlePickupOrder = async () => {
                const data = {
                    order_status: "requested_pickup"
                }
                setProgress(50);
                await UpdateOrder({ jwt, id:c._id, data }).unwrap().then((res) => {
                    console.log(res);
                    setProgress(100);
                }).catch((err) => {
                    alert(JSON.stringify(err));
                    setProgress(100);
                });
            }
            const handleGeneratePickupOrder = async () => {
                const data = {
                    order_status: "pickup_generated"
                }
                setProgress(50);
                await UpdateOrder({ jwt, id:c._id, data }).unwrap().then((res) => {
                    console.log(res);
                    setProgress(100);
                }).catch((err) => {
                    alert(JSON.stringify(err));
                    setProgress(100);
                });
            };

            const handleCancelOrder = async () => {
                const data = {
                    order_status: "cancelled"
                }
                setProgress(50);
                await UpdateOrder({ jwt, id:c._id, data }).unwrap().then((res) => {
                    console.log(res);
                    setProgress(100);
                }).catch((err) => {
                    alert(JSON.stringify(err));
                    setProgress(100);
                });
            };

            return (
              <tr>
                <td>
                  <NavLink to={`/admin/b2b/details/${c._id}`}>
                    {c.orderId}
                  </NavLink>
                </td>
                <td>
                  {c.lr_number === "" ? "-" : (
                    <NavLink to={`/admin/b2b/track/${c._id}`}>
                      {c.lr_number}
                    </NavLink>
                  )}
                </td>
                <td>XpressBees</td>
                <td>B2B</td>
                <td>{c.consignee_details.consignee_name}</td>
                <td>{c.consigner_details.consigner_name}</td>
                <td>
                  Order Total: {c.order_total} <br />
                  discount: {c.discount} <br />
                  Collectable Amount: {c.collectable_amount}
                </td>
                <td>
                  Shipping Charges: {Math.ceil(parseFloat(c.discounted_shipping_charges))}<br />
                  COD Charges: {c.cod_charges}
                </td>
                <td>{c.payment_status}</td>
                <td>
                { c.order_status === "verified" && c.customer_id === id && (
                    <>
                      <Button loading={updateResults.isLoading} onClick={handlePickupOrder}>
                        Pick Up
                      </Button>
                      <br />
                      <br />
                      <Button loading={updateResults.isLoading || updationResults.isLoading} onClick={handleCancelOrder}>
                        Cancel
                      </Button>
                    </>
                  )}
                  {c.order_status === "requested" && (
                    <>
                        <Verify id={c._id} setProgress={setProgress} />
                        <br />
                        <br />
                        <Button loading={updateResults.isLoading || updationResults.isLoading} onClick={handleCancelOrder}>
                            Cancel
                        </Button>
                    </>
                  )}{
                    c.order_status === "requested_pickup" && (
                      <>
                        <p>Pickup requested</p>
                        <br />
                        <br />
                        <Button loading={updateResults.isLoading || updationResults.isLoading} onClick={handleGeneratePickupOrder}>
                          Pickup Generated
                        </Button>
                      </>
                    )
                  }
                  {(c.order_status === 'cancelled' || c.order_status === 'pickup_generated')  && c.order_status}
                </td>
                <td>
                  -
                </td>
              </tr>
            )
          }));
          setNoProd();
        }
        else {
          setContent();
          setNoProd("No Orders Found...");
        }
      }
      else if(FetchCustomers){
        setNoProd("Loading Orders...");
        setContent();
      }
      else {
        setNoProd("Error Loading Orders!! please reload...");
        setContent();
      }
    }
    else{
        if(customerOrders){
          if(customerOrders.length !== 0){
            setDownload(customerOrders);
            setContent(customerOrders.map((c) => {
                const handlePickupOrder = async () => {
                    const data = {
                       order_status: "requested_pickup"
                    }
                    setProgress(50);
                    await UpdateOrder({ jwt, id:c._id, data }).unwrap().then((res) => {
                        console.log(res);
                        setProgress(100);
                    }).catch((err) => {
                        alert(JSON.stringify(err));
                        setProgress(100);
                    });
                }
                const handleCancelOrder = async () => {
                    const data = {
                        order_status: "cancelled"
                    }
                    setProgress(50);
                    await UpdateOrder({ jwt, id:c._id, data }).unwrap().then(async (res) => {
                        console.log(res);
                        setProgress(100);
                        const update_data = {
                            wallet: parseInt(wallet) + parseInt(c.discounted_shipping_charges),
                            no_of_orders: parseInt(no_of_orders) - 1
                        };
                        await updateUser({ data: update_data, jwt }).unwrap().then((res) => {
                            setProgress(100);
                            console.log("user_updated:",res);
                            setCookie("delivaryUserWallet", update_data.wallet);
                            setCookie("delivaryUserOrders", update_data.no_of_orders);
                        })
                    }).catch((err) => {
                        alert(JSON.stringify(err));
                        setProgress(100);
                    });
                };
    
                return (
                  <tr>
                    <td>
                      <NavLink to={`/customer/b2b/details/${c._id}`}>
                        {c.orderId}
                      </NavLink>
                    </td>
                    <td>
                      {c.lr_number === "" ? "-" : (
                        <NavLink to={`/customer/b2b/track/${c._id}`}>
                          {c.lr_number}
                        </NavLink>
                      )}
                    </td>
                    <td>XpressBees</td>
                    <td>B2B</td>
                    <td>{c.consignee_details.consignee_name}</td>
                    <td>{c.consigner_details.consigner_name}</td>
                    <td>
                      Order Total: {c.order_total} <br />
                      discount: {c.discount} <br />
                      Collectable Amount: {c.collectable_amount}
                    </td>
                    <td>
                      Shipping Charges: {Math.ceil(parseFloat(c.discounted_shipping_charges))}<br />
                      COD Charges: {c.cod_charges}
                    </td>
                    <td>{c.payment_status}</td>
                    <td>
                    { c.order_status === "verified" && (
                        <>
                          <Button loading={updateResults.isLoading} onClick={handlePickupOrder}>
                            Pick Up
                          </Button>
                          <br />
                          <br />
                          <Button loading={updateResults.isLoading || updationResults.isLoading} onClick={handleCancelOrder}>
                            Cancel
                          </Button>
                        </>
                      )}
                      {c.order_status === "requested" && (
                        <Button loading={updateResults.isLoading || updationResults.isLoading} onClick={handleCancelOrder}>
                            Cancel
                        </Button>
                      )}
                      {(c.order_status === 'cancelled' || c.order_status === 'requested_pickup' || c.order_status === "pickup_generated")  && c.order_status}
                    </td>
                    <td>
                      -
                    </td>
                  </tr>
                )
              }));
              setNoProd();
          }
          else {
            setContent();
            setNoProd("No Orders Found...");
          }
        }
        else if(FetchCustomers){
          setNoProd("Loading Orders...");
          setContent();
        }
        else {
          setNoProd("Error Loading Orders!! please reload...");
          setContent();
        }
      }
  }, [role, allOrders, customerOrders, FetchAll, FetchCustomers]);

  const exportData = () => {
      const list = Array.from(new Set([...pickup, ...codDelivery, ...prepaidDelivery]));
      const data = list.map((l) => {
        let pick = 0, cod = 0, prepaid = 0;
        [pick] = pickup.filter((p) => {
          return p === l;
        });
        [cod] = codDelivery.filter((p) => {
          return p === l;
        });
        [prepaid] = prepaidDelivery.filter((p) => {
          return p === l;
        });
        return {
          pincode: l,
          pickUp: pick === l ? "Y" : "N",
          cod: cod === l ? "Y" : "N",
          prepaid: prepaid === l ? "Y" : "N"
        };
      })
      exportToXL(data);
  };


  const handleExport = () => {
    if(download){
      const data = download.map((d) => {
        return {
          orderId: d.orderId,
          ...d.consigner_details,
          ...d.consignee_details,
          ...d.product_details,
          ...d.invoice_details,
          ...d.product_box_details,
          lr_number: d.lr_number,
          awb_number: d.awb_numbers,
          shipping_id: d.shipping_id,
          order_status: d.order_status,
          order_total: d.order_total,
          discount: d.discount,
          collectable_amount: d.collectable_amount,
          cod_charges: d.cod_charges,
          shipping_charges: d.shipping_charges,
          payment_status: d.payment_status,
          payment_method: d.payment_method,
          coupon_discount: d.coupon_discount,
          discounted_shipping_charges: d.discounted_shipping_charges
        }
      })
      exportToXL(data);
    }
  }

  return (
    <>
      <Helmet>
          <title>edaylivery - Every day Delivary, Ontime - B2B Orders</title>
          <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='orders'>
        <div className='top'>
          <input type='text' name='search' placeholder='search' />
          <NavLink to={role === "admin" ? "/admin/create-b2b-order" : "/customer/create-b2b-order"}>
            <button>+ Create B2B Order</button>
          </NavLink>
        </div>
        <div className='order-table'>
          <div className='heading'>
            <h1>B2B Orders</h1>
          </div>
          <div className='buttons'>
            <Button onClick={handleExport}>
              Export
            </Button>
            <Button onClick={exportData}>
              Pincodes List
            </Button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Tracking ID</th>
                <th>Shipping Partner</th>
                <th>Tansport Type</th>
                <th>Consignee Name</th>
                <th>Consigner Name</th>
                <th>Order Totals</th>
                <th>Shipping Charges</th>
                <th>Payment Status</th>
                <th>Action</th>
                <th>Slip</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
          {noProd && (
            <div className='no-ord'>
              {noProd}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default B2BOrders