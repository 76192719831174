import { Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import LoadingBar from 'react-top-loading-bar';
import { useEffect, useState } from 'react';
import { getCookie } from './helpers/cookies';
import AdminSideBar from './components/admin/AdminSidebar';
import CustomerSideBar from './components/customers/CustomerSideBar';
import CustomRoutes from './components/common/CustomRoutes';
import ForgotPassword from './components/auth/ForgotPassword';
import B2COrders from './pages/B2COrders';
import Customers from './pages/Customers';
import Profile from './pages/Profile';
import TransactionsCustomerPage from './pages/TransactionsCustomerPage';
import TransactionsAdminPage from './pages/TransactionAdminPage';
import CreateCustomerOrder from './pages/CreateCustomerOrder';
import CreateAdminOrder from './pages/CreateAdminOrder';
import { inject } from '@vercel/analytics';
import ProfitRates from './pages/ProfitRates';
import Escalations from './pages/Escalations';
import CreateEscalation from './pages/CreateEscalation';
import EscalationsSinglePage from './pages/EscalationsSinglePage';
import OrderDetails from './pages/OrderDetails';
import OrderTrackingPage from './pages/OrderTrackingPage';
import CustomTrackingPage from './pages/CustomTrackingPage';
import CreateCouponsPage from './pages/CreateCouponsPage';
import Coupons from './pages/Coupons';
import EditCoupons from './pages/EditCoupons';
import CustomersCoupons from './pages/CustomerCoupons';
import Label from './components/common/Label';
import Home from './pages/Home';
import CustomSideBar from './components/common/CustomSideBar';
import DashBooard from './pages/DashBoard';
import EditB2CAdminOrder from './pages/EditB2CAdminOrder';
import EditB2CCustomerOrder from './pages/EditB2CCustomerOrder';
import Calculator from './pages/Calculator';
import CreateB2BAdminOrders from './pages/CreateB2BAdminOrders';
import B2BOrders from './pages/B2BOrders';
import OrderB2BDetails from './components/orders/OrderB2BDetails';
import OrderB2BTrackingPage from './components/orders/OrderB2BTracking';
import CreateB2BCustomerOrder from './pages/CreateB2BCustomerOrder';
import Issues from './pages/Issues';
 
inject();

function App() {
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const jwt = getCookie("delivaryUserJWT");
  const role = getCookie("delivaryUserRole")
  const navigate = useNavigate();

  useEffect(() => {
    const routeName = location.pathname.split("/")[1];
    if(!jwt){
      if(routeName === "admin" || routeName === "customer"){
        navigate("/");
      }
    }
    else{
      if(role === "admin"){
        if(routeName === "auth" || routeName === "customer"){
          navigate("/admin");
        }
      }
      else {
        if(routeName === "auth" || routeName === "admin"){
          navigate("/customer");
        }
      }
    }
    // navigate("/issue");
  }, [location, jwt, navigate, role]);

  return (
    <>
      <LoadingBar
        color="#ad0e0e"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <CustomRoutes setProgress={setProgress}>
        <Route path='/' element={<CustomSideBar />}>
          <Route index element={<Home setProgress={setProgress} />} />
          <Route path='auth/signup' element={<SignUp setProgress={setProgress} />} />
          <Route path='auth/login' element={<Login setProgress={setProgress} />} />
          <Route path='forgot' element={<ForgotPassword setProgress={setProgress} />} />
          <Route path='track-order' element={<CustomTrackingPage setProgress={setProgress} />} />
        </Route>
        <Route path="/issue" element={<Issues />} /> 
        <Route path='admin' element={<AdminSideBar />}>
          <Route index element={<DashBooard setProgress={setProgress} />} />
          <Route path="orders/b2c" element={<B2COrders setProgress={setProgress} />} />
          <Route path="orders/b2b" element={<B2BOrders setProgress={setProgress} />} />
          <Route path='customers' element={<Customers setProgress={setProgress} />} />
          <Route path='transactions' element={<TransactionsAdminPage setProgress={setProgress} />} />
          <Route path='profile' element={<Profile setProgress={setProgress} />} />
          <Route path='create-b2c-order' element={<CreateAdminOrder setProgress={setProgress} />} />
          <Route path='create-b2b-order' element={<CreateB2BAdminOrders setProgress={setProgress} />} />
          <Route path='edit-order/:id' element={<EditB2CAdminOrder setProgress={setProgress} />} />
          <Route path='profit-rates' element={<ProfitRates setProgress={setProgress} />} />
          <Route path='support' element={<Escalations setProgress={setProgress} />} />
          <Route path='escalation/:id' element={<EscalationsSinglePage setProgress={setProgress} />} />
          <Route path='details/:id' element={<OrderDetails setProgress={setProgress} />} />
          <Route path='b2b/details/:id' element={<OrderB2BDetails setProgress={setProgress} />} />
          <Route path='track/:id' element={<OrderTrackingPage setProgress={setProgress} />} />
          <Route path='b2b/track/:id' element={<OrderB2BTrackingPage setProgress={setProgress} />} />
          <Route path='create-coupon' element={<CreateCouponsPage setProgress={setProgress} />} />
          <Route path='edit-coupon/:id' element={<EditCoupons setProgress={setProgress} />} />
          <Route path='coupons' element={<Coupons setProgress={setProgress} />} />
          <Route path='label/:id' element={<Label setProgress={setProgress} />} />
        </Route>
        <Route path='customer' element={<CustomerSideBar setProgress={setProgress} />}>
          <Route index element={<DashBooard setProgress={setProgress} />} />
          <Route path='orders/b2c' element={<B2COrders setProgress={setProgress} />} />
          <Route path="orders/b2b" element={<B2BOrders setProgress={setProgress} />} />
          <Route path='transactions' element={<TransactionsCustomerPage setProgress={setProgress} />} />
          <Route path='calculator' element={<Calculator setProgress={setProgress} />} />
          <Route path='profile' element={<Profile setProgress={setProgress} />} />
          <Route path='create-b2c-order' element={<CreateCustomerOrder setProgress={setProgress} />} />
          <Route path='create-b2b-order' element={<CreateB2BCustomerOrder setProgress={setProgress} />} />
          <Route path='edit-order/:id' element={<EditB2CCustomerOrder setProgress={setProgress} />} />
          <Route path='support' element={<Escalations setProgress={setProgress} />} />
          <Route path='new-escalation' element={<CreateEscalation setProgress={setProgress} />} />
          <Route path='escalation/:id' element={<EscalationsSinglePage setProgress={setProgress} />} />
          <Route path='details/:id' element={<OrderDetails setProgress={setProgress} />} />
          <Route path='b2b/details/:id' element={<OrderB2BDetails setProgress={setProgress} />} />
          <Route path='track/:id' element={<OrderTrackingPage setProgress={setProgress} />} />
          <Route path='b2b/track/:id' element={<OrderB2BTrackingPage setProgress={setProgress} />} />
          <Route path='coupons' element={<CustomersCoupons setProgress={setProgress} />} />
        </Route>
      </CustomRoutes>
    </>
  );
}

export default App;
