import React, { useEffect, useState } from 'react'
import { MdArrowBackIos, MdArrowForwardIos  } from "react-icons/md";
import { getCookie } from '../../helpers/cookies';
import { NavLink } from 'react-router-dom';

const Carousel = () => {
    const jwt = getCookie("delivaryUserJWT");
    const role = getCookie("delivaryUserRole");
    const [margin, setMargin] = useState();
    const [clicked, setClicked] = useState(false);
    const [link, setLink] = useState("/auth/login");

    useEffect(() => {
        setMargin(0);
    }, []);

    useEffect(() => {
        if(jwt) {
            if(role === "admin") {
                setLink("/admin");
            }
            else {
                setLink("/customer");
            }
        }
    }, [jwt, role]);
    
    const left = () => {
        setClicked(true);
        if(margin < 0){
            for(let i = 1; i <= 100; i++){
                setTimeout(() => setMargin(margin + i), 10);
            }
        }
    }

    const right = () => {
        setClicked(true);
        if(margin >= -100){
            for(let i = 1; i <= 100; i++){
                setTimeout(() => setMargin(margin - i), 10);
            }
        }
    }

    
  return (
    <div style={{ position: "relative" }}>
        <div className='carousel'>
            <div style={{ marginLeft: `${margin}vw` }} className={!clicked && "animate"}>
                <img src={process.env.PUBLIC_URL + 'hero-1.png'} alt='hero-1' />
                <NavLink to={link}>
                    <button>Explore</button>
                </NavLink>
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + 'hero-2.png'} alt='hero-2' />
            </div>
            <div>
                <h1>Faster Air Service Than Kiki's Delivery Services</h1>
                <img src={process.env.PUBLIC_URL + 'kiki.jpg'} alt='hero-3' />
                <NavLink to={link}>
                    <button>Dashboard</button>
                </NavLink>
            </div>
        </div>
        <div className='left-arrow' onClick={left}>
            <MdArrowBackIos />
        </div>
        <div className='right-arrow' onClick={right}>
            <MdArrowForwardIos />
        </div>
    </div>
  )
}

export default Carousel