import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetAllEscalationsQuery, useGetCustomerEscalationsQuery, useUpdateSingleEscalationMutation } from '../store/index';
import { getCookie } from '../helpers/cookies';
import { NavLink } from 'react-router-dom';
import Button from '../components/common/Button';
import { exportToXL } from '../helpers/export';

const Escalations = ({ setProgress }) => {
  const id = getCookie("delivaryUserId");
  const role = getCookie("delivaryUserRole");
  const jwt = getCookie("delivaryUserJWT");
  const [content, setContent] = useState();
  const [noProd, setNoProd] = useState();
  const { data: allEscalations, isFetching: allfetching} = useGetAllEscalationsQuery();
  const { data: customerEscalations, isFetching: customerFetching } = useGetCustomerEscalationsQuery(id);
  const [updateEscalation, updateResults] = useUpdateSingleEscalationMutation();

  const handleCloseEscalation = async (id) => {
    const data = {
      status: "Closed"
    };
    setProgress(50);
    await updateEscalation({ data, id, jwt }).unwrap().then((res) => {
      console.log(res);
      setProgress(100);
    }).catch((err) => {
      console.log(err);
      setProgress(100);
    });
  }

  useEffect(() => {
    if(role === "admin"){
      if(allEscalations){
        if(allEscalations.length !== 0){
          setContent(allEscalations.map((esc) => {
            const link = `/admin/escalation/${esc._id}`;
            return <tr>
              <td>
                <NavLink to={link}>
                  {esc._id}
                </NavLink>
              </td>
              <td>{esc.customer_id}</td>
              <td>{esc.orderId}</td>
              <td>{esc.subject}</td>
              <td>{esc.description}</td>
              <td>{new Date(esc.createdAt).toUTCString()}</td>
              <td>{esc.status}</td>
              <td>
                {esc.status !== "Closed" ? (
                  <Button loading={updateResults.isLoading} onClick={() => handleCloseEscalation(esc._id)}>
                    Close
                  </Button>
                ): "-"}
              </td>
            </tr>
          }));
          setNoProd();
        }
        else{
          setContent();
          setNoProd("No Escalations Found...");
        }
      }
      else if(allfetching){
        setContent();
        setNoProd("Loading Escalations...");
      }
      else{
        setContent();
        setNoProd("Error Loading Escalations, Please Reload!");
      }
    }
    else {
      if(customerEscalations){
        if(customerEscalations.length !== 0){
          setContent(customerEscalations.map((esc) => {
            const link = `/customer/escalation/${esc._id}`;
            return <tr>
              <td>
                <NavLink to={link}>
                  {esc._id}
                </NavLink>
              </td>
              <td>{esc.customer_id}</td>
              <td>{esc.orderId}</td>
              <td>{esc.subject}</td>
              <td>{esc.description}</td>
              <td>{esc.status}</td>
              <td>{new Date(esc.createdAt).toUTCString()}</td>
              <td>
                {esc.status !== "Closed" ? (
                  <Button loading={updateResults.isLoading} onClick={() => handleCloseEscalation(esc._id)}>
                    Close
                  </Button>
                ): "-"}
              </td>
            </tr>
          }));
          setNoProd();
        }
        else{
          setContent();
          setNoProd("No Escalations Found...");
        }
      }
      else if(customerFetching){
        setContent();
        setNoProd("Loading Escalations...");
      }
      else{
        setContent();
        setNoProd("Error Loading Escalations, Please Reload!");
      }
    }
  }, [allEscalations, customerEscalations, allfetching, customerFetching, role]);

  const handleExport = () => {
    if(role === 'admin'){
      if(allEscalations){
        exportToXL(allEscalations);
      }
    }
    else{
      if(customerEscalations){
        exportToXL(customerEscalations);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>edaylivery - Every day Delivary, Ontime - Support</title>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='orders'>
        <div className='top'>
          <input type='text' name='search' placeholder='search' />
          {role === "customer" && (
            <NavLink to="/customer/new-escalation">
              <button>+ New Escalation</button>
            </NavLink>
          )}
        </div>
        <div className='order-table'>
          <div className='heading'>
            <h1>Support</h1>
          </div>
          <div className='buttons'>
            <Button onClick={handleExport}>
              Export
            </Button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Escalation ID</th>
                <th>Customer ID</th>
                <th>Order ID</th>
                <th>Subject</th>
                <th>Description</th>
                <th>reported At</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
          {noProd && (
            <div className='no-ord'>
              {noProd}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Escalations