import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const UsersApi = createApi({
    reducerPath: "Users",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/users"
    }),
    endpoints(builders){
        return {
            Register: builders.mutation({
                invalidatesTags: (res, err, data) => {
                    return [{ type: "Users" }];
                },
                query: (data) => {
                    return {
                        url: "/register",
                        method: "POST",
                        data
                    }
                }
            }),
            Login: builders.mutation({
                query: (data) => {
                    return {
                        url: "/login",
                        method: "POST",
                        data
                    }
                }
            }),
            updatePassword: builders.mutation({
                query: (data) => {
                    return {
                        url: "/",
                        method: "PUT",
                        data
                    }
                }
            }),
            updateUser: builders.mutation({
                invalidatesTags: (res, err, data) => {
                    return [{ type: "Users"}, { type: "CurrUser", jwt: data.jwt }];
                },
                query: ({data, jwt}) => {
                    return {
                        url: "/secure/",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        method: "PUT",
                        data
                    }
                }
            }),
            getCurrUser: builders.query({
                providesTags: (res, err, jwt) => [{ type: "CurrUser", jwt }],
                query: (jwt) => {
                    return {
                        url: "/secure/",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        method: "POST",
                    }
                }
            }),
            getCustomers: builders.query({
                providesTags: (res, err, data) => {
                    return [{ type: "Users"}];
                },
                query: () => {
                    return {
                        url: "/customers/",
                        method: "GET",
                    }
                }
            }),
        }
    }
});

export const { useUpdatePasswordMutation, useGetCustomersQuery, useGetCurrUserQuery, useLoginMutation, useRegisterMutation, useUpdateUserMutation } = UsersApi;
export { UsersApi };