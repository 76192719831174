import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const ReviewsApi = createApi({
    reducerPath: "Reviews",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/reviews"
    }),
    endpoints(builders){
        return {
            createReviews: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Reviews" }, { type: "CustomerReviews" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            getAllReviews: builders.query({
                providesTags: (res, err, data) => [{ type: "Reviews" }],
                query: () => {
                    return {
                        url: "/",
                        method: "GET"
                    }
                }
            }),
            getCustomerReviews: builders.query({
                providesTags: (res, err, data) => [{ type: "CustomerReviews" }],
                query: (id) => {
                    return {
                        url: `/customer/${id}`,
                        method: "GET"
                    }
                }
            }),
            updateSingleReview:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "CustomerReviews" }, { type: "Reviews" }],
                query: ({ data, jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            cancelSingleReview:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "CustomerReviews" }, { type: "Reviews" }],
                query: ({ jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                }
            })
        }
    }
});

export const { useCreateReviewsMutation, useGetAllReviewsQuery, useGetCustomerReviewsQuery, useUpdateSingleReviewMutation, useCancelSingleReviewMutation } = ReviewsApi;
export { ReviewsApi };