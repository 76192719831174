import React from 'react'

const About = () => {
  return (
    <div className='about'>
      <div className='left'>
        <h1>About Us</h1>
        <p>The journey of our company started in 2018. In the first 2 years we have been working with Blue Dart, Aramex, and Snapdeal, Worked with it as a delivery vendor. We have been associated with the first courier and cargo booking business since the year 2020 This time we have taken franchise from Delhivery Limited. And with the passage of time, we started working with many other courier companies who are currently very prominent in our country. At this time we could only serve our nearby customers. But currently we are able to provide services all over India through our aggregation platform And that too through the leading courier companies of our country.</p>
      </div>
      <div className='right'>
        <div className='top'>
          <img src={process.env.PUBLIC_URL + 'favicon.ico'} alt='edaylivery' />
        </div>
        <div className='bottom'>
          <img src={process.env.PUBLIC_URL + 'about.jpg'} alt='about' />
        </div>
      </div>
    </div>
  )
}

export default About