// import jsPDF from "jspdf";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const Print = ({ children, label }) => {
  const ref = useRef();
  const printer = useReactToPrint({
    documentTitle: `doc-${Math.random()*100000000}`,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  const handleDownload = () => {
    printer(null, () => ref.current);
  }
  return (
    <>
      <div style={{ display: "none" }}>
        <div ref={ref}>
          {children}
        </div>
      </div>
      <button type="submit" onClick={handleDownload}>
        {label}
      </button>
    </>
  )
}

export default Print