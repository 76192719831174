import React, { useEffect, useState } from 'react'
import { useGetSingleOrderQuery } from '../../store'


const TaxInvoice = ({ id }) => {
    const { data: order } = useGetSingleOrderQuery(id);
    const [content, setContent] = useState();
    useEffect(() => {
        if(order){
            setContent(
                <div className='single'>
                    <div className='heading'>
                        <h1>tax invoice</h1>
                    </div>
                    <div className='details'>
                        <div className='left'>
                            <div className='heading'>
                                <h1>Order Details</h1>
                            </div>
                            <div>ID: {order.orderId}</div>
                            <div>Tracking ID: {order.awb_number}</div>
                        </div>
                        <div className='right'>
                            <div className='heading'>
                                <h1>Invoice Details</h1>
                            </div>
                            <div>ID: INV{new Date().getMilliseconds()}</div>
                            <div>Date: {new Date().toDateString()}</div>
                        </div>
                    </div>
                    <div className='details'>
                        <div className='left'>
                            <div className='heading'>
                                <h1>Sold By:</h1>
                            </div>
                            <div>Name: Edaylivery</div>
                            <div>Address: 6/18 Netaji Nagar Opposite Kalyani Bhaban Near Dr PK Ganguly Home Kolkata-700040</div>
                            <div>Phone: 9038176999</div>
                        </div>
                        <div className='right'>
                            <div className='heading'>
                                <h1>Billed to</h1>
                            </div>
                            <div>Name: {order.consigner_details.consigner_name}</div>
                            <div>Address:{order.consigner_details.consigner_address}</div>
                            <div>Phone:{order.consigner_details.consigner_phone}</div>
                        </div>
                    </div>
                    <div className='orders'>
                        <div className='order-table'>
                            <div className='heading'>
                                <h1>Product Details</h1>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#000" }}>Product</th>
                                        <th style={{ backgroundColor: "#000" }}>Quantity</th>
                                        <th style={{ backgroundColor: "#000" }}>Price</th>
                                        <th style={{ backgroundColor: "#000" }}>Tax</th>
                                        <th style={{ backgroundColor: "#000" }}>HSN Code</th>
                                        <th style={{ backgroundColor: "#000" }}>SKU</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order.product_details.map((p) => {
                                            return (
                                                <tr>
                                                    <td>{p.product_name || "-"}</td>
                                                    <td>{p.product_qty || "-"}</td>
                                                    <td>{p.product_price || "-"}</td>
                                                    <td>{p.product_tax_per || "-"}</td>
                                                    <td>{p.product_sku || "-"}</td>
                                                    <td>{p.product_hsn || "-"}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='order-table'>
                            <div className='heading'>
                                <h1>Product Box Details</h1>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#000" }}>Weight</th>
                                        <th style={{ backgroundColor: "#000" }}>Height</th>
                                        <th style={{ backgroundColor: "#000" }}>Breadth</th>
                                        <th style={{ backgroundColor: "#000" }}>Lenght</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{order.product_box_details.weight}</td>
                                        <td>{order.product_box_details.height}</td>
                                        <td>{order.product_box_details.breadth}</td>
                                        <td>{order.product_box_details.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='order-table'>
                            <div className='heading'>
                                <h1>Shipping Charges</h1>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#000" }}>Base Amount</th>
                                        <th style={{ backgroundColor: "#000" }}>SGST @ 9%</th>
                                        <th style={{ backgroundColor: "#000" }}>CGST @ 9%</th>
                                        <th style={{ backgroundColor: "#000" }}>Total Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{Math.ceil(parseInt(order.shipping_charges)/1.18)}</td>
                                        <td>{Math.floor(((parseInt(order.shipping_charges)/1.18)*0.09))}</td>
                                        <td>{Math.floor(((parseInt(order.shipping_charges)/1.18)*0.09))}</td>
                                        <td>{Math.ceil(parseInt(order.shipping_charges))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }, [order]);
  return (
    content
  )
}

export default TaxInvoice