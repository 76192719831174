import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const WareHouseApi = createApi({
    reducerPath: "WareHouse",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/warehouse"
    }),
    endpoints(builders){
        return {
            getWareHouse: builders.query({
                providesTags: (res, err, id) => {
                    return [{ type: "wareHouse" }];
                },
                query: (id) => {
                    return {
                        url: `/${id}`,
                        method: "GET"
                    }
                }
            }),
            createWareHouse: builders.mutation({
                query: ({data, jwt}) => {
                    return {
                        url: "/secure/",
                        headers: {
                            Authorization: `Bearer ${jwt}`
                        },
                        method: "POST",
                        data
                    }
                }
            }),
            updateWareHouse: builders.mutation({
                invalidatesTags: (res, err, data) => {
                    return [{ type: "wareHouse" }]
                },
                query: ({data, jwt}) => {
                    return {
                        url: "/secure/",
                        headers: {
                            Authorization: `Bearer ${jwt}`
                        },
                        method: "PUT",
                        data
                    }
                }
            })
        }
    }
});

export const { useCreateWareHouseMutation, useGetWareHouseQuery, useUpdateWareHouseMutation } = WareHouseApi;
export { WareHouseApi };