import React from 'react'
import Button from '../common/Button'

const ProductBoxTable = ({ results, remove }) => {
  const outRes = results.map((res) => {
    return <tr>
        <td>
            {res.name}
        </td>
        <td>
            {res.length}x{res.breadth}x{res.height}
        </td>
        <td>
            hsn: {res.hsn}<br />
            sku: {res.sku}
        </td>
        <td>
            {res.boxes}
        </td>
        <td>
            <Button onClick={() => remove(res.id)}>
              remove
            </Button>
        </td>
    </tr>
})
return (
<div className='signup'>
    <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Box Dim.</th>
                <th>UID</th>
                <th>Boxes</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>{outRes}</tbody>
    </table>
</div>
)
}

export default ProductBoxTable