import React, { useEffect, useState } from 'react'
import { useGetSingleOrderQuery } from '../../store/index';
import PrintBarcode from './Barcode';

const Label = ({ id }) => {
    const { data: order } = useGetSingleOrderQuery(id);
    const [content, setContent] = useState();
    
    useEffect(() => {
        if(order){
            const date = new Date(order.createdAt);
            const str = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
            setContent(
                <div className='label'>
                    <div className='details'>
                        <div className='left'>
                            <div>To:</div>
                            <div>{order.consignee_details.consignee_name}</div>
                            <div>{order.consignee_details.consignee_address}</div>
                            <div>{order.consignee_details.consignee_city} {order.consignee_details.consignee_state} {order.consignee_details.consignee_pincode}</div>
                            <div>{order.consignee_details.consignee_phone}</div>
                        </div>
                        <div className='right'>
                            <img src={window.location.origin + `/favicon.ico`} alt='edaylivery' />
                        </div>
                    </div>
                    <div className='pay-details'>
                        <div>{order.payment_status === "paid" ? "PAID" : "UNPAID"}</div>
                        <div>SD</div>
                    </div>
                    <div className='details'>
                        <div className='left'>
                            <div>Order ID: {order.orderId}</div>
                            <div>Order Date: {str}</div>
                        </div>
                        <div className='right'>
                            <div>
                                <PrintBarcode val={order.orderId} />
                            </div>
                        </div>
                    </div>
                    <div className='details'>
                        <div className='left'>
                            <div>Courier Name: {order.courier_id === "1234543" ? "Delhivary": "XpressBees"} {order.courier_id === 14435 ? "B2C Air": "B2C Surface"}</div>
                        </div>
                        <div className='right'>
                            <div>
                                <PrintBarcode val={order.awb_number} />
                            </div>
                        </div>
                    </div>
                    <div className='orders'>
                        <div className='order-table'>
                            <div className='heading'>
                                <h1>Product Details</h1>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#000" }}>SKU</th>
                                        <th style={{ backgroundColor: "#000" }}>Name</th>
                                        <th style={{ backgroundColor: "#000" }}>Quantity</th>
                                        <th style={{ backgroundColor: "#000" }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order.product_details.map((p) => {
                                            return (
                                                <tr>
                                                    <td>{p.product_sku || "-"}</td>
                                                    <td>{p.product_name || "-"}</td>
                                                    <td>{p.product_qty || "-"}</td>
                                                    <td>{p.product_price || "-"}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td>-</td>
                                        <td>Order Total</td>
                                        <td>-</td>
                                        <td>{order.order_total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='details' style={{ borderBottom: "none" }}>
                        <div className='left'>
                            <div>From:</div>
                            <div>{order.consigner_details.consigner_name}</div>
                            <div>{order.consigner_details.consigner_address}</div>
                            <div>{order.consigner_details.consigner_city} {order.consigner_details.consigner_state} {order.consigner_details.consigner_pincode}</div>
                            <div>{order.consigner_details.consigner_phone}</div>
                        </div>
                        <div className='right'>
                            <div>Warehouse & RTO Details:</div>
                            <div>{order.consigner_details.consigner_name}</div>
                            <div>{order.consigner_details.consigner_address}</div>
                            <div>{order.consigner_details.consigner_city} {order.consigner_details.consigner_state} {order.consigner_details.consigner_pincode}</div>
                            <div>{order.consigner_details.consigner_phone}</div>
                        </div>
                    </div>
                </div>
            );
        }
    }, [order]);

    return content
}

export default Label