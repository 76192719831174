import React, { useState, useEffect } from 'react'
import Button from '../components/common/Button';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { lookup } from 'india-pincode-lookup';
import { useCreateB2BOrdersMutation, useGetAllProfitsQuery, useGetWareHouseQuery, useUpdateUserMutation } from '../store/index';
import CalculatedOrderResults from '../components/orders/CalculateOrderResults';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from '../helpers/cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { b2b_data } from '../helpers/b2b_data';
import { codDelivery, pickup, prepaidDelivery } from '../helpers/Pincodes';
import ProductBoxForms from '../components/orders/ProductBoxForms';
import ProductBoxTable from '../components/orders/ProductBoxTable';

const CreateB2BCustomerOrder = ({ setProgress }) => {
    const role = getCookie("delivaryUserRole");
    const username = getCookie("delivaryUserName");
    const email = getCookie("delivaryUserEmail");
    const phone = getCookie("delivaryUserPhone");
    const jwt = getCookie("delivaryUserJWT");
    const wallet = getCookie("delivaryUserWallet");
    const no_of_orders = getCookie("delivaryUserOrders");
    const id = getCookie("delivaryUserId");
    const { data: profitRates } = useGetAllProfitsQuery();
    const [createOrder, creationResults] = useCreateB2BOrdersMutation();
    const [updateUser, updationResults] = useUpdateUserMutation();
    const { data: wareHouse, error: FetchError } = useGetWareHouseQuery(id);
    const [parent, enable] = useAutoAnimate({duration: 350});
    const [consigneeError, setConsigneeError] = useState();
    const [consignerError, setConsignerError] = useState();
    const [consigneeName, setConsigneeName] = useState();
    const [consigneePhone, setConsigneePhone] = useState();
    const [consigneePinCode, setConsigneePinCode] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneeState, setConsigneeState] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneeGSTNumber, setConsigneeGSTNumber] = useState("");
    const [consignerName, setConsignerName] = useState();
    const [consignerPhone, setConsignerPhone] = useState();
    const [consignerPinCode, setConsignerPinCode] = useState();
    const [consignerCity, setConsignerCity] = useState();
    const [consignerState, setConsignerState] = useState();
    const [consignerAddress, setConsignerAddress] = useState();
    const [consignerGSTNumber, setConsignerGSTNumber] = useState("");
    const [productWeight, setProductWeight] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const [productBoxes, setProductBoxes] = useState();
    const [productTax, setProductTax] = useState(0);
    const [calculations, setCalculations] = useState();
    const [courier_id, setCourierId] = useState();
    const [invoice_number, setInvoiceNumber] = useState("");
    const [invoice_date, setInvoiceDate] = useState("");
    const [ebill_number, setEbillNumber] = useState("");
    const [ebill_expiry_date, setEbillExpiryDate] = useState("");
    const [pickup_location, setPickupLocation] = useState("franchise");
    const [order_total, setOrderTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [collectable_amount, setCollectableAmount] = useState(0);
    const [cod, setCod] = useState("prepaid");
    const navigate = useNavigate();
    const [productBoxDetails, setProductBoxDetails] = useState([]);

    const handleAddBox = (data) => {
        data = {
            id: Math.random(),
            ...data
        };
        setProductBoxDetails([...productBoxDetails, data]);
    };
    const handleRemoveBox = (id) => {
        const data = productBoxDetails.filter((box) => {
            return box.id !== id;
        });
        setProductBoxDetails(data);
    }

    useEffect(() => {
      enable(true);
  }, [parent, enable]);

  useEffect(() => {
      setOrderTotal(parseInt(productPrice));
      setCollectableAmount(cod === "COD" ? Math.ceil(order_total - discount) : 0);
  }, [productPrice, discount, order_total, cod]);    

  useEffect(() => {
    if(role !== "admin"){
        if(username){
            setConsignerName(username);
        }
        if(phone){
            setConsignerPhone(phone);
        }
    }
    if(!FetchError && wareHouse){
        setConsignerAddress(wareHouse.address);
        setConsignerCity(wareHouse.city);
        setConsignerPinCode(wareHouse.pincode);
        setConsignerState(wareHouse.state);
    }
}, [role, username, email, phone, FetchError, wareHouse]);

  useEffect(() => {
      if(lookup(consigneePinCode).length === 0){
          setConsigneeError("consignee address does not exists!");
      }
      else{
          setConsigneeError();
          if(consigneePinCode){
              if(consigneePinCode.length === 6){
                  const data = lookup(consigneePinCode)[0];
                  setConsigneeCity(data.taluk);
                  setConsigneeState(data.stateName);
              }
          }
      }
      if(lookup(consignerPinCode).length === 0){
          setConsignerError("consigner address does not exists!");
      }
      else{
          setConsignerError();
          if(consignerPinCode){
              if(consignerPinCode.length === 6){
                  const data = lookup(consignerPinCode)[0];
                  setConsignerCity(data.taluk);
                  setConsignerState(data.stateName);
              }
          }
      }
  }, [consigneePinCode, consignerPinCode]);

  const handleCalculate = async () => {
    if(!consigneeName || !consigneePhone || !consigneePinCode || !consigneeCity || !consigneeState || !consigneeAddress){
        alert("All fields in Consignee Details are mandatory");
    }
    else if(!consignerName || !consignerPhone || !consignerPinCode || !consignerCity || !consignerState || !consignerAddress){
        alert("All fields in Consigner Details are mandatory");
    }
    else if(!productPrice || !productWeight || !productBoxes) {
        alert("All fields in Product Details are mandatory");
    }
    else if(consigneeError || consignerError) {
        alert("Invalid pincodes!!");
    }
    else {
      const convertedRates = [];
        const from_addr = lookup(parseInt(consigneePinCode))[0].stateName.toLowerCase();
        const to_addr = lookup(parseInt(consignerPinCode))[0].stateName.toLowerCase();
        const keyName = from_addr + "_" + to_addr;
        const base = b2b_data[keyName];
        let shipping_mul = 1;
        if(profitRates){
            const [rates] = profitRates.filter((r) => {
                return r.provider === 'XpressBees' && r.buisness === "B2B";
            });
            if(rates){
                shipping_mul = 1 + (rates.shipping_rate/100);
            }
        }
        const rate_per_kg = parseFloat((base*shipping_mul).toFixed(2));
        const weight_charges = parseFloat(((productWeight/1000)*rate_per_kg).toFixed(2));
        const fuel_charges = parseFloat((weight_charges*0.10).toFixed(2));
        const docket_charges = 100;
        const open_pickup_delivary = 0;
        const fov_charges = 100;
        const fod_charges = 0;
        let green_charges = 0;
        if(to_addr === "delhi" || from_addr === "delhi"){
            const weight_charge = parseFloat((productWeight/1000)*0.5).toFixed(2);
            green_charges = 100 > weight_charge ? 100 : weight_charge;
        }
        const appointment_delivary_charges_per_docket = 0;
        const handling_of_shipment = 0.00;
        const gst_percentage = 18;
        const base_freight = weight_charges + fuel_charges + docket_charges + open_pickup_delivary + fov_charges + fod_charges + green_charges + appointment_delivary_charges_per_docket + handling_of_shipment;
        const gst = parseFloat((base_freight*0.18).toFixed(2));
        const oda_charges = !(pickup.includes(parseInt(consigneePinCode)) || prepaidDelivery.includes(parseInt(consignerPinCode)) || codDelivery.includes(parseInt(consignerPinCode))) ? ((1500 > productWeight *0.5) ? 1500 : productWeight * 0.5) : 0;
        const total_charges_including_gst = parseFloat((base_freight + gst + oda_charges).toFixed(2));
        const bill = <td>
            Rate Per Kg: {rate_per_kg},
            Fuel Charges: {fuel_charges},
            Docket Charges: {docket_charges},
            Weight Charges: {weight_charges},
            Open Pickup Delivery: {open_pickup_delivary},
            FOV Charges: {fov_charges},
            FOD Charges: {fod_charges},
            Green Charges: {green_charges},
            Appointment Delivary Charges Per Docket: {appointment_delivary_charges_per_docket},
            Handling of Shipment: {handling_of_shipment},
            Gst Percentage: {gst_percentage},
            Base Freight: {base_freight.toFixed(2)},
            Gst Charges: {gst},
            ODA Charges: {oda_charges},
            Total Charges (Including GST): {total_charges_including_gst.toFixed(2)}
        </td>;
        const act_base_freight = (productWeight/1000)*base + parseFloat(((productWeight/1000)*base*0.10).toFixed(2)) + docket_charges + open_pickup_delivary + fov_charges + fod_charges + green_charges + appointment_delivary_charges_per_docket + handling_of_shipment;
        const act_gst = act_base_freight * 0.18;
        const act_charge = act_base_freight + act_gst + oda_charges;
        const profit = parseFloat((total_charges_including_gst - act_charge).toFixed(2));
        const data = {
          courier_id: 12345,
          actCourierCharge: act_charge,
          courier_charges: bill,
          cod_charges: 0,
          total_price: total_charges_including_gst,
          profit,
          name: "Edaylivery B2B",
          coupon_id: ""
        }
        convertedRates.push(data);
        setCalculations(convertedRates);
    }
}
const handleRequest = async () => {
    let invodate;
    try {
        invodate = new Date(invoice_date);                
    }
    catch(err) {
        alert("invalid invoice date");
    }
    if(invodate) {
        const [finalCourier] = calculations.filter((c) => {
            return c.courier_id === courier_id;
        });
        console.log(wallet, finalCourier.total_price);
        if(wallet < finalCourier.total_price){
            alert("Wallet balence is low!! Please Recharge");
        }
        else{
            const id = `EDL${Math.floor(Math.random() *100000)}`;
            const order_data = { 
                orderId: id,
                courier_id,
                consignee_details: {
                    consignee_name: consigneeName,
                    consignee_phone: consigneePhone,
                    consignee_pincode: consigneePinCode,
                    consignee_city: consigneeCity,
                    consignee_state: consigneeState,
                    consignee_address: consigneeAddress,
                    consignee_gst_number: consigneeGSTNumber,
                },
                consigner_details: {
                    consigner_name: consignerName,
                    consigner_phone: consignerPhone,
                    consigner_pincode: consignerPinCode,
                    consigner_city: consignerCity,
                    consigner_state: consignerState,
                    consigner_address: consignerAddress,
                    consigner_gst_number: consignerGSTNumber,
                },
                product_details: [
                    {
                        product_boxes: productBoxes,
                        product_price: productPrice,
                        product_tax_per: productTax,
                        product_weight: productWeight
                    }
                ],
                order_total,
                discount,
                collectable_amount,
                shipping_charges: finalCourier.total_price,
                cod_charges: finalCourier.cod_charges,
                profit: finalCourier.profit,
                payment_status: cod === "COD" ? "pending": "paid",
                payment_method: cod,
                pickup_location,
                product_box_details: productBoxDetails,
                order_status: "requested",
                invoice_details: [
                    {
                        invoice_number,
                        invoice_date: `${invodate.getFullYear()}-${invodate.getMonth()}-${invodate.getDate()}`,
                        ebill_number,
                        ebill_expiry_date
                    }
                ]
            };
            console.log(order_data);
            setProgress(33);
            await createOrder({ data: order_data, jwt }).unwrap().then(async (res) => {
                console.log(res);
                setProgress(66);
                const update_data = {
                    wallet: parseInt(wallet) - parseInt(finalCourier.total_price),
                    no_of_orders: parseInt(no_of_orders) + 1
                };
                await updateUser({ data: update_data, jwt }).unwrap().then((res) => {
                    setProgress(100);
                    console.log("user_updated:",res);
                    setCookie("delivaryUserWallet", update_data.wallet);
                    setCookie("delivaryUserOrders", update_data.no_of_orders);
                    navigate("/customer/orders/b2b");
                })
            }).catch(err => {alert(JSON.stringify(err.data.message)); setProgress(100);});
        }
    }
}

  return (
    <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Admin Create B2B Order</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='heading'>
                <h1>Create Order</h1>
            </div>
            <div className='create-order'>
                <div className='signup'>
                    <div className='heading'>
                        <h1>Consigner address</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='text'
                            name='username'
                            value={consignerName}
                            onChange={(e) => setConsignerName(e.target.value)}
                            placeholder='name'
                        />
                        <input
                            type='number'
                            name='phone'
                            value={consignerPhone}
                            onChange={(e) => setConsignerPhone(e.target.value)}
                            placeholder='phone'
                        />
                        <input
                            type='text'
                            name='pincode'
                            value={consignerPinCode}
                            onChange={(e) => setConsignerPinCode(e.target.value)}
                            placeholder='pincode'
                        />
                        <input
                            type='text'
                            name='city'
                            value={consignerCity}
                            onChange={(e) => setConsignerCity(e.target.value)}
                            placeholder='city'
                        />
                        <input
                            type='text'
                            name='state'
                            value={consignerState}
                            onChange={(e) => setConsignerState(e.target.value)}
                            placeholder='state'
                        />
                        <input
                            type='text'
                            name='address'
                            value={consignerAddress}
                            onChange={(e) => setConsignerAddress(e.target.value)}
                            placeholder='address'
                        />
                        <input
                            type='text'
                            name='gst_number'
                            value={consignerGSTNumber}
                            onChange={(e) => setConsignerGSTNumber(e.target.value)}
                            placeholder='gst_number'
                        />
                    </div>
                    {consignerError && (
                        <div className='error'>
                            {consignerError}
                        </div>
                    )}
                </div>
                <div className='signup'>
                    <div className='heading'>
                        <h1>Consignee address</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='text'
                            name='username'
                            value={consigneeName}
                            onChange={(e) => setConsigneeName(e.target.value)}
                            placeholder='name'
                        />
                        <input
                            type='number'
                            name='phone'
                            value={consigneePhone}
                            onChange={(e) => setConsigneePhone(e.target.value)}
                            placeholder='phone'
                        />
                        <input
                            type='text'
                            name='pincode'
                            value={consigneePinCode}
                            onChange={(e) => setConsigneePinCode(e.target.value)}
                            placeholder='pincode'
                        />
                        <input
                            type='text'
                            name='city'
                            value={consigneeCity}
                            onChange={(e) => setConsigneeCity(e.target.value)}
                            placeholder='city'
                        />
                        <input
                            type='text'
                            name='state'
                            value={consigneeState}
                            onChange={(e) => setConsigneeState(e.target.value)}
                            placeholder='state'
                        />
                        <input
                            type='text'
                            name='address'
                            value={consigneeAddress}
                            onChange={(e) => setConsigneeAddress(e.target.value)}
                            placeholder='address'
                        />
                        <input
                            type='text'
                            name='gst_number'
                            value={consigneeGSTNumber}
                            onChange={(e) => setConsigneeGSTNumber(e.target.value)}
                            placeholder='gst_number'
                        />
                    </div>
                    {consigneeError && (
                        <div className='error'>
                            {consigneeError}
                        </div>
                    )}
                </div>
                <div className='signup'>
                    <div className='heading'>
                        <h1>Product Details</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='number'
                            name='boxes'
                            value={productBoxes}
                            onChange={(e) => setProductBoxes(e.target.value)}
                            placeholder='no. of boxes'
                        />
                        <input
                            type='number'
                            name='price'
                            value={productPrice}
                            onChange={(e) => setProductPrice(e.target.value)}
                            placeholder='price'
                        />
                        <input
                            type='number'
                            name='tax'
                            value={productTax}
                            onChange={(e) => setProductTax(e.target.value)}
                            placeholder='tax'
                        />
                        <input
                            type='number'
                            name='weight'
                            value={productWeight}
                            onChange={(e) => setProductWeight(e.target.value)}
                            placeholder='Weight(gms)'
                        />
                        <select value={cod} onChange={(e) => setCod(e.target.value)} >
                            <option value="prepaid">PrePaid</option>
                            <option value="COD">COD</option>
                        </select>
                    </div>
                    <div className='buttons'>
                        <Button onClick={handleCalculate}>
                            Calculate
                        </Button>
                    </div>
                </div>
                {calculations && <CalculatedOrderResults results={calculations} setCourier={setCourierId} courier_id={courier_id} />}
                {courier_id && <ProductBoxForms add={handleAddBox} />}
                {productBoxDetails.length !== 0 && <ProductBoxTable results={productBoxDetails} remove={handleRemoveBox} />}
                {productBoxDetails.length !== 0 && (
                    <>
                        <div className='signup'>
                            <div className='heading'>
                                <h1>Invoice Details</h1>
                            </div>
                            <div className='inputs'>
                                <input
                                    type='text'
                                    name='invoice number'
                                    value={invoice_number}
                                    onChange={(e) => setInvoiceNumber(e.target.value)}
                                    placeholder='invoice number'
                                />
                                <DatePicker
                                    selected={invoice_date}
                                    onChange={(date) => setInvoiceDate(date)}
                                    placeholderText='invoice date'
                                />
                                <input
                                    type='text'
                                    name='ebill_number'
                                    value={ebill_number}
                                    onChange={(e) => setEbillNumber(e.target.value)}
                                    placeholder='ebill_number'
                                />
                                <DatePicker
                                    selected={ebill_expiry_date}
                                    onChange={(date) => setEbillExpiryDate(date)}
                                    placeholderText='ebill expiry date'
                                />
                            </div>
                        </div>
                        <div className='signup'>
                            <div className='heading'>
                                <h1>Other Details</h1>
                            </div>
                            <div className='inputs'>
                                <select value={pickup_location} onChange={(e) => setPickupLocation(e.target.value)} >
                                    <option value="franchise">Franchise</option>
                                    <option value="customer">Customer</option>
                                </select>
                                <input
                                    type='text'
                                    name='order total'
                                    value={order_total}
                                    onChange={(e) => setOrderTotal(e.target.value)}
                                    placeholder='order total'
                                />
                                <input
                                    type='text'
                                    name='discount'
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    placeholder='discount(%)'
                                />
                                <input
                                    type='text'
                                    name='collectable amount'
                                    value={collectable_amount}
                                    onChange={(e) => setCollectableAmount(e.target.value)}
                                    placeholder='collectable amount'
                                />
                            </div>
                            <div className='buttons'>
                                <Button loading={creationResults.isLoading || updationResults.isLoading} onClick={handleRequest}>
                                    Request Verification
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default CreateB2BCustomerOrder