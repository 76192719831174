import React, { useState } from 'react'
import Button from '../common/Button.js';
import { useLoginMutation } from '../../store/index.js';
import { setCookie } from '../../helpers/cookies.js';
import { NavLink, useNavigate } from 'react-router-dom';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import { Helmet } from 'react-helmet-async';

const Login = ({ setProgress }) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [LoginUser, LoginResults] = useLoginMutation();
    const [error, setError] = useState();

    const handleLogin = async () => {
        setProgress(50);
        await LoginUser({
            name,
            password
        }).unwrap().then((res) => {
            setCookie("delivaryUserJWT", res.jwt, 7);
            setCookie("delivaryUserId", res.id, 7);
            setCookie("delivaryUserName", res.username, 7);
            setCookie("delivaryUserEmail", res.email, 7);
            setCookie("delivaryUserRole", res.role, 7);
            setCookie("delivaryUserPhone", res.phone, 7);
            setCookie("delivaryUserWallet", res.wallet, 7);
            setCookie("delivaryUserOrders", res.no_of_orders, 7);
            setProgress(100);
            navigate("/");
        }).catch((err) => {
            try{
                setError(err.data.message);
            }
            catch{
                setError("Request Timed Out! try again");
            }
            setProgress(100);
        });
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Log In</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='login'>
                <div className='heading'>
                    <h1>Log In</h1>
                </div>
                <div className='inputs'>
                    <input
                        type='text'
                        name='name'
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        placeholder='email or username or phone no.'
                    />
                    <div className='cont'>
                        <input
                            type={showPass ? 'text': 'password'}
                            name='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            placeholder='password'
                        />
                        <div onClick={() => setShowPass(!showPass)}>
                        {
                            showPass ? <GoEye /> : <GoEyeClosed />
                        }
                        </div>
                    </div>
                </div>
                <div className='forgot'>
                    <NavLink to="/forgot">
                        Forgot Password?
                    </NavLink>
                </div>
                {error && (
                    <div className='error'>
                        {error}
                    </div>
                )}
                <div className='buttons'>
                    <Button loading={LoginResults.isLoading} onClick={handleLogin}>
                        Login
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Login;