import React, { useEffect, useState } from 'react'
import Button from '../components/common/Button.js';
import { useUpdateUserMutation } from '../store/index.js';
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { delCookie, getCookie, setCookie } from '../helpers/cookies.js';
import WareHouse from '../components/customers/WareHouse.js';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Reviews from '../components/customers/Reviews.js';

const Profile = ({ setProgress }) => {
    const _username = getCookie("delivaryUserName");
    const _email = getCookie("delivaryUserEmail");
    const _phone = getCookie("delivaryUserPhone");
    const jwt = getCookie("delivaryUserJWT");
    const role = getCookie("delivaryUserRole");
    const [updateUser, updationResults] = useUpdateUserMutation();
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const [email, setEmail] = useState(_email);
    const [username, setUsername] = useState(_username);
    const [phone, setPhone] = useState(_phone);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        enable(true);
    }, [parent, enable]);

    
    const handleLogOut = () => {
        delCookie("delivaryUserJWT");
        navigate("/auth/login");
    }

    const handleUpdate = async () => {
        const data = {
            username,
            email,
        };
        setProgress(50);
        await updateUser({ data, jwt }).unwrap().then((res) => {
            setCookie("delivaryUserName", res.username, 7);
            setCookie("delivaryUserEmail", res.email, 7);
            setProgress(100);
        }).catch((err) => {
            try{
                setError(err.data.message);
            }
            catch{
                setError("Request Timed Out! try again");
            }
            setProgress(100);
        });
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - User Profile</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='hold-container'>
                <div className='signup' ref={parent}>
                    <div className='heading'>
                        <h1>Profile</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='text'
                            name='email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            placeholder='email'
                        />
                        <input
                            type='text'
                            name='username'
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                            placeholder='username'
                        />
                        <input
                            type='number'
                            name='phone'
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                            placeholder='phone'
                        />
                    </div>
                    {error && (
                        <div className='error'>
                            {error}
                        </div>
                    )}
                    <div className='buttons'>
                        <Button loading={updationResults.isLoading} onClick={handleUpdate}>
                            Update
                        </Button>
                        <Button onClick={handleLogOut}>
                            Log Out
                        </Button>
                    </div>
                </div>
                {role === "customer" && (
                    <>
                        <WareHouse setProgress={setProgress} />
                        <Reviews setProgress={setProgress} />
                    </>
                )}
            </div>
        </>
    )
}

export default Profile