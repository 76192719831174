import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { VerifyEmailApi } from "./apis/VerifyEmailApi";
import { UsersApi } from "./apis/UsersApi";
import { WareHouseApi } from "./apis/WareHouseApi";
import { Xpressbees } from "./apis/XpressBeesApi";
import { PaymentApi } from "./apis/PaymentApi";
import { TransactionApi } from "./apis/TransactionsApi";
import { OrdersApi } from "./apis/OrdersApi";
import { ProfitsApi } from "./apis/ProfitRatesApi";
import { EscalationsApi } from "./apis/EscalationsApi";
import { CouponsApi } from "./apis/CouponsApi";
import { ReviewsApi } from "./apis/ReviewsApi";
import { B2BOrdersApi } from "./apis/B2BOrdersApi";
import { DelhivaryApi } from "./apis/DelhivaryApis";

const store = configureStore({
    reducer: {
        [VerifyEmailApi.reducerPath] : VerifyEmailApi.reducer,
        [UsersApi.reducerPath] : UsersApi.reducer,
        [WareHouseApi.reducerPath] : WareHouseApi.reducer,
        [Xpressbees.reducerPath] : Xpressbees.reducer,
        [PaymentApi.reducerPath] : PaymentApi.reducer,
        [TransactionApi.reducerPath] : TransactionApi.reducer,
        [OrdersApi.reducerPath] : OrdersApi.reducer,
        [B2BOrdersApi.reducerPath] : B2BOrdersApi.reducer,
        [ProfitsApi.reducerPath] : ProfitsApi.reducer,
        [EscalationsApi.reducerPath] : EscalationsApi.reducer,
        [CouponsApi.reducerPath] : CouponsApi.reducer,
        [ReviewsApi.reducerPath] : ReviewsApi.reducer,
        [DelhivaryApi.reducerPath] : DelhivaryApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
        .concat(VerifyEmailApi.middleware)
        .concat(UsersApi.middleware)
        .concat(WareHouseApi.middleware)
        .concat(Xpressbees.middleware)
        .concat(TransactionApi.middleware)
        .concat(OrdersApi.middleware)
        .concat(B2BOrdersApi.middleware)
        .concat(ProfitsApi.middleware)
        .concat(EscalationsApi.middleware)
        .concat(CouponsApi.middleware)
        .concat(ReviewsApi.middleware)
        .concat(PaymentApi.middleware)
        .concat(DelhivaryApi.middleware);
    }
});

setupListeners(store.dispatch);

export { store };
export { useVerifyEmailMutation } from "./apis/VerifyEmailApi";
export { useLoginMutation, useRegisterMutation, useGetCurrUserQuery, useUpdatePasswordMutation, useGetCustomersQuery, useUpdateUserMutation } from "./apis/UsersApi";
export { useCreateWareHouseMutation, useGetWareHouseQuery, useUpdateWareHouseMutation } from "./apis/WareHouseApi";
export { useCalculatePriceMutation, useGenerateTokenMutation, useCreateShipmentMutation, useTrackShipmentMutation, usePickUpShipmentMutation, useCancelShipmentMutation, useCreateNDRMutation, useGetNDRMutation } from "./apis/XpressBeesApi";
export { useCreatePaymentMutation, useValidatePaymentMutation } from "./apis/PaymentApi";
export { useCreateTransactionMutation, useGetAllTransactionsQuery, useGetCustomerTransactionsQuery, useGetSingleTransactionsQuery, useDeleteTransactionsQuery } from "./apis/TransactionsApi";
export { useCreateOrdersMutation, useGetAllOrdersQuery, useGetCustomerOrdersQuery, useGetSingleOrderQuery, useUpdateSingleOrderMutation, useCancelSingleOrderMutation } from "./apis/OrdersApi";
export { useCreateB2BOrdersMutation, useGetAllB2BOrdersQuery, useGetCustomerB2BOrdersQuery, useGetSingleB2BOrderQuery, useUpdateSingleB2BOrderMutation, useCancelSingleB2BOrderMutation } from "./apis/B2BOrdersApi";
export { useCreateProfitsMutation, useGetAllProfitsQuery, useGetSingleProfitQuery, useUpdateSingleProfitMutation, useDeleteSingleProfitMutation } from "./apis/ProfitRatesApi";
export { useCreateEscalationsMutation, useGetAllEscalationsQuery, useGetCustomerEscalationsQuery, useGetSingleEscalationQuery, useUpdateSingleEscalationMutation, useCancelSingleEscalationMutation } from "./apis/EscalationsApi";
export { useCreateCouponsMutation, useGetAllCouponsQuery, useGetCustomerCouponsQuery, useGetSingleCouponMutation, useUpdateSingleCouponMutation, useCancelSingleCouponMutation } from "./apis/CouponsApi"
export { useCreateReviewsMutation, useGetAllReviewsQuery, useGetCustomerReviewsQuery, useUpdateSingleReviewMutation, useCancelSingleReviewMutation } from "./apis/ReviewsApi";
export { useCalculateDpriceMutation, useCreateDOrderMutation, useCreateDClientWareHouseMutation, useCancelDShipmentMutation, useTrackDShipmentMutation, useCreateDPickupOrderMutation } from "./apis/DelhivaryApis";