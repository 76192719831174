import React, { useEffect, useState } from 'react'
import { useGetCustomersQuery, useGetSingleCouponMutation, useUpdateSingleCouponMutation } from '../store/index'
import { getCookie } from '../helpers/cookies';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DatePicker from "react-datepicker";
import Button from '../components/common/Button';

const EditCoupons = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const { id } = useParams()
    const [updateCoupon, updateResults] = useUpdateSingleCouponMutation();
    const [getCoupon, getResults] = useGetSingleCouponMutation();
    const [coupon, setCoupon] = useState();
    const { data: users } = useGetCustomersQuery();
    const [customers, setCustomers] = useState();
    const [usage, setUsage] = useState(1);
    const [limit, setlimit] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [status, setStatus] = useState("active");
    const [expiry_date, setExpiry] = useState(new Date());
    const [description, setDescription] = useState();
    const [customer_id, setCustomerId] = useState();
    const [err, setErr] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if(users){
            setCustomers(users);
            setCustomerId(users[0].id);
        }
    }, [users]);

    useEffect(() => {
        const fetchCoupon = async () => {
            await getCoupon(id).unwrap().then((res) => {
                console.log(res)
                setCoupon(res)
            }).catch((err) => {
                console.log(err);
            });
        }
        fetchCoupon();
    }, [id]);

    useEffect(() => {
        if(coupon) {
            setCustomerId(coupon.customer_id);
            setDescription(coupon.description);
            setStatus(coupon.status);
            setUsage(coupon.usage);
            setlimit(coupon.limit);
            setDiscount(coupon.discount);
            setExpiry(new Date(coupon.expiry_date));
        }
    }, [coupon])
    
    const handleUpdate = async () => {
        if(customer_id === "" || usage === "" || usage === "0" || status === "" || expiry_date === "" || expiry_date === new Date() || description === ""){
            setErr("all fields are mandatory!!");
        }
        else {
            const data = {
                customer_id,
                usage,
                limit,
                status,
                expiry_date: expiry_date.toUTCString(),
                description,
                discount
            };
            setProgress(50);
            await updateCoupon({ data, jwt, id }).unwrap().then((res) => {
                setProgress(100);
                console.log(res);
                navigate("/admin/coupons");
            }).catch((err) => {
                setProgress(100);
                setErr(err.message);
                console.log(err);
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - New Coupon</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='signup'>
                <div className='heading'>
                    <h1>New Coupon</h1>
                </div>
                <div className='inputs'>
                    <select
                        name='customer id'
                        value={customer_id}
                        onChange={(e) => setCustomerId(e.target.value)}
                    >
                        {customers && customers.map((c) => {
                            return <option value={c.id}> {c.username}({c.email})</option>
                        })}
                    </select>
                    <input
                        type='number'
                        name='discount'
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        placeholder='discount'
                    />
                    <input
                        type='number'
                        name='limit'
                        value={limit}
                        onChange={(e) => setlimit(e.target.value)}
                        placeholder='limit (gm)'
                    />
                    <input
                        type='number'
                        name='usage'
                        value={usage}
                        onChange={(e) => setUsage(e.target.value)}
                        placeholder='usage'
                    />
                    <select
                        name='status'
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="active">Active</option>
                        <option value="inactive">InActive</option>
                    </select>
                    <DatePicker
                        selected={expiry_date}
                        onChange={(date) => setExpiry(date)}
                        placeholderText='expiry date'
                    />
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='description'
                    />
                </div>
                {err && (
                    <div className='error'>
                        {err}
                    </div>
                )}
                <div className='buttons'>
                    <Button loading={updateResults.isLoading} onClick={handleUpdate}>
                        Update
                    </Button>
                </div>
            </div>
        </>
    )
}

export default EditCoupons