import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetCustomersQuery } from '../store/index'
import CustomerWareHouse from '../components/customers/CustomerWareHouse';
import { exportToXL } from '../helpers/export';
import Button from '../components/common/Button';

const Customers = () => {
    const { data: customers, isFetching } = useGetCustomersQuery();
    const [content, setContent] = useState();
    const [noProd, setNoProd] = useState();

    useEffect(() => {
      if(customers){
        if(customers.length !== 0){
          setContent(customers.map((c) => {
            return  (
              <tr>
                <td>{c.id}</td>
                <td>{c.username}</td>
                <td>{c.email}</td>
                <td>{c.phone}</td>
                <td>{<CustomerWareHouse id={c.id} />}</td>
                <td>{c.wallet}</td>
                <td>{c.no_of_orders}</td>
              </tr>
            )
          }));
          setNoProd();
        }
        else {
          setContent();
          setNoProd("No Customers Found");
        }
      }
      else if(isFetching){
        setContent();
        setNoProd("Loading Customers Data...");
      }
      else{
        setContent();
        setNoProd("Error Loading! please reload the page...");
      }
    }, [customers, isFetching]);

    const handleExport = () => {
      if(customers){
        exportToXL(customers);
      }
    };

    return (
      <>
        <Helmet>
            <title>edaylivery - Every day Delivary, Ontime - Customers</title>
            <link rel='canonical' href={window.location.href} />
        </Helmet>
        <div className='orders'>
          <div className='top'>
            <input type='text' name='search' placeholder='search' />
          </div>
          <div className='order-table'>
            <div className='heading'>
              <h1>Customers</h1>
            </div>
            <div className='buttons'>
              <Button onClick={handleExport}>
                Export
              </Button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Wallet Bal.</th>
                  <th>No. of Orders</th>
                </tr>
              </thead>
              <tbody>{content}</tbody>
            </table>
            {noProd && (
              <div className='no-ord'>
                {noProd}
              </div>
            )}
          </div>
        </div>
      </>
    )
}

export default Customers