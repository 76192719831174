import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const OrdersApi = createApi({
    reducerPath: "Orders",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/orders"
    }),
    endpoints(builders){
        return {
            createOrders: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Orders" }, { type: "CustomerOrders" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            getAllOrders: builders.query({
                providesTags: (res, err, data) => [{ type: "Orders" }],
                query: () => {
                    return {
                        url: "/",
                        method: "GET"
                    }
                }
            }),
            getCustomerOrders: builders.query({
                providesTags: (res, err, data) => [{ type: "CustomerOrders" }],
                query: (id) => {
                    return {
                        url: `/customer/${id}`,
                        method: "GET"
                    }
                }
            }),
            getSingleOrder: builders.query({
                providesTags: (res, err, data) => [{ type: "Order", id: data }],
                query: (id) => {
                    return {
                        url: `/${id}`,
                        method: "GET"
                    }
                }
            }),
            updateSingleOrder:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Order", id: data.id }, { type: "CustomerOrders" }, { type: "Orders" }],
                query: ({ data, jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            cancelSingleOrder:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Order", id: data.id }, { type: "CustomerOrders" }, { type: "Orders" }],
                query: ({ jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                }
            })
        }
    }
});

export const { useCreateOrdersMutation, useGetAllOrdersQuery, useGetCustomerOrdersQuery, useGetSingleOrderQuery, useUpdateSingleOrderMutation, useCancelSingleOrderMutation } = OrdersApi;
export { OrdersApi };