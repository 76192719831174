import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { useAutoAnimate } from"@formkit/auto-animate/react";
import SideBar from './SideBar';


const CustomSideBar = () => {
    const [parent, enable] = useAutoAnimate({ duration: 350 });

    useEffect(() => {
        enable(true);
    }, [parent, enable]);
  return (
    <div className='container' ref={parent}>
        <div className='main-container' ref={parent}>
            <div className='output' ref={parent}>
                <Outlet />
            </div>
        </div>
        <SideBar />
    </div>
  )
}

export default CustomSideBar