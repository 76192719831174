import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { useAutoAnimate } from"@formkit/auto-animate/react";
import SideBar from '../common/SideBar';

const AdminSideBar = () => {
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const [OrderDis, setOrderDis] = useState(false);

    const handleDisplay = (e) => {
        e.preventDefault();
        setOrderDis(!OrderDis);
    }

    useEffect(() => {
        enable(true);
    }, [parent, enable]);

    return (
        <div className='container'>
            <div className='main-container'>
                <div className='menu-items'>
                    <div className='link'>
                        <NavLink to="/admin">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/dashboard.svg" alt="DashBoard" /> Dashboard
                        </NavLink>
                    </div>
                    <div className='link' ref={parent}>
                        <NavLink to="#" onClick={handleDisplay}>
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/orders.svg" alt="Orders" />  Orders
                        </NavLink>
                        <div className='sub-link' style={{ display: OrderDis ? "block" : "none"}}>
                            <NavLink to="/admin/orders/b2c">
                                B2C
                            </NavLink>
                        </div>
                        <div className='sub-link' style={{ display: OrderDis ? "block" : "none"}}>
                            <NavLink to="/admin/orders/b2b">
                                B2B
                            </NavLink>
                        </div>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/customers">
                            <img src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png" alt="Customers" /> Customers
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/transactions">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/process_ndr.svg" alt="Transactions" />Transactions
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/profit-rates">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/tools.svg" alt="Rates" /> Profit Rates
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/support">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/support.svg" alt="Support" /> Support
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/coupons">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/manage.svg" alt="Support" /> Coupons
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/admin/profile">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/buyer_experience.svg" alt="Profile" /> Profile
                        </NavLink>
                    </div>
                </div>
                <div className='output' ref={parent}>
                    <Outlet />
                </div>
            </div>
            <SideBar />
        </div>
    )
}

export default AdminSideBar