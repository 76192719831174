import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCookie, setCookie } from "../helpers/cookies";
import { Helmet } from "react-helmet-async";
import {
  useCancelDShipmentMutation,
  useCancelShipmentMutation,
  useCreateDClientWareHouseMutation,
  useCreateDOrderMutation,
  useCreateDPickupOrderMutation,
  useCreateShipmentMutation,
  useGenerateTokenMutation,
  useGetAllOrdersQuery,
  useGetCustomerOrdersQuery,
  usePickUpShipmentMutation,
  useUpdateSingleOrderMutation,
  useUpdateUserMutation,
} from "../store/index";
import Button from "../components/common/Button";
import { exportToXL } from "../helpers/export";
import Print from "../components/common/Print";
import TaxInvoice from "../components/common/TaxInvoice";
import Label from "../components/common/Label";
import { b2c_pincodes } from "../helpers/b2c_pincodes";

const B2COrders = ({ setProgress }) => {
  const role = getCookie("delivaryUserRole");
  const id = getCookie("delivaryUserId");
  const jwt = getCookie("delivaryUserJWT");
  const wallet = getCookie("delivaryUserWallet");
  const no_of_orders = getCookie("delivaryUserOrders");
  const { data: allOrders, isFetching: FetchAll } = useGetAllOrdersQuery();
  const { data: customerOrders, isFetching: FetchCustomers } =
    useGetCustomerOrdersQuery(id);
  const [XpressBeesGenerateToken, XpressBeesGenerationResults] =
    useGenerateTokenMutation();
  const [XpressbeesCancelShipment, XpressbeesCancelShipmentResults] =
    useCancelShipmentMutation();
  const [XpressBeesPickUpShipment, XpressBeesPickUpShipmentResults] =
    usePickUpShipmentMutation();
  const [XpressBeesCreateShipment, XpressBeesCreateShipmentResults] =
    useCreateShipmentMutation();
  const [DelhivaryClientWareHouse, DelhivaryClientWareHouseResults] =
    useCreateDClientWareHouseMutation();
  const [DelhivaryCreateShipment, DelhivaryCreateShipmentResults] =
    useCreateDOrderMutation();
  const [DelhivaryPickupShipment, DelhivaryPickupResults] =
    useCreateDPickupOrderMutation();
  const [DelhivaryCancelOrder, DelhivaryCancelResults] =
    useCancelDShipmentMutation();
  const [UpdateOrder, updateResults] = useUpdateSingleOrderMutation();
  const [updateUser, updationResults] = useUpdateUserMutation();
  const [content, setContent] = useState();
  const [noProd, setNoProd] = useState();
  const [download, setDownload] = useState();

  useEffect(() => {
    if (role === "admin") {
      if (allOrders) {
        if (allOrders.length !== 0) {
          setDownload(allOrders);
          setContent(
            allOrders.map((c) => {
              const handlePickupOrder = async () => {
                setProgress(25);
                if (c.courier_id === "1234543") {
                  const date = new Date();
                  const data = {
                    pickup_time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                    pickup_date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
                    pickup_location: c.consigner_details.consigner_name,
                    expected_package_count: 1
                  }
                  await DelhivaryPickupShipment({ data, jwt }).then(async (res) => {
                    if(res.error){
                      alert("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                      setProgress(100);
                    } else {
                      const update_data = {
                        order_status: "picked_up",
                      };
                      const id = c._id;
                      setProgress(75);
                      await UpdateOrder({ data: update_data, id, jwt })
                        .unwrap()
                        .then((res) => {
                          console.log(res);
                          setProgress(100);
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                        });
                    }
                  }).catch((err) => {
                    console.log("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                    setProgress(100);
                  })
                } else {
                  await XpressBeesGenerateToken()
                    .then(async (res) => {
                      const token = res.data.data;
                      const data = {
                        awb_numbers: c.awb_number,
                      };
                      setProgress(50);
                      await XpressBeesPickUpShipment({ data, token })
                        .unwrap()
                        .then(async (res) => {
                          if (res.response === true) {
                            const update_data = {
                              order_status: "picked_up",
                            };
                            const id = c._id;
                            setProgress(75);
                            await UpdateOrder({ data: update_data, id, jwt })
                              .unwrap()
                              .then((res) => {
                                console.log(res);
                                setProgress(100);
                              })
                              .catch((err) => {
                                console.log(err);
                                setProgress(100);
                              });
                          } else {
                            alert("Server busy can't generate pickup");
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                        });
                    })
                    .catch((err) => {
                      console.log("Can't Cancel Order!, Server Details");
                      setProgress(100);
                    });
                }
              };
              const handleCancelShippedOrder = async () => {
                if (c.courier_id === "1234543") {
                  const data = {
                    waybill: c.awb_number,
                  };
                  await DelhivaryCancelOrder({ data, jwt })
                    .then(async (res) => {
                      setProgress(50);
                      const update_data = {
                        order_status: "cancelled",
                      };
                      const id = c._id;
                      await UpdateOrder({ data: update_data, id, jwt })
                        .unwrap()
                        .then(async (res) => {
                          setProgress(100);
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                        });
                    })
                    .catch((err) => {
                      console.log(err);
                      setProgress(100);
                    });
                } else {
                  await XpressBeesGenerateToken()
                    .then(async (res) => {
                      const token = res.data.data;
                      const data = {
                        awb_number: c.awb_number,
                      };
                      setProgress(50);
                      await XpressbeesCancelShipment({ data, token })
                        .unwrap()
                        .then(async (res) => {
                          setProgress(75);
                          const update_data = {
                            order_status: "cancelled",
                          };
                          const id = c._id;
                          await UpdateOrder({ data: update_data, id, jwt })
                            .unwrap()
                            .then(async (res) => {
                              setProgress(100);
                            })
                            .catch((err) => {
                              console.log(err);
                              setProgress(100);
                            });
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                        });
                    })
                    .catch((err) => {
                      console.log("Can't Cancel Order!, Server Details");
                      setProgress(100);
                    });
                }
              };

              const handleShipNow = async () => {
                if (c.courier_id === "1234543") {
                  const act_data = {
                    shipments: [
                      {
                        name: c.consignee_details.consignee_name,
                        add: c.consignee_details.consignee_address,
                        pin: c.consignee_details.consignee_pincode,
                        city: c.consignee_details.consignee_city,
                        state: c.consignee_details.consignee_state,
                        country: "India",
                        phone: c.consignee_details.consignee_phone,
                        order: `${Math.floor(Math.random() * 100000)}`,
                        payment_mode: c.cod_charges === 0 ? "Prepaid" : "COD",
                        cod_amount: c.order_amount,
                        order_date: "",
                        shipment_width: "1",
                        shipment_height: "1",
                        shipping_mode: "Surface",
                        address_type: "home",
                      },
                    ],
                    pickup_location: {
                      name: c.consigner_details.consigner_name,
                      add: c.consigner_details.consigner_address,
                      city: c.consigner_details.consigner_city,
                      pin_code: c.consigner_details.consigner_pincode,
                      country: "India",
                      phone: c.consigner_details.consigner_phone,
                    },
                  };
                  const clientwarehouse = {
                    phone: c.consigner_details.consigner_phone,
                    city: c.consigner_details.consigner_city,
                    name: c.consigner_details.consigner_name,
                    pin: c.consigner_details.consigner_pincode,
                    address: c.consigner_details.consigner_address,
                    country: "India",
                    email: "abc@gmail.com",
                    registered_name: c.consigner_details.consigner_name,
                    return_address: c.consigner_details.consigner_address,
                    return_pin: c.consigner_details.consigner_pincode,
                    return_city: c.consigner_details.consigner_city,
                    return_state: c.consigner_details.consigner_state,
                    return_country: "India",
                  };
                  await DelhivaryClientWareHouse({ data: clientwarehouse, jwt })
                    .then((res) => {
                      console.log(res);
                      setProgress(50);
                    })
                    .catch((err) => {
                      console.log(err);
                      setProgress(50);
                    });

                  await DelhivaryCreateShipment({ data: act_data, jwt })
                    .then(async (res) => {
                      if (res.data.success) {
                        const awb = res.data.packages[0].waybill;
                        const shipping = res.data.packages[0].sort_code;
                        const update_data = {
                          awb_number: awb,
                          shipping_id: shipping,
                          order_status: "Success",
                        };
                        const id = c._id;
                        setProgress(75);
                        await UpdateOrder({ data: update_data, id, jwt })
                          .unwrap()
                          .then(async (res) => {
                            setProgress(100);
                          })
                          .catch(async (err) => {
                            console.log(err);
                            const data = {
                              waybill: awb,
                            };
                            await DelhivaryCancelOrder({ data, jwt })
                              .then(async (res) => {
                                setProgress(50);
                                const update_data = {
                                  order_status: "cancelled",
                                };
                                const id = c._id;
                                await UpdateOrder({
                                  data: update_data,
                                  id,
                                  jwt,
                                })
                                  .unwrap()
                                  .then(async (res) => {
                                    setProgress(100);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    setProgress(100);
                                  });
                              })
                              .catch((err) => {
                                console.log(err);
                                setProgress(100);
                              });
                          });
                      } else {
                        console.log(res.data);
                      }
                      setProgress(100);
                    })
                    .catch((err) => {
                      console.log(err);

                      setProgress(100);
                    });
                } else {
                  const order_data = {
                    id: c.orderId,
                    order_unique_number: "yes",
                    payment_method: c.payment_method,
                    ...c.consignee_details,
                    ...c.consigner_details,
                    products: c.product_details,
                    invoice: c.invoice_details,
                    ...c.product_box_details,
                    courier_id: c.courier_id,
                    pickup_location: c.pickup_location,
                    shipping_charges: c.shipping_charges,
                    cod_charges: c.cod_charges,
                    order_amount: c.order_total,
                    discount: c.discount,
                    collectable_amount: c.collectable_amount,
                  };
                  setProgress(25);
                  await XpressBeesGenerateToken()
                    .unwrap()
                    .then(async (res) => {
                      const token = res.data;
                      setProgress(50);
                      await XpressBeesCreateShipment({
                        data: order_data,
                        token,
                      })
                        .unwrap()
                        .then(async (res) => {
                          const awb_number = res.awb_number;
                          if (!awb_number) {
                            alert(res.message);
                          } else {
                            const update_data = {
                              awb_number: res.awb_number,
                              shipping_id: res.shipping_id,
                              order_status: res.message,
                            };
                            const id = c._id;
                            setProgress(75);
                            await UpdateOrder({ data: update_data, id, jwt })
                              .unwrap()
                              .then(async (res) => {
                                setProgress(100);
                              })
                              .catch(async (err) => {
                                const cancel_data = {
                                  awb_number,
                                };
                                setProgress(75);
                                await XpressbeesCancelShipment({
                                  data: cancel_data,
                                  token,
                                })
                                  .unwrap()
                                  .then((res) => {
                                    alert("could not place the order");
                                    setProgress(100);
                                  })
                                  .catch((err) =>
                                    console.log("cancel_shipment_error:", err)
                                  );
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                          alert("couldn't ship the order, try again later");
                        });
                    })
                    .catch(async (err) => {
                      alert("couldn't ship the order, try again later");
                      setProgress(100);
                    });
                }
              };

              const handleCancelShipLaterOrder = async () => {
                setProgress(50);
                const update_data = {
                  order_status: "cancelled",
                };
                const id = c._id;
                await UpdateOrder({ data: update_data, id, jwt })
                  .unwrap()
                  .then(async (res) => {
                    setProgress(100);
                  })
                  .catch((err) => {
                    console.log(err);
                    setProgress(100);
                  });
              };

              console.log(c);

              return (
                <tr>
                  <td>
                    <NavLink to={`/admin/details/${c._id}`}>
                      {c.orderId}
                    </NavLink>
                  </td>
                  <td>
                    {c.awb_number === "-" ? (
                      c.awb_number
                    ) : (
                      <NavLink to={`/admin/track/${c._id}`}>
                        {c.awb_number}
                      </NavLink>
                    )}
                  </td>
                  <td>
                    {c.courier_id === "1234543" ? "Delhivary" : "XPressBees"}
                  </td>
                  <td>{c.courier_id === 14435 ? "B2C Air" : "B2C Surface"}</td>
                  <td>{c.consignee_details.consignee_name}</td>
                  <td>{c.consigner_details.consigner_name}</td>
                  <td>
                    Order Total: {c.order_total} <br />
                    discount: {c.discount} <br />
                    Collectable Amount: {c.collectable_amount}
                  </td>
                  <td>
                    Shipping Charges:{" "}
                    {Math.ceil(parseFloat(c.discounted_shipping_charges))}
                    <br />
                    COD Charges: {c.cod_charges}
                  </td>
                  <td>{c.payment_status}</td>
                  <td>
                    {c.customer_id === id && (
                      <>
                        {c.order_status !== "picked_up" &&
                          c.order_status !== "ship_later" &&
                          c.order_status !== "shipped" &&
                          c.order_status !== "cancelled" && (
                            <>
                              <Button
                                loading={
                                  XpressBeesGenerationResults.isLoading ||
                                  XpressBeesPickUpShipmentResults.isLoading ||
                                  updateResults.isLoading
                                }
                                onClick={handlePickupOrder}
                              >
                                Pick Up
                              </Button>
                              <br />
                              <br />
                              <Button
                                loading={
                                  XpressBeesGenerationResults.isLoading ||
                                  XpressbeesCancelShipmentResults.isLoading ||
                                  updationResults.isLoading
                                }
                                onClick={handleCancelShippedOrder}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        {c.order_status === "ship_later" && (
                          <>
                            <Button
                              loading={
                                XpressBeesGenerationResults.isLoading ||
                                XpressbeesCancelShipmentResults.isLoading ||
                                updationResults.isLoading ||
                                XpressBeesCreateShipmentResults.isLoading
                              }
                              onClick={handleShipNow}
                            >
                              Ship Now
                            </Button>
                            <br />
                            <br />
                            <NavLink to={`/admin/edit-order/${c._id}`}>
                              <Button>Edit</Button>
                            </NavLink>
                            <br />
                            <br />
                            <Button
                              loading={updationResults.isLoading}
                              onClick={handleCancelShipLaterOrder}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {c.order_status === "cancelled" && "-"}
                  </td>
                  <td>
                    <Print label="slip">
                      <TaxInvoice id={c._id} />
                    </Print>
                    <br />
                    <br />
                    <Print label="label">
                      <Label id={c._id} />
                    </Print>
                  </td>
                </tr>
              );
            })
          );
          setNoProd();
        } else {
          setContent();
          setNoProd("No Orders Found...");
        }
      } else if (FetchAll) {
        setNoProd("Loading Orders...");
        setContent();
      } else {
        setNoProd("Error Loading Orders!! please reload...");
        setContent();
      }
    } else { 
      if (customerOrders) {
        if (customerOrders.length !== 0) {
          setDownload(customerOrders);
          setContent(
            customerOrders.map((c) => {
              const handlePickupOrder = async () => {
                setProgress(25);
                if (c.courier_id === "1234543") {
                  const date = new Date();
                  const data = {
                    pickup_time: `12:00:00`,
                    pickup_date: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()+1}`,
                    pickup_location: c.consigner_details.consigner_name,
                    expected_package_count: 1
                  }
                  await DelhivaryPickupShipment({ data, jwt}).then(async (res) => {
                    console.log(res);
                    if(res.error){
                      alert("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                      setProgress(100);
                    } else {
                      const update_data = {
                        order_status: "picked_up",
                      };
                      const id = c._id;
                      setProgress(75);
                      await UpdateOrder({ data: update_data, id, jwt })
                        .unwrap()
                        .then((res) => {
                          console.log(res);
                          setProgress(100);
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                        });
                    }
                  }).catch((err) => {
                    console.log("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                    setProgress(100);
                  })
                }
              };
              const handleCancelShippedOrder = async () => {
                setProgress(20);
                if (c.courier_id === "1234543") {
                  const data = {
                    waybill: c.awb_number,
                  };
                  await DelhivaryCancelOrder({ data, jwt })
                  .then(async (res) => {
                    const prevStatus = c.order_status;
                    const update_data = {
                      order_status: "cancelled",
                    };
                    const id = c._id;
                    setProgress(60);
                    await UpdateOrder({ data: update_data, id, jwt })
                      .unwrap()
                      .then(async (res) => {
                        setProgress(80);
                        const update_data = {
                            wallet:
                              parseInt(wallet) +
                              Math.ceil(
                                parseFloat(c.discounted_shipping_charges) +
                                  parseInt(c.cod_charges)
                              ),
                            no_of_orders: parseInt(no_of_orders) - 1,
                          };
                          console.log("update_data: ", update_data);
                          console.log("user data: ", {
                            wallet,
                            discounted_shipping_charges: c.discounted_shipping_charges,
                            cod_charges: c.cod_charges,
                            no_of_orders,
                          })
                          await updateUser({ data: update_data, jwt })
                            .unwrap()
                            .then((res) => {
                              setProgress(100);
                              setCookie(
                                "delivaryUserWallet",
                                update_data.wallet
                              );
                              setCookie(
                                "delivaryUserOrders",
                                update_data.no_of_orders
                              );
                            });
                      })
                      .catch((err) => {
                        console.log(err);
                        setProgress(100);
                      });
                  })
                    .catch((err) => {
                      console.log(err);
                      setProgress(100);
                    });
                } else {
                await XpressBeesGenerateToken()
                  .then(async (res) => {
                    const token = res.data.data;
                    const data = {
                      awb_number: c.awb_number,
                    };
                    setProgress(40);
                    await XpressbeesCancelShipment({ data, token })
                      .unwrap()
                      .then(async (res) => {
                        const prevStatus = c.order_status;
                        const update_data = {
                          order_status: "cancelled",
                        };
                        const id = c._id;
                        setProgress(60);
                        await UpdateOrder({ data: update_data, id, jwt })
                          .unwrap()
                          .then(async (res) => {
                            setProgress(80);
                            if (prevStatus !== "ship_later") {
                              const update_data = {
                                wallet:
                                  parseInt(wallet) +
                                  Math.ceil(
                                    parseFloat(c.discounted_shipping_charges) +
                                      parseInt(c.cod_charges)
                                  ),
                                no_of_orders: parseInt(no_of_orders) - 1,
                              };
                              await updateUser({ data: update_data, jwt })
                                .unwrap()
                                .then((res) => {
                                  setProgress(100);
                                  setCookie(
                                    "delivaryUserWallet",
                                    update_data.wallet
                                  );
                                  setCookie(
                                    "delivaryUserOrders",
                                    update_data.no_of_orders
                                  );
                                });
                            } else {
                              setProgress(100);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            setProgress(100);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        setProgress(100);
                      });
                  })
                  .catch((err) => {
                    console.log("Can't Cancel Order!, Server Details");
                    setProgress(100);
                  });
                }
              };

              const handleShipNow = async () => {
                if (c.courier_id === "1234543") {
                  const act_data = {
                    shipments: [
                      {
                        name: c.consignee_details.consignee_name,
                        add: c.consignee_details.consignee_address,
                        pin: c.consignee_details.consignee_pincode,
                        city: c.consignee_details.consignee_city,
                        state: c.consignee_details.consignee_state,
                        country: "India",
                        phone: c.consignee_details.consignee_phone,
                        order: `${Math.floor(Math.random() * 100000)}`,
                        payment_mode: c.cod_charges === 0 ? "Prepaid" : "COD",
                        cod_amount: c.order_amount,
                        order_date: "",
                        shipment_width: "1",
                        shipment_height: "1",
                        shipping_mode: "Surface",
                        address_type: "home",
                      },
                    ],
                    pickup_location: {
                      name: c.consigner_details.consigner_name,
                      add: c.consigner_details.consigner_address,
                      city: c.consigner_details.consigner_city,
                      pin_code: c.consigner_details.consigner_pincode,
                      country: "India",
                      phone: c.consigner_details.consigner_phone,
                    },
                  };
                  const clientwarehouse = {
                    phone: c.consigner_details.consigner_phone,
                    city: c.consigner_details.consigner_city,
                    name: c.consigner_details.consigner_name,
                    pin: c.consigner_details.consigner_pincode,
                    address: c.consigner_details.consigner_address,
                    country: "India",
                    email: "abc@gmail.com",
                    registered_name: c.consigner_details.consigner_name,
                    return_address: c.consigner_details.consigner_address,
                    return_pin: c.consigner_details.consigner_pincode,
                    return_city: c.consigner_details.consigner_city,
                    return_state: c.consigner_details.consigner_state,
                    return_country: "India",
                  };
                  await DelhivaryClientWareHouse({ data: clientwarehouse, jwt })
                    .then((res) => {
                      console.log(res);
                      setProgress(50);
                    })
                    .catch((err) => {
                      console.log(err);
                      setProgress(50);
                    });

                  await DelhivaryCreateShipment({ data: act_data, jwt })
                    .then(async (res) => {
                      console.log(res);
                      if (res.data.success) {
                        const awb = res.data.packages[0].waybill;
                        const shipping = res.data.packages[0].sort_code;
                        const update_data = {
                          awb_number: awb,
                          shipping_id: shipping,
                          order_status: "Success",
                        };
                        const id = c._id;
                        setProgress(75);
                        await UpdateOrder({ data: update_data, id, jwt })
                          .unwrap()
                          .then(async (res) => {
                            const update_data = {
                              wallet:
                                parseInt(wallet) -
                                Math.ceil(
                                  parseFloat(c.discounted_shipping_charges)
                                ) -
                                parseInt(c.cod_charges),
                              no_of_orders: parseInt(no_of_orders) + 1,
                            };
                            setProgress(80);
                            await updateUser({ data: update_data, jwt })
                              .unwrap()
                              .then((res) => {
                                setCookie(
                                  "delivaryUserWallet",
                                  update_data.wallet
                                );
                                setCookie(
                                  "delivaryUserOrders",
                                  update_data.no_of_orders
                                );
                                setProgress(100);
                              })
                              .catch(async (err) => {
                                setProgress(100);
                              });
                          })
                          .catch((err) => {
                            alert("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                          });
                      } else {
                        console.log(res.data);
                      }
                      setProgress(100);
                    })
                    .catch((err) => {
                      console.log(err);
                      setProgress(100);
                    });
                } else {
                  const order_data = {
                    id: c.orderId,
                    order_unique_number: "yes",
                    payment_method: c.payment_method,
                    ...c.consignee_details,
                    ...c.consigner_details,
                    products: c.product_details,
                    invoice: c.invoice_details,
                    ...c.product_box_details,
                    courier_id: c.courier_id,
                    pickup_location: c.pickup_location,
                    shipping_charges: c.shipping_charges,
                    cod_charges: c.cod_charges,
                    order_amount: c.order_total,
                    discount: c.discount,
                    collectable_amount: c.collectable_amount,
                  };
                  console.log(order_data);
                  setProgress(20);
                  await XpressBeesGenerateToken()
                    .unwrap()
                    .then(async (res) => {
                      const token = res.data;
                      setProgress(40);
                      await XpressBeesCreateShipment({
                        data: order_data,
                        token,
                      })
                        .unwrap()
                        .then(async (res) => {
                          const awb_number = res.awb_number;
                          if (!awb_number) {
                            alert(res.message);
                          } else {
                            const update_data = {
                              awb_number: res.awb_number,
                              shipping_id: res.shipping_id,
                              order_status: res.message,
                            };
                            const id = c._id;
                            setProgress(60);
                            await UpdateOrder({ data: update_data, id, jwt })
                              .unwrap()
                              .then(async (res) => {
                                const update_data = {
                                  wallet:
                                    parseInt(wallet) -
                                    Math.ceil(
                                      parseFloat(c.discounted_shipping_charges)
                                    ) -
                                    parseInt(c.cod_charges),
                                  no_of_orders: parseInt(no_of_orders) + 1,
                                };
                                setProgress(80);
                                await updateUser({ data: update_data, jwt })
                                  .unwrap()
                                  .then((res) => {
                                    setCookie(
                                      "delivaryUserWallet",
                                      update_data.wallet
                                    );
                                    setCookie(
                                      "delivaryUserOrders",
                                      update_data.no_of_orders
                                    );
                                    setProgress(100);
                                  })
                                  .catch(async (err) => {
                                    const cancel_data = {
                                      awb_number,
                                    };
                                    setProgress(80);
                                    await XpressbeesCancelShipment({
                                      data: cancel_data,
                                      token,
                                    })
                                      .unwrap()
                                      .then((res) => {
                                        alert("could not place the order");
                                        setProgress(100);
                                      })
                                      .catch((err) =>
                                        console.log(
                                          "cancel_shipment_error:",
                                          err
                                        )
                                      );
                                  });
                              })
                              .catch(async (err) => {
                                const cancel_data = {
                                  awb_number,
                                };
                                setProgress(80);
                                await XpressbeesCancelShipment({
                                  data: cancel_data,
                                  token,
                                })
                                  .unwrap()
                                  .then((res) => {
                                    alert("could not place the order");
                                    setProgress(100);
                                  })
                                  .catch((err) =>
                                    console.log("cancel_shipment_error:", err)
                                  );
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setProgress(100);
                          alert("couldn't ship the order, try again later");
                        });
                    })
                    .catch(async (err) => {
                      alert("couldn't ship the order, try again later");
                      setProgress(100);
                    });
                }
              };

              const handleCancelShipLaterOrder = async () => {
                setProgress(50);
                const update_data = {
                  order_status: "cancelled",
                };
                const id = c._id;
                await UpdateOrder({ data: update_data, id, jwt })
                  .unwrap()
                  .then(async (res) => {
                    setProgress(100);
                  })
                  .catch((err) => {
                    console.log(err);
                    setProgress(100);
                  });
              };

              return (
                <tr>
                  <td>
                    <NavLink to={`/customer/details/${c._id}`}>
                      {c.orderId}
                    </NavLink>
                  </td>
                  <td>
                    {c.awb_number === "-" ? (
                      c.awb_number
                    ) : (
                      <NavLink to={`/customer/track/${c._id}`}>
                        {c.awb_number}
                      </NavLink>
                    )}
                  </td>
                  <td>
                    {c.courier_id === "1234543" ? "Delhivary" : "XPressBees"}
                  </td>
                  <td>{c.courier_id === 14435 ? "B2C Air" : "B2C Surface"}</td>
                  <td>{c.consignee_details.consignee_name}</td>
                  <td>{c.consigner_details.consigner_name}</td>
                  <td>
                    Order Total: {c.order_total} <br />
                    discount: {c.discount} <br />
                    Collectable Amount: {c.collectable_amount}
                  </td>
                  <td>
                    Shipping Charges:{" "}
                    {Math.ceil(parseFloat(c.discounted_shipping_charges))}
                    <br />
                    COD Charges: {c.cod_charges}
                  </td>
                  <td>{c.payment_status}</td>
                  <td>
                      <>
                        {c.order_status !== "picked_up" &&
                          c.order_status !== "ship_later" &&
                          c.order_status !== "shipped" &&
                          c.order_status !== "cancelled" && (
                            <>
                              <Button
                                loading={
                                  XpressBeesGenerationResults.isLoading ||
                                  XpressBeesPickUpShipmentResults.isLoading ||
                                  updateResults.isLoading
                                }
                                onClick={handlePickupOrder}
                              >
                                Pick Up
                              </Button>
                              <br />
                              <br />
                              <Button
                                loading={
                                  XpressBeesGenerationResults.isLoading ||
                                  XpressbeesCancelShipmentResults.isLoading ||
                                  updationResults.isLoading
                                }
                                onClick={handleCancelShippedOrder}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        {c.order_status === "ship_later" && (
                          <>
                            <Button
                              loading={
                                XpressBeesGenerationResults.isLoading ||
                                XpressbeesCancelShipmentResults.isLoading ||
                                updationResults.isLoading ||
                                XpressBeesCreateShipmentResults.isLoading
                              }
                              onClick={handleShipNow}
                            >
                              Ship Now
                            </Button>
                            <br />
                            <br />
                            <NavLink to={`/customer/edit-order/${c._id}`}>
                              <Button>Edit</Button>
                            </NavLink>
                            <br />
                            <br />
                            <Button
                              loading={
                                XpressBeesGenerationResults.isLoading ||
                                XpressbeesCancelShipmentResults.isLoading ||
                                updationResults.isLoading
                              }
                              onClick={handleCancelShipLaterOrder}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                        {c.order_status === "cancelled" && "-"}
                      </>
                  </td>
                  <td>
                    <Print label="slip">
                      <TaxInvoice id={c._id} /> 
                    </Print>
                    <br />
                    <br />
                    <Print label="label">
                      <Label id={c._id} />
                    </Print>
                  </td>
                </tr>
              );
            })
          );
          setNoProd();
        } else {
          setContent();
          setNoProd("No Orders Found...");
        }
      } else if (FetchCustomers) {
        setNoProd("Loading Orders...");
        setContent();
      } else {
        setNoProd("Error Loading Orders!! please reload...");
        setContent();
      }
    }
  }, [role, allOrders, customerOrders, FetchAll, FetchCustomers]);

  const handleExport = () => {
    if (download) {
      const data = download.map((d) => {
        return {
          orderId: d.orderId,
          ...d.consigner_details,
          ...d.consignee_details,
          ...d.product_details,
          ...d.invoice_details,
          ...d.product_box_details,
          awb_number: d.awb_number,
          shipping_id: d.shipping_id,
          order_status: d.order_status,
          order_total: d.order_total,
          discount: d.discount,
          collectable_amount: d.collectable_amount,
          cod_charges: d.cod_charges,
          shipping_charges: d.shipping_charges,
          payment_status: d.payment_status,
          payment_method: d.payment_method,
          coupon_discount: d.coupon_discount,
          discounted_shipping_charges: d.discounted_shipping_charges,
        };
      });
      exportToXL(data);
    }
  };
  const down = () => {
    exportToXL(b2c_pincodes);
  };

  return (
    <>
      <Helmet>
        <title>edaylivery - Every day Delivary, Ontime - B2C Orders</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="orders">
        <div className="top">
          <input type="text" name="search" placeholder="search" />
          <NavLink
            to={
              role === "admin"
                ? "/admin/create-b2c-order"
                : "/customer/create-b2c-order"
            }
          >
            <button>+ Create B2C Order</button>
          </NavLink>
        </div>
        <div className="order-table">
          <div className="heading">
            <h1>B2C Orders</h1>
          </div>
          <div className="buttons">
            <Button onClick={handleExport}>Export</Button>
            <Button onClick={down}>Pincodes List</Button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Tracking ID</th>
                <th>Shipping Partner</th>
                <th>Tansport Type</th>
                <th>Consignee Name</th>
                <th>Consigner Name</th>
                <th>Order Totals</th>
                <th>Shipping Charges</th>
                <th>Payment Status</th>
                <th>Action</th>
                <th>Slip</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
          {noProd && <div className="no-ord">{noProd}</div>}
        </div>
      </div>
    </>
  );
};

export default B2COrders;
