import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react'
import { useGetAllProfitsQuery } from '../../store/index.js';
import Button from '../common/Button.js';
import { lookup } from 'india-pincode-lookup';
import { b2b_data } from '../../helpers/b2b_data.js';
import { codDelivery, pickup, prepaidDelivery } from '../../helpers/Pincodes.js';

const B2BCalculator = ({ setProgress }) => {
    const { data: profitRates } = useGetAllProfitsQuery();
    const [parent, enable] = useAutoAnimate({duration: 350});
    const [origin, setOrigin] = useState();
    const [destination, setDestination] = useState();
    const [weight, setWeight] = useState();
    const [length, setLength] = useState();
    const [height, setHeight] = useState();
    const [breadth, setBreadth] = useState();
    const [box, setBox] = useState();
    const [error, setError] = useState();
    const [cod, setCod] = useState("prepaid");
    const [bill, setBill] = useState();

    useEffect(() => {
        enable(true);
    }, [parent, enable]);

    useEffect(() => {
        if(lookup(origin).length === 0){
            setError("from address does not exists!");
        }
        else if(lookup(destination).length === 0){
            setError("to address does not exists!");
        }
        else{
            setError();
        }
    }, [origin, destination]);

    const handleCalculate = async () => {
        if(error){
            return;
        }
        const from_addr = lookup(origin)[0].stateName.toLowerCase();
        const to_addr = lookup(destination)[0].stateName.toLowerCase();
        const keyName = from_addr + "_" + to_addr;
        const base = b2b_data[keyName];
        let shipping_mul = 1;
        if(profitRates){
            const [rates] = profitRates.filter((r) => {
                return r.provider === 'XpressBees' && r.buisness === "B2B";
            });
            if(rates){
                shipping_mul = 1 + (rates.shipping_rate/100);
            }
        }
        const rate_per_kg = parseFloat((base*shipping_mul).toFixed(2));
        const weight_charges = parseFloat(((weight/1000)*rate_per_kg).toFixed(2));
        const fuel_charges = parseFloat((weight_charges*0.10).toFixed(2));
        const docket_charges = 100;
        const open_pickup_delivary = 0;
        const fov_charges = 100;
        const fod_charges = 0;
        let green_charges = 0;
        if(to_addr === "delhi" || from_addr === "delhi"){
            const weight_charge = parseFloat((weight/1000)*0.5).toFixed(2);
            green_charges = 100 > weight_charge ? 100 : weight_charge;
        }
        const appointment_delivary_charges_per_docket = 0;
        const handling_of_shipment = 0.00;
        const gst_percentage = 18;
        const base_freight = weight_charges + fuel_charges + docket_charges + open_pickup_delivary + fov_charges + fod_charges + green_charges + appointment_delivary_charges_per_docket + handling_of_shipment;
        const gst = parseFloat((base_freight*0.18).toFixed(2));
        const oda_charges = !(pickup.includes(parseInt(origin)) || prepaidDelivery.includes(parseInt(destination)) || codDelivery.includes(parseInt(destination))) ? ((1500 > weight *0.5) ? 1500 : weight * 0.5) : 0;
        const total_charges_including_gst = base_freight + gst + oda_charges;        
        setBill(<td>
            Rate Per Kg: {rate_per_kg} <br />
            Fuel Charges: {fuel_charges} <br />
            Docket Charges: {docket_charges} <br />
            Weight Charges: {weight_charges} <br />
            Open Pickup Delivery: {open_pickup_delivary} <br />
            FOV Charges: {fov_charges} <br />
            FOD Charges: {fod_charges} <br />
            Green Charges: {green_charges} <br />
            Appointment Delivary Charges Per Docket: {appointment_delivary_charges_per_docket} <br />
            Handling of Shipment: {handling_of_shipment} <br />
            Gst Percentage: {gst_percentage} <br />
            Base Freight: {base_freight.toFixed(2)} <br />
            Gst Charges: {gst} <br />
            ODA Charges: {oda_charges} <br />
            Total Charges (Including GST): {total_charges_including_gst}
        </td>);
    }

    return (
        <>
            <div className='signup' ref={parent}>
                <div className='heading'>
                    <h1>B2B Shipping Rate Calculator</h1>
                </div>
                <div className='inputs'>
                    <input
                        type='number'
                        name='from'
                        value={origin}
                        onChange={(e) => {
                            setOrigin(e.target.value);
                        }}
                        placeholder='from'
                    />
                    <input
                        type='number'
                        name='to'
                        value={destination}
                        onChange={(e) => {
                            setDestination(e.target.value);
                        }}
                        placeholder='to'
                    />
                    <input
                        type='number'
                        name='to'
                        value={box}
                        onChange={(e) => {
                            setBox(e.target.value);
                        }}
                        placeholder='no_of_boxes'
                    />
                    <input
                        type='number'
                        name='weight'
                        max="20"
                        value={weight}
                        onChange={(e) => {
                            setWeight(e.target.value);
                        }}
                        placeholder='weight'
                    />
                    <input
                        type='number'
                        name='height'
                        value={height}
                        onChange={(e) => {
                            setHeight(e.target.value);
                        }}
                        placeholder='height'
                    />
                    <input
                        type='number'
                        name='length'
                        value={length}
                        onChange={(e) => {
                            setLength(e.target.value);
                        }}
                        placeholder='length'
                    />
                    <input
                        type='number'
                        name='breadth'
                        value={breadth}
                        onChange={(e) => {
                            setBreadth(e.target.value);
                        }}
                        placeholder='breadth'
                    />
                    <select value={cod} onChange={(e) => setCod(e.target.value)}>
                        <option value="cod">COD</option>
                        <option value="prepaid">PrePaid</option>
                    </select>
                </div>
                {error && (
                    <div className='error'>
                        {error}
                    </div>
                )}
                <div className='buttons'>
                    <Button onClick={handleCalculate}>
                        Calculate
                    </Button>
                </div>
            </div>
            {bill && (
                <div className='signup'>
                    <table>
                        <thead>
                            <tr>
                                <th>Provider</th>
                                <th>Charges</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Edaylivery B2B</td>
                                {bill}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default B2BCalculator