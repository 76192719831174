import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGenerateTokenMutation, useGetSingleB2BOrderQuery, useTrackShipmentMutation } from '../../store/index';
import { GoSync } from 'react-icons/go';

const OrderB2BTrackingPage = () => {
    const { id } = useParams();
    const { data: order, isFetching: orderFetching } = useGetSingleB2BOrderQuery(id);
    const [data, setData] = useState();
    const [trackShipment, trackResults] = useTrackShipmentMutation();
    const [generateToken, generateResults] = useGenerateTokenMutation();
    const [content, setContent] = useState();

    useEffect(() => {
      const handleTrack = async () => {
        await generateToken().unwrap().then(async (res) => {
            const l = order.awb_number.split(",");
            const token = res.data;
            const data = {
                awb_number: l[0],
            };
            await trackShipment({ data, token }).unwrap().then((res) => {
                setData(res);
            }).catch((err) => console.log(err));
        });
      }
      if(order) {
        handleTrack();
      }
    }, [generateToken, trackShipment, order]);

    useEffect(() => {
      if(data){
        let delivered, ofd, transit, pickup;
        if(data.tracking_data){
          if(data.tracking_data["delivered"]){
            delivered = data.tracking_data["delivered"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["in transit"]){
            transit = data.tracking_data["in transit"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["out for delivary"]){
            ofd = data.tracking_data["out for delivary"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["pending pickup"]){
            pickup = data.tracking_data["pending pickup"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
        }
        setContent(
          <>
            <div className='single'>
              <div className='heading'>
                  <h1>Order ID: {order.orderId}</h1>
              </div>
              <div className='details'>
                  <div className='left'>
                      <div className='heading'>
                          <h1>Consigner Details</h1>
                      </div>
                      <div>Name: {order.consigner_details.consigner_name}</div>
                      <div>Address:{order.consigner_details.consigner_address}</div>
                      <div>Phone:{order.consigner_details.consigner_phone}</div>
                      <div>GSTIN:{order.consigner_details.consigner_gst_number ? order.consigner_details.consigner_gst_number : "None"}</div>
                  </div>
                  <div className='right'>
                      <div className='heading'>
                          <h1>Consignee Details</h1>
                      </div>
                      <div>Name: {order.consignee_details.consignee_name}</div>
                      <div>Address:{order.consignee_details.consignee_address}</div>
                      <div>Phone:{order.consignee_details.consignee_phone}</div>
                      <div>GSTIN:{order.consignee_details.consignee_gst_number ? order.consignee_details.consignee_gst_number : "None"}</div>
                  </div>
              </div>
              <div className='orders'>
                  <div className='order-details'>
                    <div className='heading'>
                      <h1>Tracking Details</h1>
                    </div>
                    {data.response === false && (
                      <div className='details-single'>
                        <div className='status'>
                          {data.message}
                        </div>
                      </div>
                    )}
                    {delivered}
                    {transit}
                    {ofd}
                    {pickup}
                  </div>
              </div>
          </div>
          </>
        );
      }
      else if (trackResults.isLoading || generateResults.isLoading || orderFetching.isLoading){
        setContent(
          <div className='skel'>
            <div className='spinner'>
              <GoSync className='animate-spin' />
            </div>
            <h1>Loading escalation data...</h1>
          </div>
        )
      }
    }, [data, trackResults.isLoading, generateResults.isLoading, orderFetching.isLoading]);

    return content;
}

export default OrderB2BTrackingPage