import React, { useEffect } from 'react'
import B2CCalculator from '../components/customers/B2CCalculator'
import { Helmet } from 'react-helmet-async'
import { useAutoAnimate } from '@formkit/auto-animate/react';
import B2BCalculator from '../components/customers/B2BCalculator';

const Calculator = ({ setProgress }) => {
  const [parent, enable] = useAutoAnimate({duration: 350});  

  useEffect(() => {
      enable(true);
  }, [parent, enable]);

  return (
    <>
        <Helmet>
            <title>edaylivery - Every day Delivary, Ontime - Shipping Rate Calculator</title>
            <link rel='canonical' href={window.location.href} />
        </Helmet>
        <div className='hold-container' ref={parent}>
          <B2CCalculator setProgress={setProgress} />
          <B2BCalculator setProgress={setProgress} />
        </div>
    </>
  )
}

export default Calculator