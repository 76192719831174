import { useEffect, useState } from 'react';
import { useGetWareHouseQuery } from '../../store/index';

const CustomerWareHouse = ({ id }) => {
    const { data: wareHouse, isFetching } = useGetWareHouseQuery(id);
    const [content, setContent] = useState();

    useEffect(() => {
        if(wareHouse){
            setContent(
                wareHouse.address +
                ', ' +
                wareHouse.city +
                ', ' +
                wareHouse.state +
                ' - ' +
                wareHouse.pincode 
            )
        }
    }, [wareHouse, isFetching]);

    return content;
}

export default CustomerWareHouse;