import React, { useEffect, useState } from 'react'
import Button from '../common/Button.js';
import { useRegisterMutation } from '../../store/index.js';
import { useNavigate } from 'react-router-dom';
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useVerifyEmailMutation } from '../../store/index.js';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import { Helmet } from 'react-helmet-async';

const SignUp = ({ setProgress }) => {
    const [registerUser, registrationResults] = useRegisterMutation();
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const [email, setEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [verifyEmail, verificationResults] = useVerifyEmailMutation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [conPass, setConPass] = useState("");
    const [otp, setOtp] = useState("");
    const [role, setRole] = useState("customer");
    const [phone, setPhone] = useState();
    const [error, setError] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [showConPass, setShowConPass] = useState(false);

    useEffect(() => {
        enable(true);
        if(password !== "" && conPass !== "" && password !== conPass){
            setError("Password Does Not Match!");
        }
        else{
            setError(null);
        }
    }, [parent, enable, password, conPass]);

    const navigate = useNavigate();

    const handleRegister = async () => {
        setProgress(50);
        await registerUser({
            username,
            email,
            password,
            otp,
            role,
            phone
        }).unwrap().then((res) => {
            setProgress(100);
            navigate("/");
        }).catch((err) => {
            try{
                alert(err.data.message);
            }
            catch{
                alert("Request Timed Out! try again");
            }
            setProgress(100);
        });
    }

    const handleVerifyEmail = async () => {
        setProgress(50);
        await verifyEmail({ email }).unwrap().then((res) => {
            setProgress(100);
            setEmailVerified(true);
        }).catch((err) => {
            try{
                alert(err.data.message);
            }
            catch{
                alert("Request Timed Out! try again");
            }
            setProgress(100);
        });
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Sign Up</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='signup' ref={parent}>
                <div className='heading'>
                    <h1>Sign Up</h1>
                </div>
                {!emailVerified && (
                    <>
                    <div className='inputs'>
                        <input
                            type='text'
                            name='email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            placeholder='email'
                        />
                    </div>
                    <div className='buttons'>
                        <Button loading={verificationResults.isLoading} onClick={handleVerifyEmail}>
                            Verify Email
                        </Button>
                    </div>
                </>
                )}
                {emailVerified && (
                    <>
                        <div className='inputs'>
                            <input
                                type='text'
                                name='email'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder='email'
                            />
                            <input
                                type='Number'
                                name='phone no.'
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                                placeholder='phone no.'
                            />
                            <input
                                type='text'
                                name='username'
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                                placeholder='username'
                            />
                            <div className='cont'>
                                <input
                                    type={showPass ? 'text': 'password'}
                                    name='password'
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    placeholder='password'
                                />
                                <div onClick={() => setShowPass(!showPass)}>
                                {
                                    showPass ? <GoEye /> : <GoEyeClosed />
                                }
                                </div>
                            </div>
                            <div className='cont'>
                                <input
                                    type={showConPass ? 'text': 'password'}
                                    name='confirm_password'
                                    value={conPass}
                                    onChange={(e) => {
                                        setConPass(e.target.value);
                                    }}
                                    placeholder='confirm password'
                                />
                                <div onClick={() => setShowConPass(!showConPass)}>
                                    {
                                        showConPass ? <GoEye /> : <GoEyeClosed />
                                    }
                                </div>
                            </div>
                            <input
                                type='text'
                                name='otp'
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                }}
                                placeholder='OTP'
                            />
                        </div>
                        {error && (
                            <div className='error'>
                                {error}
                            </div>
                        )}
                        <div className='buttons'>
                            <Button loading={registrationResults.isLoading} onClick={handleRegister}>
                                Register
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default SignUp