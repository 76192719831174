import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetSingleEscalationQuery, useUpdateSingleEscalationMutation } from '../store/index';
import { GoSync } from 'react-icons/go';
import { getCookie } from '../helpers/cookies';
import Button from '../components/common/Button';

const EscalationsSinglePage = ({ setProgress }) => {
  const jwt = getCookie("delivaryUserJWT");
  const username = getCookie("delivaryUserName");
  const { id } = useParams();
  const { data: escalation, isFetching: escalationFetching } = useGetSingleEscalationQuery(id);
  const [content, setContent] = useState();
  const [updateEscalation, updateResults] = useUpdateSingleEscalationMutation();
  const [userReply, setReply] = useState();

  useEffect(() => {
    if(escalation){
      const handleReply = async () => {
        const data = {
          reply: {
            name: username,
            message: userReply
          }
        };
        setProgress(50);
        await updateEscalation({ data, id, jwt }).unwrap().then((res) => {
          console.log(res);
          setReply();
          setProgress(100);
        }).catch((err) => {
          console.log(err);
          setProgress(100);
        });
      }

      const handleCloseEscalation = async (id) => {
        const data = {
          status: "Closed"
        };
        setProgress(50);
        await updateEscalation({ data, id, jwt }).unwrap().then((res) => {
          console.log(res);
          setProgress(100);
        }).catch((err) => {
          console.log(err);
          setProgress(100);
        });
      }

      setContent(
        <div className='single'>
          <div className='heading'>
            <h1>{escalation.subject}</h1>
          </div>
          <div className='details'>
            <div className='left'>
              <div>Escalation ID: {escalation._id}</div>
              <div>Order ID: {escalation.orderId}</div>
            </div>
            <div className='right'>
              <div>Customer ID: {escalation.customer_id}</div>
              <div>Report Date: {new Date(escalation.createdAt).toLocaleDateString()}</div>
              <div>Status: {escalation.status}</div>
            </div>
          </div>
          <div className='desc'>
            <div>{escalation.description}</div>
            {escalation.reply.map((r) => {
              return  <div>{r.name} : {r.message}</div>;
            })}
          </div>
          {escalation.status !== "Closed" && (
            <div className='reply'>
              <div className='heading'>
                Reply
              </div>
              <textarea
                name='reply'
                placeholder='reply'
                value={userReply}
                onChange={(e) => setReply(e.target.value)}
              />
              <div className='buttons'>
                <Button loading={updateResults.isLoading} onClick={handleReply}>
                  Reply
                </Button>
                <Button loading={updateResults.isLoading} onClick={() => handleCloseEscalation(escalation._id)}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      )
    }
    else if(escalationFetching){
      setContent(
        <div className='skel'>
          <div className='spinner'>
            <GoSync className='animate-spin' />
          </div>
          <h1>Loading escalation data...</h1>
        </div>
      );
    }
  }, [escalation, escalationFetching, userReply, id, jwt, updateEscalation, updateResults.isLoading, setProgress, username])

  return content;
}

export default EscalationsSinglePage