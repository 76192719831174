import React, { useEffect, useState } from 'react'
import { getCookie } from '../helpers/cookies';
import { useGetCustomerTransactionsQuery } from '../store/index';
import { Helmet } from 'react-helmet-async';
import { exportToXL } from '../helpers/export';
import Button from '../components/common/Button';

const TransactionsCustomerPage = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const id = getCookie("delivaryUserId");
    const { data: transactions, isFetching: transactionsFetching } = useGetCustomerTransactionsQuery({ jwt, id });
    const [output, setOutput] = useState();
    const [noProd, setNoProd] = useState();

    
    useEffect(() => {
        if(transactions) {
            if(transactions.length !== 0) {
                const output = transactions.map((t) => {
                    return (
                        <tr key={t._id}>
                            <td>{t._id}</td>
                            <td>{t.transactionId}</td>
                            <td>{t.amount}</td>
                            <td>edaylivery</td>
                            <td>{new Date(t.createdAt).toUTCString()}</td>
                        </tr>
                    );
                });
                setNoProd();
                setOutput(output);
            }
            else {
                setNoProd(<div className='no-ord'>
                    No Transactions Found...
                </div>
                );
            }
        }
        else if(transactionsFetching === true){
            setNoProd(<div className='no-ord'>
                Loading Transactions...
            </div>
            );
            setOutput();
        }
        else{
            setNoProd(<div className='no-ord'>
                Error loading Transactions! Please Reload.
            </div>
            );
            setOutput();
        }
    }, [transactions, transactionsFetching]);

    const handleExport = () => {
        if(transactions){
            exportToXL(transactions);
        }
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Customer Transaction</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='orders'>
                <div className='top'>
                    <input type='text' name='search' placeholder='search' />
                </div>
                <div className='order-table'>
                    <div className='heading'>
                        <h1>Transactions</h1>
                    </div>
                    <div className='buttons'>
                        <Button onClick={handleExport}>
                        Export
                        </Button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Transaction ID</th>
                                <th>Recharge Amount</th>
                                <th>Transacted To</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {output}
                        </tbody>
                    </table>
                    {noProd}
                </div>
            </div>
        </>
    )
}

export default TransactionsCustomerPage