import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const VerifyEmailApi = createApi({
    reducerPath: "VerifyEmail",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/users",
    }),
    endpoints(builders){
        return {
            VerifyEmail: builders.mutation({
                query: (data) => {
                    return {
                        url: "/verify_email",
                        method: "POST",
                       data
                    }
                }
            }),
        }
    }
});

export const { useVerifyEmailMutation } = VerifyEmailApi;
export { VerifyEmailApi };