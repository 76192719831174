import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import SideBar from '../common/SideBar';

const CustomerSideBar = ({ setProgress }) => {    
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const [OrderDis, setOrderDis] = useState(false);
    const [BillDis, setBillDis] = useState(false);

    const handleOrderDisplay = (e) => {
        e.preventDefault();
        setOrderDis(!OrderDis);
    }

    const handleBillDisplay = (e) => {
        e.preventDefault();
        setBillDis(!BillDis);
    }

    useEffect(() => {
        enable(true);
    }, [parent, enable]);
    return (
        <div className='container' ref={parent}>
            <div className='main-container' ref={parent}>
                <div className='menu-items'>
                <div className='link'>
                        <NavLink to="/customer">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/dashboard.svg" alt="DashBoard" /> Dashboard
                        </NavLink>
                    </div>
                    <div className='link' ref={parent}>
                        <NavLink to="#" onClick={handleOrderDisplay}>
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/orders.svg" alt="Orders" /> Orders
                        </NavLink>
                        <div className='sub-link' style={{ display: OrderDis ? "block" : "none"}}>
                            <NavLink to="/customer/orders/b2c">
                                B2C
                            </NavLink>
                        </div>
                        <div className='sub-link' style={{ display: OrderDis ? "block" : "none"}}>
                            <NavLink to="/customer/orders/b2b">
                                B2B
                            </NavLink>
                        </div>
                    </div>
                    <div className='link' ref={parent}>
                        <NavLink to="#" onClick={handleBillDisplay}>
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/billing.svg" alt="Billing" />  Billing
                        </NavLink>
                        <div className='sub-link' style={{ display: BillDis ? "block" : "none"}}>
                            <NavLink to="/customer/calculator">
                                Rate Calculator
                            </NavLink>
                        </div>
                    </div>
                    <div className='link'>
                        <NavLink to="/customer/transactions">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/process_ndr.svg" alt="Transactions" /> Transactions
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/customer/support">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/support.svg" alt="Support" /> Support
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/customer/coupons">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/manage.svg" alt="Support" /> Coupons
                        </NavLink>
                    </div>
                    <div className='link'>
                        <NavLink to="/customer/profile">
                            <img src="https://sr-sidebar.shiprocket.in/assets/navbar/buyer_experience.svg" alt="Profile" /> Profile
                        </NavLink>
                    </div>
                </div>
                <div className='output' ref={parent}>
                    <Outlet />
                </div>
            </div>
            <SideBar setProgress={setProgress} />
        </div>
    )
}

export default CustomerSideBar