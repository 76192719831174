import React, { useEffect, useState } from 'react'
import { useLocation, Routes } from 'react-router-dom';

const CustomRoutes = ({ children, setProgress }) => {
    const [previousRoute, setPreviousRoute] = useState('');
    const location = useLocation();
    useEffect(() => {
        if(previousRoute !== location.pathname){
            setProgress(100);
        }
        else {
            setPreviousRoute(location.pathname);
        }
    }, [location , previousRoute, setProgress]);
  return <Routes>
    {children}
  </Routes>;
}

export default CustomRoutes