import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";

const BarChart = ({ series }) => {
  return (
    <Bar data={series} options={{ indexAxis: "y"}} width={500} height={-2500} />
  )
}

export default BarChart