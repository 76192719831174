import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const TransactionApi = createApi({
    reducerPath: "Transactions",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/transactions"
    }),
    endpoints(builders){
        return {
            createTransaction: builders.mutation({
                invalidatesTags: (res, err, data) => {
                    return [{ type: "Transactions", jwt: data.jwt }]
                },
                query: ({ data, jwt }) => {
                    return {
                        url: "/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            getAllTransactions: builders.query({
                providesTags: (res, err, data) => {
                    return [{ type: "Transactions", jwt: data }]
                },
                query: (jwt) => {
                    return {
                        url: "/",
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        }
                    }
                }
            }),
            getCustomerTransactions: builders.query({
                providesTags: (res, err, data) => {
                    return [{ type: "Transactions", jwt: data.jwt }]
                },
                query: ({ jwt, id }) => {
                    return  {
                        url: `/customer/${id}`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        }
                    }
                }
            }),
            getSingleTransactions: builders.query({
                query: ({ id, jwt }) => {
                    return  {
                        url: `/${id}`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        }
                    }
                }
            }),
            deleteTransactions: builders.query({
                invalidatesTags: (res, err, data) => {
                    return [{ type: "Transactions", jwt: data.jwt }]
                },
                query: ({ id, jwt }) => {
                    return  {
                        url: `/${id}`,
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        }
                    }
                }
            })
        }
    }
});

export const { useCreateTransactionMutation, useGetAllTransactionsQuery, useGetCustomerTransactionsQuery, useGetSingleTransactionsQuery, useDeleteTransactionsQuery } = TransactionApi;
export { TransactionApi };