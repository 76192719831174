import React from 'react'

const Partners = () => {
  return (
    <div className='partners'>
        <h1>Our Trusted Partners</h1>
        <div className='images'>
            <img src={process.env.PUBLIC_URL + 'xpressbees.png'} alt='xpressbees' />
            <img src={process.env.PUBLIC_URL + 'delhivery.png'} alt='delhivery' />
        </div>
    </div>
  )
}

export default Partners