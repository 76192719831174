import Barcode from 'react-barcode';

const PrintBarcode = ({ val }) => {
  return (
    <div className='barcode'>
      <Barcode width={2} height={60} value={val} />
    </div>
  );
}

export default PrintBarcode;