import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetSingleB2BOrderQuery } from '../../store/index';
import { GoSync } from 'react-icons/go';

const OrderB2BDetails = () => {
    const { id } = useParams();
    const { data: order, isFetching: orderFetching } = useGetSingleB2BOrderQuery(id);
    const [content, setContent] = useState();

    useEffect(() => {
        if(order){
            console.log(order);
            setContent(
                <>
                    <div className='single'>
                        <div className='heading'>
                            <h1>Order ID: {order.orderId}</h1>
                        </div>
                        <div className='details'>
                            <div className='left'>
                                <div className='heading'>
                                    <h1>Consigner Details</h1>
                                </div>
                                <div>Name: {order.consigner_details.consigner_name}</div>
                                <div>Address:{order.consigner_details.consigner_address}</div>
                                <div>Phone:{order.consigner_details.consigner_phone}</div>
                                <div>GSTIN:{order.consigner_details.consigner_gst_number ? order.consigner_details.consigner_gst_number : "None"}</div>
                            </div>
                            <div className='right'>
                                <div className='heading'>
                                    <h1>Consignee Details</h1>
                                </div>
                                <div>Name: {order.consignee_details.consignee_name}</div>
                                <div>Address:{order.consignee_details.consignee_address}</div>
                                <div>Phone:{order.consignee_details.consignee_phone}</div>
                                <div>GSTIN:{order.consignee_details.consignee_gst_number ? order.consignee_details.consignee_gst_number : "None"}</div>
                            </div>
                        </div>
                        <div className='orders'>
                            <div className='order-table'>
                                <div className='heading'>
                                    <h1>Provider Details</h1>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Provider Name</th>
                                            <th>LR Number</th>
                                            <th>Tracking ID</th>
                                            <th>Shipping ID</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    order.courier_id === 12345 ? "XpressBees B2B": "-"
                                                }
                                            </td>
                                            <td>{order.lr_number}</td>
                                            <td>{order.awb_number}</td>
                                            <td>{order.shipping_id}</td>
                                            <td>{order.order_status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='order-table'>
                                <div className='heading'>
                                    <h1>Product Details</h1>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Total Boxes</th>
                                            <th>Invoice Price</th>
                                            <th>Tax</th>
                                            <th>Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order.product_details.map((p) => {
                                                return (
                                                    <tr>
                                                        <td>{p.product_boxes}</td>
                                                        <td>{p.product_price}</td>
                                                        <td>{p.product_tax_per}</td>
                                                        <td>{p.product_weight}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='order-table'>
                                <div className='heading'>
                                    <h1>Invoice Details</h1>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Invoice Date</th>
                                            <th>EBill Number</th>
                                            <th>Ebill Expiry date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order.invoice_details.map((p) => {
                                                return (
                                                    <tr>
                                                        <td>{p.invoice_number || "-"}</td>
                                                        <td>{p.invoice_date || "-"}</td>
                                                        <td>{p.ebill_number || "-"}</td>
                                                        <td>{p.ebill_expiry_date || "-"}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='order-table'>
                                <div className='heading'>
                                    <h1>Product Box Details</h1>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Height</th>
                                            <th>Breadth</th>
                                            <th>Lenght</th>
                                            <th>HSN</th>
                                            <th>SKU</th>
                                            <th>Boxes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order.product_box_details.map((o) => {
                                                return <tr>
                                                    <td>{o.name}</td>
                                                    <td>{o.height}</td>
                                                    <td>{o.breadth}</td>
                                                    <td>{o.length}</td>
                                                    <td>{o.hsn}</td>
                                                    <td>{o.sku}</td>
                                                    <td>{o.boxes}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='order-table'>
                                <div className='heading'>
                                    <h1>Payment Details</h1>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Order Total</th>
                                            <th>Order Discount</th>
                                            <th>Collectable Amount</th>
                                            <th>COD Charges</th>
                                            <th>Shipping Charges</th>
                                            <th>Payment Status</th>
                                            <th>Payment Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{order.order_total}</td>
                                            <td>{order.discount}</td>
                                            <td>{order.collectable_amount}</td>
                                            <td>{order.cod_charges}</td>
                                            <td>{order.shipping_charges}</td>
                                            <td>{order.payment_status}</td>
                                            <td>{order.payment_method}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (orderFetching.isLoading) {
            setContent(
                <div className='skel'>
                    <div className='spinner'>
                        <GoSync className='animate-spin' />
                    </div>
                    <h1>Loading escalation data...</h1>
                </div>
            )
        }
        else {
            setContent();
        }
    }, [order, orderFetching.isLoading]);

    return content;
}

export default OrderB2BDetails