import React, { useState, useEffect } from "react";
import Button from "../components/common/Button";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { lookup } from "india-pincode-lookup";
import {
  useCalculateDpriceMutation,
  useCalculatePriceMutation,
  useCancelShipmentMutation,
  useCreateDClientWareHouseMutation,
  useCreateDOrderMutation,
  useCreateOrdersMutation,
  useCreateShipmentMutation,
  useGenerateTokenMutation,
  useGetAllProfitsQuery,
  useGetSingleCouponMutation,
} from "../store/index";
import CalculatedOrderResults from "../components/orders/CalculateOrderResults";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../helpers/cookies";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateAdminOrder = ({ setProgress }) => {
  const jwt = getCookie("delivaryUserJWT");
  const { data: profitRates } = useGetAllProfitsQuery();
  const [XpressBeesGenerateToken, XpressBeesGenerationResults] =
    useGenerateTokenMutation();
  const [XpressBeesCalculateRate, XpressBeesCalculationResults] =
    useCalculatePriceMutation();
  const [DelhivaryCalculateRate, DelhivaryCalculateResults] =
    useCalculateDpriceMutation();
  const [XpressbeesCreateShipment, XpressBeesCreateShipmentResults] =
    useCreateShipmentMutation();
  const [XpressbeesCancelShipment, XpressbeesCancelShipmentResults] =
    useCancelShipmentMutation();
  const [DelhivaryCreateShipment, DelhivaryCreateShipmentResults] =
    useCreateDOrderMutation();
  const [DelhivaryClientWareHouse, DelhivaryClientWareHouseResults] =
    useCreateDClientWareHouseMutation();
  const [createOrder, creationResults] = useCreateOrdersMutation();
  const [parent, enable] = useAutoAnimate({ duration: 350 });
  const [consigneeError, setConsigneeError] = useState();
  const [consignerError, setConsignerError] = useState();
  const [consigneeName, setConsigneeName] = useState();
  const [consigneePhone, setConsigneePhone] = useState();
  const [consigneePinCode, setConsigneePinCode] = useState();
  const [consigneeCity, setConsigneeCity] = useState();
  const [consigneeState, setConsigneeState] = useState();
  const [consigneeAddress, setConsigneeAddress] = useState();
  const [consigneeGSTNumber, setConsigneeGSTNumber] = useState("");
  const [consignerName, setConsignerName] = useState();
  const [consignerPhone, setConsignerPhone] = useState();
  const [consignerPinCode, setConsignerPinCode] = useState();
  const [consignerCity, setConsignerCity] = useState();
  const [consignerState, setConsignerState] = useState();
  const [consignerAddress, setConsignerAddress] = useState();
  const [consignerGSTNumber, setConsignerGSTNumber] = useState("");
  const [productName, setProductName] = useState();
  const [productQuantity, setProductQuantity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productTax, setProductTax] = useState(0);
  const [productSKU, setProductSKU] = useState("");
  const [productHSN, setProductHSN] = useState("");
  const [productWeight, setProductWeight] = useState();
  const [productHeight, setProductHeight] = useState();
  const [productBreadth, setProductBreadth] = useState();
  const [productLength, setProductLength] = useState();
  const [calculations, setCalculations] = useState();
  const [courier_id, setCourierId] = useState();
  const [invoice_number, setInvoiceNumber] = useState("");
  const [invoice_date, setInvoiceDate] = useState("");
  const [ebill_number, setEbillNumber] = useState("");
  const [ebill_expiry_date, setEbillExpiryDate] = useState("");
  const [pickup_location, setPickupLocation] = useState("franchise");
  const [order_total, setOrderTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [collectable_amount, setCollectableAmount] = useState(0);
  const [cod, setCod] = useState("prepaid");
  const [coupon_id, setCouponId] = useState();
  const [getCoupon, getResults] = useGetSingleCouponMutation();
  const navigate = useNavigate();

  useEffect(() => {
    enable(true);
  }, [parent, enable]);

  useEffect(() => {
    setOrderTotal(parseInt(productPrice) * parseInt(productQuantity));
    setCollectableAmount(cod === "COD" ? Math.ceil(order_total - discount) : 0);
  }, [productPrice, productQuantity, discount, order_total, cod]);

  useEffect(() => {
    if (lookup(consigneePinCode).length === 0) {
      setConsigneeError("consignee address does not exists!");
    } else {
      setConsigneeError();
      if (consigneePinCode) {
        if (consigneePinCode.length === 6) {
          const data = lookup(consigneePinCode)[0];
          setConsigneeCity(data.taluk);
          setConsigneeState(data.stateName);
        }
      }
    }
    if (lookup(consignerPinCode).length === 0) {
      setConsignerError("consigner address does not exists!");
    } else {
      setConsignerError();
      if (consignerPinCode) {
        if (consignerPinCode.length === 6) {
          const data = lookup(consignerPinCode)[0];
          setConsignerCity(data.taluk);
          setConsignerState(data.stateName);
        }
      }
    }
  }, [consigneePinCode, consignerPinCode]);

  const handleCalculate = async () => {
    if (
      !consigneeName ||
      !consigneePhone ||
      !consigneePinCode ||
      !consigneeCity ||
      !consigneeState ||
      !consigneeAddress
    ) {
      alert("All fields in Consignee Details are mandatory");
    } else if (
      !consignerName ||
      !consignerPhone ||
      !consignerPinCode ||
      !consignerCity ||
      !consignerState ||
      !consignerAddress
    ) {
      alert("All fields in Consigner Details are mandatory");
    } else if (
      !productName ||
      !productPrice ||
      !productWeight ||
      !productHeight ||
      !productBreadth ||
      !productLength
    ) {
      alert("All fields in Product Details are mandatory");
    } else if (consigneeError || consignerError) {
      alert("Invalid pincodes!!");
    } else {
      const data = {
        origin: consigneePinCode,
        destination: consignerPinCode,
        weight: parseInt(productWeight) / 1000,
        height: productHeight,
        breadth: productBreadth,
        length: productLength,
        order_type_user: "ecom",
        cod_amount: "23",
        cod: cod === "COD" ? "yes" : "no",
      };
      setProgress(33);
      let finalRates = [];
      await XpressBeesGenerateToken()
        .then(async (res) => {
          const token = res.data.data;
          setProgress(66);
          let coupon;
          if (coupon_id) {
            await getCoupon(coupon_id)
              .unwrap()
              .then((res) => {
                coupon = res;
              })
              .catch((err) => {
                console.log(err);
                alert("Invalid Coupon ID");
              });
          }
          await XpressBeesCalculateRate({ data, token }).then((res) => {
            const rates = res.data.message;
            const convertedRates = [];
            rates.map((r) => {
              let myProfitRate;
              if (profitRates) {
                if (profitRates.length !== 0) {
                  [myProfitRate] = profitRates.filter((myRate) => {
                    if (myRate.provider === "XpressBees") {
                      if (
                        r.name.toLowerCase().includes("air") &&
                        myRate.transport.toLowerCase() === "air"
                      ) {
                        return true;
                      } else if (
                        !r.name.toLowerCase().includes("air") &&
                        myRate.transport.toLowerCase() === "surface"
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return false;
                  });
                }
              }
              if (myProfitRate !== undefined) {
                const shippingMultiple =
                  1 + parseInt(myProfitRate.shipping_rate) / 100;
                let discount = 1;
                if (coupon) {
                  if (
                    new Date(coupon.expiry_date).getTime() >=
                    new Date().getTime()
                  ) {
                    if (parseInt(coupon.usage) > parseInt(coupon.used)) {
                      if (parseInt(coupon.limit) <= parseInt(productWeight)) {
                        discount = 1 - parseInt(coupon.discount) / 100;
                      } else {
                        alert("Coupon limit does not meet!!");
                      }
                    } else {
                      alert("Coupon usage limit exceeded!!");
                    }
                  } else {
                    alert("Coupon Expired!!");
                  }
                }
                const codMultiple = 1 + parseInt(myProfitRate.cod_rate) / 100;
                const actCouierCharge = Math.ceil(
                  parseFloat(r.courier_charges) * shippingMultiple * 1.18
                );
                const courier_charges = Math.ceil(actCouierCharge * discount);
                const cod_charges = Math.ceil(
                  parseFloat(r.cod_charges) * codMultiple * 1.18
                );
                const total_price = courier_charges + cod_charges;
                const profit = actCouierCharge - parseInt(courier_charges);
                convertedRates.push({
                  ...r,
                  coupon_id,
                  actCouierCharge,
                  courier_charges,
                  cod_charges,
                  total_price,
                  profit,
                  courier_id: myProfitRate.courier_id,
                  name: r.name.replace("BIKASH BHADRA FRANCHISE", "XPressBees"),
                });
              } else {
                convertedRates.push(r);
              }
              return r;
            });
            finalRates.push(...convertedRates);
            setProgress(100);
          });
        })
        .catch((err) => {
          console.log(err);
        });
      const this_data = {
        md: "S",
        ss: "Delivered",
        o_pin: consigneePinCode,
        d_pin: consignerPinCode,
        cgm: productWeight,
        pt: cod === "prepaid" ? "Pre-paid" : "COD",
        cod: "0",
      };
      await DelhivaryCalculateRate({ data: this_data, jwt })
        .then(async (res) => {
          let coupon;
          if (coupon_id) {
            await getCoupon(coupon_id)
              .unwrap()
              .then((res) => {
                coupon = res;
              })
              .catch((err) => {
                console.log(err);
                alert("Invalid Coupon ID");
              });
          }
          if (profitRates) {
            const [myProfitRate] = profitRates.filter((myRate) => {
              if (myRate.provider === "Delhivary") {
                if (myRate.transport.toLowerCase() === "surface") {
                  return true;
                } else {
                  return false;
                }
              }
              return false;
            });
            if (myProfitRate !== undefined) {
              const shippingMultiple =
                1 + parseInt(myProfitRate.shipping_rate) / 100;
              let discount = 1;
              if (coupon) {
                if (
                  new Date(coupon.expiry_date).getTime() >= new Date().getTime()
                ) {
                  if (parseInt(coupon.usage) > parseInt(coupon.used)) {
                    if (parseInt(coupon.limit) <= parseInt(productWeight)) {
                      discount = 1 - parseInt(coupon.discount) / 100;
                    } else {
                      alert("Coupon limit does not meet!!");
                    }
                  } else {
                    alert("Coupon usage limit exceeded!!");
                  }
                } else {
                  alert("Coupon Expired!!");
                }
              }
              const codMultiple = 1 + parseInt(myProfitRate.cod_rate) / 100;
              const actCouierCharge = Math.ceil(
                parseFloat(res.data[0].gross_amount) * shippingMultiple * 1.18
              );
              const courier_charges = Math.ceil(actCouierCharge * discount);
              const cod_charges = Math.ceil(
                parseFloat(res.data[0].charge_COD) * codMultiple * 1.18
              );
              const total_price = courier_charges + cod_charges;
              const profit = actCouierCharge - parseInt(courier_charges);

              finalRates.push({
                coupon_id,
                actCouierCharge,
                courier_charges,
                cod_charges,
                total_price,
                profit,
                courier_id: "1234543",
                name: "Delhivary B2C",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setProgress(100);
        });
      setCalculations(finalRates);
    }
  };

  const handleShipLater = async () => {
    if (
      !consigneeName ||
      !consigneePhone ||
      !consigneePinCode ||
      !consigneeCity ||
      !consigneeState ||
      !consigneeAddress
    ) {
      alert("All fields in Consignee Details are mandatory");
    } else if (
      !consignerName ||
      !consignerPhone ||
      !consignerPinCode ||
      !consignerCity ||
      !consignerState ||
      !consignerAddress
    ) {
      alert("All fields in Consigner Details are mandatory");
    } else if (
      !productName ||
      !productPrice ||
      !productWeight ||
      !productHeight ||
      !productBreadth ||
      !productLength
    ) {
      alert("All fields in Product Details are mandatory");
    } else if (!invoice_number || !invoice_date) {
      alert("Invoice Fields are mandatory");
    } else if (
      !pickup_location ||
      order_total === "" ||
      collectable_amount === ""
    ) {
      alert("All other details are mandatory");
    } else if (!courier_id) {
      alert("please select a courier option");
    } else {
      let invodate;
      try {
        invodate = new Date(invoice_date);
      } catch (err) {
        alert("invalid invoice date");
      }
      if (invodate) {
        const [finalCourier] = calculations.filter((c) => {
          return c.courier_id === courier_id;
        });
        const id = `EDL${Math.floor(Math.random() * 100000)}`;
        const order_data = {
          orderId: id,
          awb_number: "-",
          shipping_id: "-",
          courier_id,
          consignee_details: {
            consignee_name: consigneeName,
            consignee_phone: consigneePhone,
            consignee_pincode: consigneePinCode,
            consignee_city: consigneeCity,
            consignee_state: consigneeState,
            consignee_address: consigneeAddress,
            consignee_gst_number: consigneeGSTNumber,
          },
          consigner_details: {
            consigner_name: consignerName,
            consigner_phone: consignerPhone,
            consigner_pincode: consignerPinCode,
            consigner_city: consignerCity,
            consigner_state: consignerState,
            consigner_address: consignerAddress,
            consigner_gst_number: consignerGSTNumber,
          },
          product_details: [
            {
              product_name: productName,
              product_qty: productQuantity,
              product_price: productPrice,
              product_tax_per: productTax,
              product_sku: productSKU,
              product_hsn: productHSN,
            },
          ],
          order_total,
          discount,
          collectable_amount,
          shipping_charges: finalCourier.actCouierCharge,
          cod_charges: finalCourier.cod_charges,
          profit: finalCourier.profit,
          payment_status: cod === "COD" ? "pending" : "paid",
          payment_method: cod,
          pickup_location,
          product_box_details: {
            weight: productWeight,
            height: productHeight,
            length: productLength,
            breadth: productBreadth,
          },
          order_status: "ship_later",
          invoice_details: [
            {
              invoice_number,
              invoice_date: `${invodate.getFullYear()}-${invodate.getMonth()}-${invodate.getDate()}`,
              ebill_number,
              ebill_expiry_date,
            },
          ],
        };
        console.log(order_data);
        setProgress(50);
        await createOrder({ data: order_data, jwt })
          .unwrap()
          .then(async (res) => {
            console.log(res);
            setProgress(100);
            navigate("/admin/orders/b2c");
          })
          .catch((err) => {
            alert(err.message);
            setProgress(100);
          });
      }
    }
  };

  const handleShipNow = async () => {
    if (
      !consigneeName ||
      !consigneePhone ||
      !consigneePinCode ||
      !consigneeCity ||
      !consigneeState ||
      !consigneeAddress
    ) {
      alert("All fields in Consignee Details are mandatory");
    } else if (
      !consignerName ||
      !consignerPhone ||
      !consignerPinCode ||
      !consignerCity ||
      !consignerState ||
      !consignerAddress
    ) {
      alert("All fields in Consigner Details are mandatory");
    } else if (
      !productName ||
      !productPrice ||
      !productWeight ||
      !productHeight ||
      !productBreadth ||
      !productLength
    ) {
      alert("All fields in Product Details are mandatory");
    } else if (!invoice_number || !invoice_date) {
      alert("Invoice Fields are mandatory");
    } else if (
      !pickup_location ||
      order_total === "" ||
      collectable_amount === ""
    ) {
      alert("All other details are mandatory");
    } else if (!courier_id) {
      alert("please select a courier option");
    } else {
      let invodate;
      try {
        invodate = new Date(invoice_date);
      } catch (err) {
        alert("invalid invoice date");
      }
      if (invodate) {
        const [finalCourier] = calculations.filter((c) => {
          return c.courier_id === courier_id;
        });
        console.log("fc: ", finalCourier);
        if (finalCourier.name === "Delhivary B2C") {
          const act_data = {
            shipments: [
              {
                name: consigneeName,
                add: consigneeAddress,
                pin: consigneePinCode,
                city: consigneeCity,
                state: consigneeState,
                country:"India",
                phone: consigneePhone,
                order: `${Math.ceil(Math.random() * 10000)}`,
                payment_mode: cod === "COD" ? "COD" : "Pre-Paid",
                return_pin: consignerPinCode,
                return_city: consignerCity,
                return_phone: consignerPhone,
                return_add: consignerAddress,
                return_state: consignerState,
                return_country: "India",
                products_desc: "",
                hsn_code: productHSN,
                cod_amount: cod === "COD"? 0 : collectable_amount,
                order_date: null,
                total_amount: order_total,
                seller_add: consignerAddress,
                seller_name: consignerName,
                seller_inv: "",
                quantity: 1,
                waybill: "",
                shipment_width: productBreadth,
                shipment_height: productHeight,
                weight: productWeight,
                seller_gst_tin: "",
                shipping_mode: "Surface",
                address_type: "home"
              }
            ],
            pickup_location: {
              name: consignerName,
              add: consignerAddress,
              city: consignerCity,
              pin_code: consignerPinCode,
              country: "India",
              phone: consignerPhone
            }
          };
          const clientwarehouse = {
            phone: consignerPhone,
            city: consignerCity,
            name: consignerName,
            pin: consignerPinCode,
            address: consignerAddress,
            country: "India",
            email: "abc@gmail.com",
            registered_name: consignerName,
            return_address: consignerAddress,
            return_pin: consignerPinCode,
            return_city: consignerCity,
            return_state: consignerState,
            return_country: "India",
          };
          await DelhivaryClientWareHouse({ data: clientwarehouse, jwt })
            .then((res) => {
              console.log(res);
              setProgress(50);
            })
            .catch((err) => {
              console.log(err);
              setProgress(50);
            });
          console.log(act_data);

          await DelhivaryCreateShipment({ data: act_data, jwt })
            .then(async (res) => {
              console.log("results:",res);
              if (res.data.success) {
                const awb = res.data.packages[0].waybill;
                const shipping = res.data.packages[0].sort_code;
                const id = `EDL${Math.floor(Math.random() * 100000)}`;
                const order_data = {
                  orderId: id,
                  awb_number: awb,
                  shipping_id: shipping,
                  courier_id,
                  consignee_details: {
                    consignee_name: consigneeName,
                    consignee_phone: consigneePhone,
                    consignee_pincode: consigneePinCode,
                    consignee_city: consigneeCity,
                    consignee_state: consigneeState,
                    consignee_address: consigneeAddress,
                    consignee_gst_number: consigneeGSTNumber,
                  },
                  consigner_details: {
                    consigner_name: consignerName,
                    consigner_phone: consignerPhone,
                    consigner_pincode: consignerPinCode,
                    consigner_city: consignerCity,
                    consigner_state: consignerState,
                    consigner_address: consignerAddress,
                    consigner_gst_number: consignerGSTNumber,
                  },
                  product_details: [
                    {
                      product_name: productName,
                      product_qty: productQuantity,
                      product_price: productPrice,
                      product_tax_per: productTax,
                      product_sku: productSKU,
                      product_hsn: productHSN,
                    },
                  ],
                  order_total,
                  discount,
                  collectable_amount,
                  shipping_charges: finalCourier.actCouierCharge,
                  cod_charges: finalCourier.cod_charges,
                  profit: finalCourier.profit,
                  coupon_id: finalCourier.coupon_id,
                  payment_status: cod === "COD" ? "pending" : "paid",
                  payment_method: cod,
                  pickup_location,
                  product_box_details: {
                    weight: productWeight,
                    height: productHeight,
                    length: productLength,
                    breadth: productBreadth,
                  },
                  order_status: "Success",
                  invoice_details: [
                    {
                      invoice_number,
                      invoice_date: `${invodate.getFullYear()}-${invodate.getMonth()}-${invodate.getDate()}`,
                      ebill_number,
                      ebill_expiry_date,
                    },
                  ],
                };
                console.log(order_data);
                await createOrder({ data: order_data, jwt })
                  .unwrap()
                  .then(async (res) => {
                    setProgress(100);
                    console.log("order_created:", res);
                    navigate("/admin/orders/b2c");
                  })
                  .catch(async (err) => {
                    setProgress(80);
                    console.log("user_update_error:", err);
                  });
              } else {
                console.log(res.data);
              }
              setProgress(100);
            })
            .catch((err) => {
              alert("We regret to inform you that, We are very much occupied with loads, please try again later or reach out to our support team. Thank You");
                      setProgress(100);
            });
        } else {
          const id = `EDL${Math.floor(Math.random() * 100000)}`;
          const data = {
            id,
            order_unique_number: "yes",
            payment_method: cod,
            consigner_name: consignerName,
            consigner_phone: consignerPhone,
            consigner_pincode: consignerPinCode,
            consigner_city: consignerCity,
            consigner_state: consignerState,
            consigner_address: consignerAddress,
            consigner_gst_number: consignerGSTNumber,
            consignee_name: consigneeName,
            consignee_phone: consigneePhone,
            consignee_pincode: consigneePinCode,
            consignee_city: consigneeCity,
            consignee_state: consigneeState,
            consignee_address: consigneeAddress,
            consignee_gst_number: consigneeGSTNumber,
            products: [
              {
                product_name: productName,
                product_qty: productQuantity,
                product_price: productPrice,
                product_tax_per: productTax,
                product_sku: productSKU,
                product_hsn: productHSN,
              },
            ],
            invoice: [
              {
                invoice_number,
                invoice_date: `${invodate.getFullYear()}-${invodate.getMonth()}-${invodate.getDate()}`,
                ebill_number,
                ebill_expiry_date,
              },
            ],
            weight: productWeight,
            height: productHeight,
            length: productLength,
            breadth: productBreadth,
            courier_id: courier_id.toString(),
            pickup_location,
            shipping_charges: finalCourier.courier_charges.toString(),
            cod_charges: finalCourier.cod_charges.toString(),
            order_amount: order_total.toString(),
            discount: discount.toString(),
            collectable_amount: collectable_amount.toString(),
          };
          setProgress(20);
          await XpressBeesGenerateToken()
            .unwrap()
            .then(async (res) => {
              const token = res.data;
              setProgress(40);
              console.log("token genrated");
              await XpressbeesCreateShipment({ data, token })
                .unwrap()
                .then(async (res) => {
                  setProgress(60);
                  console.log("create_shipment:", res);
                  const awb_number = res.awb_number;
                  if (!awb_number) {
                    alert(JSON.stringify(res));
                  } else {
                    const order_data = {
                      orderId: id,
                      awb_number: res.awb_number,
                      shipping_id: res.shipping_id,
                      courier_id,
                      consignee_details: {
                        consignee_name: consigneeName,
                        consignee_phone: consigneePhone,
                        consignee_pincode: consigneePinCode,
                        consignee_city: consigneeCity,
                        consignee_state: consigneeState,
                        consignee_address: consigneeAddress,
                        consignee_gst_number: consigneeGSTNumber,
                      },
                      consigner_details: {
                        consigner_name: consignerName,
                        consigner_phone: consignerPhone,
                        consigner_pincode: consignerPinCode,
                        consigner_city: consignerCity,
                        consigner_state: consignerState,
                        consigner_address: consignerAddress,
                        consigner_gst_number: consignerGSTNumber,
                      },
                      product_details: [
                        {
                          product_name: productName,
                          product_qty: productQuantity,
                          product_price: productPrice,
                          product_tax_per: productTax,
                          product_sku: productSKU,
                          product_hsn: productHSN,
                        },
                      ],
                      order_total,
                      discount,
                      collectable_amount,
                      shipping_charges: finalCourier.actCouierCharge,
                      cod_charges: finalCourier.cod_charges,
                      profit: finalCourier.profit,
                      coupon_id: finalCourier.coupon_id,
                      payment_status: cod === "COD" ? "pending" : "paid",
                      payment_method: cod,
                      pickup_location,
                      product_box_details: {
                        weight: productWeight,
                        height: productHeight,
                        length: productLength,
                        breadth: productBreadth,
                      },
                      order_status: res.message,
                      invoice_details: [
                        {
                          invoice_number,
                          invoice_date: `${invodate.getFullYear()}-${invodate.getMonth()}-${invodate.getDate()}`,
                          ebill_number,
                          ebill_expiry_date,
                        },
                      ],
                    };
                    console.log("order_data:", order_data);
                    await createOrder({ data: order_data, jwt })
                      .unwrap()
                      .then(async (res) => {
                        setProgress(100);
                        console.log("order_created:", res);
                        navigate("/admin/orders/b2c");
                      })
                      .catch(async (err) => {
                        setProgress(80);
                        console.log("user_update_error:", err);
                        const cancel_data = {
                          awb_number,
                        };
                        alert(JSON.stringify(err));
                        console.log("cancel_shipment_data:", cancel_data);
                        await XpressbeesCancelShipment({
                          data: cancel_data,
                          token,
                        })
                          .unwrap()
                          .then((res) => {
                            alert("could not place the order");
                            setProgress(100);
                          })
                          .catch((err) =>
                            console.log("cancel_shipment_error:", err)
                          );
                      });
                  }
                });
            })
            .catch((err) => {
              setProgress(100);
              alert(err.data.message);
            });
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          edaylivery - Every day Delivary, Ontime - Admin Create B2C Order
        </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="heading">
        <h1>Create Order</h1>
      </div>
      <div className="create-order">
        <div className="signup">
          <div className="heading">
            <h1>Consigner address</h1>
          </div>
          <div className="inputs">
            <input
              type="text"
              name="username"
              value={consignerName}
              onChange={(e) => setConsignerName(e.target.value)}
              placeholder="name"
            />
            <input
              type="number"
              name="phone"
              value={consignerPhone}
              onChange={(e) => setConsignerPhone(e.target.value)}
              placeholder="phone"
            />
            <input
              type="text"
              name="pincode"
              value={consignerPinCode}
              onChange={(e) => setConsignerPinCode(e.target.value)}
              placeholder="pincode"
            />
            <input
              type="text"
              name="city"
              value={consignerCity}
              onChange={(e) => setConsignerCity(e.target.value)}
              placeholder="city"
            />
            <input
              type="text"
              name="state"
              value={consignerState}
              onChange={(e) => setConsignerState(e.target.value)}
              placeholder="state"
            />
            <input
              type="text"
              name="address"
              value={consignerAddress}
              onChange={(e) => setConsignerAddress(e.target.value)}
              placeholder="address"
            />
            <input
              type="text"
              name="gst_number"
              value={consignerGSTNumber}
              onChange={(e) => setConsignerGSTNumber(e.target.value)}
              placeholder="gst_number"
            />
          </div>
          {consignerError && <div className="error">{consignerError}</div>}
        </div>
        <div className="signup">
          <div className="heading">
            <h1>Consignee address</h1>
          </div>
          <div className="inputs">
            <input
              type="text"
              name="username"
              value={consigneeName}
              onChange={(e) => setConsigneeName(e.target.value)}
              placeholder="name"
            />
            <input
              type="number"
              name="phone"
              value={consigneePhone}
              onChange={(e) => setConsigneePhone(e.target.value)}
              placeholder="phone"
            />
            <input
              type="text"
              name="pincode"
              value={consigneePinCode}
              onChange={(e) => setConsigneePinCode(e.target.value)}
              placeholder="pincode"
            />
            <input
              type="text"
              name="city"
              value={consigneeCity}
              onChange={(e) => setConsigneeCity(e.target.value)}
              placeholder="city"
            />
            <input
              type="text"
              name="state"
              value={consigneeState}
              onChange={(e) => setConsigneeState(e.target.value)}
              placeholder="state"
            />
            <input
              type="text"
              name="address"
              value={consigneeAddress}
              onChange={(e) => setConsigneeAddress(e.target.value)}
              placeholder="address"
            />
            <input
              type="text"
              name="gst_number"
              value={consigneeGSTNumber}
              onChange={(e) => setConsigneeGSTNumber(e.target.value)}
              placeholder="gst_number"
            />
          </div>
          {consigneeError && <div className="error">{consigneeError}</div>}
        </div>
        <div className="signup">
          <div className="heading">
            <h1>Product Details</h1>
          </div>
          <div className="inputs">
            <input
              type="text"
              name="name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="name"
            />
            <input
              type="number"
              name="quantity"
              value={productQuantity}
              onChange={(e) => setProductQuantity(e.target.value)}
              placeholder="quantity"
            />
            <input
              type="number"
              name="price"
              value={productPrice}
              onChange={(e) => setProductPrice(e.target.value)}
              placeholder="price"
            />
            <input
              type="number"
              name="tax"
              value={productTax}
              onChange={(e) => setProductTax(e.target.value)}
              placeholder="tax"
            />
            <input
              type="text"
              name="SKU"
              value={productSKU}
              onChange={(e) => setProductSKU(e.target.value)}
              placeholder="SKU"
            />
            <input
              type="text"
              name="HSN"
              value={productHSN}
              onChange={(e) => setProductHSN(e.target.value)}
              placeholder="HSN"
            />
            <input
              type="number"
              name="weight"
              value={productWeight}
              onChange={(e) => setProductWeight(e.target.value)}
              placeholder="weight(gms)"
            />
            <input
              type="number"
              name="height"
              value={productHeight}
              onChange={(e) => setProductHeight(e.target.value)}
              placeholder="height"
            />
            <input
              type="number"
              name="breadth"
              value={productBreadth}
              onChange={(e) => setProductBreadth(e.target.value)}
              placeholder="breadth"
            />
            <input
              type="number"
              name="length"
              value={productLength}
              onChange={(e) => setProductLength(e.target.value)}
              placeholder="length"
            />
            <select value={cod} onChange={(e) => setCod(e.target.value)}>
              <option value="prepaid">PrePaid</option>
              <option value="COD">COD</option>
            </select>
            <input
              type="text"
              name="coupon_id"
              value={coupon_id}
              onChange={(e) => setCouponId(e.target.value)}
              placeholder="Coupon ID"
            />
          </div>
          <div className="buttons">
            <Button
              loading={
                XpressBeesGenerationResults.isLoading ||
                XpressBeesCalculationResults.isLoading
              }
              onClick={handleCalculate}
            >
              Calculate
            </Button>
          </div>
        </div>
        {calculations && (
          <CalculatedOrderResults
            results={calculations}
            setCourier={setCourierId}
            courier_id={courier_id}
          />
        )}
        {courier_id && (
          <>
            <div className="signup">
              <div className="heading">
                <h1>Invoice Details</h1>
              </div>
              <div className="inputs">
                <input
                  type="text"
                  name="invoice number"
                  value={invoice_number}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  placeholder="invoice number"
                />
                <DatePicker
                  selected={invoice_date}
                  onChange={(date) => setInvoiceDate(date)}
                  placeholderText="invoice date"
                />
                <input
                  type="text"
                  name="ebill_number"
                  value={ebill_number}
                  onChange={(e) => setEbillNumber(e.target.value)}
                  placeholder="ebill_number"
                />
                <DatePicker
                  selected={ebill_expiry_date}
                  onChange={(date) => setEbillExpiryDate(date)}
                  placeholderText="ebill expiry date"
                />
              </div>
            </div>
            <div className="signup">
              <div className="heading">
                <h1>Other Details</h1>
              </div>
              <div className="inputs">
                <select
                  value={pickup_location}
                  onChange={(e) => setPickupLocation(e.target.value)}
                >
                  <option value="franchise">Franchise</option>
                  <option value="customer">Customer</option>
                </select>
                <input
                  type="text"
                  name="order total"
                  value={order_total}
                  onChange={(e) => setOrderTotal(e.target.value)}
                  placeholder="order total"
                />
                <input
                  type="text"
                  name="discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  placeholder="discount(%)"
                />
                <input
                  type="text"
                  name="collectable amount"
                  value={collectable_amount}
                  onChange={(e) => setCollectableAmount(e.target.value)}
                  placeholder="collectable amount"
                />
              </div>
              <div className="buttons">
                <Button
                  loading={
                    XpressBeesGenerationResults.isLoading ||
                    XpressBeesCreateShipmentResults.isLoading ||
                    creationResults.isLoading ||
                    XpressbeesCancelShipmentResults.isLoading ||
                    DelhivaryClientWareHouseResults.isLoading ||
                    DelhivaryCreateShipmentResults.isLoading
                  }
                  onClick={handleShipNow}
                >
                  Ship Now
                </Button>
                <Button
                  loading={creationResults.isLoading}
                  onClick={handleShipLater}
                >
                  Ship Later
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateAdminOrder;
