import React from 'react'
import Carousel from '../components/home/Carousel'
import About from '../components/home/About'
import Partners from '../components/home/Partners'
import Footer from '../components/home/Footer'
import Reviews from '../components/home/Reviews'

const Home = () => {
  return <>
    <Carousel />
    <About />
    <div className="services">
      <img src={process.env.PUBLIC_URL + 'services.png'} alt='services' />
    </div>
    <div className="services-2">
      <h1>Results That You Can Measure</h1>
      <img src={process.env.PUBLIC_URL + 'services-2.png'} alt='services-2' />
    </div>
    <Partners />
    <Reviews />
    <Footer />
  </>
}

export default Home