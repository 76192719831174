import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const EscalationsApi = createApi({
    reducerPath: "Escalations",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/escalations"
    }),
    endpoints(builders){
        return {
            createEscalations: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Escalations" }, { type: "CustomerEscalations" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            getAllEscalations: builders.query({
                providesTags: (res, err, data) => [{ type: "Escalations" }],
                query: () => {
                    return {
                        url: "/",
                        method: "GET"
                    }
                }
            }),
            getCustomerEscalations: builders.query({
                providesTags: (res, err, data) => [{ type: "CustomerEscalations" }],
                query: (id) => {
                    return {
                        url: `/customer/${id}`,
                        method: "GET"
                    }
                }
            }),
            getSingleEscalation: builders.query({
                providesTags: (res, err, data) => [{ type: "Escalation", id: data }],
                query: (id) => {
                    return {
                        url: `/${id}`,
                        method: "GET"
                    }
                }
            }),
            updateSingleEscalation:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Escalation", id: data.id }, { type: "CustomerEscalations" }, { type: "Escalations" }],
                query: ({ data, jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            cancelSingleEscalation:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Escalation", id: data.id }, { type: "CustomerEscalations" }, { type: "Escalations" }],
                query: ({ jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                }
            })
        }
    }
});

export const { useCreateEscalationsMutation, useGetAllEscalationsQuery, useGetCustomerEscalationsQuery, useGetSingleEscalationQuery, useUpdateSingleEscalationMutation, useCancelSingleEscalationMutation } = EscalationsApi;
export { EscalationsApi };