import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetCustomerCouponsQuery } from '../store/index'
import { GoSync } from 'react-icons/go'
import { getCookie } from '../helpers/cookies'

const CustomersCoupons = ({ setProgress }) => {
    const id = getCookie("delivaryUserId");
    const { data: coupons, isFetching } = useGetCustomerCouponsQuery(id);
    const [content, setContent] = useState()
    const [noProd, setNoProd] = useState();

    useEffect(() => {
        if(coupons) {
            if(coupons.length === 0) {
                setContent();
                setNoProd(
                    <div className='no-prod'>
                        No Coupons available yet...
                    </div>
                );
            }
            else {
                setNoProd()
                setContent(coupons.map((c) => {
                    return <tr>
                        <td>
                            {c._id}
                        </td>
                        <td>
                            {c.discount}
                        </td>
                        <td>
                            {c.usage}
                        </td>
                        <td>
                            {c.limit}
                        </td>
                        <td>
                            {c.used}
                        </td>
                        <td>
                            {new Date(c.expiry_date).getDate()}-{new Date(c.expiry_date).getMonth()}-{new Date(c.expiry_date).getFullYear()}
                        </td>
                    </tr>
                }));
            }
        }
        else if(isFetching){
            setContent();
            setNoProd(
                <div className='no-prod'>
                    <GoSync className='animate-spin' />
                    <p>Loading Coupons</p>
                </div>
            )
        }
        else {
            setContent();
            setNoProd(
                <div className='no-prod'>
                    reload!! to try again
                </div>
            )
        }
    }, [coupons, isFetching]);
    
    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Orders</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='orders'>
                <div className='top'>
                    <input type='text' name='search' placeholder='search' />
                </div>
                <div className='order-table'>
                    <div className='heading'>
                        <h1>Coupons</h1>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>Coupon ID</th>
                            <th>Discount</th>
                            <th>Usage</th>
                            <th>Order Limit (gms)</th>
                            <th>Used</th>
                            <th>Expiry Date</th>
                        </tr>
                        </thead>
                        <tbody>{content}</tbody>
                    </table>
                    {noProd && (
                        <div className='no-ord'>
                            {noProd}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default CustomersCoupons