import React, { useEffect, useState } from 'react'
import Button from '../common/Button';
import { getCookie } from '../../helpers/cookies';
import { useCreateReviewsMutation, useGetCustomerReviewsQuery, useUpdateSingleReviewMutation } from '../../store';

const Reviews = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const id = getCookie("delivaryUserId")
    const [review, setReview] = useState();
    const { data: reviews, isFetching } = useGetCustomerReviewsQuery(id);
    const [createReview, createResults] = useCreateReviewsMutation();
    const [updateReview, UpdateResults] = useUpdateSingleReviewMutation();

    useEffect(() => {
        if(reviews){
            setReview(reviews.review);
        }
    }, [reviews]);

    const handlePost = async () => {
        if(isFetching){
            alert("Fetching previous data")
        }
        else if(reviews){
            const id = reviews._id;
            const data = {
                review
            }
            setProgress(50);
            await updateReview({ data, jwt, id }).unwrap().then((res) => {
                setProgress(100);
            }).catch((err) => {
                alert(JSON.stringify(err));
                setProgress(100);
            });
        }
        else {
            const data = {
                review
            }
            setProgress(50);
            await createReview({ data, jwt }).unwrap().then((res) => {
                setProgress(100);
            }).catch((err) => {
                alert(JSON.stringify(err));
                setProgress(100);
            });
        }
    }

  return (
    <div className='signup'>
        <div className='heading'>
            <h1>Reviews</h1>
        </div>
        <div className='inputs'>
            <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Your Review"
            />
        </div>
        <div className='buttons'>
            <Button loading={createResults.isLoading || UpdateResults.isLoading} onClick={handlePost}>
                Post
            </Button>
        </div>
    </div>
  )
}

export default Reviews