import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Button from '../components/common/Button';
import { GoSync } from 'react-icons/go';
import { useGenerateTokenMutation, useTrackShipmentMutation } from '../store/index';

const CustomTrackingPage = ({ setProgress }) => {
    const [searchName, setSearchName] = useState();
    const [data, setData] = useState();
    const [trackShipment, trackResults] = useTrackShipmentMutation();
    const [generateToken, generateResults] = useGenerateTokenMutation();
    const [content, setContent] = useState();

    const handleTrack = async () => {
        if(searchName !== ""){
            setProgress(33);
            await generateToken().unwrap().then(async (res) => {
                const token = res.data;
                const data = {
                    awb_number: searchName,
                };
                setProgress(66);
                await trackShipment({ data, token }).unwrap().then((res) => {
                    setData(res);
                    setProgress(100);
                }).catch((err) => console.log(err));
            });
        }
    }

    useEffect(() => {
      if(data){
        let delivered, ofd, transit, pickup;
        if(data.tracking_data){
          if(data.tracking_data["delivered"]){
            delivered = data.tracking_data["delivered"].map((c) => {
                console.log(c);
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["in transit"]){
            transit = data.tracking_data["in transit"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["out for delivary"]){
            ofd = data.tracking_data["out for delivary"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
          if(data.tracking_data["pending pickup"]){
            pickup = data.tracking_data["pending pickup"].map((c) => {
              return (
                <div className='details'>
                  <div className='status'>{c.status}</div>
                  <div className='det'>
                    <div className='message'>{c.message}</div>
                    <div className='location'>{c.location}</div>
                  </div>
                </div>
              );
            });
          }
        }
        setContent(
          <>
              <div className='orders'>
                  <div className='order-details'>
                    <div className='heading'>
                      <h1>Tracking Details</h1>
                    </div>
                    {data.response === false && (
                      <div className='details-single'>
                        <div className='status'>
                          {data.message}
                        </div>
                      </div>
                    )}
                    {delivered}
                    {transit}
                    {ofd}
                    {pickup}
                  </div>
              </div>
          </>
        );
      }
      else if (trackResults.isLoading || generateResults.isLoading){
        setContent(
          <div className='skel'>
            <div className='spinner'>
              <GoSync className='animate-spin' />
            </div>
            <h1>Loading escalation data...</h1>
          </div>
        )
      }
    }, [data, trackResults.isLoading, generateResults.isLoading]);

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Orders</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='orders track'>
                <div className='top'>
                    <input
                        type='text'
                        name='search'
                        placeholder='Tracking ID..'
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                    />
                    <Button onClick={handleTrack}>
                        Track
                    </Button>
                </div>
                {content}
            </div>
        </>
    )
}

export default CustomTrackingPage