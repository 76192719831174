import React, { useEffect, useState } from 'react'
import { useCreateProfitsMutation, useGetAllProfitsQuery, useUpdateSingleProfitMutation } from '../store/index'
import { Helmet } from 'react-helmet-async';
import Button from '../components/common/Button';
import { getCookie } from '../helpers/cookies';

const ProfitRates = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const { data: rates } = useGetAllProfitsQuery();
    const [createProfit, createResults] = useCreateProfitsMutation();
    const [updateProfit, updateResults] = useUpdateSingleProfitMutation();
    const [XpressBeesB2CSurfaceShipping, setXpressBeesB2CSurfaceShipping] = useState(0);
    const [XpressBeesB2CSurfaceCOD, setXpressBeesB2CSurfaceCOD] = useState(0);
    const [XpressBeesB2CAirShipping, setXpressBeesB2CAirShipping] = useState(0);
    const [XpressBeesB2CAirCOD, setXpressBeesB2CAirCOD] = useState(0);
    const [XpressBeesB2BShipping, setXpressBeesB2BShipping] = useState(0);
    const [XpressBeesB2BCOD, setXpressBeesB2BCOD] = useState(0);
    const [DelhivaryB2CShipping, setDelhivaryB2CShipping] = useState(0);
    const [DelhivaryB2CCOD, setDelhivaryB2CCOD] = useState(0);

    useEffect(() => {
        if(rates){
            if(rates.length !== 0){
                const [XpressBeesB2CSurface] = rates.filter((r) => {
                    return r.provider === 'XpressBees' && r.courier_id === "14435";
                });
                if(XpressBeesB2CSurface !== undefined){
                    setXpressBeesB2CSurfaceShipping(XpressBeesB2CSurface.shipping_rate);
                    setXpressBeesB2CSurfaceCOD(XpressBeesB2CSurface.cod_rate);
                }
                const [XpressBeesB2CAir] = rates.filter((r) => {
                    return r.provider === 'XpressBees' && r.courier_id === "14436";
                });
                if(XpressBeesB2CAir !== undefined){
                    setXpressBeesB2CAirShipping(XpressBeesB2CAir.shipping_rate);
                    setXpressBeesB2CAirCOD(XpressBeesB2CAir.cod_rate);
                }
                const [XpressBeesB2B] = rates.filter((r) => {
                    return r.provider === 'XpressBees' && r.buisness === "B2B";
                });
                if(XpressBeesB2B !== undefined){
                    setXpressBeesB2BShipping(XpressBeesB2B.shipping_rate);
                    setXpressBeesB2BCOD(XpressBeesB2B.cod_rate);
                }
                const [DelhivaryB2C] = rates.filter((r) => {
                    return r.provider === 'Delhivary' && r.buisness === "B2C";
                });
                if(DelhivaryB2C !== undefined){
                    setDelhivaryB2CShipping(DelhivaryB2C.shipping_rate);
                    setDelhivaryB2CCOD(DelhivaryB2C.cod_rate);
                }
            }
        }
    }, [rates]);

    const handleUpdateB2CSurface = async () => {
        setProgress(25);
        const [XpressBeesB2CSurface] = rates.filter((r) => {
            return r.provider === 'XpressBees' && r.courier_id === "14435";
        });
        setProgress(50);
        if(XpressBeesB2CSurface === undefined){
            const data = {
                provider: "XpressBees",
                buisness: "B2C",
                transport: "Surface",
                courier_id: "14435",
                shipping_rate: XpressBeesB2CSurfaceShipping,
                cod_rate: XpressBeesB2CSurfaceCOD
            };
            setProgress(75);
            await createProfit({ data, jwt }).unwrap().then((res) => {
                setProgress(100);
            }).catch(err => console.log(err)); 
        }
        else {
            const id = XpressBeesB2CSurface._id;
            const data = {
                shipping_rate: XpressBeesB2CSurfaceShipping,
                cod_rate: XpressBeesB2CSurfaceCOD
            };
            setProgress(80);
            await updateProfit({ id, data, jwt }).unwrap().then(res => setProgress(100)).catch(err => console.log(err));
        }
    }

    const handleUpdateDelhivary = async () => {
        setProgress(25);
        const [DelhivaryB2C] = rates.filter((r) => {
            return r.provider === 'Delhivary' && r.courier_id === "12343";
        });
        setProgress(50);
        if(DelhivaryB2C === undefined){
            const data = {
                provider: "Delhivary",
                buisness: "B2C",
                transport: "Surface",
                courier_id: "12343",
                shipping_rate: DelhivaryB2CShipping,
                cod_rate: DelhivaryB2CCOD
            };
            setProgress(80);
            await createProfit({ data, jwt }).unwrap().then((res) => {
                setProgress(100)
            }).catch(err => console.log(err)); 
        }
        else {
            const id = DelhivaryB2C._id;
            const data = {
                shipping_rate: DelhivaryB2CShipping,
                cod_rate: DelhivaryB2CCOD
            };
            setProgress(80);
            await updateProfit({ id, data, jwt }).unwrap().then(res => setProgress(100)).catch(err => console.log(err));
        }
    }

    const handleUpdateB2CAir = async () => {
        setProgress(25);
        const [XpressBeesB2CAir] = rates.filter((r) => {
            return r.provider === 'XpressBees' && r.courier_id === "14436";
        });
        setProgress(50);
        if(XpressBeesB2CAir === undefined){
            const data = {
                provider: "XpressBees",
                buisness: "B2C",
                transport: "Air",
                courier_id: "14436",
                shipping_rate: XpressBeesB2CAirShipping,
                cod_rate: XpressBeesB2CAirCOD
            };
            setProgress(80);
            await createProfit({ data, jwt }).unwrap().then((res) => {
                setProgress(100)
            }).catch(err => console.log(err)); 
        }
        else {
            const id = XpressBeesB2CAir._id;
            const data = {
                shipping_rate: XpressBeesB2CAirShipping,
                cod_rate: XpressBeesB2CAirCOD
            };
            setProgress(80);
            await updateProfit({ id, data, jwt }).unwrap().then(res => setProgress(100)).catch(err => console.log(err));
        }
    }

    const handleUpdateB2B = async () => {
        setProgress(25);
        const [XpressBeesB2B] = rates.filter((r) => {
            return r.provider === 'XpressBees' && r.buisness === "B2B";
        });
        setProgress(50);
        if(XpressBeesB2B === undefined){
            const data = {
                provider: "XpressBees",
                buisness: "B2B",
                transport: "Air",
                courier_id: "12345",
                shipping_rate: XpressBeesB2BShipping,
                cod_rate: XpressBeesB2BCOD
            };
            setProgress(75);
            await createProfit({ data, jwt }).unwrap().then((res) => {
                setProgress(100);
            }).catch(err => console.log(err)); 
        }
        else {
            const id = XpressBeesB2B._id;
            const data = {
                shipping_rate: XpressBeesB2BShipping,
                cod_rate: XpressBeesB2BCOD
            };
            setProgress(80);
            await updateProfit({ id, data, jwt }).unwrap().then(res => setProgress(100)).catch(err => console.log(err));
        }
    }

    return (
        <>
            <Helmet>
                <title>edaylivery - Every day Delivary, Ontime - Admin Profit Rates</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='heading'>
                <h1>Profit Rates</h1>
            </div>
            <div className='create-orders'>
                <div className='signup'>
                    <div className='heading'>
                        <h1>XpressBees B2C Surface</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='number'
                            name='shipping_charges'
                            value={XpressBeesB2CSurfaceShipping}
                            onChange={(e) => setXpressBeesB2CSurfaceShipping(e.target.value)}
                            placeholder='Shipping Charges'
                        />
                        <input
                            type='number'
                            name='cod_charges'
                            value={XpressBeesB2CSurfaceCOD}
                            onChange={(e) => setXpressBeesB2CSurfaceCOD(e.target.value)}
                            placeholder='COD Charges'
                        />
                    </div>
                    <div className='buttons'>
                        <Button loading={updateResults.isLoading || createResults.isLoading} onClick={handleUpdateB2CSurface}>
                            Update
                        </Button>
                    </div>
                </div>
                <div className='signup'>
                    <div className='heading'>
                        <h1>XpressBees B2C Air</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='number'
                            name='shipping_charges'
                            value={XpressBeesB2CAirShipping}
                            onChange={(e) => setXpressBeesB2CAirShipping(e.target.value)}
                            placeholder='Shipping Charges'
                        />
                        <input
                            type='number'
                            name='cod_charges'
                            value={XpressBeesB2CAirCOD}
                            onChange={(e) => setXpressBeesB2CAirCOD(e.target.value)}
                            placeholder='COD Charges'
                        />
                    </div>
                    <div className='buttons'>
                        <Button loading={createResults.isLoading || updateResults.isLoading} onClick={handleUpdateB2CAir}>
                            Update
                        </Button>
                    </div>
                </div>
                <div className='signup'>
                    <div className='heading'>
                        <h1>XpressBees B2B</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='number'
                            name='shipping_charges'
                            value={XpressBeesB2BShipping}
                            onChange={(e) => setXpressBeesB2BShipping(e.target.value)}
                            placeholder='Shipping Charges'
                        />
                        <input
                            type='number'
                            name='cod_charges'
                            value={XpressBeesB2BCOD}
                            onChange={(e) => setXpressBeesB2BCOD(e.target.value)}
                            placeholder='COD Charges'
                        />
                    </div>
                    <div className='buttons'>
                        <Button loading={createResults.isLoading || updateResults.isLoading} onClick={handleUpdateB2B}>
                            Update
                        </Button>
                    </div>
                </div>
                <div className='signup'>
                    <div className='heading'>
                        <h1>Delhivary</h1>
                    </div>
                    <div className='inputs'>
                        <input
                            type='number'
                            name='shipping_charges'
                            value={DelhivaryB2CShipping}
                            onChange={(e) => setDelhivaryB2CShipping(e.target.value)}
                            placeholder='Shipping Charges'
                        />
                        <input
                            type='number'
                            name='cod_charges'
                            value={DelhivaryB2CCOD}
                            onChange={(e) => setDelhivaryB2CCOD(e.target.value)}
                            placeholder='COD Charges'
                        />
                    </div>
                    <div className='buttons'>
                        <Button loading={createResults.isLoading || updateResults.isLoading} onClick={handleUpdateDelhivary}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfitRates