import React from 'react'

const CalculatedResults = ({ results }) => {
    const outRes = results.map((res) => {
        return <tr>
            <td>
                {res.name}
            </td>
            <td>
                {res.courier_charges}
            </td>
            <td>
                {res.cod_charges}
            </td>
            <td>
                {res.total_price}
            </td>
        </tr>
    })
  return (
    <div className='signup'>
        <table>
            <thead>
                <tr>
                    <th>Provider</th>
                    <th>Courier Charges</th>
                    <th>COD Charges</th>
                    <th>Total Charges</th>
                </tr>
            </thead>
            <tbody>{outRes}</tbody>
        </table>
    </div>
  )
}

export default CalculatedResults