import React, { useState } from 'react'
import Button from '../common/Button'
import ReactModal from 'react-modal';
import { useUpdateSingleB2BOrderMutation } from '../../store';
import { getCookie } from '../../helpers/cookies';

const Verify = ({ id, setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const [clicked, setClicked] = useState(false);
    const [lr_number, setLr] = useState();
    const [awb_numbers, setAwB] = useState();
    const [shipping_id, setSid] = useState();
    const [error, setError] = useState();
    const [update, updateResults] = useUpdateSingleB2BOrderMutation();
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };
    const handleVerification = async () => {
        const data = {
            lr_number,
            awb_numbers,
            shipping_id,
            order_status: "verified"
        };
        setProgress(50);
        await update({ jwt, id, data }).unwrap().then((res) => {
            console.log(res);
            setProgress(100);
        }).catch((err) => {
            console.log(err);
            setProgress(100);
        });
    }
  return (
    <>
        <Button onClick={() => setClicked(true)}>
            Verify
        </Button>
        <ReactModal
            isOpen={clicked}
            onRequestClose={() => setClicked(false)}
            style={customStyles}
            contentLabel='Verify Order'
        >
            <div className='recharge-modal'>
                <div className='heading'>Verify Order</div>
                <input type="number" value={lr_number} onChange={(e) => setLr(e.target.value)} placeholder='LR Number' />
                <input type="number" value={awb_numbers} onChange={(e) => setAwB(e.target.value)} placeholder='comma separated awb numbers' />
                <input type="number" value={shipping_id} onChange={(e) => setSid(e.target.value)} placeholder='Shipping ID' />
                {error && (
                    <div className='error'>
                        {error}
                    </div>
                )}
                <Button loading={updateResults.isLoading} onClick={handleVerification}>Verify</Button>
            </div>
        </ReactModal>
    </>
  )
}

export default Verify