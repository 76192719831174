import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const CouponsApi = createApi({
    reducerPath: "Coupons",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/coupons"
    }),
    endpoints(builders){
        return {
            createCoupons: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Coupons" }, { type: "CustomerCoupons" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            getAllCoupons: builders.query({
                providesTags: (res, err, data) => [{ type: "Coupons" }],
                query: () => {
                    return {
                        url: "/",
                        method: "GET"
                    }
                }
            }),
            getCustomerCoupons: builders.query({
                providesTags: (res, err, data) => [{ type: "CustomerCoupons" }],
                query: (id) => {
                    return {
                        url: `/customer/${id}`,
                        method: "GET"
                    }
                }
            }),
            getSingleCoupon: builders.mutation({
                providesTags: (res, err, data) => [{ type: "Coupon", id: data }],
                query: (id) => {
                    return {
                        url: `/${id}`,
                        method: "GET"
                    }
                }
            }),
            updateSingleCoupon:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Coupon", id: data.id }, { type: "CustomerCoupons" }, { type: "Coupons" }],
                query: ({ data, jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            cancelSingleCoupon:  builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Coupon", id: data.id }, { type: "CustomerCoupons" }, { type: "Coupons" }],
                query: ({ jwt, id }) => {
                    return {
                        url: `/secure/${id}`,
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                }
            })
        }
    }
});

export const { useCreateCouponsMutation, useGetAllCouponsQuery, useGetCustomerCouponsQuery, useGetSingleCouponMutation, useUpdateSingleCouponMutation, useCancelSingleCouponMutation } = CouponsApi;
export { CouponsApi };