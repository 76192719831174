import React, { useEffect, useState } from 'react'
import { useGetAllReviewsQuery } from '../../store'
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Button from '../common/Button';

const Reviews = () => {
  const { data: reviews } = useGetAllReviewsQuery();
  const [data, setData] = useState();
  const [margin, setMargin] = useState(0);
  const left = () => {
    if(margin >= -360 * (reviews.length - 2)){
      for (let i = 0; i < 360; i++) {
        setTimeout(() => {
          setMargin(margin - i);
        }, 1);
      }
    }
  }
  const right = () => {
    if(margin < 0){
      for (let i = 0; i < 360; i++) {
        setTimeout(() => {
          setMargin(margin + i);
        }, 1);
      }
    }
  }
  useEffect(() => {
    if(reviews){
      setData(
        <div className='reviews'>
          <h1>Reviews</h1>
          {margin < 0 && (
            <Button onClick={left}>
              <FaArrowCircleLeft className='left' />
            </Button>
          )}
          <div className='list'>
            {reviews.map((r, i) => {
              return (
                <>
                  <div className='single' style={{ marginLeft: i=== 0 && margin + 'px' }}>
                    <div className='heading'>
                      <p>{r.customer_name}</p>
                    </div>
                    <p>{r.review}</p>
                  </div>
                </>
              )
            })}
          </div>
          {reviews.length > 3 && (
            <Button onClick={right}>
              <FaArrowCircleRight className='right' />
            </Button>
          )}
        </div>
      )
    }
  }, [reviews, margin]);
  return data;
}

export default Reviews