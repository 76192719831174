import { useAutoAnimate } from "@formkit/auto-animate/react";
import React, { useEffect, useState } from "react";
import {
  useCalculateDpriceMutation,
  useCalculatePriceMutation,
  useGenerateTokenMutation,
  useGetAllProfitsQuery,
} from "../../store/index.js";
import Button from "../common/Button.js";
import { lookup } from "india-pincode-lookup";
import CalculatedResults from "../orders/CalculatedResults.js";
import { getCookie } from "../../helpers/cookies.js";

const B2CCalculator = ({ setProgress }) => {
  const jwt = getCookie("delivaryUserJWT");
  const { data: profitRates } = useGetAllProfitsQuery();
  const [parent, enable] = useAutoAnimate({ duration: 350 });
  const [XpressBeesGenerateToken, XpressBeesGenerationResults] =
    useGenerateTokenMutation();
  const [XpressBeesCalculateRate, XpressBeesCalculationResults] =
    useCalculatePriceMutation();
  const [DelhivaryCalculatePrice, DelhivaryCalculateResults] =
    useCalculateDpriceMutation();
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [weight, setWeight] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [breadth, setBreadth] = useState();
  const [error, setError] = useState();
  const [cod, setCod] = useState("prepaid");
  const [results, setResults] = useState(null);

  useEffect(() => {
    enable(true);
  }, [parent, enable]);

  useEffect(() => {
    if (lookup(origin).length === 0) {
      setError("from address does not exists!");
    } else if (lookup(destination).length === 0) {
      setError("to address does not exists!");
    } else {
      setError();
    }
  }, [origin, destination]);

  const handleCalculate = async () => {
    const data = {
      origin,
      destination,
      weight: parseInt(weight) / 1000,
      height,
      breadth,
      length,
      order_type_user: "ecom",
      cod_amount: "23",
      cod: cod === "cod" ? "yes" : "no",
    };
    setProgress(33);
    let finalRates = [];
    await XpressBeesGenerateToken()
      .then(async (res) => {
        const token = res.data.data;
        setProgress(66);
        await XpressBeesCalculateRate({ data, token }).then((res) => {
          const rates = res.data.message;
          const convertedRates = [];
          rates.map((r) => {
            let profitShippingRate = 0;
            let profitCODRate = 0;
            if (profitRates) {
              if (profitRates.length !== 0) {
                const [myProfitRate] = profitRates.filter((myRate) => {
                  if (myRate.provider === "XpressBees") {
                    if (
                      r.name.toLowerCase().includes("air") &&
                      myRate.transport.toLowerCase() === "air"
                    ) {
                      return true;
                    } else if (
                      !r.name.toLowerCase().includes("air") &&
                      myRate.transport.toLowerCase() === "surface"
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return false;
                });
                if (myProfitRate !== undefined) {
                  profitShippingRate = myProfitRate.shipping_rate;
                  profitCODRate = myProfitRate.cod_rate;
                }
              }
            }
            const shippingMultiple = 1 + parseInt(profitShippingRate) / 100;
            const codMultiple = 1 + parseInt(profitCODRate) / 100;
            const courier_charges = Math.ceil(
              parseFloat(r.courier_charges) * shippingMultiple * 1.18
            );
            const cod_charges = Math.ceil(
              parseFloat(r.cod_charges) * codMultiple * 1.18
            );
            const total_price = courier_charges + cod_charges;
            convertedRates.push({
              ...r,
              courier_charges,
              cod_charges,
              total_price,
              name: r.name.replace("BIKASH BHADRA FRANCHISE", "XPressBees"),
            });
            return r;
          });
          finalRates.push(...convertedRates);
          setProgress(90);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    const this_data = {
      md: "S",
      ss: "Delivered",
      o_pin: origin,
      d_pin: destination,
      cgm: weight,
      pt: cod === "prepaid" ? "Pre-paid" : "COD",
      cod: "0",
    };
    console.log(this_data);
    await DelhivaryCalculatePrice({ data: this_data, jwt })
      .then((res) => {
        console.log(res);
        let profitShippingRate = 0;
        let profitCODRate = 0;
        if (profitRates) {
          if (profitRates.length !== 0) {
            const [myProfitRate] = profitRates.filter((myRate) => {
              if (myRate.provider === "Delhivary") {
                if (myRate.transport.toLowerCase() === "b2c") {
                  return true;
                } else {
                  return false;
                }
              }
              return false;
            });
            if (myProfitRate !== undefined) {
              profitShippingRate = myProfitRate.shipping_rate;
              profitCODRate = myProfitRate.cod_rate;
            }
          }
        }
        console.log("data:",res.data[0]);
        const shippingMultiple = 1 + parseInt(profitShippingRate) / 100;
        const codMultiple = 1 + parseInt(profitCODRate) / 100;
        const courier_charges = Math.ceil(
          parseFloat(res.data[0].charge_DL) * shippingMultiple * 1.18
        );
        const cod_charges = Math.ceil(
          parseFloat(res.data[0].charge_COD) * codMultiple * 1.18
        );
        const total_price = courier_charges + cod_charges;
        finalRates.push({
          courier_charges,
          cod_charges,
          total_price,
          name: "Delhivary B2C",
        });
        setProgress(100);
      })
      .catch((err) => {
        console.log(err);
        setProgress(100);
      });
    console.log(finalRates);
    setResults(finalRates);
  };

  return (
    <>
      <div className="signup" ref={parent}>
        <div className="heading">
          <h1>B2C Shipping Rate Calculator</h1>
        </div>
        <div className="inputs">
          <input
            type="number"
            name="from"
            value={origin}
            onChange={(e) => {
              setOrigin(e.target.value);
            }}
            placeholder="from"
          />
          <input
            type="number"
            name="to"
            value={destination}
            onChange={(e) => {
              setDestination(e.target.value);
            }}
            placeholder="to"
          />
          <input
            type="number"
            name="weight"
            max="20"
            value={weight}
            onChange={(e) => {
              setWeight(e.target.value);
            }}
            placeholder="weight"
          />
          <input
            type="number"
            name="height"
            value={height}
            onChange={(e) => {
              setHeight(e.target.value);
            }}
            placeholder="height"
          />
          <input
            type="number"
            name="length"
            value={length}
            onChange={(e) => {
              setLength(e.target.value);
            }}
            placeholder="length"
          />
          <input
            type="number"
            name="breadth"
            value={breadth}
            onChange={(e) => {
              setBreadth(e.target.value);
            }}
            placeholder="breadth"
          />
          <select value={cod} onChange={(e) => setCod(e.target.value)}>
            <option value="cod">COD</option>
            <option value="prepaid">PrePaid</option>
          </select>
        </div>
        {error && <div className="error">{error}</div>}
        <div className="buttons">
          <Button
            loading={
              XpressBeesGenerationResults.isLoading ||
              XpressBeesCalculationResults.isLoading ||
              DelhivaryCalculateResults.isLoading
            }
            onClick={handleCalculate}
          >
            Calculate
          </Button>
        </div>
      </div>
      {results && <CalculatedResults results={results} />}
    </>
  );
};

export default B2CCalculator;
