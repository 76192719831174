import React, { useEffect, useState } from 'react'
import { getCookie } from '../helpers/cookies'
import { useGetAllOrdersQuery, useGetCustomerOrdersQuery, useTrackShipmentMutation, useGenerateTokenMutation } from '../store/index';
import BarChart from '../components/common/BarChart';
import PieChart from '../components/common/PieChart';

const DashBooard = ({ setProgress }) => {
  const role = getCookie("delivaryUserRole");
  const id = getCookie("delivaryUserId");
  const { data: allOrders } = useGetAllOrdersQuery();
  const { data: customerOrders } = useGetCustomerOrdersQuery(id);
  const [trackShipment, trackResults] = useTrackShipmentMutation();
  const [generateToken, generateResults] = useGenerateTokenMutation();
  const [delivered, setDelivered] = useState([]);
  const [inTransit, setInTransit] = useState([]);
  const [pendingPickup, setPendingPickup] = useState([]);
  const [states, setStates] = useState([]);
  const [loads, setLoads] = useState([]);
  const [booked, setBooked] = useState([]);
  const [orders, setOrders] = useState();

  useEffect(() => {
    if(role === "admin"){
      if(allOrders) {
        setOrders(allOrders);
      }
    }
    else {
      if(customerOrders){
        setOrders(customerOrders);
      }
    }
  },[role, allOrders, customerOrders]);

  useEffect(() => {
    if(orders) {
      const s = [];
      const l = {};
      const d = [];
      const i = [];
      const p = [];
      const b = [];
      orders.map(async (o) => {
        await generateToken().unwrap().then(async (res) => {
          const token = res.data;
          const data = {
            awb_number: o.awb_number,
          };
          await trackShipment({ data, token }).unwrap().then((res) => {
            if(res.tracking_data){
              const state = o.consignee_details.consignee_state.toLowerCase();
              const load = o.product_box_details.weight;
              if(!s.includes(state)){
                s.push(state)
                l[state] = parseInt(load);
              }
              else {
                l[state] += parseInt(load);
              }
              if(res.tracking_data["delivered"]){
                d.push(o);
              }
              else if(res.tracking_data["in transit"] || res.tracking_data["out for delivary"]){
                i.push(o)
              }
              else if(res.tracking_data["pending pickup"]){
                p.push(o)
              }
              else {
                b.push(o)
              }
            }
            if(res.message === "Current status: pending pickup"){
              const state = o.consignee_details.consignee_state.toLowerCase();
              const load = o.product_box_details.weight;
              if(!s.includes(state)){
                s.push(state)
                l[state] = parseInt(load);
              }
              else {
                l[state] += parseInt(load);
              }
              p.push(o);
            }
            if(res.message === "Current status: booked"){
              const state = o.consignee_details.consignee_state.toLowerCase();
              const load = o.product_box_details.weight;
              if(!s.includes(state)){
                s.push(state)
                l[state] = parseInt(load);
              }
              else {
                l[state] += parseInt(load);
              }
              b.push(o);
            }
          }).catch((err) => console.log(err));
        });
      })
      setStates(s);
      setLoads(l);
      setDelivered(d);
      setInTransit(i);
      setPendingPickup(p);
      setBooked(b);
    }
  }, [orders]);

  const total_shipment = delivered.length + inTransit.length + pendingPickup.length + booked.length;
  let chartData, stateData, pieData;
  if(total_shipment !== 0){
    chartData = {
      labels: [(parseFloat(delivered.length/total_shipment) *100).toFixed(0)],
      datasets: [{
        label: "Delivary Percentage",
        data: [(parseFloat(delivered.length/total_shipment) *100).toFixed(0), 100]
      }]
    }
  
    stateData = {
      labels: states,
      datasets: [{
        label: "State Wise Loads",
        data: [...states.map((s) => loads[s])]
      }]
    }
  
    pieData = {
      labels: ["delivered", "in transit", "pending pickup", "booked"],
      datasets: [{
        label: "State Wise Loads",
        data: [(parseFloat(delivered.length/total_shipment) *100).toFixed(0),(parseFloat(inTransit.length/total_shipment) *100).toFixed(0),(parseFloat(pendingPickup.length/total_shipment) *100).toFixed(0),(parseFloat(booked.length/total_shipment) *100).toFixed(0)]
      }]
    }
  }

  return (
    <div className="dashboard">
      <div className='top'>
        <div className='left'>
          <div>
            <h1>Total Shipments</h1>
            <p>{total_shipment}</p>
          </div>
          <div>
            <h1>Delivered Shipments</h1>
            <p>{delivered.length}</p>
          </div>
          <div>
            <h1>In Transit Shipments</h1>
            <p>{inTransit.length}</p>
          </div>
          <div>
            <h1>Pending Pickup Shipments</h1>
            <p>{pendingPickup.length}</p>
          </div>
          <div>
            <h1>Booked Shipments</h1>
            <p>{booked.length}</p>
          </div>
        </div>
        <div className="right">
          {total_shipment !== 0 ? (
            <>
              <div>
                <div className="top">
                  <h1>Delivary Percentage</h1>
                  <p>{((delivered.length/total_shipment) *100).toFixed(0)}%</p>
                </div>
                <div className='bar'>
                  <BarChart series={chartData} />
                </div>
              </div>
              <div>
                <div className='bar'>
                  <BarChart series={stateData} />
                </div>
              </div>
              <div>
                <div className="top">
                  <h1>Shipment Status</h1>
                </div>
                <div className='bar'>
                  <PieChart series={pieData} />
                </div>
              </div>
            </>
          ): (
              <div className="welcome">
                <div className="top">
                  <h1>Welcome to edaylivary</h1>
                </div>
                <div className='bar'>
                  <img src={process.env.PUBLIC_URL + "/favicon.ico"} alt="edaylivary" width="200px" height="100px" />
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashBooard