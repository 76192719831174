import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS } from "chart.js/auto";

const PieChart = ({ series }) => {
  return (
    <Pie data={series} />
  );
}

export default PieChart