import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import Button from '../common/Button';
import { useCreatePaymentMutation, useCreateTransactionMutation, useUpdateUserMutation, useValidatePaymentMutation } from '../../store/index';
import { getCookie, setCookie } from '../../helpers/cookies';

const RechargeWallet = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const username = getCookie("delivaryUserName");
    const email = getCookie("delivaryUserEmail");
    const wallet = getCookie("delivaryUserWallet");
    const [amount, setAmount] = useState(1);
    const [clicked, setClicked] = useState(false);
    const [error, setError] = useState();
    const [orderData, orderResults] = useCreatePaymentMutation();
    const [orderValidate, validationResults] = useValidatePaymentMutation();
    const [updateUser, updateResults] = useUpdateUserMutation();
    const [createTransaction, creationResults] = useCreateTransactionMutation();

    useEffect(() => {
        if(parseInt(amount) === 0 && amount !== "" ){
            setError("Amount cannot be dispensible!");
        }
        else {
            setError();
        }
    }, [amount]);

    const handlePayment = async (e) => {
        if (amount === "0" || amount === ""){
            setError("Amount cannot be dispensible!");
        }
        else {
            setError();
            const data = {
                amount: amount*100,
                currency: "INR",
                receipt: "chbchr1234"
            };
            const order = await orderData(data);
            if(order) {
                const options = {
                    key: "rzp_live_1vPUDenBk1oqfw", // Enter the Key ID generated from the Dashboard
                    amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: data.currency,
                    name: "Edaylivery",
                    description: "Wallet Recharge",
                    image: null,
                    order_id: order.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    handler: async function (response){
                        const data = {
                            ...response,
                        };
                        const validatePayment = await orderValidate(data);
                        if(validatePayment.data.status === true){
                            const totalAmount = parseInt(wallet) + parseInt(amount);
                            const data = {
                                wallet: totalAmount
                            };
                            await updateUser({ data, jwt }).unwrap().then(async (res) => {
                                const data = {
                                    transactionId: validatePayment.data.order_id,
                                    amount: parseInt(amount),
                                };
                                await createTransaction({ data, jwt }).unwrap().then((res) => {
                                    setCookie("delivaryUserWallet", totalAmount, 7);
                                    setClicked(false);
                                }).catch((err) => {
                                    console.log(err);
                                });
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                        else {
                            alert(validatePayment.data.status.message);
                        }
                    },
                    prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                        name: username, //your customer's name
                        email, 
                        contact: "9000090000"  //Provide the customer's phone number for better conversion rates 
                    },
                    notes: {
                        address: "Razorpay Corporate Office"
                    },
                    theme: {
                        color: "#3399cc"
                    }
                };
                var rzp1 = new window.Razorpay(options);
                rzp1.on('payment.failed', function (response){
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
                });
                rzp1.open();
                e.preventDefault();
            }
            else {
                alert("Server Error");
            }
        }
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <>
            <div className='recharge' onClick={() => setClicked(true)}>Recharge</div>
            <ReactModal
                isOpen={clicked}
                onRequestClose={() => setClicked(false)}
                style={customStyles}
                contentLabel='Recharge Wallet'
            >
                <div className='recharge-modal'>
                    <div className='heading'>Recharge Wallet</div>
                    <input
                        type='number'
                        name='recharge-value'
                        min={100}
                        step={100}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder='recharge amount'
                    />
                    {error && (
                        <div className='error'>
                            {error}
                        </div>
                    )}
                    <Button loading={ orderResults.isLoading || validationResults.isLoading || updateResults.isLoading || creationResults.isLoading } onClick={handlePayment}>Recharge</Button>
                </div>
            </ReactModal>
        </>
    )
}

export default RechargeWallet