import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const DelhivaryApi = createApi({
    reducerPath: "Delhivary",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://delivary-management-backend.vercel.app/api/delhivary"
    }),
    endpoints(builders){
        return {
            CalculateDprice: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "CustomerDelhivary" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            CreateDOrder: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "CustomerOrder" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/create",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            CreateDClientWareHouse: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "CustomerClientWareHouse" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/create/warehouse",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            CreateDPickupOrder: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "PickupOrder" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/pickup",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            CancelDShipment: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "CancelOrder" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/cancel",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            }),
            trackDShipment: builders.mutation({
                invalidatesTags: (res, err, data) => [{ type: "Delhivary" }, { type: "TrackOrder" }],
                query: ({ data, jwt }) => {
                    return {
                        url: "/secure/track",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                        data
                    }
                }
            })
        }
    }
});

export const { useCalculateDpriceMutation, useCreateDOrderMutation, useCreateDClientWareHouseMutation, useCancelDShipmentMutation, useTrackDShipmentMutation, useCreateDPickupOrderMutation } = DelhivaryApi;
export { DelhivaryApi };