import React from 'react'

const CalculatedOrderResults = ({ results, setCourier, courier_id }) => {
    console.log(results);
    const outRes = results.map((res) => {
        return <tr>
            <td>
                {res.name}
            </td>
            <td>
                {res.courier_charges}
            </td>
            <td>
                {res.cod_charges}
            </td>
            <td>
                {res.total_price}
            </td>
            <td>
                <div className='click' onClick={() =>  setCourier(res.courier_id)}>
                    {courier_id === res.courier_id ? "Selected": "Select"}
                </div>
            </td>
        </tr>
    })
  return (
    <div className='signup'>
        <table>
            <thead>
                <tr>
                    <th>Provider</th>
                    <th>Courier Charges</th>
                    <th>COD Charges</th>
                    <th>Total Charges</th>
                    <th>action</th>
                </tr>
            </thead>
            <tbody>{outRes}</tbody>
        </table>
    </div>
  )
}

export default CalculatedOrderResults