import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../customeBaseQuery/axiosBaseQuery";

const Xpressbees = createApi({
    reducerPath: "XpressBees",
    baseQuery: axiosBaseQuery({
        baseUrl: "https://ship.xpressbees.com/api"
    }),
    endpoints(builders){
        return {
            calculatePrice: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/shipments/calculate_pricing",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        data
                    }
                }
            }),
            generateToken: builders.mutation({
                query: () => {
                    return {
                        url: "/users/franchise_login",
                        method: "POST",
                        data: {
                            email: "adtraders97@gmail.com",
                            password: "Shivangini@2022"
                        }
                    }
                }
            }),
            createShipment: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/shipments",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        data
                    }
                }
            }),
            trackShipment: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/shipments/track_shipment",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        data
                    }
                } 
            }),
            pickUpShipment: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/shipments/pickup",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        data
                    }
                }
            }),
            cancelShipment: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/shipments/cancel_shipment",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        data
                    }
                } 
            }),
            createNDR: builders.mutation({
                query: ({ data, token }) => {
                    return {
                        url: "/franchise/ndr/create",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        data
                    }
                } 
            }),
            getNDR: builders.mutation({
                query: (token) => {
                    return {
                        url: "/franchise/ndr",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                } 
            }),
        }
    }
});

export const { useCalculatePriceMutation, useGenerateTokenMutation, useCreateShipmentMutation, useTrackShipmentMutation, usePickUpShipmentMutation, useCancelShipmentMutation, useCreateNDRMutation, useGetNDRMutation } = Xpressbees;
export { Xpressbees };