export const b2b_data = {
    "arunachal pradesh_arunachal pradesh": 20,
    "assam_arunachal pradesh": 20,
    "manipur_arunachal pradesh": 20,
    "meghalaya_arunachal pradesh": 20,
    "mizoram_arunachal pradesh": 20,
    "nagaland_arunachal pradesh": 20,
    "sikkim_arunachal pradesh": 20,
    "tripura_arunachal pradesh": 20,
    "northe east_arunachal pradesh": 20,
    "arunachal pradesh_assam": 20,
    "assam_assam": 20,
    "manipur_assam": 20,
    "meghalaya_assam": 20,
    "mizoram_assam": 20,
    "nagaland_assam": 20,
    "sikkim_assam": 20,
    "tripura_assam": 20,
    "northe east_assam": 20,
    "arunachal pradesh_manipur": 20,
    "assam_manipur": 20,
    "manipur_manipur": 20,
    "meghalaya_manipur": 20,
    "mizoram_manipur": 20,
    "nagaland_manipur": 20,
    "sikkim_manipur": 20,
    "tripura_manipur": 20,
    "northe east_manipur": 20,
    "arunachal pradesh_meghalaya": 20,
    "assam_meghalaya": 20,
    "manipur_meghalaya": 20,
    "meghalaya_meghalaya": 20,
    "mizoram_meghalaya": 20,
    "nagaland_meghalaya": 20,
    "sikkim_meghalaya": 20,
    "tripura_meghalaya": 20,
    "northe east_meghalaya": 20,
    "arunachal pradesh_mizoram": 20,
    "assam_mizoram": 20,
    "manipur_mizoram": 20,
    "meghalaya_mizoram": 20,
    "mizoram_mizoram": 20,
    "nagaland_mizoram": 20,
    "sikkim_mizoram": 20,
    "tripura_mizoram": 20,
    "northe east_mizoram": 20,
    "arunachal pradesh_nagaland": 20,
    "assam_nagaland": 20,
    "manipur_nagaland": 20,
    "meghalaya_nagaland": 20,
    "mizoram_nagaland": 20,
    "nagaland_nagaland": 20,
    "sikkim_nagaland": 20,
    "tripura_nagaland": 20,
    "northe east_nagaland": 20,
    "arunachal pradesh_sikkim": 20,
    "assam_sikkim": 20,
    "manipur_sikkim": 20,
    "meghalaya_sikkim": 20,
    "mizoram_sikkim": 20,
    "nagaland_sikkim": 20,
    "sikkim_sikkim": 20,
    "tripura_sikkim": 20,
    "northe east_sikkim": 20,
    "arunachal pradesh_tripura": 20,
    "assam_tripura": 20,
    "manipur_tripura": 20,
    "meghalaya_tripura": 20,
    "mizoram_tripura": 20,
    "nagaland_tripura": 20,
    "sikkim_tripura": 20,
    "tripura_tripura": 20,
    "northe east_tripura": 20,
    "arunachal pradesh_northe east": 20,
    "assam_northe east": 20,
    "manipur_northe east": 20,
    "meghalaya_northe east": 20,
    "mizoram_northe east": 20,
    "nagaland_northe east": 20,
    "sikkim_northe east": 20,
    "tripura_northe east": 20,
    "northe east_northe east": 20,
    "arunachal pradesh_kolhapur": 15,
    "assam_kolhapur": 15,
    "manipur_kolhapur": 15,
    "meghalaya_kolhapur": 15,
    "mizoram_kolhapur": 15,
    "nagaland_kolhapur": 15,
    "sikkim_kolhapur": 15,
    "tripura_kolhapur": 15,
    "northe east_kolhapur": 15,
    "arunachal pradesh_aurangabad": 15,
    "assam_aurangabad": 15,
    "manipur_aurangabad": 15,
    "meghalaya_aurangabad": 15,
    "mizoram_aurangabad": 15,
    "nagaland_aurangabad": 15,
    "sikkim_aurangabad": 15,
    "tripura_aurangabad": 15,
    "northe east_aurangabad": 15,
    "arunachal pradesh_satara": 15,
    "assam_satara": 15,
    "manipur_satara": 15,
    "meghalaya_satara": 15,
    "mizoram_satara": 15,
    "nagaland_satara": 15,
    "sikkim_satara": 15,
    "tripura_satara": 15,
    "northe east_satara": 15,
    "arunachal pradesh_goa": 15,
    "assam_goa": 15,
    "manipur_goa": 15,
    "meghalaya_goa": 15,
    "mizoram_goa": 15,
    "nagaland_goa": 15,
    "sikkim_goa": 15,
    "tripura_goa": 15,
    "northe east_goa": 15,
    "arunachal pradesh_nashik": 15,
    "assam_nashik": 15,
    "manipur_nashik": 15,
    "meghalaya_nashik": 15,
    "mizoram_nashik": 15,
    "nagaland_nashik": 15,
    "sikkim_nashik": 15,
    "tripura_nashik": 15,
    "northe east_nashik": 15,
    "arunachal pradesh_mumbai": 15,
    "assam_mumbai": 15,
    "manipur_mumbai": 15,
    "meghalaya_mumbai": 15,
    "mizoram_mumbai": 15,
    "nagaland_mumbai": 15,
    "sikkim_mumbai": 15,
    "tripura_mumbai": 15,
    "northe east_mumbai": 15,
    "arunachal pradesh_bhiwandi": 15,
    "assam_bhiwandi": 15,
    "manipur_bhiwandi": 15,
    "meghalaya_bhiwandi": 15,
    "mizoram_bhiwandi": 15,
    "nagaland_bhiwandi": 15,
    "sikkim_bhiwandi": 15,
    "tripura_bhiwandi": 15,
    "northe east_bhiwandi": 15,
    "arunachal pradesh_panvel": 15,
    "assam_panvel": 15,
    "manipur_panvel": 15,
    "meghalaya_panvel": 15,
    "mizoram_panvel": 15,
    "nagaland_panvel": 15,
    "sikkim_panvel": 15,
    "tripura_panvel": 15,
    "northe east_panvel": 15,
    "arunachal pradesh_gujrat": 15,
    "assam_gujrat": 15,
    "manipur_gujrat": 15,
    "meghalaya_gujrat": 15,
    "mizoram_gujrat": 15,
    "nagaland_gujrat": 15,
    "sikkim_gujrat": 15,
    "tripura_gujrat": 15,
    "northe east_gujrat": 15,
    "arunachal pradesh_vapi": 15,
    "assam_vapi": 15,
    "manipur_vapi": 15,
    "meghalaya_vapi": 15,
    "mizoram_vapi": 15,
    "nagaland_vapi": 15,
    "sikkim_vapi": 15,
    "tripura_vapi": 15,
    "northe east_vapi": 15,
    "arunachal pradesh_daman": 15,
    "assam_daman": 15,
    "manipur_daman": 15,
    "meghalaya_daman": 15,
    "mizoram_daman": 15,
    "nagaland_daman": 15,
    "sikkim_daman": 15,
    "tripura_daman": 15,
    "northe east_daman": 15,
    "arunachal pradesh_silvasa": 15,
    "assam_silvasa": 15,
    "manipur_silvasa": 15,
    "meghalaya_silvasa": 15,
    "mizoram_silvasa": 15,
    "nagaland_silvasa": 15,
    "sikkim_silvasa": 15,
    "tripura_silvasa": 15,
    "northe east_silvasa": 15,
    "arunachal pradesh_delhi": 13,
    "assam_delhi": 13,
    "manipur_delhi": 13,
    "meghalaya_delhi": 13,
    "mizoram_delhi": 13,
    "nagaland_delhi": 13,
    "sikkim_delhi": 13,
    "tripura_delhi": 13,
    "northe east_delhi": 13,
    "arunachal pradesh_rajasthan": 13,
    "assam_rajasthan": 13,
    "manipur_rajasthan": 13,
    "meghalaya_rajasthan": 13,
    "mizoram_rajasthan": 13,
    "nagaland_rajasthan": 13,
    "sikkim_rajasthan": 13,
    "tripura_rajasthan": 13,
    "northe east_rajasthan": 13,
    "arunachal pradesh_chandigarh": 14,
    "assam_chandigarh": 14,
    "manipur_chandigarh": 14,
    "meghalaya_chandigarh": 14,
    "mizoram_chandigarh": 14,
    "nagaland_chandigarh": 14,
    "sikkim_chandigarh": 14,
    "tripura_chandigarh": 14,
    "northe east_chandigarh": 14,
    "arunachal pradesh_uttarakhand": 14,
    "assam_uttarakhand": 14,
    "manipur_uttarakhand": 14,
    "meghalaya_uttarakhand": 14,
    "mizoram_uttarakhand": 14,
    "nagaland_uttarakhand": 14,
    "sikkim_uttarakhand": 14,
    "tripura_uttarakhand": 14,
    "northe east_uttarakhand": 14,
    "arunachal pradesh_punjab": 14,
    "assam_punjab": 14,
    "manipur_punjab": 14,
    "meghalaya_punjab": 14,
    "mizoram_punjab": 14,
    "nagaland_punjab": 14,
    "sikkim_punjab": 14,
    "tripura_punjab": 14,
    "northe east_punjab": 14,
    "arunachal pradesh_haryana": 14,
    "assam_haryana": 14,
    "manipur_haryana": 14,
    "meghalaya_haryana": 14,
    "mizoram_haryana": 14,
    "nagaland_haryana": 14,
    "sikkim_haryana": 14,
    "tripura_haryana": 14,
    "northe east_haryana": 14,
    "arunachal pradesh_himachal pradesh": 14,
    "assam_himachal pradesh": 14,
    "manipur_himachal pradesh": 14,
    "meghalaya_himachal pradesh": 14,
    "mizoram_himachal pradesh": 14,
    "nagaland_himachal pradesh": 14,
    "sikkim_himachal pradesh": 14,
    "tripura_himachal pradesh": 14,
    "northe east_himachal pradesh": 14,
    "arunachal pradesh_jammu & kashmir": 16,
    "assam_jammu & kashmir": 16,
    "manipur_jammu & kashmir": 16,
    "meghalaya_jammu & kashmir": 16,
    "mizoram_jammu & kashmir": 16,
    "nagaland_jammu & kashmir": 16,
    "sikkim_jammu & kashmir": 16,
    "tripura_jammu & kashmir": 16,
    "northe east_jammu & kashmir": 16,
    "arunachal pradesh_andhra pradesh": 14,
    "assam_andhra pradesh": 14,
    "manipur_andhra pradesh": 14,
    "meghalaya_andhra pradesh": 14,
    "mizoram_andhra pradesh": 14,
    "nagaland_andhra pradesh": 14,
    "sikkim_andhra pradesh": 14,
    "tripura_andhra pradesh": 14,
    "northe east_andhra pradesh": 14,
    "arunachal pradesh_telangana": 14,
    "assam_telangana": 14,
    "manipur_telangana": 14,
    "meghalaya_telangana": 14,
    "mizoram_telangana": 14,
    "nagaland_telangana": 14,
    "sikkim_telangana": 14,
    "tripura_telangana": 14,
    "northe east_telangana": 14,
    "arunachal pradesh_hyderabad": 8.5,
    "assam_hyderabad": 8.5,
    "manipur_hyderabad": 8.5,
    "meghalaya_hyderabad": 8.5,
    "mizoram_hyderabad": 8.5,
    "nagaland_hyderabad": 8.5,
    "sikkim_hyderabad": 8.5,
    "tripura_hyderabad": 8.5,
    "northe east_hyderabad": 8.5,
    "arunachal pradesh_karnataka": 15,
    "assam_karnataka": 15,
    "manipur_karnataka": 15,
    "meghalaya_karnataka": 15,
    "mizoram_karnataka": 15,
    "nagaland_karnataka": 15,
    "sikkim_karnataka": 15,
    "tripura_karnataka": 15,
    "northe east_karnataka": 15,
    "arunachal pradesh_bengaluru": 15,
    "assam_bengaluru": 15,
    "manipur_bengaluru": 15,
    "meghalaya_bengaluru": 15,
    "mizoram_bengaluru": 15,
    "nagaland_bengaluru": 15,
    "sikkim_bengaluru": 15,
    "tripura_bengaluru": 15,
    "northe east_bengaluru": 15,
    "arunachal pradesh_south tamil nadu - madras": 15,
    "assam_south tamil nadu - madras": 15,
    "manipur_south tamil nadu - madras": 15,
    "meghalaya_south tamil nadu - madras": 15,
    "mizoram_south tamil nadu - madras": 15,
    "nagaland_south tamil nadu - madras": 15,
    "sikkim_south tamil nadu - madras": 15,
    "tripura_south tamil nadu - madras": 15,
    "northe east_south tamil nadu - madras": 15,
    "arunachal pradesh_pondicherry": 15,
    "assam_pondicherry": 15,
    "manipur_pondicherry": 15,
    "meghalaya_pondicherry": 15,
    "mizoram_pondicherry": 15,
    "nagaland_pondicherry": 15,
    "sikkim_pondicherry": 15,
    "tripura_pondicherry": 15,
    "northe east_pondicherry": 15,
    "arunachal pradesh_madurai": 15,
    "assam_madurai": 15,
    "manipur_madurai": 15,
    "meghalaya_madurai": 15,
    "mizoram_madurai": 15,
    "nagaland_madurai": 15,
    "sikkim_madurai": 15,
    "tripura_madurai": 15,
    "northe east_madurai": 15,
    "arunachal pradesh_trichy": 15,
    "assam_trichy": 15,
    "manipur_trichy": 15,
    "meghalaya_trichy": 15,
    "mizoram_trichy": 15,
    "nagaland_trichy": 15,
    "sikkim_trichy": 15,
    "tripura_trichy": 15,
    "northe east_trichy": 15,
    "arunachal pradesh_tanjavur": 15,
    "assam_tanjavur": 15,
    "manipur_tanjavur": 15,
    "meghalaya_tanjavur": 15,
    "mizoram_tanjavur": 15,
    "nagaland_tanjavur": 15,
    "sikkim_tanjavur": 15,
    "tripura_tanjavur": 15,
    "northe east_tanjavur": 15,
    "arunachal pradesh_tiruarunachal pradesh": 15,
    "assam_tiruarunachal pradesh": 15,
    "manipur_tiruarunachal pradesh": 15,
    "meghalaya_tiruarunachal pradesh": 15,
    "mizoram_tiruarunachal pradesh": 15,
    "nagaland_tiruarunachal pradesh": 15,
    "sikkim_tiruarunachal pradesh": 15,
    "tripura_tiruarunachal pradesh": 15,
    "northe east_tiruarunachal pradesh": 15,
    "arunachal pradesh_northe eastlvelli": 15,
    "assam_northe eastlvelli": 15,
    "manipur_northe eastlvelli": 15,
    "meghalaya_northe eastlvelli": 15,
    "mizoram_northe eastlvelli": 15,
    "nagaland_northe eastlvelli": 15,
    "sikkim_northe eastlvelli": 15,
    "tripura_northe eastlvelli": 15,
    "northe east_northe eastlvelli": 15,
    "arunachal pradesh_tuticorin": 15,
    "assam_tuticorin": 15,
    "manipur_tuticorin": 15,
    "meghalaya_tuticorin": 15,
    "mizoram_tuticorin": 15,
    "nagaland_tuticorin": 15,
    "sikkim_tuticorin": 15,
    "tripura_tuticorin": 15,
    "northe east_tuticorin": 15,
    "arunachal pradesh_erode": 15.5,
    "assam_erode": 15.5,
    "manipur_erode": 15.5,
    "meghalaya_erode": 15.5,
    "mizoram_erode": 15.5,
    "nagaland_erode": 15.5,
    "sikkim_erode": 15.5,
    "tripura_erode": 15.5,
    "northe east_erode": 15.5,
    "arunachal pradesh_salem": 15.5,
    "assam_salem": 15.5,
    "manipur_salem": 15.5,
    "meghalaya_salem": 15.5,
    "mizoram_salem": 15.5,
    "nagaland_salem": 15.5,
    "sikkim_salem": 15.5,
    "tripura_salem": 15.5,
    "northe east_salem": 15.5,
    "arunachal pradesh_tirupur": 15.5,
    "assam_tirupur": 15.5,
    "manipur_tirupur": 15.5,
    "meghalaya_tirupur": 15.5,
    "mizoram_tirupur": 15.5,
    "nagaland_tirupur": 15.5,
    "sikkim_tirupur": 15.5,
    "tripura_tirupur": 15.5,
    "northe east_tirupur": 15.5,
    "arunachal pradesh_coimbatore": 15.5,
    "assam_coimbatore": 15.5,
    "manipur_coimbatore": 15.5,
    "meghalaya_coimbatore": 15.5,
    "mizoram_coimbatore": 15.5,
    "nagaland_coimbatore": 15.5,
    "sikkim_coimbatore": 15.5,
    "tripura_coimbatore": 15.5,
    "northe east_coimbatore": 15.5,
    "arunachal pradesh_cochin": 16,
    "assam_cochin": 16,
    "manipur_cochin": 16,
    "meghalaya_cochin": 16,
    "mizoram_cochin": 16,
    "nagaland_cochin": 16,
    "sikkim_cochin": 16,
    "tripura_cochin": 16,
    "northe east_cochin": 16,
    "arunachal pradesh_kerala": 16,
    "assam_kerala": 16,
    "manipur_kerala": 16,
    "meghalaya_kerala": 16,
    "mizoram_kerala": 16,
    "nagaland_kerala": 16,
    "sikkim_kerala": 16,
    "tripura_kerala": 16,
    "northe east_kerala": 16,
    "arunachal pradesh_indore": 13,
    "assam_indore": 13,
    "manipur_indore": 13,
    "meghalaya_indore": 13,
    "mizoram_indore": 13,
    "nagaland_indore": 13,
    "sikkim_indore": 13,
    "tripura_indore": 13,
    "northe east_indore": 13,
    "arunachal pradesh_madhya pradesh": 13,
    "assam_madhya pradesh": 13,
    "manipur_madhya pradesh": 13,
    "meghalaya_madhya pradesh": 13,
    "mizoram_madhya pradesh": 13,
    "nagaland_madhya pradesh": 13,
    "sikkim_madhya pradesh": 13,
    "tripura_madhya pradesh": 13,
    "northe east_madhya pradesh": 13,
    "arunachal pradesh_nagpur": 13,
    "assam_nagpur": 13,
    "manipur_nagpur": 13,
    "meghalaya_nagpur": 13,
    "mizoram_nagpur": 13,
    "nagaland_nagpur": 13,
    "sikkim_nagpur": 13,
    "tripura_nagpur": 13,
    "northe east_nagpur": 13,
    "arunachal pradesh_raipur": 13,
    "assam_raipur": 13,
    "manipur_raipur": 13,
    "meghalaya_raipur": 13,
    "mizoram_raipur": 13,
    "nagaland_raipur": 13,
    "sikkim_raipur": 13,
    "tripura_raipur": 13,
    "northe east_raipur": 13,
    "arunachal pradesh_chhattisgarh": 13,
    "assam_chhattisgarh": 13,
    "manipur_chhattisgarh": 13,
    "meghalaya_chhattisgarh": 13,
    "mizoram_chhattisgarh": 13,
    "nagaland_chhattisgarh": 13,
    "sikkim_chhattisgarh": 13,
    "tripura_chhattisgarh": 13,
    "northe east_chhattisgarh": 13,
    "arunachal pradesh_vidarbha": 13,
    "assam_vidarbha": 13,
    "manipur_vidarbha": 13,
    "meghalaya_vidarbha": 13,
    "mizoram_vidarbha": 13,
    "nagaland_vidarbha": 13,
    "sikkim_vidarbha": 13,
    "tripura_vidarbha": 13,
    "northe east_vidarbha": 13,
    "arunachal pradesh_akola": 13,
    "assam_akola": 13,
    "manipur_akola": 13,
    "meghalaya_akola": 13,
    "mizoram_akola": 13,
    "nagaland_akola": 13,
    "sikkim_akola": 13,
    "tripura_akola": 13,
    "northe east_akola": 13,
    "arunachal pradesh_amravati": 13,
    "assam_amravati": 13,
    "manipur_amravati": 13,
    "meghalaya_amravati": 13,
    "mizoram_amravati": 13,
    "nagaland_amravati": 13,
    "sikkim_amravati": 13,
    "tripura_amravati": 13,
    "northe east_amravati": 13,
    "arunachal pradesh_chandrapur": 13,
    "assam_chandrapur": 13,
    "manipur_chandrapur": 13,
    "meghalaya_chandrapur": 13,
    "mizoram_chandrapur": 13,
    "nagaland_chandrapur": 13,
    "sikkim_chandrapur": 13,
    "tripura_chandrapur": 13,
    "northe east_chandrapur": 13,
    "arunachal pradesh_kolkata": 11,
    "assam_kolkata": 11,
    "manipur_kolkata": 11,
    "meghalaya_kolkata": 11,
    "mizoram_kolkata": 11,
    "nagaland_kolkata": 11,
    "sikkim_kolkata": 11,
    "tripura_kolkata": 11,
    "northe east_kolkata": 11,
    "arunachal pradesh_west bengal": 11,
    "assam_west bengal": 11,
    "manipur_west bengal": 11,
    "meghalaya_west bengal": 11,
    "mizoram_west bengal": 11,
    "nagaland_west bengal": 11,
    "sikkim_west bengal": 11,
    "tripura_west bengal": 11,
    "northe east_west bengal": 11,
    "arunachal pradesh_odisha": 11,
    "assam_odisha": 11,
    "manipur_odisha": 11,
    "meghalaya_odisha": 11,
    "mizoram_odisha": 11,
    "nagaland_odisha": 11,
    "sikkim_odisha": 11,
    "tripura_odisha": 11,
    "northe east_odisha": 11,
    "arunachal pradesh_guwahati": 10,
    "assam_guwahati": 10,
    "manipur_guwahati": 10,
    "meghalaya_guwahati": 10,
    "mizoram_guwahati": 10,
    "nagaland_guwahati": 10,
    "sikkim_guwahati": 10,
    "tripura_guwahati": 10,
    "northe east_guwahati": 10,
    "arunachal pradesh_lucknow": 13,
    "assam_lucknow": 13,
    "manipur_lucknow": 13,
    "meghalaya_lucknow": 13,
    "mizoram_lucknow": 13,
    "nagaland_lucknow": 13,
    "sikkim_lucknow": 13,
    "tripura_lucknow": 13,
    "northe east_lucknow": 13,
    "arunachal pradesh_allahabad": 13,
    "assam_allahabad": 13,
    "manipur_allahabad": 13,
    "meghalaya_allahabad": 13,
    "mizoram_allahabad": 13,
    "nagaland_allahabad": 13,
    "sikkim_allahabad": 13,
    "tripura_allahabad": 13,
    "northe east_allahabad": 13,
    "arunachal pradesh_kanpur": 13,
    "assam_kanpur": 13,
    "manipur_kanpur": 13,
    "meghalaya_kanpur": 13,
    "mizoram_kanpur": 13,
    "nagaland_kanpur": 13,
    "sikkim_kanpur": 13,
    "tripura_kanpur": 13,
    "northe east_kanpur": 13,
    "arunachal pradesh_varanasi": 13,
    "assam_varanasi": 13,
    "manipur_varanasi": 13,
    "meghalaya_varanasi": 13,
    "mizoram_varanasi": 13,
    "nagaland_varanasi": 13,
    "sikkim_varanasi": 13,
    "tripura_varanasi": 13,
    "northe east_varanasi": 13,
    "arunachal pradesh_agra": 13,
    "assam_agra": 13,
    "manipur_agra": 13,
    "meghalaya_agra": 13,
    "mizoram_agra": 13,
    "nagaland_agra": 13,
    "sikkim_agra": 13,
    "tripura_agra": 13,
    "northe east_agra": 13,
    "arunachal pradesh_mathura": 13,
    "assam_mathura": 13,
    "manipur_mathura": 13,
    "meghalaya_mathura": 13,
    "mizoram_mathura": 13,
    "nagaland_mathura": 13,
    "sikkim_mathura": 13,
    "tripura_mathura": 13,
    "northe east_mathura": 13,
    "arunachal pradesh_eastern uttarpradesh": 13,
    "assam_eastern uttarpradesh": 13,
    "manipur_eastern uttarpradesh": 13,
    "meghalaya_eastern uttarpradesh": 13,
    "mizoram_eastern uttarpradesh": 13,
    "nagaland_eastern uttarpradesh": 13,
    "sikkim_eastern uttarpradesh": 13,
    "tripura_eastern uttarpradesh": 13,
    "northe east_eastern uttarpradesh": 13,
    "arunachal pradesh_bihar": 11,
    "assam_bihar": 11,
    "manipur_bihar": 11,
    "meghalaya_bihar": 11,
    "mizoram_bihar": 11,
    "nagaland_bihar": 11,
    "sikkim_bihar": 11,
    "tripura_bihar": 11,
    "northe east_bihar": 11,
    "arunachal pradesh_jharkhand": 11,
    "assam_jharkhand": 11,
    "manipur_jharkhand": 11,
    "meghalaya_jharkhand": 11,
    "mizoram_jharkhand": 11,
    "nagaland_jharkhand": 11,
    "sikkim_jharkhand": 11,
    "tripura_jharkhand": 11,
    "northe east_jharkhand": 11,
    "nashik_arunachal pradesh": 24,
    "mumbai_arunachal pradesh": 24,
    "bhiwandi_arunachal pradesh": 24,
    "panvel_arunachal pradesh": 24,
    "nashik_assam": 24,
    "mumbai_assam": 24,
    "bhiwandi_assam": 24,
    "panvel_assam": 24,
    "nashik_manipur": 24,
    "mumbai_manipur": 24,
    "bhiwandi_manipur": 24,
    "panvel_manipur": 24,
    "nashik_meghalaya": 24,
    "mumbai_meghalaya": 24,
    "bhiwandi_meghalaya": 24,
    "panvel_meghalaya": 24,
    "nashik_mizoram": 24,
    "mumbai_mizoram": 24,
    "bhiwandi_mizoram": 24,
    "panvel_mizoram": 24,
    "nashik_nagaland": 24,
    "mumbai_nagaland": 24,
    "bhiwandi_nagaland": 24,
    "panvel_nagaland": 24,
    "nashik_sikkim": 24,
    "mumbai_sikkim": 24,
    "bhiwandi_sikkim": 24,
    "panvel_sikkim": 24,
    "nashik_tripura": 24,
    "mumbai_tripura": 24,
    "bhiwandi_tripura": 24,
    "panvel_tripura": 24,
    "nashik_northe east": 24,
    "mumbai_northe east": 24,
    "bhiwandi_northe east": 24,
    "panvel_northe east": 24,
    "nashik_kolhapur": 7.5,
    "mumbai_kolhapur": 7.5,
    "bhiwandi_kolhapur": 7.5,
    "panvel_kolhapur": 7.5,
    "nashik_aurangabad": 7.5,
    "mumbai_aurangabad": 7.5,
    "bhiwandi_aurangabad": 7.5,
    "panvel_aurangabad": 7.5,
    "nashik_satara": 7.5,
    "mumbai_satara": 7.5,
    "bhiwandi_satara": 7.5,
    "panvel_satara": 7.5,
    "nashik_goa": 7.5,
    "mumbai_goa": 7.5,
    "bhiwandi_goa": 7.5,
    "panvel_goa": 7.5,
    "nashik_nashik": 6.5,
    "mumbai_nashik": 6.5,
    "bhiwandi_nashik": 6.5,
    "panvel_nashik": 6.5,
    "nashik_mumbai": 6.5,
    "mumbai_mumbai": 6.5,
    "bhiwandi_mumbai": 6.5,
    "panvel_mumbai": 6.5,
    "nashik_bhiwandi": 6.5,
    "mumbai_bhiwandi": 6.5,
    "bhiwandi_bhiwandi": 6.5,
    "panvel_bhiwandi": 6.5,
    "nashik_panvel": 6.5,
    "mumbai_panvel": 6.5,
    "bhiwandi_panvel": 6.5,
    "panvel_panvel": 6.5,
    "nashik_gujrat": 8,
    "mumbai_gujrat": 8,
    "bhiwandi_gujrat": 8,
    "panvel_gujrat": 8,
    "nashik_vapi": 8,
    "mumbai_vapi": 8,
    "bhiwandi_vapi": 8,
    "panvel_vapi": 8,
    "nashik_daman": 8,
    "mumbai_daman": 8,
    "bhiwandi_daman": 8,
    "panvel_daman": 8,
    "nashik_silvasa": 8,
    "mumbai_silvasa": 8,
    "bhiwandi_silvasa": 8,
    "panvel_silvasa": 8,
    "nashik_delhi": 9.5,
    "mumbai_delhi": 9.5,
    "bhiwandi_delhi": 9.5,
    "panvel_delhi": 9.5,
    "nashik_rajasthan": 9.5,
    "mumbai_rajasthan": 9.5,
    "bhiwandi_rajasthan": 9.5,
    "panvel_rajasthan": 9.5,
    "nashik_chandigarh": 10.5,
    "mumbai_chandigarh": 10.5,
    "bhiwandi_chandigarh": 10.5,
    "panvel_chandigarh": 10.5,
    "nashik_uttarakhand": 10.5,
    "mumbai_uttarakhand": 10.5,
    "bhiwandi_uttarakhand": 10.5,
    "panvel_uttarakhand": 10.5,
    "nashik_punjab": 10.5,
    "mumbai_punjab": 10.5,
    "bhiwandi_punjab": 10.5,
    "panvel_punjab": 10.5,
    "nashik_haryana": 10.5,
    "mumbai_haryana": 10.5,
    "bhiwandi_haryana": 10.5,
    "panvel_haryana": 10.5,
    "nashik_himachal pradesh": 10.5,
    "mumbai_himachal pradesh": 10.5,
    "bhiwandi_himachal pradesh": 10.5,
    "panvel_himachal pradesh": 10.5,
    "nashik_jammu & kashmir": 12.5,
    "mumbai_jammu & kashmir": 12.5,
    "bhiwandi_jammu & kashmir": 12.5,
    "panvel_jammu & kashmir": 12.5,
    "nashik_andhra pradesh": 8.5,
    "mumbai_andhra pradesh": 8.5,
    "bhiwandi_andhra pradesh": 8.5,
    "panvel_andhra pradesh": 8.5,
    "nashik_telangana": 8.5,
    "mumbai_telangana": 8.5,
    "bhiwandi_telangana": 8.5,
    "panvel_telangana": 8.5,
    "nashik_karnataka": 8.5,
    "mumbai_karnataka": 8.5,
    "bhiwandi_karnataka": 8.5,
    "panvel_karnataka": 8.5,
    "nashik_bengaluru": 8.5,
    "mumbai_bengaluru": 8.5,
    "bhiwandi_bengaluru": 8.5,
    "panvel_bengaluru": 8.5,
    "nashik_south tamil nadu - madras": 9.5,
    "mumbai_south tamil nadu - madras": 9.5,
    "bhiwandi_south tamil nadu - madras": 9.5,
    "panvel_south tamil nadu - madras": 9.5,
    "nashik_pondicherry": 9.5,
    "mumbai_pondicherry": 9.5,
    "bhiwandi_pondicherry": 9.5,
    "panvel_pondicherry": 9.5,
    "nashik_madurai": 9.5,
    "mumbai_madurai": 9.5,
    "bhiwandi_madurai": 9.5,
    "panvel_madurai": 9.5,
    "nashik_trichy": 9.5,
    "mumbai_trichy": 9.5,
    "bhiwandi_trichy": 9.5,
    "panvel_trichy": 9.5,
    "nashik_tanjavur": 9.5,
    "mumbai_tanjavur": 9.5,
    "bhiwandi_tanjavur": 9.5,
    "panvel_tanjavur": 9.5,
    "nashik_tiruarunachal pradesh": 9.5,
    "mumbai_tiruarunachal pradesh": 9.5,
    "bhiwandi_tiruarunachal pradesh": 9.5,
    "panvel_tiruarunachal pradesh": 9.5,
    "nashik_northe eastlvelli": 9.5,
    "mumbai_northe eastlvelli": 9.5,
    "bhiwandi_northe eastlvelli": 9.5,
    "panvel_northe eastlvelli": 9.5,
    "nashik_tuticorin": 9.5,
    "mumbai_tuticorin": 9.5,
    "bhiwandi_tuticorin": 9.5,
    "panvel_tuticorin": 9.5,
    "nashik_erode": 10,
    "mumbai_erode": 10,
    "bhiwandi_erode": 10,
    "panvel_erode": 10,
    "nashik_salem": 10,
    "mumbai_salem": 10,
    "bhiwandi_salem": 10,
    "panvel_salem": 10,
    "nashik_tirupur": 10,
    "mumbai_tirupur": 10,
    "bhiwandi_tirupur": 10,
    "panvel_tirupur": 10,
    "nashik_coimbatore": 10,
    "mumbai_coimbatore": 10,
    "bhiwandi_coimbatore": 10,
    "panvel_coimbatore": 10,
    "nashik_cochin": 13,
    "mumbai_cochin": 13,
    "bhiwandi_cochin": 13,
    "panvel_cochin": 13,
    "nashik_kerala": 13,
    "mumbai_kerala": 13,
    "bhiwandi_kerala": 13,
    "panvel_kerala": 13,
    "nashik_indore": 8,
    "mumbai_indore": 8,
    "bhiwandi_indore": 8,
    "panvel_indore": 8,
    "nashik_madhya pradesh": 8,
    "mumbai_madhya pradesh": 8,
    "bhiwandi_madhya pradesh": 8,
    "panvel_madhya pradesh": 8,
    "nashik_nagpur": 7,
    "mumbai_nagpur": 7,
    "bhiwandi_nagpur": 7,
    "panvel_nagpur": 7,
    "nashik_raipur": 7,
    "mumbai_raipur": 7,
    "bhiwandi_raipur": 7,
    "panvel_raipur": 7,
    "nashik_chhattisgarh": 7,
    "mumbai_chhattisgarh": 7,
    "bhiwandi_chhattisgarh": 7,
    "panvel_chhattisgarh": 7,
    "nashik_vidarbha": 7,
    "mumbai_vidarbha": 7,
    "bhiwandi_vidarbha": 7,
    "panvel_vidarbha": 7,
    "nashik_akola": 7,
    "mumbai_akola": 7,
    "bhiwandi_akola": 7,
    "panvel_akola": 7,
    "nashik_amravati": 7,
    "mumbai_amravati": 7,
    "bhiwandi_amravati": 7,
    "panvel_amravati": 7,
    "nashik_chandrapur": 7,
    "mumbai_chandrapur": 7,
    "bhiwandi_chandrapur": 7,
    "panvel_chandrapur": 7,
    "nashik_kolkata": 12.5,
    "mumbai_kolkata": 12.5,
    "bhiwandi_kolkata": 12.5,
    "panvel_kolkata": 12.5,
    "nashik_west bengal": 12.5,
    "mumbai_west bengal": 12.5,
    "bhiwandi_west bengal": 12.5,
    "panvel_west bengal": 12.5,
    "nashik_odisha": 12.5,
    "mumbai_odisha": 12.5,
    "bhiwandi_odisha": 12.5,
    "panvel_odisha": 12.5,
    "nashik_guwahati": 16,
    "mumbai_guwahati": 16,
    "bhiwandi_guwahati": 16,
    "panvel_guwahati": 16,
    "nashik_lucknow": 10.5,
    "mumbai_lucknow": 10.5,
    "bhiwandi_lucknow": 10.5,
    "panvel_lucknow": 10.5,
    "nashik_allahabad": 10.5,
    "mumbai_allahabad": 10.5,
    "bhiwandi_allahabad": 10.5,
    "panvel_allahabad": 10.5,
    "nashik_kanpur": 10.5,
    "mumbai_kanpur": 10.5,
    "bhiwandi_kanpur": 10.5,
    "panvel_kanpur": 10.5,
    "nashik_varanasi": 10.5,
    "mumbai_varanasi": 10.5,
    "bhiwandi_varanasi": 10.5,
    "panvel_varanasi": 10.5,
    "nashik_agra": 10.5,
    "mumbai_agra": 10.5,
    "bhiwandi_agra": 10.5,
    "panvel_agra": 10.5,
    "nashik_mathura": 10.5,
    "mumbai_mathura": 10.5,
    "bhiwandi_mathura": 10.5,
    "panvel_mathura": 10.5,
    "nashik_eastern uttarpradesh": 10.5,
    "mumbai_eastern uttarpradesh": 10.5,
    "bhiwandi_eastern uttarpradesh": 10.5,
    "panvel_eastern uttarpradesh": 10.5,
    "nashik_bihar": 10.5,
    "mumbai_bihar": 10.5,
    "bhiwandi_bihar": 10.5,
    "panvel_bihar": 10.5,
    "nashik_jharkhand": 10.5,
    "mumbai_jharkhand": 10.5,
    "bhiwandi_jharkhand": 10.5,
    "panvel_jharkhand": 10.5,
    "gujrat_arunachal pradesh": 24,
    "vapi_arunachal pradesh": 24,
    "daman_arunachal pradesh": 24,
    "silvasa_arunachal pradesh": 24,
    "gujrat_assam": 24,
    "vapi_assam": 24,
    "daman_assam": 24,
    "silvasa_assam": 24,
    "gujrat_manipur": 24,
    "vapi_manipur": 24,
    "daman_manipur": 24,
    "silvasa_manipur": 24,
    "gujrat_meghalaya": 24,
    "vapi_meghalaya": 24,
    "daman_meghalaya": 24,
    "silvasa_meghalaya": 24,
    "gujrat_mizoram": 24,
    "vapi_mizoram": 24,
    "daman_mizoram": 24,
    "silvasa_mizoram": 24,
    "gujrat_nagaland": 24,
    "vapi_nagaland": 24,
    "daman_nagaland": 24,
    "silvasa_nagaland": 24,
    "gujrat_sikkim": 24,
    "vapi_sikkim": 24,
    "daman_sikkim": 24,
    "silvasa_sikkim": 24,
    "gujrat_tripura": 24,
    "vapi_tripura": 24,
    "daman_tripura": 24,
    "silvasa_tripura": 24,
    "gujrat_northe east": 24,
    "vapi_northe east": 24,
    "daman_northe east": 24,
    "silvasa_northe east": 24,
    "gujrat_kolhapur": 8,
    "vapi_kolhapur": 8,
    "daman_kolhapur": 8,
    "silvasa_kolhapur": 8,
    "gujrat_aurangabad": 8,
    "vapi_aurangabad": 8,
    "daman_aurangabad": 8,
    "silvasa_aurangabad": 8,
    "gujrat_satara": 8,
    "vapi_satara": 8,
    "daman_satara": 8,
    "silvasa_satara": 8,
    "gujrat_goa": 8,
    "vapi_goa": 8,
    "daman_goa": 8,
    "silvasa_goa": 8,
    "gujrat_nashik": 7.5,
    "vapi_nashik": 7.5,
    "daman_nashik": 7.5,
    "silvasa_nashik": 7.5,
    "gujrat_mumbai": 7.5,
    "vapi_mumbai": 7.5,
    "daman_mumbai": 7.5,
    "silvasa_mumbai": 7.5,
    "gujrat_bhiwandi": 7.5,
    "vapi_bhiwandi": 7.5,
    "daman_bhiwandi": 7.5,
    "silvasa_bhiwandi": 7.5,
    "gujrat_panvel": 7.5,
    "vapi_panvel": 7.5,
    "daman_panvel": 7.5,
    "silvasa_panvel": 7.5,
    "gujrat_gujrat": 6.5,
    "vapi_gujrat": 6.5,
    "daman_gujrat": 6.5,
    "silvasa_gujrat": 6.5,
    "gujrat_vapi": 6.5,
    "vapi_vapi": 6.5,
    "daman_vapi": 6.5,
    "silvasa_vapi": 6.5,
    "gujrat_daman": 6.5,
    "vapi_daman": 6.5,
    "daman_daman": 6.5,
    "silvasa_daman": 6.5,
    "gujrat_silvasa": 6.5,
    "vapi_silvasa": 6.5,
    "daman_silvasa": 6.5,
    "silvasa_silvasa": 6.5,
    "gujrat_delhi": 9.5,
    "vapi_delhi": 9.5,
    "daman_delhi": 9.5,
    "silvasa_delhi": 9.5,
    "gujrat_rajasthan": 9.5,
    "vapi_rajasthan": 9.5,
    "daman_rajasthan": 9.5,
    "silvasa_rajasthan": 9.5,
    "gujrat_chandigarh": 10.5,
    "vapi_chandigarh": 10.5,
    "daman_chandigarh": 10.5,
    "silvasa_chandigarh": 10.5,
    "gujrat_uttarakhand": 10.5,
    "vapi_uttarakhand": 10.5,
    "daman_uttarakhand": 10.5,
    "silvasa_uttarakhand": 10.5,
    "gujrat_punjab": 10.5,
    "vapi_punjab": 10.5,
    "daman_punjab": 10.5,
    "silvasa_punjab": 10.5,
    "gujrat_haryana": 10.5,
    "vapi_haryana": 10.5,
    "daman_haryana": 10.5,
    "silvasa_haryana": 10.5,
    "gujrat_himachal pradesh": 10.5,
    "vapi_himachal pradesh": 10.5,
    "daman_himachal pradesh": 10.5,
    "silvasa_himachal pradesh": 10.5,
    "gujrat_jammu & kashmir": 12.5,
    "vapi_jammu & kashmir": 12.5,
    "daman_jammu & kashmir": 12.5,
    "silvasa_jammu & kashmir": 12.5,
    "gujrat_andhra pradesh": 8.5,
    "vapi_andhra pradesh": 8.5,
    "daman_andhra pradesh": 8.5,
    "silvasa_andhra pradesh": 8.5,
    "gujrat_telangana": 8.5,
    "vapi_telangana": 8.5,
    "daman_telangana": 8.5,
    "silvasa_telangana": 8.5,
    "gujrat_karnataka": 8.5,
    "vapi_karnataka": 8.5,
    "daman_karnataka": 8.5,
    "silvasa_karnataka": 8.5,
    "gujrat_bengaluru": 8.5,
    "vapi_bengaluru": 8.5,
    "daman_bengaluru": 8.5,
    "silvasa_bengaluru": 8.5,
    "gujrat_south tamil nadu - madras": 9.5,
    "vapi_south tamil nadu - madras": 9.5,
    "daman_south tamil nadu - madras": 9.5,
    "silvasa_south tamil nadu - madras": 9.5,
    "gujrat_pondicherry": 9.5,
    "vapi_pondicherry": 9.5,
    "daman_pondicherry": 9.5,
    "silvasa_pondicherry": 9.5,
    "gujrat_madurai": 9.5,
    "vapi_madurai": 9.5,
    "daman_madurai": 9.5,
    "silvasa_madurai": 9.5,
    "gujrat_trichy": 9.5,
    "vapi_trichy": 9.5,
    "daman_trichy": 9.5,
    "silvasa_trichy": 9.5,
    "gujrat_tanjavur": 9.5,
    "vapi_tanjavur": 9.5,
    "daman_tanjavur": 9.5,
    "silvasa_tanjavur": 9.5,
    "gujrat_tiruarunachal pradesh": 9.5,
    "vapi_tiruarunachal pradesh": 9.5,
    "daman_tiruarunachal pradesh": 9.5,
    "silvasa_tiruarunachal pradesh": 9.5,
    "gujrat_northe eastlvelli": 9.5,
    "vapi_northe eastlvelli": 9.5,
    "daman_northe eastlvelli": 9.5,
    "silvasa_northe eastlvelli": 9.5,
    "gujrat_tuticorin": 9.5,
    "vapi_tuticorin": 9.5,
    "daman_tuticorin": 9.5,
    "silvasa_tuticorin": 9.5,
    "gujrat_erode": 10,
    "vapi_erode": 10,
    "daman_erode": 10,
    "silvasa_erode": 10,
    "gujrat_salem": 10,
    "vapi_salem": 10,
    "daman_salem": 10,
    "silvasa_salem": 10,
    "gujrat_tirupur": 10,
    "vapi_tirupur": 10,
    "daman_tirupur": 10,
    "silvasa_tirupur": 10,
    "gujrat_coimbatore": 10,
    "vapi_coimbatore": 10,
    "daman_coimbatore": 10,
    "silvasa_coimbatore": 10,
    "gujrat_cochin": 13,
    "vapi_cochin": 13,
    "daman_cochin": 13,
    "silvasa_cochin": 13,
    "gujrat_kerala": 13,
    "vapi_kerala": 13,
    "daman_kerala": 13,
    "silvasa_kerala": 13,
    "gujrat_indore": 7.5,
    "vapi_indore": 7.5,
    "daman_indore": 7.5,
    "silvasa_indore": 7.5,
    "gujrat_madhya pradesh": 7.5,
    "vapi_madhya pradesh": 7.5,
    "daman_madhya pradesh": 7.5,
    "silvasa_madhya pradesh": 7.5,
    "gujrat_nagpur": 7,
    "vapi_nagpur": 7,
    "daman_nagpur": 7,
    "silvasa_nagpur": 7,
    "gujrat_raipur": 7,
    "vapi_raipur": 7,
    "daman_raipur": 7,
    "silvasa_raipur": 7,
    "gujrat_chhattisgarh": 7,
    "vapi_chhattisgarh": 7,
    "daman_chhattisgarh": 7,
    "silvasa_chhattisgarh": 7,
    "gujrat_vidarbha": 7,
    "vapi_vidarbha": 7,
    "daman_vidarbha": 7,
    "silvasa_vidarbha": 7,
    "gujrat_akola": 7,
    "vapi_akola": 7,
    "daman_akola": 7,
    "silvasa_akola": 7,
    "gujrat_amravati": 7,
    "vapi_amravati": 7,
    "daman_amravati": 7,
    "silvasa_amravati": 7,
    "gujrat_chandrapur": 7,
    "vapi_chandrapur": 7,
    "daman_chandrapur": 7,
    "silvasa_chandrapur": 7,
    "gujrat_kolkata": 12.5,
    "vapi_kolkata": 12.5,
    "daman_kolkata": 12.5,
    "silvasa_kolkata": 12.5,
    "gujrat_west bengal": 12.5,
    "vapi_west bengal": 12.5,
    "daman_west bengal": 12.5,
    "silvasa_west bengal": 12.5,
    "gujrat_odisha": 12.5,
    "vapi_odisha": 12.5,
    "daman_odisha": 12.5,
    "silvasa_odisha": 12.5,
    "gujrat_guwahati": 16,
    "vapi_guwahati": 16,
    "daman_guwahati": 16,
    "silvasa_guwahati": 16,
    "gujrat_lucknow": 10.5,
    "vapi_lucknow": 10.5,
    "daman_lucknow": 10.5,
    "silvasa_lucknow": 10.5,
    "gujrat_allahabad": 10.5,
    "vapi_allahabad": 10.5,
    "daman_allahabad": 10.5,
    "silvasa_allahabad": 10.5,
    "gujrat_kanpur": 10.5,
    "vapi_kanpur": 10.5,
    "daman_kanpur": 10.5,
    "silvasa_kanpur": 10.5,
    "gujrat_varanasi": 10.5,
    "vapi_varanasi": 10.5,
    "daman_varanasi": 10.5,
    "silvasa_varanasi": 10.5,
    "gujrat_agra": 10.5,
    "vapi_agra": 10.5,
    "daman_agra": 10.5,
    "silvasa_agra": 10.5,
    "gujrat_mathura": 10.5,
    "vapi_mathura": 10.5,
    "daman_mathura": 10.5,
    "silvasa_mathura": 10.5,
    "gujrat_eastern uttarpradesh": 10.5,
    "vapi_eastern uttarpradesh": 10.5,
    "daman_eastern uttarpradesh": 10.5,
    "silvasa_eastern uttarpradesh": 10.5,
    "gujrat_bihar": 10.5,
    "vapi_bihar": 10.5,
    "daman_bihar": 10.5,
    "silvasa_bihar": 10.5,
    "gujrat_jharkhand": 10.5,
    "vapi_jharkhand": 10.5,
    "daman_jharkhand": 10.5,
    "silvasa_jharkhand": 10.5,
    "delhi_arunachal pradesh": 24,
    "rajasthan_arunachal pradesh": 24,
    "delhi_assam": 24,
    "rajasthan_assam": 24,
    "delhi_manipur": 24,
    "rajasthan_manipur": 24,
    "delhi_meghalaya": 24,
    "rajasthan_meghalaya": 24,
    "delhi_mizoram": 24,
    "rajasthan_mizoram": 24,
    "delhi_nagaland": 24,
    "rajasthan_nagaland": 24,
    "delhi_sikkim": 24,
    "rajasthan_sikkim": 24,
    "delhi_tripura": 24,
    "rajasthan_tripura": 24,
    "delhi_northe east": 24,
    "rajasthan_northe east": 24,
    "delhi_kolhapur": 9.5,
    "rajasthan_kolhapur": 9.5,
    "delhi_aurangabad": 9.5,
    "rajasthan_aurangabad": 9.5,
    "delhi_satara": 9.5,
    "rajasthan_satara": 9.5,
    "delhi_goa": 9.5,
    "rajasthan_goa": 9.5,
    "delhi_nashik": 9.5,
    "rajasthan_nashik": 9.5,
    "delhi_mumbai": 9.5,
    "rajasthan_mumbai": 9.5,
    "delhi_bhiwandi": 9.5,
    "rajasthan_bhiwandi": 9.5,
    "delhi_panvel": 9.5,
    "rajasthan_panvel": 9.5,
    "delhi_gujrat": 9.5,
    "rajasthan_gujrat": 9.5,
    "delhi_vapi": 9.5,
    "rajasthan_vapi": 9.5,
    "delhi_daman": 9.5,
    "rajasthan_daman": 9.5,
    "delhi_silvasa": 9.5,
    "rajasthan_silvasa": 9.5,
    "delhi_delhi": 6.5,
    "rajasthan_delhi": 6.5,
    "delhi_rajasthan": 6.5,
    "rajasthan_rajasthan": 6.5,
    "delhi_chandigarh": 6.5,
    "rajasthan_chandigarh": 6.5,
    "delhi_uttarakhand": 6.5,
    "rajasthan_uttarakhand": 6.5,
    "delhi_punjab": 6.5,
    "rajasthan_punjab": 6.5,
    "delhi_haryana": 6.5,
    "rajasthan_haryana": 6.5,
    "delhi_himachal pradesh": 6.5,
    "rajasthan_himachal pradesh": 6.5,
    "delhi_jammu & kashmir": 8.5,
    "rajasthan_jammu & kashmir": 8.5,
    "delhi_andhra pradesh": 9.5,
    "rajasthan_andhra pradesh": 9.5,
    "delhi_telangana": 9.5,
    "rajasthan_telangana": 9.5,
    "delhi_karnataka": 10.5,
    "rajasthan_karnataka": 10.5,
    "delhi_bengaluru": 10.5,
    "rajasthan_bengaluru": 10.5,
    "delhi_south tamil nadu - madras": 10.5,
    "rajasthan_south tamil nadu - madras": 10.5,
    "delhi_pondicherry": 10.5,
    "rajasthan_pondicherry": 10.5,
    "delhi_madurai": 10.5,
    "rajasthan_madurai": 10.5,
    "delhi_trichy": 10.5,
    "rajasthan_trichy": 10.5,
    "delhi_tanjavur": 10.5,
    "rajasthan_tanjavur": 10.5,
    "delhi_tiruarunachal pradesh": 10.5,
    "rajasthan_tiruarunachal pradesh": 10.5,
    "delhi_northe eastlvelli": 10.5,
    "rajasthan_northe eastlvelli": 10.5,
    "delhi_tuticorin": 10.5,
    "rajasthan_tuticorin": 10.5,
    "delhi_erode": 11.5,
    "rajasthan_erode": 11.5,
    "delhi_salem": 11.5,
    "rajasthan_salem": 11.5,
    "delhi_tirupur": 11.5,
    "rajasthan_tirupur": 11.5,
    "delhi_coimbatore": 11.5,
    "rajasthan_coimbatore": 11.5,
    "delhi_cochin": 15,
    "rajasthan_cochin": 15,
    "delhi_kerala": 15,
    "rajasthan_kerala": 15,
    "delhi_indore": 9,
    "rajasthan_indore": 9,
    "delhi_madhya pradesh": 9,
    "rajasthan_madhya pradesh": 9,
    "delhi_nagpur": 9,
    "rajasthan_nagpur": 9,
    "delhi_raipur": 9,
    "rajasthan_raipur": 9,
    "delhi_chhattisgarh": 9,
    "rajasthan_chhattisgarh": 9,
    "delhi_vidarbha": 9,
    "rajasthan_vidarbha": 9,
    "delhi_akola": 9,
    "rajasthan_akola": 9,
    "delhi_amravati": 9,
    "rajasthan_amravati": 9,
    "delhi_chandrapur": 9,
    "rajasthan_chandrapur": 9,
    "delhi_kolkata": 13,
    "rajasthan_kolkata": 13,
    "delhi_west bengal": 13,
    "rajasthan_west bengal": 13,
    "delhi_odisha": 13,
    "rajasthan_odisha": 13,
    "delhi_guwahati": 16,
    "rajasthan_guwahati": 16,
    "delhi_lucknow": 6.5,
    "rajasthan_lucknow": 6.5,
    "delhi_allahabad": 6.5,
    "rajasthan_allahabad": 6.5,
    "delhi_kanpur": 6.5,
    "rajasthan_kanpur": 6.5,
    "delhi_varanasi": 6.5,
    "rajasthan_varanasi": 6.5,
    "delhi_agra": 6.5,
    "rajasthan_agra": 6.5,
    "delhi_mathura": 6.5,
    "rajasthan_mathura": 6.5,
    "delhi_eastern uttarpradesh": 6.5,
    "rajasthan_eastern uttarpradesh": 6.5,
    "delhi_bihar": 12,
    "rajasthan_bihar": 12,
    "delhi_jharkhand": 12,
    "rajasthan_jharkhand": 12,
    "chandigarh_arunachal pradesh": 24,
    "uttarakhand_arunachal pradesh": 24,
    "punjab_arunachal pradesh": 24,
    "haryana_arunachal pradesh": 24,
    "himachal pradesh_arunachal pradesh": 24,
    "chandigarh_assam": 24,
    "uttarakhand_assam": 24,
    "punjab_assam": 24,
    "haryana_assam": 24,
    "himachal pradesh_assam": 24,
    "chandigarh_manipur": 24,
    "uttarakhand_manipur": 24,
    "punjab_manipur": 24,
    "haryana_manipur": 24,
    "himachal pradesh_manipur": 24,
    "chandigarh_meghalaya": 24,
    "uttarakhand_meghalaya": 24,
    "punjab_meghalaya": 24,
    "haryana_meghalaya": 24,
    "himachal pradesh_meghalaya": 24,
    "chandigarh_mizoram": 24,
    "uttarakhand_mizoram": 24,
    "punjab_mizoram": 24,
    "haryana_mizoram": 24,
    "himachal pradesh_mizoram": 24,
    "chandigarh_nagaland": 24,
    "uttarakhand_nagaland": 24,
    "punjab_nagaland": 24,
    "haryana_nagaland": 24,
    "himachal pradesh_nagaland": 24,
    "chandigarh_sikkim": 24,
    "uttarakhand_sikkim": 24,
    "punjab_sikkim": 24,
    "haryana_sikkim": 24,
    "himachal pradesh_sikkim": 24,
    "chandigarh_tripura": 24,
    "uttarakhand_tripura": 24,
    "punjab_tripura": 24,
    "haryana_tripura": 24,
    "himachal pradesh_tripura": 24,
    "chandigarh_northe east": 24,
    "uttarakhand_northe east": 24,
    "punjab_northe east": 24,
    "haryana_northe east": 24,
    "himachal pradesh_northe east": 24,
    "chandigarh_kolhapur": 10.5,
    "uttarakhand_kolhapur": 10.5,
    "punjab_kolhapur": 10.5,
    "haryana_kolhapur": 10.5,
    "himachal pradesh_kolhapur": 10.5,
    "chandigarh_aurangabad": 10.5,
    "uttarakhand_aurangabad": 10.5,
    "punjab_aurangabad": 10.5,
    "haryana_aurangabad": 10.5,
    "himachal pradesh_aurangabad": 10.5,
    "chandigarh_satara": 10.5,
    "uttarakhand_satara": 10.5,
    "punjab_satara": 10.5,
    "haryana_satara": 10.5,
    "himachal pradesh_satara": 10.5,
    "chandigarh_goa": 10.5,
    "uttarakhand_goa": 10.5,
    "punjab_goa": 10.5,
    "haryana_goa": 10.5,
    "himachal pradesh_goa": 10.5,
    "chandigarh_nashik": 10.5,
    "uttarakhand_nashik": 10.5,
    "punjab_nashik": 10.5,
    "haryana_nashik": 10.5,
    "himachal pradesh_nashik": 10.5,
    "chandigarh_mumbai": 10.5,
    "uttarakhand_mumbai": 10.5,
    "punjab_mumbai": 10.5,
    "haryana_mumbai": 10.5,
    "himachal pradesh_mumbai": 10.5,
    "chandigarh_bhiwandi": 10.5,
    "uttarakhand_bhiwandi": 10.5,
    "punjab_bhiwandi": 10.5,
    "haryana_bhiwandi": 10.5,
    "himachal pradesh_bhiwandi": 10.5,
    "chandigarh_panvel": 10.5,
    "uttarakhand_panvel": 10.5,
    "punjab_panvel": 10.5,
    "haryana_panvel": 10.5,
    "himachal pradesh_panvel": 10.5,
    "chandigarh_gujrat": 9.5,
    "uttarakhand_gujrat": 9.5,
    "punjab_gujrat": 9.5,
    "haryana_gujrat": 9.5,
    "himachal pradesh_gujrat": 9.5,
    "chandigarh_vapi": 9.5,
    "uttarakhand_vapi": 9.5,
    "punjab_vapi": 9.5,
    "haryana_vapi": 9.5,
    "himachal pradesh_vapi": 9.5,
    "chandigarh_daman": 9.5,
    "uttarakhand_daman": 9.5,
    "punjab_daman": 9.5,
    "haryana_daman": 9.5,
    "himachal pradesh_daman": 9.5,
    "chandigarh_silvasa": 9.5,
    "uttarakhand_silvasa": 9.5,
    "punjab_silvasa": 9.5,
    "haryana_silvasa": 9.5,
    "himachal pradesh_silvasa": 9.5,
    "chandigarh_delhi": 6.5,
    "uttarakhand_delhi": 6.5,
    "punjab_delhi": 6.5,
    "haryana_delhi": 6.5,
    "himachal pradesh_delhi": 6.5,
    "chandigarh_rajasthan": 6.5,
    "uttarakhand_rajasthan": 6.5,
    "punjab_rajasthan": 6.5,
    "haryana_rajasthan": 6.5,
    "himachal pradesh_rajasthan": 6.5,
    "chandigarh_chandigarh": 6.5,
    "uttarakhand_chandigarh": 6.5,
    "punjab_chandigarh": 6.5,
    "haryana_chandigarh": 6.5,
    "himachal pradesh_chandigarh": 6.5,
    "chandigarh_uttarakhand": 6.5,
    "uttarakhand_uttarakhand": 6.5,
    "punjab_uttarakhand": 6.5,
    "haryana_uttarakhand": 6.5,
    "himachal pradesh_uttarakhand": 6.5,
    "chandigarh_punjab": 6.5,
    "uttarakhand_punjab": 6.5,
    "punjab_punjab": 6.5,
    "haryana_punjab": 6.5,
    "himachal pradesh_punjab": 6.5,
    "chandigarh_haryana": 6.5,
    "uttarakhand_haryana": 6.5,
    "punjab_haryana": 6.5,
    "haryana_haryana": 6.5,
    "himachal pradesh_haryana": 6.5,
    "chandigarh_himachal pradesh": 6.5,
    "uttarakhand_himachal pradesh": 6.5,
    "punjab_himachal pradesh": 6.5,
    "haryana_himachal pradesh": 6.5,
    "himachal pradesh_himachal pradesh": 6.5,
    "chandigarh_jammu & kashmir": 8.5,
    "uttarakhand_jammu & kashmir": 8.5,
    "punjab_jammu & kashmir": 8.5,
    "haryana_jammu & kashmir": 8.5,
    "himachal pradesh_jammu & kashmir": 8.5,
    "chandigarh_andhra pradesh": 11.5,
    "uttarakhand_andhra pradesh": 11.5,
    "punjab_andhra pradesh": 11.5,
    "haryana_andhra pradesh": 11.5,
    "himachal pradesh_andhra pradesh": 11.5,
    "chandigarh_telangana": 11.5,
    "uttarakhand_telangana": 11.5,
    "punjab_telangana": 11.5,
    "haryana_telangana": 11.5,
    "himachal pradesh_telangana": 11.5,
    "chandigarh_karnataka": 11.5,
    "uttarakhand_karnataka": 11.5,
    "punjab_karnataka": 11.5,
    "haryana_karnataka": 11.5,
    "himachal pradesh_karnataka": 11.5,
    "chandigarh_bengaluru": 11.5,
    "uttarakhand_bengaluru": 11.5,
    "punjab_bengaluru": 11.5,
    "haryana_bengaluru": 11.5,
    "himachal pradesh_bengaluru": 11.5,
    "chandigarh_south tamil nadu - madras": 11.5,
    "uttarakhand_south tamil nadu - madras": 11.5,
    "punjab_south tamil nadu - madras": 11.5,
    "haryana_south tamil nadu - madras": 11.5,
    "himachal pradesh_south tamil nadu - madras": 11.5,
    "chandigarh_pondicherry": 11.5,
    "uttarakhand_pondicherry": 11.5,
    "punjab_pondicherry": 11.5,
    "haryana_pondicherry": 11.5,
    "himachal pradesh_pondicherry": 11.5,
    "chandigarh_madurai": 11.5,
    "uttarakhand_madurai": 11.5,
    "punjab_madurai": 11.5,
    "haryana_madurai": 11.5,
    "himachal pradesh_madurai": 11.5,
    "chandigarh_trichy": 11.5,
    "uttarakhand_trichy": 11.5,
    "punjab_trichy": 11.5,
    "haryana_trichy": 11.5,
    "himachal pradesh_trichy": 11.5,
    "chandigarh_tanjavur": 11.5,
    "uttarakhand_tanjavur": 11.5,
    "punjab_tanjavur": 11.5,
    "haryana_tanjavur": 11.5,
    "himachal pradesh_tanjavur": 11.5,
    "chandigarh_tiruarunachal pradesh": 11.5,
    "uttarakhand_tiruarunachal pradesh": 11.5,
    "punjab_tiruarunachal pradesh": 11.5,
    "haryana_tiruarunachal pradesh": 11.5,
    "himachal pradesh_tiruarunachal pradesh": 11.5,
    "chandigarh_northe eastlvelli": 11.5,
    "uttarakhand_northe eastlvelli": 11.5,
    "punjab_northe eastlvelli": 11.5,
    "haryana_northe eastlvelli": 11.5,
    "himachal pradesh_northe eastlvelli": 11.5,
    "chandigarh_tuticorin": 11.5,
    "uttarakhand_tuticorin": 11.5,
    "punjab_tuticorin": 11.5,
    "haryana_tuticorin": 11.5,
    "himachal pradesh_tuticorin": 11.5,
    "chandigarh_erode": 12.5,
    "uttarakhand_erode": 12.5,
    "punjab_erode": 12.5,
    "haryana_erode": 12.5,
    "himachal pradesh_erode": 12.5,
    "chandigarh_salem": 12.5,
    "uttarakhand_salem": 12.5,
    "punjab_salem": 12.5,
    "haryana_salem": 12.5,
    "himachal pradesh_salem": 12.5,
    "chandigarh_tirupur": 12.5,
    "uttarakhand_tirupur": 12.5,
    "punjab_tirupur": 12.5,
    "haryana_tirupur": 12.5,
    "himachal pradesh_tirupur": 12.5,
    "chandigarh_coimbatore": 12.5,
    "uttarakhand_coimbatore": 12.5,
    "punjab_coimbatore": 12.5,
    "haryana_coimbatore": 12.5,
    "himachal pradesh_coimbatore": 12.5,
    "chandigarh_cochin": 15,
    "uttarakhand_cochin": 15,
    "punjab_cochin": 15,
    "haryana_cochin": 15,
    "himachal pradesh_cochin": 15,
    "chandigarh_kerala": 15,
    "uttarakhand_kerala": 15,
    "punjab_kerala": 15,
    "haryana_kerala": 15,
    "himachal pradesh_kerala": 15,
    "chandigarh_indore": 9,
    "uttarakhand_indore": 9,
    "punjab_indore": 9,
    "haryana_indore": 9,
    "himachal pradesh_indore": 9,
    "chandigarh_madhya pradesh": 9,
    "uttarakhand_madhya pradesh": 9,
    "punjab_madhya pradesh": 9,
    "haryana_madhya pradesh": 9,
    "himachal pradesh_madhya pradesh": 9,
    "chandigarh_nagpur": 10,
    "uttarakhand_nagpur": 10,
    "punjab_nagpur": 10,
    "haryana_nagpur": 10,
    "himachal pradesh_nagpur": 10,
    "chandigarh_raipur": 10,
    "uttarakhand_raipur": 10,
    "punjab_raipur": 10,
    "haryana_raipur": 10,
    "himachal pradesh_raipur": 10,
    "chandigarh_chhattisgarh": 10,
    "uttarakhand_chhattisgarh": 10,
    "punjab_chhattisgarh": 10,
    "haryana_chhattisgarh": 10,
    "himachal pradesh_chhattisgarh": 10,
    "chandigarh_vidarbha": 10,
    "uttarakhand_vidarbha": 10,
    "punjab_vidarbha": 10,
    "haryana_vidarbha": 10,
    "himachal pradesh_vidarbha": 10,
    "chandigarh_akola": 10,
    "uttarakhand_akola": 10,
    "punjab_akola": 10,
    "haryana_akola": 10,
    "himachal pradesh_akola": 10,
    "chandigarh_amravati": 10,
    "uttarakhand_amravati": 10,
    "punjab_amravati": 10,
    "haryana_amravati": 10,
    "himachal pradesh_amravati": 10,
    "chandigarh_chandrapur": 10,
    "uttarakhand_chandrapur": 10,
    "punjab_chandrapur": 10,
    "haryana_chandrapur": 10,
    "himachal pradesh_chandrapur": 10,
    "chandigarh_kolkata": 13,
    "uttarakhand_kolkata": 13,
    "punjab_kolkata": 13,
    "haryana_kolkata": 13,
    "himachal pradesh_kolkata": 13,
    "chandigarh_west bengal": 13,
    "uttarakhand_west bengal": 13,
    "punjab_west bengal": 13,
    "haryana_west bengal": 13,
    "himachal pradesh_west bengal": 13,
    "chandigarh_odisha": 13,
    "uttarakhand_odisha": 13,
    "punjab_odisha": 13,
    "haryana_odisha": 13,
    "himachal pradesh_odisha": 13,
    "chandigarh_guwahati": 16,
    "uttarakhand_guwahati": 16,
    "punjab_guwahati": 16,
    "haryana_guwahati": 16,
    "himachal pradesh_guwahati": 16,
    "chandigarh_lucknow": 6.5,
    "uttarakhand_lucknow": 6.5,
    "punjab_lucknow": 6.5,
    "haryana_lucknow": 6.5,
    "himachal pradesh_lucknow": 6.5,
    "chandigarh_allahabad": 6.5,
    "uttarakhand_allahabad": 6.5,
    "punjab_allahabad": 6.5,
    "haryana_allahabad": 6.5,
    "himachal pradesh_allahabad": 6.5,
    "chandigarh_kanpur": 6.5,
    "uttarakhand_kanpur": 6.5,
    "punjab_kanpur": 6.5,
    "haryana_kanpur": 6.5,
    "himachal pradesh_kanpur": 6.5,
    "chandigarh_varanasi": 6.5,
    "uttarakhand_varanasi": 6.5,
    "punjab_varanasi": 6.5,
    "haryana_varanasi": 6.5,
    "himachal pradesh_varanasi": 6.5,
    "chandigarh_agra": 6.5,
    "uttarakhand_agra": 6.5,
    "punjab_agra": 6.5,
    "haryana_agra": 6.5,
    "himachal pradesh_agra": 6.5,
    "chandigarh_mathura": 6.5,
    "uttarakhand_mathura": 6.5,
    "punjab_mathura": 6.5,
    "haryana_mathura": 6.5,
    "himachal pradesh_mathura": 6.5,
    "chandigarh_eastern uttarpradesh": 6.5,
    "uttarakhand_eastern uttarpradesh": 6.5,
    "punjab_eastern uttarpradesh": 6.5,
    "haryana_eastern uttarpradesh": 6.5,
    "himachal pradesh_eastern uttarpradesh": 6.5,
    "chandigarh_bihar": 12,
    "uttarakhand_bihar": 12,
    "punjab_bihar": 12,
    "haryana_bihar": 12,
    "himachal pradesh_bihar": 12,
    "chandigarh_jharkhand": 12,
    "uttarakhand_jharkhand": 12,
    "punjab_jharkhand": 12,
    "haryana_jharkhand": 12,
    "himachal pradesh_jharkhand": 12,
    "jammu & kashmir_arunachal pradesh": 24,
    "jammu & kashmir_assam": 24,
    "jammu & kashmir_manipur": 24,
    "jammu & kashmir_meghalaya": 24,
    "jammu & kashmir_mizoram": 24,
    "jammu & kashmir_nagaland": 24,
    "jammu & kashmir_sikkim": 24,
    "jammu & kashmir_tripura": 24,
    "jammu & kashmir_northe east": 24,
    "jammu & kashmir_kolhapur": 11.5,
    "jammu & kashmir_aurangabad": 11.5,
    "jammu & kashmir_satara": 11.5,
    "jammu & kashmir_goa": 11.5,
    "jammu & kashmir_nashik": 11.5,
    "jammu & kashmir_mumbai": 11.5,
    "jammu & kashmir_bhiwandi": 11.5,
    "jammu & kashmir_panvel": 11.5,
    "jammu & kashmir_gujrat": 10.5,
    "jammu & kashmir_vapi": 10.5,
    "jammu & kashmir_daman": 10.5,
    "jammu & kashmir_silvasa": 10.5,
    "jammu & kashmir_delhi": 8.5,
    "jammu & kashmir_rajasthan": 8.5,
    "jammu & kashmir_chandigarh": 8,
    "jammu & kashmir_uttarakhand": 8,
    "jammu & kashmir_punjab": 8,
    "jammu & kashmir_haryana": 8,
    "jammu & kashmir_himachal pradesh": 8,
    "jammu & kashmir_jammu & kashmir": 7,
    "jammu & kashmir_andhra pradesh": 12,
    "jammu & kashmir_telangana": 12,
    "jammu & kashmir_karnataka": 12,
    "jammu & kashmir_bengaluru": 12,
    "jammu & kashmir_south tamil nadu - madras": 12,
    "jammu & kashmir_pondicherry": 12,
    "jammu & kashmir_madurai": 12,
    "jammu & kashmir_trichy": 12,
    "jammu & kashmir_tanjavur": 12,
    "jammu & kashmir_tiruarunachal pradesh": 12,
    "jammu & kashmir_northe eastlvelli": 12,
    "jammu & kashmir_tuticorin": 12,
    "jammu & kashmir_erode": 13,
    "jammu & kashmir_salem": 13,
    "jammu & kashmir_tirupur": 13,
    "jammu & kashmir_coimbatore": 13,
    "jammu & kashmir_cochin": 17,
    "jammu & kashmir_kerala": 17,
    "jammu & kashmir_indore": 11,
    "jammu & kashmir_madhya pradesh": 11,
    "jammu & kashmir_nagpur": 11,
    "jammu & kashmir_raipur": 11,
    "jammu & kashmir_chhattisgarh": 11,
    "jammu & kashmir_vidarbha": 11,
    "jammu & kashmir_akola": 11,
    "jammu & kashmir_amravati": 11,
    "jammu & kashmir_chandrapur": 11,
    "jammu & kashmir_kolkata": 15,
    "jammu & kashmir_west bengal": 15,
    "jammu & kashmir_odisha": 15,
    "jammu & kashmir_guwahati": 16,
    "jammu & kashmir_lucknow": 9.5,
    "jammu & kashmir_allahabad": 9.5,
    "jammu & kashmir_kanpur": 9.5,
    "jammu & kashmir_varanasi": 9.5,
    "jammu & kashmir_agra": 9.5,
    "jammu & kashmir_mathura": 9.5,
    "jammu & kashmir_eastern uttarpradesh": 9.5,
    "jammu & kashmir_bihar": 13,
    "jammu & kashmir_jharkhand": 13,
    "andhra pradesh_arunachal pradesh": 24,
    "telangana_arunachal pradesh": 24,
    "andhra pradesh_assam": 24,
    "telangana_assam": 24,
    "andhra pradesh_manipur": 24,
    "telangana_manipur": 24,
    "andhra pradesh_meghalaya": 24,
    "telangana_meghalaya": 24,
    "andhra pradesh_mizoram": 24,
    "telangana_mizoram": 24,
    "andhra pradesh_nagaland": 24,
    "telangana_nagaland": 24,
    "andhra pradesh_sikkim": 24,
    "telangana_sikkim": 24,
    "andhra pradesh_tripura": 24,
    "telangana_tripura": 24,
    "andhra pradesh_northe east": 24,
    "telangana_northe east": 24,
    "andhra pradesh_kolhapur": 7.5,
    "telangana_kolhapur": 7.5,
    "andhra pradesh_aurangabad": 7.5,
    "telangana_aurangabad": 7.5,
    "andhra pradesh_satara": 7.5,
    "telangana_satara": 7.5,
    "andhra pradesh_goa": 7.5,
    "telangana_goa": 7.5,
    "andhra pradesh_nashik": 8.5,
    "telangana_nashik": 8.5,
    "andhra pradesh_mumbai": 8.5,
    "telangana_mumbai": 8.5,
    "andhra pradesh_bhiwandi": 8.5,
    "telangana_bhiwandi": 8.5,
    "andhra pradesh_panvel": 8.5,
    "telangana_panvel": 8.5,
    "andhra pradesh_gujrat": 8.5,
    "telangana_gujrat": 8.5,
    "andhra pradesh_vapi": 8.5,
    "telangana_vapi": 8.5,
    "andhra pradesh_daman": 8.5,
    "telangana_daman": 8.5,
    "andhra pradesh_silvasa": 8.5,
    "telangana_silvasa": 8.5,
    "andhra pradesh_delhi": 9.5,
    "telangana_delhi": 9.5,
    "andhra pradesh_rajasthan": 9.5,
    "telangana_rajasthan": 9.5,
    "andhra pradesh_chandigarh": 10.5,
    "telangana_chandigarh": 10.5,
    "andhra pradesh_uttarakhand": 10.5,
    "telangana_uttarakhand": 10.5,
    "andhra pradesh_punjab": 10.5,
    "telangana_punjab": 10.5,
    "andhra pradesh_haryana": 10.5,
    "telangana_haryana": 10.5,
    "andhra pradesh_himachal pradesh": 10.5,
    "telangana_himachal pradesh": 10.5,
    "andhra pradesh_jammu & kashmir": 12.5,
    "telangana_jammu & kashmir": 12.5,
    "andhra pradesh_andhra pradesh": 7,
    "telangana_andhra pradesh": 7,
    "andhra pradesh_telangana": 7,
    "telangana_telangana": 7,
    "andhra pradesh_karnataka": 7.5,
    "telangana_karnataka": 7.5,
    "andhra pradesh_bengaluru": 7.5,
    "telangana_bengaluru": 7.5,
    "andhra pradesh_south tamil nadu - madras": 7.5,
    "telangana_south tamil nadu - madras": 7.5,
    "andhra pradesh_pondicherry": 7.5,
    "telangana_pondicherry": 7.5,
    "andhra pradesh_madurai": 7.5,
    "telangana_madurai": 7.5,
    "andhra pradesh_trichy": 7.5,
    "telangana_trichy": 7.5,
    "andhra pradesh_tanjavur": 7.5,
    "telangana_tanjavur": 7.5,
    "andhra pradesh_tiruarunachal pradesh": 7.5,
    "telangana_tiruarunachal pradesh": 7.5,
    "andhra pradesh_northe eastlvelli": 7.5,
    "telangana_northe eastlvelli": 7.5,
    "andhra pradesh_tuticorin": 7.5,
    "telangana_tuticorin": 7.5,
    "andhra pradesh_erode": 8.5,
    "telangana_erode": 8.5,
    "andhra pradesh_salem": 8.5,
    "telangana_salem": 8.5,
    "andhra pradesh_tirupur": 8.5,
    "telangana_tirupur": 8.5,
    "andhra pradesh_coimbatore": 8.5,
    "telangana_coimbatore": 8.5,
    "andhra pradesh_cochin": 13,
    "telangana_cochin": 13,
    "andhra pradesh_kerala": 13,
    "telangana_kerala": 13,
    "andhra pradesh_indore": 8.5,
    "telangana_indore": 8.5,
    "andhra pradesh_madhya pradesh": 8.5,
    "telangana_madhya pradesh": 8.5,
    "andhra pradesh_nagpur": 7.5,
    "telangana_nagpur": 7.5,
    "andhra pradesh_raipur": 7.5,
    "telangana_raipur": 7.5,
    "andhra pradesh_chhattisgarh": 7.5,
    "telangana_chhattisgarh": 7.5,
    "andhra pradesh_vidarbha": 7.5,
    "telangana_vidarbha": 7.5,
    "andhra pradesh_akola": 7.5,
    "telangana_akola": 7.5,
    "andhra pradesh_amravati": 7.5,
    "telangana_amravati": 7.5,
    "andhra pradesh_chandrapur": 7.5,
    "telangana_chandrapur": 7.5,
    "andhra pradesh_kolkata": 12,
    "telangana_kolkata": 12,
    "andhra pradesh_west bengal": 12,
    "telangana_west bengal": 12,
    "andhra pradesh_odisha": 12,
    "telangana_odisha": 12,
    "andhra pradesh_guwahati": 16,
    "telangana_guwahati": 16,
    "andhra pradesh_lucknow": 11,
    "telangana_lucknow": 11,
    "andhra pradesh_allahabad": 11,
    "telangana_allahabad": 11,
    "andhra pradesh_kanpur": 11,
    "telangana_kanpur": 11,
    "andhra pradesh_varanasi": 11,
    "telangana_varanasi": 11,
    "andhra pradesh_agra": 11,
    "telangana_agra": 11,
    "andhra pradesh_mathura": 11,
    "telangana_mathura": 11,
    "andhra pradesh_eastern uttarpradesh": 11,
    "telangana_eastern uttarpradesh": 11,
    "andhra pradesh_bihar": 12,
    "telangana_bihar": 12,
    "andhra pradesh_jharkhand": 12,
    "telangana_jharkhand": 12,
    "karnataka_arunachal pradesh": 24,
    "bengaluru_arunachal pradesh": 24,
    "karnataka_assam": 24,
    "bengaluru_assam": 24,
    "karnataka_manipur": 24,
    "bengaluru_manipur": 24,
    "karnataka_meghalaya": 24,
    "bengaluru_meghalaya": 24,
    "karnataka_mizoram": 24,
    "bengaluru_mizoram": 24,
    "karnataka_nagaland": 24,
    "bengaluru_nagaland": 24,
    "karnataka_sikkim": 24,
    "bengaluru_sikkim": 24,
    "karnataka_tripura": 24,
    "bengaluru_tripura": 24,
    "karnataka_northe east": 24,
    "bengaluru_northe east": 24,
    "karnataka_kolhapur": 8.5,
    "bengaluru_kolhapur": 8.5,
    "karnataka_aurangabad": 8.5,
    "bengaluru_aurangabad": 8.5,
    "karnataka_satara": 8.5,
    "bengaluru_satara": 8.5,
    "karnataka_goa": 8.5,
    "bengaluru_goa": 8.5,
    "karnataka_nashik": 8.5,
    "bengaluru_nashik": 8.5,
    "karnataka_mumbai": 8.5,
    "bengaluru_mumbai": 8.5,
    "karnataka_bhiwandi": 8.5,
    "bengaluru_bhiwandi": 8.5,
    "karnataka_panvel": 8.5,
    "bengaluru_panvel": 8.5,
    "karnataka_gujrat": 9.5,
    "bengaluru_gujrat": 9.5,
    "karnataka_vapi": 9.5,
    "bengaluru_vapi": 9.5,
    "karnataka_daman": 9.5,
    "bengaluru_daman": 9.5,
    "karnataka_silvasa": 9.5,
    "bengaluru_silvasa": 9.5,
    "karnataka_delhi": 10.5,
    "bengaluru_delhi": 10.5,
    "karnataka_rajasthan": 10.5,
    "bengaluru_rajasthan": 10.5,
    "karnataka_chandigarh": 11.5,
    "bengaluru_chandigarh": 11.5,
    "karnataka_uttarakhand": 11.5,
    "bengaluru_uttarakhand": 11.5,
    "karnataka_punjab": 11.5,
    "bengaluru_punjab": 11.5,
    "karnataka_haryana": 11.5,
    "bengaluru_haryana": 11.5,
    "karnataka_himachal pradesh": 11.5,
    "bengaluru_himachal pradesh": 11.5,
    "karnataka_jammu & kashmir": 14,
    "bengaluru_jammu & kashmir": 14,
    "karnataka_andhra pradesh": 7.5,
    "bengaluru_andhra pradesh": 7.5,
    "karnataka_telangana": 7.5,
    "bengaluru_telangana": 7.5,
    "karnataka_karnataka": 6.5,
    "bengaluru_karnataka": 6.5,
    "karnataka_bengaluru": 6.5,
    "bengaluru_bengaluru": 6.5,
    "karnataka_south tamil nadu - madras": 7,
    "bengaluru_south tamil nadu - madras": 7,
    "karnataka_pondicherry": 7,
    "bengaluru_pondicherry": 7,
    "karnataka_madurai": 7,
    "bengaluru_madurai": 7,
    "karnataka_trichy": 7,
    "bengaluru_trichy": 7,
    "karnataka_tanjavur": 7,
    "bengaluru_tanjavur": 7,
    "karnataka_tiruarunachal pradesh": 7,
    "bengaluru_tiruarunachal pradesh": 7,
    "karnataka_northe eastlvelli": 7,
    "bengaluru_northe eastlvelli": 7,
    "karnataka_tuticorin": 7,
    "bengaluru_tuticorin": 7,
    "karnataka_erode": 8,
    "bengaluru_erode": 8,
    "karnataka_salem": 8,
    "bengaluru_salem": 8,
    "karnataka_tirupur": 8,
    "bengaluru_tirupur": 8,
    "karnataka_coimbatore": 8,
    "bengaluru_coimbatore": 8,
    "karnataka_cochin": 11,
    "bengaluru_cochin": 11,
    "karnataka_kerala": 11,
    "bengaluru_kerala": 11,
    "karnataka_indore": 9.5,
    "bengaluru_indore": 9.5,
    "karnataka_madhya pradesh": 9.5,
    "bengaluru_madhya pradesh": 9.5,
    "karnataka_nagpur": 8.5,
    "bengaluru_nagpur": 8.5,
    "karnataka_raipur": 8.5,
    "bengaluru_raipur": 8.5,
    "karnataka_chhattisgarh": 8.5,
    "bengaluru_chhattisgarh": 8.5,
    "karnataka_vidarbha": 8.5,
    "bengaluru_vidarbha": 8.5,
    "karnataka_akola": 8.5,
    "bengaluru_akola": 8.5,
    "karnataka_amravati": 8.5,
    "bengaluru_amravati": 8.5,
    "karnataka_chandrapur": 8.5,
    "bengaluru_chandrapur": 8.5,
    "karnataka_kolkata": 12.5,
    "bengaluru_kolkata": 12.5,
    "karnataka_west bengal": 12.5,
    "bengaluru_west bengal": 12.5,
    "karnataka_odisha": 12.5,
    "bengaluru_odisha": 12.5,
    "karnataka_guwahati": 16,
    "bengaluru_guwahati": 16,
    "karnataka_lucknow": 11,
    "bengaluru_lucknow": 11,
    "karnataka_allahabad": 11,
    "bengaluru_allahabad": 11,
    "karnataka_kanpur": 11,
    "bengaluru_kanpur": 11,
    "karnataka_varanasi": 11,
    "bengaluru_varanasi": 11,
    "karnataka_agra": 11,
    "bengaluru_agra": 11,
    "karnataka_mathura": 11,
    "bengaluru_mathura": 11,
    "karnataka_eastern uttarpradesh": 11,
    "bengaluru_eastern uttarpradesh": 11,
    "karnataka_bihar": 12,
    "bengaluru_bihar": 12,
    "karnataka_jharkhand": 12,
    "bengaluru_jharkhand": 12,
    "south tamil nadu - madras_arunachal pradesh": 24,
    "pondicherry_arunachal pradesh": 24,
    "madurai_arunachal pradesh": 24,
    "trichy_arunachal pradesh": 24,
    "tanjavur_arunachal pradesh": 24,
    "tiruarunachal pradesh_arunachal pradesh": 24,
    "northe eastlvelli_arunachal pradesh": 24,
    "tuticorin_arunachal pradesh": 24,
    "south tamil nadu - madras_assam": 24,
    "pondicherry_assam": 24,
    "madurai_assam": 24,
    "trichy_assam": 24,
    "tanjavur_assam": 24,
    "tiruarunachal pradesh_assam": 24,
    "northe eastlvelli_assam": 24,
    "tuticorin_assam": 24,
    "south tamil nadu - madras_manipur": 24,
    "pondicherry_manipur": 24,
    "madurai_manipur": 24,
    "trichy_manipur": 24,
    "tanjavur_manipur": 24,
    "tiruarunachal pradesh_manipur": 24,
    "northe eastlvelli_manipur": 24,
    "tuticorin_manipur": 24,
    "south tamil nadu - madras_meghalaya": 24,
    "pondicherry_meghalaya": 24,
    "madurai_meghalaya": 24,
    "trichy_meghalaya": 24,
    "tanjavur_meghalaya": 24,
    "tiruarunachal pradesh_meghalaya": 24,
    "northe eastlvelli_meghalaya": 24,
    "tuticorin_meghalaya": 24,
    "south tamil nadu - madras_mizoram": 24,
    "pondicherry_mizoram": 24,
    "madurai_mizoram": 24,
    "trichy_mizoram": 24,
    "tanjavur_mizoram": 24,
    "tiruarunachal pradesh_mizoram": 24,
    "northe eastlvelli_mizoram": 24,
    "tuticorin_mizoram": 24,
    "south tamil nadu - madras_nagaland": 24,
    "pondicherry_nagaland": 24,
    "madurai_nagaland": 24,
    "trichy_nagaland": 24,
    "tanjavur_nagaland": 24,
    "tiruarunachal pradesh_nagaland": 24,
    "northe eastlvelli_nagaland": 24,
    "tuticorin_nagaland": 24,
    "south tamil nadu - madras_sikkim": 24,
    "pondicherry_sikkim": 24,
    "madurai_sikkim": 24,
    "trichy_sikkim": 24,
    "tanjavur_sikkim": 24,
    "tiruarunachal pradesh_sikkim": 24,
    "northe eastlvelli_sikkim": 24,
    "tuticorin_sikkim": 24,
    "south tamil nadu - madras_tripura": 24,
    "pondicherry_tripura": 24,
    "madurai_tripura": 24,
    "trichy_tripura": 24,
    "tanjavur_tripura": 24,
    "tiruarunachal pradesh_tripura": 24,
    "northe eastlvelli_tripura": 24,
    "tuticorin_tripura": 24,
    "south tamil nadu - madras_northe east": 24,
    "pondicherry_northe east": 24,
    "madurai_northe east": 24,
    "trichy_northe east": 24,
    "tanjavur_northe east": 24,
    "tiruarunachal pradesh_northe east": 24,
    "northe eastlvelli_northe east": 24,
    "tuticorin_northe east": 24,
    "south tamil nadu - madras_kolhapur": 8.5,
    "pondicherry_kolhapur": 8.5,
    "madurai_kolhapur": 8.5,
    "trichy_kolhapur": 8.5,
    "tanjavur_kolhapur": 8.5,
    "tiruarunachal pradesh_kolhapur": 8.5,
    "northe eastlvelli_kolhapur": 8.5,
    "tuticorin_kolhapur": 8.5,
    "south tamil nadu - madras_aurangabad": 8.5,
    "pondicherry_aurangabad": 8.5,
    "madurai_aurangabad": 8.5,
    "trichy_aurangabad": 8.5,
    "tanjavur_aurangabad": 8.5,
    "tiruarunachal pradesh_aurangabad": 8.5,
    "northe eastlvelli_aurangabad": 8.5,
    "tuticorin_aurangabad": 8.5,
    "south tamil nadu - madras_satara": 8.5,
    "pondicherry_satara": 8.5,
    "madurai_satara": 8.5,
    "trichy_satara": 8.5,
    "tanjavur_satara": 8.5,
    "tiruarunachal pradesh_satara": 8.5,
    "northe eastlvelli_satara": 8.5,
    "tuticorin_satara": 8.5,
    "south tamil nadu - madras_goa": 8.5,
    "pondicherry_goa": 8.5,
    "madurai_goa": 8.5,
    "trichy_goa": 8.5,
    "tanjavur_goa": 8.5,
    "tiruarunachal pradesh_goa": 8.5,
    "northe eastlvelli_goa": 8.5,
    "tuticorin_goa": 8.5,
    "south tamil nadu - madras_nashik": 8.5,
    "pondicherry_nashik": 8.5,
    "madurai_nashik": 8.5,
    "trichy_nashik": 8.5,
    "tanjavur_nashik": 8.5,
    "tiruarunachal pradesh_nashik": 8.5,
    "northe eastlvelli_nashik": 8.5,
    "tuticorin_nashik": 8.5,
    "south tamil nadu - madras_mumbai": 8.5,
    "pondicherry_mumbai": 8.5,
    "madurai_mumbai": 8.5,
    "trichy_mumbai": 8.5,
    "tanjavur_mumbai": 8.5,
    "tiruarunachal pradesh_mumbai": 8.5,
    "northe eastlvelli_mumbai": 8.5,
    "tuticorin_mumbai": 8.5,
    "south tamil nadu - madras_bhiwandi": 8.5,
    "pondicherry_bhiwandi": 8.5,
    "madurai_bhiwandi": 8.5,
    "trichy_bhiwandi": 8.5,
    "tanjavur_bhiwandi": 8.5,
    "tiruarunachal pradesh_bhiwandi": 8.5,
    "northe eastlvelli_bhiwandi": 8.5,
    "tuticorin_bhiwandi": 8.5,
    "south tamil nadu - madras_panvel": 8.5,
    "pondicherry_panvel": 8.5,
    "madurai_panvel": 8.5,
    "trichy_panvel": 8.5,
    "tanjavur_panvel": 8.5,
    "tiruarunachal pradesh_panvel": 8.5,
    "northe eastlvelli_panvel": 8.5,
    "tuticorin_panvel": 8.5,
    "south tamil nadu - madras_gujrat": 9.5,
    "pondicherry_gujrat": 9.5,
    "madurai_gujrat": 9.5,
    "trichy_gujrat": 9.5,
    "tanjavur_gujrat": 9.5,
    "tiruarunachal pradesh_gujrat": 9.5,
    "northe eastlvelli_gujrat": 9.5,
    "tuticorin_gujrat": 9.5,
    "south tamil nadu - madras_vapi": 9.5,
    "pondicherry_vapi": 9.5,
    "madurai_vapi": 9.5,
    "trichy_vapi": 9.5,
    "tanjavur_vapi": 9.5,
    "tiruarunachal pradesh_vapi": 9.5,
    "northe eastlvelli_vapi": 9.5,
    "tuticorin_vapi": 9.5,
    "south tamil nadu - madras_daman": 9.5,
    "pondicherry_daman": 9.5,
    "madurai_daman": 9.5,
    "trichy_daman": 9.5,
    "tanjavur_daman": 9.5,
    "tiruarunachal pradesh_daman": 9.5,
    "northe eastlvelli_daman": 9.5,
    "tuticorin_daman": 9.5,
    "south tamil nadu - madras_silvasa": 9.5,
    "pondicherry_silvasa": 9.5,
    "madurai_silvasa": 9.5,
    "trichy_silvasa": 9.5,
    "tanjavur_silvasa": 9.5,
    "tiruarunachal pradesh_silvasa": 9.5,
    "northe eastlvelli_silvasa": 9.5,
    "tuticorin_silvasa": 9.5,
    "south tamil nadu - madras_delhi": 10.5,
    "pondicherry_delhi": 10.5,
    "madurai_delhi": 10.5,
    "trichy_delhi": 10.5,
    "tanjavur_delhi": 10.5,
    "tiruarunachal pradesh_delhi": 10.5,
    "northe eastlvelli_delhi": 10.5,
    "tuticorin_delhi": 10.5,
    "south tamil nadu - madras_rajasthan": 10.5,
    "pondicherry_rajasthan": 10.5,
    "madurai_rajasthan": 10.5,
    "trichy_rajasthan": 10.5,
    "tanjavur_rajasthan": 10.5,
    "tiruarunachal pradesh_rajasthan": 10.5,
    "northe eastlvelli_rajasthan": 10.5,
    "tuticorin_rajasthan": 10.5,
    "south tamil nadu - madras_chandigarh": 11.5,
    "pondicherry_chandigarh": 11.5,
    "madurai_chandigarh": 11.5,
    "trichy_chandigarh": 11.5,
    "tanjavur_chandigarh": 11.5,
    "tiruarunachal pradesh_chandigarh": 11.5,
    "northe eastlvelli_chandigarh": 11.5,
    "tuticorin_chandigarh": 11.5,
    "south tamil nadu - madras_uttarakhand": 11.5,
    "pondicherry_uttarakhand": 11.5,
    "madurai_uttarakhand": 11.5,
    "trichy_uttarakhand": 11.5,
    "tanjavur_uttarakhand": 11.5,
    "tiruarunachal pradesh_uttarakhand": 11.5,
    "northe eastlvelli_uttarakhand": 11.5,
    "tuticorin_uttarakhand": 11.5,
    "south tamil nadu - madras_punjab": 11.5,
    "pondicherry_punjab": 11.5,
    "madurai_punjab": 11.5,
    "trichy_punjab": 11.5,
    "tanjavur_punjab": 11.5,
    "tiruarunachal pradesh_punjab": 11.5,
    "northe eastlvelli_punjab": 11.5,
    "tuticorin_punjab": 11.5,
    "south tamil nadu - madras_haryana": 11.5,
    "pondicherry_haryana": 11.5,
    "madurai_haryana": 11.5,
    "trichy_haryana": 11.5,
    "tanjavur_haryana": 11.5,
    "tiruarunachal pradesh_haryana": 11.5,
    "northe eastlvelli_haryana": 11.5,
    "tuticorin_haryana": 11.5,
    "south tamil nadu - madras_himachal pradesh": 11.5,
    "pondicherry_himachal pradesh": 11.5,
    "madurai_himachal pradesh": 11.5,
    "trichy_himachal pradesh": 11.5,
    "tanjavur_himachal pradesh": 11.5,
    "tiruarunachal pradesh_himachal pradesh": 11.5,
    "northe eastlvelli_himachal pradesh": 11.5,
    "tuticorin_himachal pradesh": 11.5,
    "south tamil nadu - madras_jammu & kashmir": 14,
    "pondicherry_jammu & kashmir": 14,
    "madurai_jammu & kashmir": 14,
    "trichy_jammu & kashmir": 14,
    "tanjavur_jammu & kashmir": 14,
    "tiruarunachal pradesh_jammu & kashmir": 14,
    "northe eastlvelli_jammu & kashmir": 14,
    "tuticorin_jammu & kashmir": 14,
    "south tamil nadu - madras_andhra pradesh": 7.5,
    "pondicherry_andhra pradesh": 7.5,
    "madurai_andhra pradesh": 7.5,
    "trichy_andhra pradesh": 7.5,
    "tanjavur_andhra pradesh": 7.5,
    "tiruarunachal pradesh_andhra pradesh": 7.5,
    "northe eastlvelli_andhra pradesh": 7.5,
    "tuticorin_andhra pradesh": 7.5,
    "south tamil nadu - madras_telangana": 7.5,
    "pondicherry_telangana": 7.5,
    "madurai_telangana": 7.5,
    "trichy_telangana": 7.5,
    "tanjavur_telangana": 7.5,
    "tiruarunachal pradesh_telangana": 7.5,
    "northe eastlvelli_telangana": 7.5,
    "tuticorin_telangana": 7.5,
    "south tamil nadu - madras_karnataka": 7.5,
    "pondicherry_karnataka": 7.5,
    "madurai_karnataka": 7.5,
    "trichy_karnataka": 7.5,
    "tanjavur_karnataka": 7.5,
    "tiruarunachal pradesh_karnataka": 7.5,
    "northe eastlvelli_karnataka": 7.5,
    "tuticorin_karnataka": 7.5,
    "south tamil nadu - madras_bengaluru": 7.5,
    "pondicherry_bengaluru": 7.5,
    "madurai_bengaluru": 7.5,
    "trichy_bengaluru": 7.5,
    "tanjavur_bengaluru": 7.5,
    "tiruarunachal pradesh_bengaluru": 7.5,
    "northe eastlvelli_bengaluru": 7.5,
    "tuticorin_bengaluru": 7.5,
    "south tamil nadu - madras_south tamil nadu - madras": 6.5,
    "pondicherry_south tamil nadu - madras": 6.5,
    "madurai_south tamil nadu - madras": 6.5,
    "trichy_south tamil nadu - madras": 6.5,
    "tanjavur_south tamil nadu - madras": 6.5,
    "tiruarunachal pradesh_south tamil nadu - madras": 6.5,
    "northe eastlvelli_south tamil nadu - madras": 6.5,
    "tuticorin_south tamil nadu - madras": 6.5,
    "south tamil nadu - madras_pondicherry": 6.5,
    "pondicherry_pondicherry": 6.5,
    "madurai_pondicherry": 6.5,
    "trichy_pondicherry": 6.5,
    "tanjavur_pondicherry": 6.5,
    "tiruarunachal pradesh_pondicherry": 6.5,
    "northe eastlvelli_pondicherry": 6.5,
    "tuticorin_pondicherry": 6.5,
    "south tamil nadu - madras_madurai": 6.5,
    "pondicherry_madurai": 6.5,
    "madurai_madurai": 6.5,
    "trichy_madurai": 6.5,
    "tanjavur_madurai": 6.5,
    "tiruarunachal pradesh_madurai": 6.5,
    "northe eastlvelli_madurai": 6.5,
    "tuticorin_madurai": 6.5,
    "south tamil nadu - madras_trichy": 6.5,
    "pondicherry_trichy": 6.5,
    "madurai_trichy": 6.5,
    "trichy_trichy": 6.5,
    "tanjavur_trichy": 6.5,
    "tiruarunachal pradesh_trichy": 6.5,
    "northe eastlvelli_trichy": 6.5,
    "tuticorin_trichy": 6.5,
    "south tamil nadu - madras_tanjavur": 6.5,
    "pondicherry_tanjavur": 6.5,
    "madurai_tanjavur": 6.5,
    "trichy_tanjavur": 6.5,
    "tanjavur_tanjavur": 6.5,
    "tiruarunachal pradesh_tanjavur": 6.5,
    "northe eastlvelli_tanjavur": 6.5,
    "tuticorin_tanjavur": 6.5,
    "south tamil nadu - madras_tiruarunachal pradesh": 6.5,
    "pondicherry_tiruarunachal pradesh": 6.5,
    "madurai_tiruarunachal pradesh": 6.5,
    "trichy_tiruarunachal pradesh": 6.5,
    "tanjavur_tiruarunachal pradesh": 6.5,
    "tiruarunachal pradesh_tiruarunachal pradesh": 6.5,
    "northe eastlvelli_tiruarunachal pradesh": 6.5,
    "tuticorin_tiruarunachal pradesh": 6.5,
    "south tamil nadu - madras_northe eastlvelli": 6.5,
    "pondicherry_northe eastlvelli": 6.5,
    "madurai_northe eastlvelli": 6.5,
    "trichy_northe eastlvelli": 6.5,
    "tanjavur_northe eastlvelli": 6.5,
    "tiruarunachal pradesh_northe eastlvelli": 6.5,
    "northe eastlvelli_northe eastlvelli": 6.5,
    "tuticorin_northe eastlvelli": 6.5,
    "south tamil nadu - madras_tuticorin": 6.5,
    "pondicherry_tuticorin": 6.5,
    "madurai_tuticorin": 6.5,
    "trichy_tuticorin": 6.5,
    "tanjavur_tuticorin": 6.5,
    "tiruarunachal pradesh_tuticorin": 6.5,
    "northe eastlvelli_tuticorin": 6.5,
    "tuticorin_tuticorin": 6.5,
    "south tamil nadu - madras_erode": 7.5,
    "pondicherry_erode": 7.5,
    "madurai_erode": 7.5,
    "trichy_erode": 7.5,
    "tanjavur_erode": 7.5,
    "tiruarunachal pradesh_erode": 7.5,
    "northe eastlvelli_erode": 7.5,
    "tuticorin_erode": 7.5,
    "south tamil nadu - madras_salem": 7.5,
    "pondicherry_salem": 7.5,
    "madurai_salem": 7.5,
    "trichy_salem": 7.5,
    "tanjavur_salem": 7.5,
    "tiruarunachal pradesh_salem": 7.5,
    "northe eastlvelli_salem": 7.5,
    "tuticorin_salem": 7.5,
    "south tamil nadu - madras_tirupur": 7.5,
    "pondicherry_tirupur": 7.5,
    "madurai_tirupur": 7.5,
    "trichy_tirupur": 7.5,
    "tanjavur_tirupur": 7.5,
    "tiruarunachal pradesh_tirupur": 7.5,
    "northe eastlvelli_tirupur": 7.5,
    "tuticorin_tirupur": 7.5,
    "south tamil nadu - madras_coimbatore": 7.5,
    "pondicherry_coimbatore": 7.5,
    "madurai_coimbatore": 7.5,
    "trichy_coimbatore": 7.5,
    "tanjavur_coimbatore": 7.5,
    "tiruarunachal pradesh_coimbatore": 7.5,
    "northe eastlvelli_coimbatore": 7.5,
    "tuticorin_coimbatore": 7.5,
    "south tamil nadu - madras_cochin": 10.5,
    "pondicherry_cochin": 10.5,
    "madurai_cochin": 10.5,
    "trichy_cochin": 10.5,
    "tanjavur_cochin": 10.5,
    "tiruarunachal pradesh_cochin": 10.5,
    "northe eastlvelli_cochin": 10.5,
    "tuticorin_cochin": 10.5,
    "south tamil nadu - madras_kerala": 10.5,
    "pondicherry_kerala": 10.5,
    "madurai_kerala": 10.5,
    "trichy_kerala": 10.5,
    "tanjavur_kerala": 10.5,
    "tiruarunachal pradesh_kerala": 10.5,
    "northe eastlvelli_kerala": 10.5,
    "tuticorin_kerala": 10.5,
    "south tamil nadu - madras_indore": 9.5,
    "pondicherry_indore": 9.5,
    "madurai_indore": 9.5,
    "trichy_indore": 9.5,
    "tanjavur_indore": 9.5,
    "tiruarunachal pradesh_indore": 9.5,
    "northe eastlvelli_indore": 9.5,
    "tuticorin_indore": 9.5,
    "south tamil nadu - madras_madhya pradesh": 9.5,
    "pondicherry_madhya pradesh": 9.5,
    "madurai_madhya pradesh": 9.5,
    "trichy_madhya pradesh": 9.5,
    "tanjavur_madhya pradesh": 9.5,
    "tiruarunachal pradesh_madhya pradesh": 9.5,
    "northe eastlvelli_madhya pradesh": 9.5,
    "tuticorin_madhya pradesh": 9.5,
    "south tamil nadu - madras_nagpur": 8.5,
    "pondicherry_nagpur": 8.5,
    "madurai_nagpur": 8.5,
    "trichy_nagpur": 8.5,
    "tanjavur_nagpur": 8.5,
    "tiruarunachal pradesh_nagpur": 8.5,
    "northe eastlvelli_nagpur": 8.5,
    "tuticorin_nagpur": 8.5,
    "south tamil nadu - madras_raipur": 8.5,
    "pondicherry_raipur": 8.5,
    "madurai_raipur": 8.5,
    "trichy_raipur": 8.5,
    "tanjavur_raipur": 8.5,
    "tiruarunachal pradesh_raipur": 8.5,
    "northe eastlvelli_raipur": 8.5,
    "tuticorin_raipur": 8.5,
    "south tamil nadu - madras_chhattisgarh": 8.5,
    "pondicherry_chhattisgarh": 8.5,
    "madurai_chhattisgarh": 8.5,
    "trichy_chhattisgarh": 8.5,
    "tanjavur_chhattisgarh": 8.5,
    "tiruarunachal pradesh_chhattisgarh": 8.5,
    "northe eastlvelli_chhattisgarh": 8.5,
    "tuticorin_chhattisgarh": 8.5,
    "south tamil nadu - madras_vidarbha": 8.5,
    "pondicherry_vidarbha": 8.5,
    "madurai_vidarbha": 8.5,
    "trichy_vidarbha": 8.5,
    "tanjavur_vidarbha": 8.5,
    "tiruarunachal pradesh_vidarbha": 8.5,
    "northe eastlvelli_vidarbha": 8.5,
    "tuticorin_vidarbha": 8.5,
    "south tamil nadu - madras_akola": 8.5,
    "pondicherry_akola": 8.5,
    "madurai_akola": 8.5,
    "trichy_akola": 8.5,
    "tanjavur_akola": 8.5,
    "tiruarunachal pradesh_akola": 8.5,
    "northe eastlvelli_akola": 8.5,
    "tuticorin_akola": 8.5,
    "south tamil nadu - madras_amravati": 8.5,
    "pondicherry_amravati": 8.5,
    "madurai_amravati": 8.5,
    "trichy_amravati": 8.5,
    "tanjavur_amravati": 8.5,
    "tiruarunachal pradesh_amravati": 8.5,
    "northe eastlvelli_amravati": 8.5,
    "tuticorin_amravati": 8.5,
    "south tamil nadu - madras_chandrapur": 8.5,
    "pondicherry_chandrapur": 8.5,
    "madurai_chandrapur": 8.5,
    "trichy_chandrapur": 8.5,
    "tanjavur_chandrapur": 8.5,
    "tiruarunachal pradesh_chandrapur": 8.5,
    "northe eastlvelli_chandrapur": 8.5,
    "tuticorin_chandrapur": 8.5,
    "south tamil nadu - madras_kolkata": 12.5,
    "pondicherry_kolkata": 12.5,
    "madurai_kolkata": 12.5,
    "trichy_kolkata": 12.5,
    "tanjavur_kolkata": 12.5,
    "tiruarunachal pradesh_kolkata": 12.5,
    "northe eastlvelli_kolkata": 12.5,
    "tuticorin_kolkata": 12.5,
    "south tamil nadu - madras_west bengal": 12.5,
    "pondicherry_west bengal": 12.5,
    "madurai_west bengal": 12.5,
    "trichy_west bengal": 12.5,
    "tanjavur_west bengal": 12.5,
    "tiruarunachal pradesh_west bengal": 12.5,
    "northe eastlvelli_west bengal": 12.5,
    "tuticorin_west bengal": 12.5,
    "south tamil nadu - madras_odisha": 12.5,
    "pondicherry_odisha": 12.5,
    "madurai_odisha": 12.5,
    "trichy_odisha": 12.5,
    "tanjavur_odisha": 12.5,
    "tiruarunachal pradesh_odisha": 12.5,
    "northe eastlvelli_odisha": 12.5,
    "tuticorin_odisha": 12.5,
    "south tamil nadu - madras_guwahati": 16,
    "pondicherry_guwahati": 16,
    "madurai_guwahati": 16,
    "trichy_guwahati": 16,
    "tanjavur_guwahati": 16,
    "tiruarunachal pradesh_guwahati": 16,
    "northe eastlvelli_guwahati": 16,
    "tuticorin_guwahati": 16,
    "south tamil nadu - madras_lucknow": 11.5,
    "pondicherry_lucknow": 11.5,
    "madurai_lucknow": 11.5,
    "trichy_lucknow": 11.5,
    "tanjavur_lucknow": 11.5,
    "tiruarunachal pradesh_lucknow": 11.5,
    "northe eastlvelli_lucknow": 11.5,
    "tuticorin_lucknow": 11.5,
    "south tamil nadu - madras_allahabad": 11.5,
    "pondicherry_allahabad": 11.5,
    "madurai_allahabad": 11.5,
    "trichy_allahabad": 11.5,
    "tanjavur_allahabad": 11.5,
    "tiruarunachal pradesh_allahabad": 11.5,
    "northe eastlvelli_allahabad": 11.5,
    "tuticorin_allahabad": 11.5,
    "south tamil nadu - madras_kanpur": 11.5,
    "pondicherry_kanpur": 11.5,
    "madurai_kanpur": 11.5,
    "trichy_kanpur": 11.5,
    "tanjavur_kanpur": 11.5,
    "tiruarunachal pradesh_kanpur": 11.5,
    "northe eastlvelli_kanpur": 11.5,
    "tuticorin_kanpur": 11.5,
    "south tamil nadu - madras_varanasi": 11.5,
    "pondicherry_varanasi": 11.5,
    "madurai_varanasi": 11.5,
    "trichy_varanasi": 11.5,
    "tanjavur_varanasi": 11.5,
    "tiruarunachal pradesh_varanasi": 11.5,
    "northe eastlvelli_varanasi": 11.5,
    "tuticorin_varanasi": 11.5,
    "south tamil nadu - madras_agra": 11.5,
    "pondicherry_agra": 11.5,
    "madurai_agra": 11.5,
    "trichy_agra": 11.5,
    "tanjavur_agra": 11.5,
    "tiruarunachal pradesh_agra": 11.5,
    "northe eastlvelli_agra": 11.5,
    "tuticorin_agra": 11.5,
    "south tamil nadu - madras_mathura": 11.5,
    "pondicherry_mathura": 11.5,
    "madurai_mathura": 11.5,
    "trichy_mathura": 11.5,
    "tanjavur_mathura": 11.5,
    "tiruarunachal pradesh_mathura": 11.5,
    "northe eastlvelli_mathura": 11.5,
    "tuticorin_mathura": 11.5,
    "south tamil nadu - madras_eastern uttarpradesh": 11.5,
    "pondicherry_eastern uttarpradesh": 11.5,
    "madurai_eastern uttarpradesh": 11.5,
    "trichy_eastern uttarpradesh": 11.5,
    "tanjavur_eastern uttarpradesh": 11.5,
    "tiruarunachal pradesh_eastern uttarpradesh": 11.5,
    "northe eastlvelli_eastern uttarpradesh": 11.5,
    "tuticorin_eastern uttarpradesh": 11.5,
    "south tamil nadu - madras_bihar": 12,
    "pondicherry_bihar": 12,
    "madurai_bihar": 12,
    "trichy_bihar": 12,
    "tanjavur_bihar": 12,
    "tiruarunachal pradesh_bihar": 12,
    "northe eastlvelli_bihar": 12,
    "tuticorin_bihar": 12,
    "south tamil nadu - madras_jharkhand": 12,
    "pondicherry_jharkhand": 12,
    "madurai_jharkhand": 12,
    "trichy_jharkhand": 12,
    "tanjavur_jharkhand": 12,
    "tiruarunachal pradesh_jharkhand": 12,
    "northe eastlvelli_jharkhand": 12,
    "tuticorin_jharkhand": 12,
    "erode_arunachal pradesh": 24,
    "salem_arunachal pradesh": 24,
    "tirupur_arunachal pradesh": 24,
    "coimbatore_arunachal pradesh": 24,
    "erode_assam": 24,
    "salem_assam": 24,
    "tirupur_assam": 24,
    "coimbatore_assam": 24,
    "erode_manipur": 24,
    "salem_manipur": 24,
    "tirupur_manipur": 24,
    "coimbatore_manipur": 24,
    "erode_meghalaya": 24,
    "salem_meghalaya": 24,
    "tirupur_meghalaya": 24,
    "coimbatore_meghalaya": 24,
    "erode_mizoram": 24,
    "salem_mizoram": 24,
    "tirupur_mizoram": 24,
    "coimbatore_mizoram": 24,
    "erode_nagaland": 24,
    "salem_nagaland": 24,
    "tirupur_nagaland": 24,
    "coimbatore_nagaland": 24,
    "erode_sikkim": 24,
    "salem_sikkim": 24,
    "tirupur_sikkim": 24,
    "coimbatore_sikkim": 24,
    "erode_tripura": 24,
    "salem_tripura": 24,
    "tirupur_tripura": 24,
    "coimbatore_tripura": 24,
    "erode_northe east": 24,
    "salem_northe east": 24,
    "tirupur_northe east": 24,
    "coimbatore_northe east": 24,
    "erode_kolhapur": 9,
    "salem_kolhapur": 9,
    "tirupur_kolhapur": 9,
    "coimbatore_kolhapur": 9,
    "erode_aurangabad": 9,
    "salem_aurangabad": 9,
    "tirupur_aurangabad": 9,
    "coimbatore_aurangabad": 9,
    "erode_satara": 9,
    "salem_satara": 9,
    "tirupur_satara": 9,
    "coimbatore_satara": 9,
    "erode_goa": 9,
    "salem_goa": 9,
    "tirupur_goa": 9,
    "coimbatore_goa": 9,
    "erode_nashik": 9,
    "salem_nashik": 9,
    "tirupur_nashik": 9,
    "coimbatore_nashik": 9,
    "erode_mumbai": 9,
    "salem_mumbai": 9,
    "tirupur_mumbai": 9,
    "coimbatore_mumbai": 9,
    "erode_bhiwandi": 9,
    "salem_bhiwandi": 9,
    "tirupur_bhiwandi": 9,
    "coimbatore_bhiwandi": 9,
    "erode_panvel": 9,
    "salem_panvel": 9,
    "tirupur_panvel": 9,
    "coimbatore_panvel": 9,
    "erode_gujrat": 10,
    "salem_gujrat": 10,
    "tirupur_gujrat": 10,
    "coimbatore_gujrat": 10,
    "erode_vapi": 10,
    "salem_vapi": 10,
    "tirupur_vapi": 10,
    "coimbatore_vapi": 10,
    "erode_daman": 10,
    "salem_daman": 10,
    "tirupur_daman": 10,
    "coimbatore_daman": 10,
    "erode_silvasa": 10,
    "salem_silvasa": 10,
    "tirupur_silvasa": 10,
    "coimbatore_silvasa": 10,
    "erode_delhi": 11.5,
    "salem_delhi": 11.5,
    "tirupur_delhi": 11.5,
    "coimbatore_delhi": 11.5,
    "erode_rajasthan": 11.5,
    "salem_rajasthan": 11.5,
    "tirupur_rajasthan": 11.5,
    "coimbatore_rajasthan": 11.5,
    "erode_chandigarh": 12.5,
    "salem_chandigarh": 12.5,
    "tirupur_chandigarh": 12.5,
    "coimbatore_chandigarh": 12.5,
    "erode_uttarakhand": 12.5,
    "salem_uttarakhand": 12.5,
    "tirupur_uttarakhand": 12.5,
    "coimbatore_uttarakhand": 12.5,
    "erode_punjab": 12.5,
    "salem_punjab": 12.5,
    "tirupur_punjab": 12.5,
    "coimbatore_punjab": 12.5,
    "erode_haryana": 12.5,
    "salem_haryana": 12.5,
    "tirupur_haryana": 12.5,
    "coimbatore_haryana": 12.5,
    "erode_himachal pradesh": 12.5,
    "salem_himachal pradesh": 12.5,
    "tirupur_himachal pradesh": 12.5,
    "coimbatore_himachal pradesh": 12.5,
    "erode_jammu & kashmir": 14,
    "salem_jammu & kashmir": 14,
    "tirupur_jammu & kashmir": 14,
    "coimbatore_jammu & kashmir": 14,
    "erode_andhra pradesh": 7.5,
    "salem_andhra pradesh": 7.5,
    "tirupur_andhra pradesh": 7.5,
    "coimbatore_andhra pradesh": 7.5,
    "erode_telangana": 7.5,
    "salem_telangana": 7.5,
    "tirupur_telangana": 7.5,
    "coimbatore_telangana": 7.5,
    "erode_karnataka": 7.5,
    "salem_karnataka": 7.5,
    "tirupur_karnataka": 7.5,
    "coimbatore_karnataka": 7.5,
    "erode_bengaluru": 7.5,
    "salem_bengaluru": 7.5,
    "tirupur_bengaluru": 7.5,
    "coimbatore_bengaluru": 7.5,
    "erode_south tamil nadu - madras": 7.5,
    "salem_south tamil nadu - madras": 7.5,
    "tirupur_south tamil nadu - madras": 7.5,
    "coimbatore_south tamil nadu - madras": 7.5,
    "erode_pondicherry": 7.5,
    "salem_pondicherry": 7.5,
    "tirupur_pondicherry": 7.5,
    "coimbatore_pondicherry": 7.5,
    "erode_madurai": 7.5,
    "salem_madurai": 7.5,
    "tirupur_madurai": 7.5,
    "coimbatore_madurai": 7.5,
    "erode_trichy": 7.5,
    "salem_trichy": 7.5,
    "tirupur_trichy": 7.5,
    "coimbatore_trichy": 7.5,
    "erode_tanjavur": 7.5,
    "salem_tanjavur": 7.5,
    "tirupur_tanjavur": 7.5,
    "coimbatore_tanjavur": 7.5,
    "erode_tiruarunachal pradesh": 7.5,
    "salem_tiruarunachal pradesh": 7.5,
    "tirupur_tiruarunachal pradesh": 7.5,
    "coimbatore_tiruarunachal pradesh": 7.5,
    "erode_northe eastlvelli": 7.5,
    "salem_northe eastlvelli": 7.5,
    "tirupur_northe eastlvelli": 7.5,
    "coimbatore_northe eastlvelli": 7.5,
    "erode_tuticorin": 7.5,
    "salem_tuticorin": 7.5,
    "tirupur_tuticorin": 7.5,
    "coimbatore_tuticorin": 7.5,
    "erode_erode": 6.5,
    "salem_erode": 6.5,
    "tirupur_erode": 6.5,
    "coimbatore_erode": 6.5,
    "erode_salem": 6.5,
    "salem_salem": 6.5,
    "tirupur_salem": 6.5,
    "coimbatore_salem": 6.5,
    "erode_tirupur": 6.5,
    "salem_tirupur": 6.5,
    "tirupur_tirupur": 6.5,
    "coimbatore_tirupur": 6.5,
    "erode_coimbatore": 6.5,
    "salem_coimbatore": 6.5,
    "tirupur_coimbatore": 6.5,
    "coimbatore_coimbatore": 6.5,
    "erode_cochin": 10,
    "salem_cochin": 10,
    "tirupur_cochin": 10,
    "coimbatore_cochin": 10,
    "erode_kerala": 10,
    "salem_kerala": 10,
    "tirupur_kerala": 10,
    "coimbatore_kerala": 10,
    "erode_indore": 9.5,
    "salem_indore": 9.5,
    "tirupur_indore": 9.5,
    "coimbatore_indore": 9.5,
    "erode_madhya pradesh": 9.5,
    "salem_madhya pradesh": 9.5,
    "tirupur_madhya pradesh": 9.5,
    "coimbatore_madhya pradesh": 9.5,
    "erode_nagpur": 8.5,
    "salem_nagpur": 8.5,
    "tirupur_nagpur": 8.5,
    "coimbatore_nagpur": 8.5,
    "erode_raipur": 8.5,
    "salem_raipur": 8.5,
    "tirupur_raipur": 8.5,
    "coimbatore_raipur": 8.5,
    "erode_chhattisgarh": 8.5,
    "salem_chhattisgarh": 8.5,
    "tirupur_chhattisgarh": 8.5,
    "coimbatore_chhattisgarh": 8.5,
    "erode_vidarbha": 8.5,
    "salem_vidarbha": 8.5,
    "tirupur_vidarbha": 8.5,
    "coimbatore_vidarbha": 8.5,
    "erode_akola": 8.5,
    "salem_akola": 8.5,
    "tirupur_akola": 8.5,
    "coimbatore_akola": 8.5,
    "erode_amravati": 8.5,
    "salem_amravati": 8.5,
    "tirupur_amravati": 8.5,
    "coimbatore_amravati": 8.5,
    "erode_chandrapur": 8.5,
    "salem_chandrapur": 8.5,
    "tirupur_chandrapur": 8.5,
    "coimbatore_chandrapur": 8.5,
    "erode_kolkata": 12.5,
    "salem_kolkata": 12.5,
    "tirupur_kolkata": 12.5,
    "coimbatore_kolkata": 12.5,
    "erode_west bengal": 12.5,
    "salem_west bengal": 12.5,
    "tirupur_west bengal": 12.5,
    "coimbatore_west bengal": 12.5,
    "erode_odisha": 12.5,
    "salem_odisha": 12.5,
    "tirupur_odisha": 12.5,
    "coimbatore_odisha": 12.5,
    "erode_guwahati": 16,
    "salem_guwahati": 16,
    "tirupur_guwahati": 16,
    "coimbatore_guwahati": 16,
    "erode_lucknow": 12.5,
    "salem_lucknow": 12.5,
    "tirupur_lucknow": 12.5,
    "coimbatore_lucknow": 12.5,
    "erode_allahabad": 12.5,
    "salem_allahabad": 12.5,
    "tirupur_allahabad": 12.5,
    "coimbatore_allahabad": 12.5,
    "erode_kanpur": 12.5,
    "salem_kanpur": 12.5,
    "tirupur_kanpur": 12.5,
    "coimbatore_kanpur": 12.5,
    "erode_varanasi": 12.5,
    "salem_varanasi": 12.5,
    "tirupur_varanasi": 12.5,
    "coimbatore_varanasi": 12.5,
    "erode_agra": 12.5,
    "salem_agra": 12.5,
    "tirupur_agra": 12.5,
    "coimbatore_agra": 12.5,
    "erode_mathura": 12.5,
    "salem_mathura": 12.5,
    "tirupur_mathura": 12.5,
    "coimbatore_mathura": 12.5,
    "erode_eastern uttarpradesh": 12.5,
    "salem_eastern uttarpradesh": 12.5,
    "tirupur_eastern uttarpradesh": 12.5,
    "coimbatore_eastern uttarpradesh": 12.5,
    "erode_bihar": 13.5,
    "salem_bihar": 13.5,
    "tirupur_bihar": 13.5,
    "coimbatore_bihar": 13.5,
    "erode_jharkhand": 13.5,
    "salem_jharkhand": 13.5,
    "tirupur_jharkhand": 13.5,
    "coimbatore_jharkhand": 13.5,
    "cochin_arunachal pradesh": 24,
    "kerala_arunachal pradesh": 24,
    "cochin_assam": 24,
    "kerala_assam": 24,
    "cochin_manipur": 24,
    "kerala_manipur": 24,
    "cochin_meghalaya": 24,
    "kerala_meghalaya": 24,
    "cochin_mizoram": 24,
    "kerala_mizoram": 24,
    "cochin_nagaland": 24,
    "kerala_nagaland": 24,
    "cochin_sikkim": 24,
    "kerala_sikkim": 24,
    "cochin_tripura": 24,
    "kerala_tripura": 24,
    "cochin_northe east": 24,
    "kerala_northe east": 24,
    "cochin_kolhapur": 8.5,
    "kerala_kolhapur": 8.5,
    "cochin_aurangabad": 8.5,
    "kerala_aurangabad": 8.5,
    "cochin_satara": 8.5,
    "kerala_satara": 8.5,
    "cochin_goa": 8.5,
    "kerala_goa": 8.5,
    "cochin_nashik": 8.5,
    "kerala_nashik": 8.5,
    "cochin_mumbai": 8.5,
    "kerala_mumbai": 8.5,
    "cochin_bhiwandi": 8.5,
    "kerala_bhiwandi": 8.5,
    "cochin_panvel": 8.5,
    "kerala_panvel": 8.5,
    "cochin_gujrat": 9.5,
    "kerala_gujrat": 9.5,
    "cochin_vapi": 9.5,
    "kerala_vapi": 9.5,
    "cochin_daman": 9.5,
    "kerala_daman": 9.5,
    "cochin_silvasa": 9.5,
    "kerala_silvasa": 9.5,
    "cochin_delhi": 10.5,
    "kerala_delhi": 10.5,
    "cochin_rajasthan": 10.5,
    "kerala_rajasthan": 10.5,
    "cochin_chandigarh": 11.5,
    "kerala_chandigarh": 11.5,
    "cochin_uttarakhand": 11.5,
    "kerala_uttarakhand": 11.5,
    "cochin_punjab": 11.5,
    "kerala_punjab": 11.5,
    "cochin_haryana": 11.5,
    "kerala_haryana": 11.5,
    "cochin_himachal pradesh": 11.5,
    "kerala_himachal pradesh": 11.5,
    "cochin_jammu & kashmir": 14,
    "kerala_jammu & kashmir": 14,
    "cochin_andhra pradesh": 7.5,
    "kerala_andhra pradesh": 7.5,
    "cochin_telangana": 7.5,
    "kerala_telangana": 7.5,
    "cochin_karnataka": 7.5,
    "kerala_karnataka": 7.5,
    "cochin_bengaluru": 7.5,
    "kerala_bengaluru": 7.5,
    "cochin_south tamil nadu - madras": 7.5,
    "kerala_south tamil nadu - madras": 7.5,
    "cochin_pondicherry": 7.5,
    "kerala_pondicherry": 7.5,
    "cochin_madurai": 7.5,
    "kerala_madurai": 7.5,
    "cochin_trichy": 7.5,
    "kerala_trichy": 7.5,
    "cochin_tanjavur": 7.5,
    "kerala_tanjavur": 7.5,
    "cochin_tiruarunachal pradesh": 7.5,
    "kerala_tiruarunachal pradesh": 7.5,
    "cochin_northe eastlvelli": 7.5,
    "kerala_northe eastlvelli": 7.5,
    "cochin_tuticorin": 7.5,
    "kerala_tuticorin": 7.5,
    "cochin_erode": 6.5,
    "kerala_erode": 6.5,
    "cochin_salem": 6.5,
    "kerala_salem": 6.5,
    "cochin_tirupur": 6.5,
    "kerala_tirupur": 6.5,
    "cochin_coimbatore": 6.5,
    "kerala_coimbatore": 6.5,
    "cochin_cochin": 9,
    "kerala_cochin": 9,
    "cochin_kerala": 9,
    "kerala_kerala": 9,
    "cochin_indore": 9.5,
    "kerala_indore": 9.5,
    "cochin_madhya pradesh": 9.5,
    "kerala_madhya pradesh": 9.5,
    "cochin_nagpur": 8.5,
    "kerala_nagpur": 8.5,
    "cochin_raipur": 8.5,
    "kerala_raipur": 8.5,
    "cochin_chhattisgarh": 8.5,
    "kerala_chhattisgarh": 8.5,
    "cochin_vidarbha": 8.5,
    "kerala_vidarbha": 8.5,
    "cochin_akola": 8.5,
    "kerala_akola": 8.5,
    "cochin_amravati": 8.5,
    "kerala_amravati": 8.5,
    "cochin_chandrapur": 8.5,
    "kerala_chandrapur": 8.5,
    "cochin_kolkata": 12.5,
    "kerala_kolkata": 12.5,
    "cochin_west bengal": 12.5,
    "kerala_west bengal": 12.5,
    "cochin_odisha": 12.5,
    "kerala_odisha": 12.5,
    "cochin_guwahati": 16,
    "kerala_guwahati": 16,
    "cochin_lucknow": 12.5,
    "kerala_lucknow": 12.5,
    "cochin_allahabad": 12.5,
    "kerala_allahabad": 12.5,
    "cochin_kanpur": 12.5,
    "kerala_kanpur": 12.5,
    "cochin_varanasi": 12.5,
    "kerala_varanasi": 12.5,
    "cochin_agra": 12.5,
    "kerala_agra": 12.5,
    "cochin_mathura": 12.5,
    "kerala_mathura": 12.5,
    "cochin_eastern uttarpradesh": 12.5,
    "kerala_eastern uttarpradesh": 12.5,
    "cochin_bihar": 13.5,
    "kerala_bihar": 13.5,
    "cochin_jharkhand": 13.5,
    "kerala_jharkhand": 13.5,
    "indore_arunachal pradesh": 24,
    "madhya pradesh_arunachal pradesh": 24,
    "indore_assam": 24,
    "madhya pradesh_assam": 24,
    "indore_manipur": 24,
    "madhya pradesh_manipur": 24,
    "indore_meghalaya": 24,
    "madhya pradesh_meghalaya": 24,
    "indore_mizoram": 24,
    "madhya pradesh_mizoram": 24,
    "indore_nagaland": 24,
    "madhya pradesh_nagaland": 24,
    "indore_sikkim": 24,
    "madhya pradesh_sikkim": 24,
    "indore_tripura": 24,
    "madhya pradesh_tripura": 24,
    "indore_northe east": 24,
    "madhya pradesh_northe east": 24,
    "indore_kolhapur": 7.5,
    "madhya pradesh_kolhapur": 7.5,
    "indore_aurangabad": 7.5,
    "madhya pradesh_aurangabad": 7.5,
    "indore_satara": 7.5,
    "madhya pradesh_satara": 7.5,
    "indore_goa": 7.5,
    "madhya pradesh_goa": 7.5,
    "indore_nashik": 7.5,
    "madhya pradesh_nashik": 7.5,
    "indore_mumbai": 7.5,
    "madhya pradesh_mumbai": 7.5,
    "indore_bhiwandi": 7.5,
    "madhya pradesh_bhiwandi": 7.5,
    "indore_panvel": 7.5,
    "madhya pradesh_panvel": 7.5,
    "indore_gujrat": 7.5,
    "madhya pradesh_gujrat": 7.5,
    "indore_vapi": 7.5,
    "madhya pradesh_vapi": 7.5,
    "indore_daman": 7.5,
    "madhya pradesh_daman": 7.5,
    "indore_silvasa": 7.5,
    "madhya pradesh_silvasa": 7.5,
    "indore_delhi": 9,
    "madhya pradesh_delhi": 9,
    "indore_rajasthan": 9,
    "madhya pradesh_rajasthan": 9,
    "indore_chandigarh": 10,
    "madhya pradesh_chandigarh": 10,
    "indore_uttarakhand": 10,
    "madhya pradesh_uttarakhand": 10,
    "indore_punjab": 10,
    "madhya pradesh_punjab": 10,
    "indore_haryana": 10,
    "madhya pradesh_haryana": 10,
    "indore_himachal pradesh": 10,
    "madhya pradesh_himachal pradesh": 10,
    "indore_jammu & kashmir": 13,
    "madhya pradesh_jammu & kashmir": 13,
    "indore_andhra pradesh": 9,
    "madhya pradesh_andhra pradesh": 9,
    "indore_telangana": 9,
    "madhya pradesh_telangana": 9,
    "indore_karnataka": 9.5,
    "madhya pradesh_karnataka": 9.5,
    "indore_bengaluru": 9.5,
    "madhya pradesh_bengaluru": 9.5,
    "indore_south tamil nadu - madras": 10,
    "madhya pradesh_south tamil nadu - madras": 10,
    "indore_pondicherry": 10,
    "madhya pradesh_pondicherry": 10,
    "indore_madurai": 10,
    "madhya pradesh_madurai": 10,
    "indore_trichy": 10,
    "madhya pradesh_trichy": 10,
    "indore_tanjavur": 10,
    "madhya pradesh_tanjavur": 10,
    "indore_tiruarunachal pradesh": 10,
    "madhya pradesh_tiruarunachal pradesh": 10,
    "indore_northe eastlvelli": 10,
    "madhya pradesh_northe eastlvelli": 10,
    "indore_tuticorin": 10,
    "madhya pradesh_tuticorin": 10,
    "indore_erode": 10.5,
    "madhya pradesh_erode": 10.5,
    "indore_salem": 10.5,
    "madhya pradesh_salem": 10.5,
    "indore_tirupur": 10.5,
    "madhya pradesh_tirupur": 10.5,
    "indore_coimbatore": 10.5,
    "madhya pradesh_coimbatore": 10.5,
    "indore_cochin": 13,
    "madhya pradesh_cochin": 13,
    "indore_kerala": 13,
    "madhya pradesh_kerala": 13,
    "indore_indore": 6.5,
    "madhya pradesh_indore": 6.5,
    "indore_madhya pradesh": 6.5,
    "madhya pradesh_madhya pradesh": 6.5,
    "indore_nagpur": 7.5,
    "madhya pradesh_nagpur": 7.5,
    "indore_raipur": 7.5,
    "madhya pradesh_raipur": 7.5,
    "indore_chhattisgarh": 7.5,
    "madhya pradesh_chhattisgarh": 7.5,
    "indore_vidarbha": 7.5,
    "madhya pradesh_vidarbha": 7.5,
    "indore_akola": 7.5,
    "madhya pradesh_akola": 7.5,
    "indore_amravati": 7.5,
    "madhya pradesh_amravati": 7.5,
    "indore_chandrapur": 7.5,
    "madhya pradesh_chandrapur": 7.5,
    "indore_kolkata": 11.5,
    "madhya pradesh_kolkata": 11.5,
    "indore_west bengal": 11.5,
    "madhya pradesh_west bengal": 11.5,
    "indore_odisha": 11.5,
    "madhya pradesh_odisha": 11.5,
    "indore_guwahati": 16,
    "madhya pradesh_guwahati": 16,
    "indore_lucknow": 10.5,
    "madhya pradesh_lucknow": 10.5,
    "indore_allahabad": 10.5,
    "madhya pradesh_allahabad": 10.5,
    "indore_kanpur": 10.5,
    "madhya pradesh_kanpur": 10.5,
    "indore_varanasi": 10.5,
    "madhya pradesh_varanasi": 10.5,
    "indore_agra": 10.5,
    "madhya pradesh_agra": 10.5,
    "indore_mathura": 10.5,
    "madhya pradesh_mathura": 10.5,
    "indore_eastern uttarpradesh": 10.5,
    "madhya pradesh_eastern uttarpradesh": 10.5,
    "indore_bihar": 11.5,
    "madhya pradesh_bihar": 11.5,
    "indore_jharkhand": 11.5,
    "madhya pradesh_jharkhand": 11.5,
    "nagpur_arunachal pradesh": 24,
    "raipur_arunachal pradesh": 24,
    "chhattisgarh_arunachal pradesh": 24,
    "vidarbha_arunachal pradesh": 24,
    "akola_arunachal pradesh": 24,
    "amravati_arunachal pradesh": 24,
    "chandrapur_arunachal pradesh": 24,
    "nagpur_assam": 24,
    "raipur_assam": 24,
    "chhattisgarh_assam": 24,
    "vidarbha_assam": 24,
    "akola_assam": 24,
    "amravati_assam": 24,
    "chandrapur_assam": 24,
    "nagpur_manipur": 24,
    "raipur_manipur": 24,
    "chhattisgarh_manipur": 24,
    "vidarbha_manipur": 24,
    "akola_manipur": 24,
    "amravati_manipur": 24,
    "chandrapur_manipur": 24,
    "nagpur_meghalaya": 24,
    "raipur_meghalaya": 24,
    "chhattisgarh_meghalaya": 24,
    "vidarbha_meghalaya": 24,
    "akola_meghalaya": 24,
    "amravati_meghalaya": 24,
    "chandrapur_meghalaya": 24,
    "nagpur_mizoram": 24,
    "raipur_mizoram": 24,
    "chhattisgarh_mizoram": 24,
    "vidarbha_mizoram": 24,
    "akola_mizoram": 24,
    "amravati_mizoram": 24,
    "chandrapur_mizoram": 24,
    "nagpur_nagaland": 24,
    "raipur_nagaland": 24,
    "chhattisgarh_nagaland": 24,
    "vidarbha_nagaland": 24,
    "akola_nagaland": 24,
    "amravati_nagaland": 24,
    "chandrapur_nagaland": 24,
    "nagpur_sikkim": 24,
    "raipur_sikkim": 24,
    "chhattisgarh_sikkim": 24,
    "vidarbha_sikkim": 24,
    "akola_sikkim": 24,
    "amravati_sikkim": 24,
    "chandrapur_sikkim": 24,
    "nagpur_tripura": 24,
    "raipur_tripura": 24,
    "chhattisgarh_tripura": 24,
    "vidarbha_tripura": 24,
    "akola_tripura": 24,
    "amravati_tripura": 24,
    "chandrapur_tripura": 24,
    "nagpur_northe east": 24,
    "raipur_northe east": 24,
    "chhattisgarh_northe east": 24,
    "vidarbha_northe east": 24,
    "akola_northe east": 24,
    "amravati_northe east": 24,
    "chandrapur_northe east": 24,
    "nagpur_kolhapur": 7.5,
    "raipur_kolhapur": 7.5,
    "chhattisgarh_kolhapur": 7.5,
    "vidarbha_kolhapur": 7.5,
    "akola_kolhapur": 7.5,
    "amravati_kolhapur": 7.5,
    "chandrapur_kolhapur": 7.5,
    "nagpur_aurangabad": 7.5,
    "raipur_aurangabad": 7.5,
    "chhattisgarh_aurangabad": 7.5,
    "vidarbha_aurangabad": 7.5,
    "akola_aurangabad": 7.5,
    "amravati_aurangabad": 7.5,
    "chandrapur_aurangabad": 7.5,
    "nagpur_satara": 7.5,
    "raipur_satara": 7.5,
    "chhattisgarh_satara": 7.5,
    "vidarbha_satara": 7.5,
    "akola_satara": 7.5,
    "amravati_satara": 7.5,
    "chandrapur_satara": 7.5,
    "nagpur_goa": 7.5,
    "raipur_goa": 7.5,
    "chhattisgarh_goa": 7.5,
    "vidarbha_goa": 7.5,
    "akola_goa": 7.5,
    "amravati_goa": 7.5,
    "chandrapur_goa": 7.5,
    "nagpur_nashik": 7.5,
    "raipur_nashik": 7.5,
    "chhattisgarh_nashik": 7.5,
    "vidarbha_nashik": 7.5,
    "akola_nashik": 7.5,
    "amravati_nashik": 7.5,
    "chandrapur_nashik": 7.5,
    "nagpur_mumbai": 7.5,
    "raipur_mumbai": 7.5,
    "chhattisgarh_mumbai": 7.5,
    "vidarbha_mumbai": 7.5,
    "akola_mumbai": 7.5,
    "amravati_mumbai": 7.5,
    "chandrapur_mumbai": 7.5,
    "nagpur_bhiwandi": 7.5,
    "raipur_bhiwandi": 7.5,
    "chhattisgarh_bhiwandi": 7.5,
    "vidarbha_bhiwandi": 7.5,
    "akola_bhiwandi": 7.5,
    "amravati_bhiwandi": 7.5,
    "chandrapur_bhiwandi": 7.5,
    "nagpur_panvel": 7.5,
    "raipur_panvel": 7.5,
    "chhattisgarh_panvel": 7.5,
    "vidarbha_panvel": 7.5,
    "akola_panvel": 7.5,
    "amravati_panvel": 7.5,
    "chandrapur_panvel": 7.5,
    "nagpur_gujrat": 7.5,
    "raipur_gujrat": 7.5,
    "chhattisgarh_gujrat": 7.5,
    "vidarbha_gujrat": 7.5,
    "akola_gujrat": 7.5,
    "amravati_gujrat": 7.5,
    "chandrapur_gujrat": 7.5,
    "nagpur_vapi": 7.5,
    "raipur_vapi": 7.5,
    "chhattisgarh_vapi": 7.5,
    "vidarbha_vapi": 7.5,
    "akola_vapi": 7.5,
    "amravati_vapi": 7.5,
    "chandrapur_vapi": 7.5,
    "nagpur_daman": 7.5,
    "raipur_daman": 7.5,
    "chhattisgarh_daman": 7.5,
    "vidarbha_daman": 7.5,
    "akola_daman": 7.5,
    "amravati_daman": 7.5,
    "chandrapur_daman": 7.5,
    "nagpur_silvasa": 7.5,
    "raipur_silvasa": 7.5,
    "chhattisgarh_silvasa": 7.5,
    "vidarbha_silvasa": 7.5,
    "akola_silvasa": 7.5,
    "amravati_silvasa": 7.5,
    "chandrapur_silvasa": 7.5,
    "nagpur_delhi": 8.5,
    "raipur_delhi": 8.5,
    "chhattisgarh_delhi": 8.5,
    "vidarbha_delhi": 8.5,
    "akola_delhi": 8.5,
    "amravati_delhi": 8.5,
    "chandrapur_delhi": 8.5,
    "nagpur_rajasthan": 8.5,
    "raipur_rajasthan": 8.5,
    "chhattisgarh_rajasthan": 8.5,
    "vidarbha_rajasthan": 8.5,
    "akola_rajasthan": 8.5,
    "amravati_rajasthan": 8.5,
    "chandrapur_rajasthan": 8.5,
    "nagpur_chandigarh": 10.5,
    "raipur_chandigarh": 10.5,
    "chhattisgarh_chandigarh": 10.5,
    "vidarbha_chandigarh": 10.5,
    "akola_chandigarh": 10.5,
    "amravati_chandigarh": 10.5,
    "chandrapur_chandigarh": 10.5,
    "nagpur_uttarakhand": 10.5,
    "raipur_uttarakhand": 10.5,
    "chhattisgarh_uttarakhand": 10.5,
    "vidarbha_uttarakhand": 10.5,
    "akola_uttarakhand": 10.5,
    "amravati_uttarakhand": 10.5,
    "chandrapur_uttarakhand": 10.5,
    "nagpur_punjab": 10.5,
    "raipur_punjab": 10.5,
    "chhattisgarh_punjab": 10.5,
    "vidarbha_punjab": 10.5,
    "akola_punjab": 10.5,
    "amravati_punjab": 10.5,
    "chandrapur_punjab": 10.5,
    "nagpur_haryana": 10.5,
    "raipur_haryana": 10.5,
    "chhattisgarh_haryana": 10.5,
    "vidarbha_haryana": 10.5,
    "akola_haryana": 10.5,
    "amravati_haryana": 10.5,
    "chandrapur_haryana": 10.5,
    "nagpur_himachal pradesh": 10.5,
    "raipur_himachal pradesh": 10.5,
    "chhattisgarh_himachal pradesh": 10.5,
    "vidarbha_himachal pradesh": 10.5,
    "akola_himachal pradesh": 10.5,
    "amravati_himachal pradesh": 10.5,
    "chandrapur_himachal pradesh": 10.5,
    "nagpur_jammu & kashmir": 13,
    "raipur_jammu & kashmir": 13,
    "chhattisgarh_jammu & kashmir": 13,
    "vidarbha_jammu & kashmir": 13,
    "akola_jammu & kashmir": 13,
    "amravati_jammu & kashmir": 13,
    "chandrapur_jammu & kashmir": 13,
    "nagpur_andhra pradesh": 7.5,
    "raipur_andhra pradesh": 7.5,
    "chhattisgarh_andhra pradesh": 7.5,
    "vidarbha_andhra pradesh": 7.5,
    "akola_andhra pradesh": 7.5,
    "amravati_andhra pradesh": 7.5,
    "chandrapur_andhra pradesh": 7.5,
    "nagpur_telangana": 7.5,
    "raipur_telangana": 7.5,
    "chhattisgarh_telangana": 7.5,
    "vidarbha_telangana": 7.5,
    "akola_telangana": 7.5,
    "amravati_telangana": 7.5,
    "chandrapur_telangana": 7.5,
    "nagpur_karnataka": 8,
    "raipur_karnataka": 8,
    "chhattisgarh_karnataka": 8,
    "vidarbha_karnataka": 8,
    "akola_karnataka": 8,
    "amravati_karnataka": 8,
    "chandrapur_karnataka": 8,
    "nagpur_bengaluru": 8,
    "raipur_bengaluru": 8,
    "chhattisgarh_bengaluru": 8,
    "vidarbha_bengaluru": 8,
    "akola_bengaluru": 8,
    "amravati_bengaluru": 8,
    "chandrapur_bengaluru": 8,
    "nagpur_south tamil nadu - madras": 8.5,
    "raipur_south tamil nadu - madras": 8.5,
    "chhattisgarh_south tamil nadu - madras": 8.5,
    "vidarbha_south tamil nadu - madras": 8.5,
    "akola_south tamil nadu - madras": 8.5,
    "amravati_south tamil nadu - madras": 8.5,
    "chandrapur_south tamil nadu - madras": 8.5,
    "nagpur_pondicherry": 8.5,
    "raipur_pondicherry": 8.5,
    "chhattisgarh_pondicherry": 8.5,
    "vidarbha_pondicherry": 8.5,
    "akola_pondicherry": 8.5,
    "amravati_pondicherry": 8.5,
    "chandrapur_pondicherry": 8.5,
    "nagpur_madurai": 8.5,
    "raipur_madurai": 8.5,
    "chhattisgarh_madurai": 8.5,
    "vidarbha_madurai": 8.5,
    "akola_madurai": 8.5,
    "amravati_madurai": 8.5,
    "chandrapur_madurai": 8.5,
    "nagpur_trichy": 8.5,
    "raipur_trichy": 8.5,
    "chhattisgarh_trichy": 8.5,
    "vidarbha_trichy": 8.5,
    "akola_trichy": 8.5,
    "amravati_trichy": 8.5,
    "chandrapur_trichy": 8.5,
    "nagpur_tanjavur": 8.5,
    "raipur_tanjavur": 8.5,
    "chhattisgarh_tanjavur": 8.5,
    "vidarbha_tanjavur": 8.5,
    "akola_tanjavur": 8.5,
    "amravati_tanjavur": 8.5,
    "chandrapur_tanjavur": 8.5,
    "nagpur_tiruarunachal pradesh": 8.5,
    "raipur_tiruarunachal pradesh": 8.5,
    "chhattisgarh_tiruarunachal pradesh": 8.5,
    "vidarbha_tiruarunachal pradesh": 8.5,
    "akola_tiruarunachal pradesh": 8.5,
    "amravati_tiruarunachal pradesh": 8.5,
    "chandrapur_tiruarunachal pradesh": 8.5,
    "nagpur_northe eastlvelli": 8.5,
    "raipur_northe eastlvelli": 8.5,
    "chhattisgarh_northe eastlvelli": 8.5,
    "vidarbha_northe eastlvelli": 8.5,
    "akola_northe eastlvelli": 8.5,
    "amravati_northe eastlvelli": 8.5,
    "chandrapur_northe eastlvelli": 8.5,
    "nagpur_tuticorin": 8.5,
    "raipur_tuticorin": 8.5,
    "chhattisgarh_tuticorin": 8.5,
    "vidarbha_tuticorin": 8.5,
    "akola_tuticorin": 8.5,
    "amravati_tuticorin": 8.5,
    "chandrapur_tuticorin": 8.5,
    "nagpur_erode": 9.5,
    "raipur_erode": 9.5,
    "chhattisgarh_erode": 9.5,
    "vidarbha_erode": 9.5,
    "akola_erode": 9.5,
    "amravati_erode": 9.5,
    "chandrapur_erode": 9.5,
    "nagpur_salem": 9.5,
    "raipur_salem": 9.5,
    "chhattisgarh_salem": 9.5,
    "vidarbha_salem": 9.5,
    "akola_salem": 9.5,
    "amravati_salem": 9.5,
    "chandrapur_salem": 9.5,
    "nagpur_tirupur": 9.5,
    "raipur_tirupur": 9.5,
    "chhattisgarh_tirupur": 9.5,
    "vidarbha_tirupur": 9.5,
    "akola_tirupur": 9.5,
    "amravati_tirupur": 9.5,
    "chandrapur_tirupur": 9.5,
    "nagpur_coimbatore": 9.5,
    "raipur_coimbatore": 9.5,
    "chhattisgarh_coimbatore": 9.5,
    "vidarbha_coimbatore": 9.5,
    "akola_coimbatore": 9.5,
    "amravati_coimbatore": 9.5,
    "chandrapur_coimbatore": 9.5,
    "nagpur_cochin": 11.5,
    "raipur_cochin": 11.5,
    "chhattisgarh_cochin": 11.5,
    "vidarbha_cochin": 11.5,
    "akola_cochin": 11.5,
    "amravati_cochin": 11.5,
    "chandrapur_cochin": 11.5,
    "nagpur_kerala": 11.5,
    "raipur_kerala": 11.5,
    "chhattisgarh_kerala": 11.5,
    "vidarbha_kerala": 11.5,
    "akola_kerala": 11.5,
    "amravati_kerala": 11.5,
    "chandrapur_kerala": 11.5,
    "nagpur_indore": 7.5,
    "raipur_indore": 7.5,
    "chhattisgarh_indore": 7.5,
    "vidarbha_indore": 7.5,
    "akola_indore": 7.5,
    "amravati_indore": 7.5,
    "chandrapur_indore": 7.5,
    "nagpur_madhya pradesh": 7.5,
    "raipur_madhya pradesh": 7.5,
    "chhattisgarh_madhya pradesh": 7.5,
    "vidarbha_madhya pradesh": 7.5,
    "akola_madhya pradesh": 7.5,
    "amravati_madhya pradesh": 7.5,
    "chandrapur_madhya pradesh": 7.5,
    "nagpur_nagpur": 6.5,
    "raipur_nagpur": 6.5,
    "chhattisgarh_nagpur": 6.5,
    "vidarbha_nagpur": 6.5,
    "akola_nagpur": 6.5,
    "amravati_nagpur": 6.5,
    "chandrapur_nagpur": 6.5,
    "nagpur_raipur": 6.5,
    "raipur_raipur": 6.5,
    "chhattisgarh_raipur": 6.5,
    "vidarbha_raipur": 6.5,
    "akola_raipur": 6.5,
    "amravati_raipur": 6.5,
    "chandrapur_raipur": 6.5,
    "nagpur_chhattisgarh": 6.5,
    "raipur_chhattisgarh": 6.5,
    "chhattisgarh_chhattisgarh": 6.5,
    "vidarbha_chhattisgarh": 6.5,
    "akola_chhattisgarh": 6.5,
    "amravati_chhattisgarh": 6.5,
    "chandrapur_chhattisgarh": 6.5,
    "nagpur_vidarbha": 6.5,
    "raipur_vidarbha": 6.5,
    "chhattisgarh_vidarbha": 6.5,
    "vidarbha_vidarbha": 6.5,
    "akola_vidarbha": 6.5,
    "amravati_vidarbha": 6.5,
    "chandrapur_vidarbha": 6.5,
    "nagpur_akola": 6.5,
    "raipur_akola": 6.5,
    "chhattisgarh_akola": 6.5,
    "vidarbha_akola": 6.5,
    "akola_akola": 6.5,
    "amravati_akola": 6.5,
    "chandrapur_akola": 6.5,
    "nagpur_amravati": 6.5,
    "raipur_amravati": 6.5,
    "chhattisgarh_amravati": 6.5,
    "vidarbha_amravati": 6.5,
    "akola_amravati": 6.5,
    "amravati_amravati": 6.5,
    "chandrapur_amravati": 6.5,
    "nagpur_chandrapur": 6.5,
    "raipur_chandrapur": 6.5,
    "chhattisgarh_chandrapur": 6.5,
    "vidarbha_chandrapur": 6.5,
    "akola_chandrapur": 6.5,
    "amravati_chandrapur": 6.5,
    "chandrapur_chandrapur": 6.5,
    "nagpur_kolkata": 9.5,
    "raipur_kolkata": 9.5,
    "chhattisgarh_kolkata": 9.5,
    "vidarbha_kolkata": 9.5,
    "akola_kolkata": 9.5,
    "amravati_kolkata": 9.5,
    "chandrapur_kolkata": 9.5,
    "nagpur_west bengal": 9.5,
    "raipur_west bengal": 9.5,
    "chhattisgarh_west bengal": 9.5,
    "vidarbha_west bengal": 9.5,
    "akola_west bengal": 9.5,
    "amravati_west bengal": 9.5,
    "chandrapur_west bengal": 9.5,
    "nagpur_odisha": 9.5,
    "raipur_odisha": 9.5,
    "chhattisgarh_odisha": 9.5,
    "vidarbha_odisha": 9.5,
    "akola_odisha": 9.5,
    "amravati_odisha": 9.5,
    "chandrapur_odisha": 9.5,
    "nagpur_guwahati": 16,
    "raipur_guwahati": 16,
    "chhattisgarh_guwahati": 16,
    "vidarbha_guwahati": 16,
    "akola_guwahati": 16,
    "amravati_guwahati": 16,
    "chandrapur_guwahati": 16,
    "nagpur_lucknow": 10,
    "raipur_lucknow": 10,
    "chhattisgarh_lucknow": 10,
    "vidarbha_lucknow": 10,
    "akola_lucknow": 10,
    "amravati_lucknow": 10,
    "chandrapur_lucknow": 10,
    "nagpur_allahabad": 10,
    "raipur_allahabad": 10,
    "chhattisgarh_allahabad": 10,
    "vidarbha_allahabad": 10,
    "akola_allahabad": 10,
    "amravati_allahabad": 10,
    "chandrapur_allahabad": 10,
    "nagpur_kanpur": 10,
    "raipur_kanpur": 10,
    "chhattisgarh_kanpur": 10,
    "vidarbha_kanpur": 10,
    "akola_kanpur": 10,
    "amravati_kanpur": 10,
    "chandrapur_kanpur": 10,
    "nagpur_varanasi": 10,
    "raipur_varanasi": 10,
    "chhattisgarh_varanasi": 10,
    "vidarbha_varanasi": 10,
    "akola_varanasi": 10,
    "amravati_varanasi": 10,
    "chandrapur_varanasi": 10,
    "nagpur_agra": 10,
    "raipur_agra": 10,
    "chhattisgarh_agra": 10,
    "vidarbha_agra": 10,
    "akola_agra": 10,
    "amravati_agra": 10,
    "chandrapur_agra": 10,
    "nagpur_mathura": 10,
    "raipur_mathura": 10,
    "chhattisgarh_mathura": 10,
    "vidarbha_mathura": 10,
    "akola_mathura": 10,
    "amravati_mathura": 10,
    "chandrapur_mathura": 10,
    "nagpur_eastern uttarpradesh": 10,
    "raipur_eastern uttarpradesh": 10,
    "chhattisgarh_eastern uttarpradesh": 10,
    "vidarbha_eastern uttarpradesh": 10,
    "akola_eastern uttarpradesh": 10,
    "amravati_eastern uttarpradesh": 10,
    "chandrapur_eastern uttarpradesh": 10,
    "nagpur_bihar": 11.5,
    "raipur_bihar": 11.5,
    "chhattisgarh_bihar": 11.5,
    "vidarbha_bihar": 11.5,
    "akola_bihar": 11.5,
    "amravati_bihar": 11.5,
    "chandrapur_bihar": 11.5,
    "nagpur_jharkhand": 11.5,
    "raipur_jharkhand": 11.5,
    "chhattisgarh_jharkhand": 11.5,
    "vidarbha_jharkhand": 11.5,
    "akola_jharkhand": 11.5,
    "amravati_jharkhand": 11.5,
    "chandrapur_jharkhand": 11.5,
    "kolkata_arunachal pradesh": 20,
    "west bengal_arunachal pradesh": 20,
    "odisha_arunachal pradesh": 20,
    "kolkata_assam": 20,
    "west bengal_assam": 20,
    "odisha_assam": 20,
    "kolkata_manipur": 20,
    "west bengal_manipur": 20,
    "odisha_manipur": 20,
    "kolkata_meghalaya": 20,
    "west bengal_meghalaya": 20,
    "odisha_meghalaya": 20,
    "kolkata_mizoram": 20,
    "west bengal_mizoram": 20,
    "odisha_mizoram": 20,
    "kolkata_nagaland": 20,
    "west bengal_nagaland": 20,
    "odisha_nagaland": 20,
    "kolkata_sikkim": 20,
    "west bengal_sikkim": 20,
    "odisha_sikkim": 20,
    "kolkata_tripura": 20,
    "west bengal_tripura": 20,
    "odisha_tripura": 20,
    "kolkata_northe east": 20,
    "west bengal_northe east": 20,
    "odisha_northe east": 20,
    "kolkata_kolhapur": 9.5,
    "west bengal_kolhapur": 9.5,
    "odisha_kolhapur": 9.5,
    "kolkata_aurangabad": 9.5,
    "west bengal_aurangabad": 9.5,
    "odisha_aurangabad": 9.5,
    "kolkata_satara": 9.5,
    "west bengal_satara": 9.5,
    "odisha_satara": 9.5,
    "kolkata_goa": 9.5,
    "west bengal_goa": 9.5,
    "odisha_goa": 9.5,
    "kolkata_nashik": 9.5,
    "west bengal_nashik": 9.5,
    "odisha_nashik": 9.5,
    "kolkata_mumbai": 9.5,
    "west bengal_mumbai": 9.5,
    "odisha_mumbai": 9.5,
    "kolkata_bhiwandi": 9.5,
    "west bengal_bhiwandi": 9.5,
    "odisha_bhiwandi": 9.5,
    "kolkata_panvel": 9.5,
    "west bengal_panvel": 9.5,
    "odisha_panvel": 9.5,
    "kolkata_gujrat": 9.5,
    "west bengal_gujrat": 9.5,
    "odisha_gujrat": 9.5,
    "kolkata_vapi": 9.5,
    "west bengal_vapi": 9.5,
    "odisha_vapi": 9.5,
    "kolkata_daman": 9.5,
    "west bengal_daman": 9.5,
    "odisha_daman": 9.5,
    "kolkata_silvasa": 9.5,
    "west bengal_silvasa": 9.5,
    "odisha_silvasa": 9.5,
    "kolkata_delhi": 9.5,
    "west bengal_delhi": 9.5,
    "odisha_delhi": 9.5,
    "kolkata_rajasthan": 9.5,
    "west bengal_rajasthan": 9.5,
    "odisha_rajasthan": 9.5,
    "kolkata_chandigarh": 10.5,
    "west bengal_chandigarh": 10.5,
    "odisha_chandigarh": 10.5,
    "kolkata_uttarakhand": 10.5,
    "west bengal_uttarakhand": 10.5,
    "odisha_uttarakhand": 10.5,
    "kolkata_punjab": 10.5,
    "west bengal_punjab": 10.5,
    "odisha_punjab": 10.5,
    "kolkata_haryana": 10.5,
    "west bengal_haryana": 10.5,
    "odisha_haryana": 10.5,
    "kolkata_himachal pradesh": 10.5,
    "west bengal_himachal pradesh": 10.5,
    "odisha_himachal pradesh": 10.5,
    "kolkata_jammu & kashmir": 14,
    "west bengal_jammu & kashmir": 14,
    "odisha_jammu & kashmir": 14,
    "kolkata_andhra pradesh": 9.5,
    "west bengal_andhra pradesh": 9.5,
    "odisha_andhra pradesh": 9.5,
    "kolkata_telangana": 9.5,
    "west bengal_telangana": 9.5,
    "odisha_telangana": 9.5,
    "kolkata_karnataka": 9.5,
    "west bengal_karnataka": 9.5,
    "odisha_karnataka": 9.5,
    "kolkata_bengaluru": 9.5,
    "west bengal_bengaluru": 9.5,
    "odisha_bengaluru": 9.5,
    "kolkata_south tamil nadu - madras": 10.5,
    "west bengal_south tamil nadu - madras": 10.5,
    "odisha_south tamil nadu - madras": 10.5,
    "kolkata_pondicherry": 10.5,
    "west bengal_pondicherry": 10.5,
    "odisha_pondicherry": 10.5,
    "kolkata_madurai": 10.5,
    "west bengal_madurai": 10.5,
    "odisha_madurai": 10.5,
    "kolkata_trichy": 10.5,
    "west bengal_trichy": 10.5,
    "odisha_trichy": 10.5,
    "kolkata_tanjavur": 10.5,
    "west bengal_tanjavur": 10.5,
    "odisha_tanjavur": 10.5,
    "kolkata_tiruarunachal pradesh": 10.5,
    "west bengal_tiruarunachal pradesh": 10.5,
    "odisha_tiruarunachal pradesh": 10.5,
    "kolkata_northe eastlvelli": 10.5,
    "west bengal_northe eastlvelli": 10.5,
    "odisha_northe eastlvelli": 10.5,
    "kolkata_tuticorin": 10.5,
    "west bengal_tuticorin": 10.5,
    "odisha_tuticorin": 10.5,
    "kolkata_erode": 11,
    "west bengal_erode": 11,
    "odisha_erode": 11,
    "kolkata_salem": 11,
    "west bengal_salem": 11,
    "odisha_salem": 11,
    "kolkata_tirupur": 11,
    "west bengal_tirupur": 11,
    "odisha_tirupur": 11,
    "kolkata_coimbatore": 11,
    "west bengal_coimbatore": 11,
    "odisha_coimbatore": 11,
    "kolkata_cochin": 13,
    "west bengal_cochin": 13,
    "odisha_cochin": 13,
    "kolkata_kerala": 13,
    "west bengal_kerala": 13,
    "odisha_kerala": 13,
    "kolkata_indore": 9.5,
    "west bengal_indore": 9.5,
    "odisha_indore": 9.5,
    "kolkata_madhya pradesh": 9.5,
    "west bengal_madhya pradesh": 9.5,
    "odisha_madhya pradesh": 9.5,
    "kolkata_nagpur": 8.5,
    "west bengal_nagpur": 8.5,
    "odisha_nagpur": 8.5,
    "kolkata_raipur": 8.5,
    "west bengal_raipur": 8.5,
    "odisha_raipur": 8.5,
    "kolkata_chhattisgarh": 8.5,
    "west bengal_chhattisgarh": 8.5,
    "odisha_chhattisgarh": 8.5,
    "kolkata_vidarbha": 8.5,
    "west bengal_vidarbha": 8.5,
    "odisha_vidarbha": 8.5,
    "kolkata_akola": 8.5,
    "west bengal_akola": 8.5,
    "odisha_akola": 8.5,
    "kolkata_amravati": 8.5,
    "west bengal_amravati": 8.5,
    "odisha_amravati": 8.5,
    "kolkata_chandrapur": 8.5,
    "west bengal_chandrapur": 8.5,
    "odisha_chandrapur": 8.5,
    "kolkata_kolkata": 7.5,
    "west bengal_kolkata": 7.5,
    "odisha_kolkata": 7.5,
    "kolkata_west bengal": 7.5,
    "west bengal_west bengal": 7.5,
    "odisha_west bengal": 7.5,
    "kolkata_odisha": 7.5,
    "west bengal_odisha": 7.5,
    "odisha_odisha": 7.5,
    "kolkata_guwahati": 10.5,
    "west bengal_guwahati": 10.5,
    "odisha_guwahati": 10.5,
    "kolkata_lucknow": 9,
    "west bengal_lucknow": 9,
    "odisha_lucknow": 9,
    "kolkata_allahabad": 9,
    "west bengal_allahabad": 9,
    "odisha_allahabad": 9,
    "kolkata_kanpur": 9,
    "west bengal_kanpur": 9,
    "odisha_kanpur": 9,
    "kolkata_varanasi": 9,
    "west bengal_varanasi": 9,
    "odisha_varanasi": 9,
    "kolkata_agra": 9,
    "west bengal_agra": 9,
    "odisha_agra": 9,
    "kolkata_mathura": 9,
    "west bengal_mathura": 9,
    "odisha_mathura": 9,
    "kolkata_eastern uttarpradesh": 9,
    "west bengal_eastern uttarpradesh": 9,
    "odisha_eastern uttarpradesh": 9,
    "kolkata_bihar": 8,
    "west bengal_bihar": 8,
    "odisha_bihar": 8,
    "kolkata_jharkhand": 8,
    "west bengal_jharkhand": 8,
    "odisha_jharkhand": 8,
    "guwahati_arunachal pradesh": 20,
    "guwahati_assam": 20,
    "guwahati_manipur": 20,
    "guwahati_meghalaya": 20,
    "guwahati_mizoram": 20,
    "guwahati_nagaland": 20,
    "guwahati_sikkim": 20,
    "guwahati_tripura": 20,
    "guwahati_northe east": 20,
    "guwahati_kolhapur": 10,
    "guwahati_aurangabad": 10,
    "guwahati_satara": 10,
    "guwahati_goa": 10,
    "guwahati_nashik": 10,
    "guwahati_mumbai": 10,
    "guwahati_bhiwandi": 10,
    "guwahati_panvel": 10,
    "guwahati_gujrat": 10,
    "guwahati_vapi": 10,
    "guwahati_daman": 10,
    "guwahati_silvasa": 10,
    "guwahati_delhi": 10,
    "guwahati_rajasthan": 10,
    "guwahati_chandigarh": 11,
    "guwahati_uttarakhand": 11,
    "guwahati_punjab": 11,
    "guwahati_haryana": 11,
    "guwahati_himachal pradesh": 11,
    "guwahati_jammu & kashmir": 14,
    "guwahati_andhra pradesh": 10,
    "guwahati_telangana": 10,
    "guwahati_karnataka": 10,
    "guwahati_bengaluru": 10,
    "guwahati_south tamil nadu - madras": 10,
    "guwahati_pondicherry": 10,
    "guwahati_madurai": 10,
    "guwahati_trichy": 10,
    "guwahati_tanjavur": 10,
    "guwahati_tiruarunachal pradesh": 10,
    "guwahati_northe eastlvelli": 10,
    "guwahati_tuticorin": 10,
    "guwahati_erode": 13,
    "guwahati_salem": 13,
    "guwahati_tirupur": 13,
    "guwahati_coimbatore": 13,
    "guwahati_cochin": 14,
    "guwahati_kerala": 14,
    "guwahati_indore": 10,
    "guwahati_madhya pradesh": 10,
    "guwahati_nagpur": 9.5,
    "guwahati_raipur": 9.5,
    "guwahati_chhattisgarh": 9.5,
    "guwahati_vidarbha": 9.5,
    "guwahati_akola": 9.5,
    "guwahati_amravati": 9.5,
    "guwahati_chandrapur": 9.5,
    "guwahati_kolkata": 9,
    "guwahati_west bengal": 9,
    "guwahati_odisha": 9,
    "guwahati_guwahati": 8,
    "guwahati_lucknow": 10.5,
    "guwahati_allahabad": 10.5,
    "guwahati_kanpur": 10.5,
    "guwahati_varanasi": 10.5,
    "guwahati_agra": 10.5,
    "guwahati_mathura": 10.5,
    "guwahati_eastern uttarpradesh": 10.5,
    "guwahati_bihar": 9,
    "guwahati_jharkhand": 9,
    "lucknow_arunachal pradesh": 24,
    "allahabad_arunachal pradesh": 24,
    "kanpur_arunachal pradesh": 24,
    "varanasi_arunachal pradesh": 24,
    "agra_arunachal pradesh": 24,
    "mathura_arunachal pradesh": 24,
    "eastern uttarpradesh_arunachal pradesh": 24,
    "lucknow_assam": 24,
    "allahabad_assam": 24,
    "kanpur_assam": 24,
    "varanasi_assam": 24,
    "agra_assam": 24,
    "mathura_assam": 24,
    "eastern uttarpradesh_assam": 24,
    "lucknow_manipur": 24,
    "allahabad_manipur": 24,
    "kanpur_manipur": 24,
    "varanasi_manipur": 24,
    "agra_manipur": 24,
    "mathura_manipur": 24,
    "eastern uttarpradesh_manipur": 24,
    "lucknow_meghalaya": 24,
    "allahabad_meghalaya": 24,
    "kanpur_meghalaya": 24,
    "varanasi_meghalaya": 24,
    "agra_meghalaya": 24,
    "mathura_meghalaya": 24,
    "eastern uttarpradesh_meghalaya": 24,
    "lucknow_mizoram": 24,
    "allahabad_mizoram": 24,
    "kanpur_mizoram": 24,
    "varanasi_mizoram": 24,
    "agra_mizoram": 24,
    "mathura_mizoram": 24,
    "eastern uttarpradesh_mizoram": 24,
    "lucknow_nagaland": 24,
    "allahabad_nagaland": 24,
    "kanpur_nagaland": 24,
    "varanasi_nagaland": 24,
    "agra_nagaland": 24,
    "mathura_nagaland": 24,
    "eastern uttarpradesh_nagaland": 24,
    "lucknow_sikkim": 24,
    "allahabad_sikkim": 24,
    "kanpur_sikkim": 24,
    "varanasi_sikkim": 24,
    "agra_sikkim": 24,
    "mathura_sikkim": 24,
    "eastern uttarpradesh_sikkim": 24,
    "lucknow_tripura": 24,
    "allahabad_tripura": 24,
    "kanpur_tripura": 24,
    "varanasi_tripura": 24,
    "agra_tripura": 24,
    "mathura_tripura": 24,
    "eastern uttarpradesh_tripura": 24,
    "lucknow_northe east": 24,
    "allahabad_northe east": 24,
    "kanpur_northe east": 24,
    "varanasi_northe east": 24,
    "agra_northe east": 24,
    "mathura_northe east": 24,
    "eastern uttarpradesh_northe east": 24,
    "lucknow_kolhapur": 10.5,
    "allahabad_kolhapur": 10.5,
    "kanpur_kolhapur": 10.5,
    "varanasi_kolhapur": 10.5,
    "agra_kolhapur": 10.5,
    "mathura_kolhapur": 10.5,
    "eastern uttarpradesh_kolhapur": 10.5,
    "lucknow_aurangabad": 10.5,
    "allahabad_aurangabad": 10.5,
    "kanpur_aurangabad": 10.5,
    "varanasi_aurangabad": 10.5,
    "agra_aurangabad": 10.5,
    "mathura_aurangabad": 10.5,
    "eastern uttarpradesh_aurangabad": 10.5,
    "lucknow_satara": 10.5,
    "allahabad_satara": 10.5,
    "kanpur_satara": 10.5,
    "varanasi_satara": 10.5,
    "agra_satara": 10.5,
    "mathura_satara": 10.5,
    "eastern uttarpradesh_satara": 10.5,
    "lucknow_goa": 10.5,
    "allahabad_goa": 10.5,
    "kanpur_goa": 10.5,
    "varanasi_goa": 10.5,
    "agra_goa": 10.5,
    "mathura_goa": 10.5,
    "eastern uttarpradesh_goa": 10.5,
    "lucknow_nashik": 10.5,
    "allahabad_nashik": 10.5,
    "kanpur_nashik": 10.5,
    "varanasi_nashik": 10.5,
    "agra_nashik": 10.5,
    "mathura_nashik": 10.5,
    "eastern uttarpradesh_nashik": 10.5,
    "lucknow_mumbai": 10.5,
    "allahabad_mumbai": 10.5,
    "kanpur_mumbai": 10.5,
    "varanasi_mumbai": 10.5,
    "agra_mumbai": 10.5,
    "mathura_mumbai": 10.5,
    "eastern uttarpradesh_mumbai": 10.5,
    "lucknow_bhiwandi": 10.5,
    "allahabad_bhiwandi": 10.5,
    "kanpur_bhiwandi": 10.5,
    "varanasi_bhiwandi": 10.5,
    "agra_bhiwandi": 10.5,
    "mathura_bhiwandi": 10.5,
    "eastern uttarpradesh_bhiwandi": 10.5,
    "lucknow_panvel": 10.5,
    "allahabad_panvel": 10.5,
    "kanpur_panvel": 10.5,
    "varanasi_panvel": 10.5,
    "agra_panvel": 10.5,
    "mathura_panvel": 10.5,
    "eastern uttarpradesh_panvel": 10.5,
    "lucknow_gujrat": 10.5,
    "allahabad_gujrat": 10.5,
    "kanpur_gujrat": 10.5,
    "varanasi_gujrat": 10.5,
    "agra_gujrat": 10.5,
    "mathura_gujrat": 10.5,
    "eastern uttarpradesh_gujrat": 10.5,
    "lucknow_vapi": 10.5,
    "allahabad_vapi": 10.5,
    "kanpur_vapi": 10.5,
    "varanasi_vapi": 10.5,
    "agra_vapi": 10.5,
    "mathura_vapi": 10.5,
    "eastern uttarpradesh_vapi": 10.5,
    "lucknow_daman": 10.5,
    "allahabad_daman": 10.5,
    "kanpur_daman": 10.5,
    "varanasi_daman": 10.5,
    "agra_daman": 10.5,
    "mathura_daman": 10.5,
    "eastern uttarpradesh_daman": 10.5,
    "lucknow_silvasa": 10.5,
    "allahabad_silvasa": 10.5,
    "kanpur_silvasa": 10.5,
    "varanasi_silvasa": 10.5,
    "agra_silvasa": 10.5,
    "mathura_silvasa": 10.5,
    "eastern uttarpradesh_silvasa": 10.5,
    "lucknow_delhi": 6.5,
    "allahabad_delhi": 6.5,
    "kanpur_delhi": 6.5,
    "varanasi_delhi": 6.5,
    "agra_delhi": 6.5,
    "mathura_delhi": 6.5,
    "eastern uttarpradesh_delhi": 6.5,
    "lucknow_rajasthan": 6.5,
    "allahabad_rajasthan": 6.5,
    "kanpur_rajasthan": 6.5,
    "varanasi_rajasthan": 6.5,
    "agra_rajasthan": 6.5,
    "mathura_rajasthan": 6.5,
    "eastern uttarpradesh_rajasthan": 6.5,
    "lucknow_chandigarh": 6.5,
    "allahabad_chandigarh": 6.5,
    "kanpur_chandigarh": 6.5,
    "varanasi_chandigarh": 6.5,
    "agra_chandigarh": 6.5,
    "mathura_chandigarh": 6.5,
    "eastern uttarpradesh_chandigarh": 6.5,
    "lucknow_uttarakhand": 6.5,
    "allahabad_uttarakhand": 6.5,
    "kanpur_uttarakhand": 6.5,
    "varanasi_uttarakhand": 6.5,
    "agra_uttarakhand": 6.5,
    "mathura_uttarakhand": 6.5,
    "eastern uttarpradesh_uttarakhand": 6.5,
    "lucknow_punjab": 6.5,
    "allahabad_punjab": 6.5,
    "kanpur_punjab": 6.5,
    "varanasi_punjab": 6.5,
    "agra_punjab": 6.5,
    "mathura_punjab": 6.5,
    "eastern uttarpradesh_punjab": 6.5,
    "lucknow_haryana": 6.5,
    "allahabad_haryana": 6.5,
    "kanpur_haryana": 6.5,
    "varanasi_haryana": 6.5,
    "agra_haryana": 6.5,
    "mathura_haryana": 6.5,
    "eastern uttarpradesh_haryana": 6.5,
    "lucknow_himachal pradesh": 6.5,
    "allahabad_himachal pradesh": 6.5,
    "kanpur_himachal pradesh": 6.5,
    "varanasi_himachal pradesh": 6.5,
    "agra_himachal pradesh": 6.5,
    "mathura_himachal pradesh": 6.5,
    "eastern uttarpradesh_himachal pradesh": 6.5,
    "lucknow_jammu & kashmir": 9.5,
    "allahabad_jammu & kashmir": 9.5,
    "kanpur_jammu & kashmir": 9.5,
    "varanasi_jammu & kashmir": 9.5,
    "agra_jammu & kashmir": 9.5,
    "mathura_jammu & kashmir": 9.5,
    "eastern uttarpradesh_jammu & kashmir": 9.5,
    "lucknow_andhra pradesh": 10.5,
    "allahabad_andhra pradesh": 10.5,
    "kanpur_andhra pradesh": 10.5,
    "varanasi_andhra pradesh": 10.5,
    "agra_andhra pradesh": 10.5,
    "mathura_andhra pradesh": 10.5,
    "eastern uttarpradesh_andhra pradesh": 10.5,
    "lucknow_telangana": 10.5,
    "allahabad_telangana": 10.5,
    "kanpur_telangana": 10.5,
    "varanasi_telangana": 10.5,
    "agra_telangana": 10.5,
    "mathura_telangana": 10.5,
    "eastern uttarpradesh_telangana": 10.5,
    "lucknow_karnataka": 10.5,
    "allahabad_karnataka": 10.5,
    "kanpur_karnataka": 10.5,
    "varanasi_karnataka": 10.5,
    "agra_karnataka": 10.5,
    "mathura_karnataka": 10.5,
    "eastern uttarpradesh_karnataka": 10.5,
    "lucknow_bengaluru": 10.5,
    "allahabad_bengaluru": 10.5,
    "kanpur_bengaluru": 10.5,
    "varanasi_bengaluru": 10.5,
    "agra_bengaluru": 10.5,
    "mathura_bengaluru": 10.5,
    "eastern uttarpradesh_bengaluru": 10.5,
    "lucknow_south tamil nadu - madras": 10.5,
    "allahabad_south tamil nadu - madras": 10.5,
    "kanpur_south tamil nadu - madras": 10.5,
    "varanasi_south tamil nadu - madras": 10.5,
    "agra_south tamil nadu - madras": 10.5,
    "mathura_south tamil nadu - madras": 10.5,
    "eastern uttarpradesh_south tamil nadu - madras": 10.5,
    "lucknow_pondicherry": 10.5,
    "allahabad_pondicherry": 10.5,
    "kanpur_pondicherry": 10.5,
    "varanasi_pondicherry": 10.5,
    "agra_pondicherry": 10.5,
    "mathura_pondicherry": 10.5,
    "eastern uttarpradesh_pondicherry": 10.5,
    "lucknow_madurai": 10.5,
    "allahabad_madurai": 10.5,
    "kanpur_madurai": 10.5,
    "varanasi_madurai": 10.5,
    "agra_madurai": 10.5,
    "mathura_madurai": 10.5,
    "eastern uttarpradesh_madurai": 10.5,
    "lucknow_trichy": 10.5,
    "allahabad_trichy": 10.5,
    "kanpur_trichy": 10.5,
    "varanasi_trichy": 10.5,
    "agra_trichy": 10.5,
    "mathura_trichy": 10.5,
    "eastern uttarpradesh_trichy": 10.5,
    "lucknow_tanjavur": 10.5,
    "allahabad_tanjavur": 10.5,
    "kanpur_tanjavur": 10.5,
    "varanasi_tanjavur": 10.5,
    "agra_tanjavur": 10.5,
    "mathura_tanjavur": 10.5,
    "eastern uttarpradesh_tanjavur": 10.5,
    "lucknow_tiruarunachal pradesh": 10.5,
    "allahabad_tiruarunachal pradesh": 10.5,
    "kanpur_tiruarunachal pradesh": 10.5,
    "varanasi_tiruarunachal pradesh": 10.5,
    "agra_tiruarunachal pradesh": 10.5,
    "mathura_tiruarunachal pradesh": 10.5,
    "eastern uttarpradesh_tiruarunachal pradesh": 10.5,
    "lucknow_northe eastlvelli": 10.5,
    "allahabad_northe eastlvelli": 10.5,
    "kanpur_northe eastlvelli": 10.5,
    "varanasi_northe eastlvelli": 10.5,
    "agra_northe eastlvelli": 10.5,
    "mathura_northe eastlvelli": 10.5,
    "eastern uttarpradesh_northe eastlvelli": 10.5,
    "lucknow_tuticorin": 10.5,
    "allahabad_tuticorin": 10.5,
    "kanpur_tuticorin": 10.5,
    "varanasi_tuticorin": 10.5,
    "agra_tuticorin": 10.5,
    "mathura_tuticorin": 10.5,
    "eastern uttarpradesh_tuticorin": 10.5,
    "lucknow_erode": 12.5,
    "allahabad_erode": 12.5,
    "kanpur_erode": 12.5,
    "varanasi_erode": 12.5,
    "agra_erode": 12.5,
    "mathura_erode": 12.5,
    "eastern uttarpradesh_erode": 12.5,
    "lucknow_salem": 12.5,
    "allahabad_salem": 12.5,
    "kanpur_salem": 12.5,
    "varanasi_salem": 12.5,
    "agra_salem": 12.5,
    "mathura_salem": 12.5,
    "eastern uttarpradesh_salem": 12.5,
    "lucknow_tirupur": 12.5,
    "allahabad_tirupur": 12.5,
    "kanpur_tirupur": 12.5,
    "varanasi_tirupur": 12.5,
    "agra_tirupur": 12.5,
    "mathura_tirupur": 12.5,
    "eastern uttarpradesh_tirupur": 12.5,
    "lucknow_coimbatore": 12.5,
    "allahabad_coimbatore": 12.5,
    "kanpur_coimbatore": 12.5,
    "varanasi_coimbatore": 12.5,
    "agra_coimbatore": 12.5,
    "mathura_coimbatore": 12.5,
    "eastern uttarpradesh_coimbatore": 12.5,
    "lucknow_cochin": 14,
    "allahabad_cochin": 14,
    "kanpur_cochin": 14,
    "varanasi_cochin": 14,
    "agra_cochin": 14,
    "mathura_cochin": 14,
    "eastern uttarpradesh_cochin": 14,
    "lucknow_kerala": 14,
    "allahabad_kerala": 14,
    "kanpur_kerala": 14,
    "varanasi_kerala": 14,
    "agra_kerala": 14,
    "mathura_kerala": 14,
    "eastern uttarpradesh_kerala": 14,
    "lucknow_indore": 9,
    "allahabad_indore": 9,
    "kanpur_indore": 9,
    "varanasi_indore": 9,
    "agra_indore": 9,
    "mathura_indore": 9,
    "eastern uttarpradesh_indore": 9,
    "lucknow_madhya pradesh": 9,
    "allahabad_madhya pradesh": 9,
    "kanpur_madhya pradesh": 9,
    "varanasi_madhya pradesh": 9,
    "agra_madhya pradesh": 9,
    "mathura_madhya pradesh": 9,
    "eastern uttarpradesh_madhya pradesh": 9,
    "lucknow_nagpur": 9,
    "allahabad_nagpur": 9,
    "kanpur_nagpur": 9,
    "varanasi_nagpur": 9,
    "agra_nagpur": 9,
    "mathura_nagpur": 9,
    "eastern uttarpradesh_nagpur": 9,
    "lucknow_raipur": 9,
    "allahabad_raipur": 9,
    "kanpur_raipur": 9,
    "varanasi_raipur": 9,
    "agra_raipur": 9,
    "mathura_raipur": 9,
    "eastern uttarpradesh_raipur": 9,
    "lucknow_chhattisgarh": 9,
    "allahabad_chhattisgarh": 9,
    "kanpur_chhattisgarh": 9,
    "varanasi_chhattisgarh": 9,
    "agra_chhattisgarh": 9,
    "mathura_chhattisgarh": 9,
    "eastern uttarpradesh_chhattisgarh": 9,
    "lucknow_vidarbha": 9,
    "allahabad_vidarbha": 9,
    "kanpur_vidarbha": 9,
    "varanasi_vidarbha": 9,
    "agra_vidarbha": 9,
    "mathura_vidarbha": 9,
    "eastern uttarpradesh_vidarbha": 9,
    "lucknow_akola": 9,
    "allahabad_akola": 9,
    "kanpur_akola": 9,
    "varanasi_akola": 9,
    "agra_akola": 9,
    "mathura_akola": 9,
    "eastern uttarpradesh_akola": 9,
    "lucknow_amravati": 9,
    "allahabad_amravati": 9,
    "kanpur_amravati": 9,
    "varanasi_amravati": 9,
    "agra_amravati": 9,
    "mathura_amravati": 9,
    "eastern uttarpradesh_amravati": 9,
    "lucknow_chandrapur": 9,
    "allahabad_chandrapur": 9,
    "kanpur_chandrapur": 9,
    "varanasi_chandrapur": 9,
    "agra_chandrapur": 9,
    "mathura_chandrapur": 9,
    "eastern uttarpradesh_chandrapur": 9,
    "lucknow_kolkata": 11.5,
    "allahabad_kolkata": 11.5,
    "kanpur_kolkata": 11.5,
    "varanasi_kolkata": 11.5,
    "agra_kolkata": 11.5,
    "mathura_kolkata": 11.5,
    "eastern uttarpradesh_kolkata": 11.5,
    "lucknow_west bengal": 11.5,
    "allahabad_west bengal": 11.5,
    "kanpur_west bengal": 11.5,
    "varanasi_west bengal": 11.5,
    "agra_west bengal": 11.5,
    "mathura_west bengal": 11.5,
    "eastern uttarpradesh_west bengal": 11.5,
    "lucknow_odisha": 11.5,
    "allahabad_odisha": 11.5,
    "kanpur_odisha": 11.5,
    "varanasi_odisha": 11.5,
    "agra_odisha": 11.5,
    "mathura_odisha": 11.5,
    "eastern uttarpradesh_odisha": 11.5,
    "lucknow_guwahati": 16,
    "allahabad_guwahati": 16,
    "kanpur_guwahati": 16,
    "varanasi_guwahati": 16,
    "agra_guwahati": 16,
    "mathura_guwahati": 16,
    "eastern uttarpradesh_guwahati": 16,
    "lucknow_lucknow": 6.5,
    "allahabad_lucknow": 6.5,
    "kanpur_lucknow": 6.5,
    "varanasi_lucknow": 6.5,
    "agra_lucknow": 6.5,
    "mathura_lucknow": 6.5,
    "eastern uttarpradesh_lucknow": 6.5,
    "lucknow_allahabad": 6.5,
    "allahabad_allahabad": 6.5,
    "kanpur_allahabad": 6.5,
    "varanasi_allahabad": 6.5,
    "agra_allahabad": 6.5,
    "mathura_allahabad": 6.5,
    "eastern uttarpradesh_allahabad": 6.5,
    "lucknow_kanpur": 6.5,
    "allahabad_kanpur": 6.5,
    "kanpur_kanpur": 6.5,
    "varanasi_kanpur": 6.5,
    "agra_kanpur": 6.5,
    "mathura_kanpur": 6.5,
    "eastern uttarpradesh_kanpur": 6.5,
    "lucknow_varanasi": 6.5,
    "allahabad_varanasi": 6.5,
    "kanpur_varanasi": 6.5,
    "varanasi_varanasi": 6.5,
    "agra_varanasi": 6.5,
    "mathura_varanasi": 6.5,
    "eastern uttarpradesh_varanasi": 6.5,
    "lucknow_agra": 6.5,
    "allahabad_agra": 6.5,
    "kanpur_agra": 6.5,
    "varanasi_agra": 6.5,
    "agra_agra": 6.5,
    "mathura_agra": 6.5,
    "eastern uttarpradesh_agra": 6.5,
    "lucknow_mathura": 6.5,
    "allahabad_mathura": 6.5,
    "kanpur_mathura": 6.5,
    "varanasi_mathura": 6.5,
    "agra_mathura": 6.5,
    "mathura_mathura": 6.5,
    "eastern uttarpradesh_mathura": 6.5,
    "lucknow_eastern uttarpradesh": 6.5,
    "allahabad_eastern uttarpradesh": 6.5,
    "kanpur_eastern uttarpradesh": 6.5,
    "varanasi_eastern uttarpradesh": 6.5,
    "agra_eastern uttarpradesh": 6.5,
    "mathura_eastern uttarpradesh": 6.5,
    "eastern uttarpradesh_eastern uttarpradesh": 6.5,
    "lucknow_bihar": 10,
    "allahabad_bihar": 10,
    "kanpur_bihar": 10,
    "varanasi_bihar": 10,
    "agra_bihar": 10,
    "mathura_bihar": 10,
    "eastern uttarpradesh_bihar": 10,
    "lucknow_jharkhand": 10,
    "allahabad_jharkhand": 10,
    "kanpur_jharkhand": 10,
    "varanasi_jharkhand": 10,
    "agra_jharkhand": 10,
    "mathura_jharkhand": 10,
    "eastern uttarpradesh_jharkhand": 10,
    "bihar_arunachal pradesh": 24,
    "jharkhand_arunachal pradesh": 24,
    "bihar_assam": 24,
    "jharkhand_assam": 24,
    "bihar_manipur": 24,
    "jharkhand_manipur": 24,
    "bihar_meghalaya": 24,
    "jharkhand_meghalaya": 24,
    "bihar_mizoram": 24,
    "jharkhand_mizoram": 24,
    "bihar_nagaland": 24,
    "jharkhand_nagaland": 24,
    "bihar_sikkim": 24,
    "jharkhand_sikkim": 24,
    "bihar_tripura": 24,
    "jharkhand_tripura": 24,
    "bihar_northe east": 24,
    "jharkhand_northe east": 24,
    "bihar_kolhapur": 9,
    "jharkhand_kolhapur": 9,
    "bihar_aurangabad": 9,
    "jharkhand_aurangabad": 9,
    "bihar_satara": 9,
    "jharkhand_satara": 9,
    "bihar_goa": 9,
    "jharkhand_goa": 9,
    "bihar_nashik": 9,
    "jharkhand_nashik": 9,
    "bihar_mumbai": 9,
    "jharkhand_mumbai": 9,
    "bihar_bhiwandi": 9,
    "jharkhand_bhiwandi": 9,
    "bihar_panvel": 9,
    "jharkhand_panvel": 9,
    "bihar_gujrat": 9,
    "jharkhand_gujrat": 9,
    "bihar_vapi": 9,
    "jharkhand_vapi": 9,
    "bihar_daman": 9,
    "jharkhand_daman": 9,
    "bihar_silvasa": 9,
    "jharkhand_silvasa": 9,
    "bihar_delhi": 9,
    "jharkhand_delhi": 9,
    "bihar_rajasthan": 9,
    "jharkhand_rajasthan": 9,
    "bihar_chandigarh": 11,
    "jharkhand_chandigarh": 11,
    "bihar_uttarakhand": 11,
    "jharkhand_uttarakhand": 11,
    "bihar_punjab": 11,
    "jharkhand_punjab": 11,
    "bihar_haryana": 11,
    "jharkhand_haryana": 11,
    "bihar_himachal pradesh": 11,
    "jharkhand_himachal pradesh": 11,
    "bihar_jammu & kashmir": 14,
    "jharkhand_jammu & kashmir": 14,
    "bihar_andhra pradesh": 10,
    "jharkhand_andhra pradesh": 10,
    "bihar_telangana": 10,
    "jharkhand_telangana": 10,
    "bihar_karnataka": 11,
    "jharkhand_karnataka": 11,
    "bihar_bengaluru": 11,
    "jharkhand_bengaluru": 11,
    "bihar_south tamil nadu - madras": 11,
    "jharkhand_south tamil nadu - madras": 11,
    "bihar_pondicherry": 11,
    "jharkhand_pondicherry": 11,
    "bihar_madurai": 11,
    "jharkhand_madurai": 11,
    "bihar_trichy": 11,
    "jharkhand_trichy": 11,
    "bihar_tanjavur": 11,
    "jharkhand_tanjavur": 11,
    "bihar_tiruarunachal pradesh": 11,
    "jharkhand_tiruarunachal pradesh": 11,
    "bihar_northe eastlvelli": 11,
    "jharkhand_northe eastlvelli": 11,
    "bihar_tuticorin": 11,
    "jharkhand_tuticorin": 11,
    "bihar_erode": 12,
    "jharkhand_erode": 12,
    "bihar_salem": 12,
    "jharkhand_salem": 12,
    "bihar_tirupur": 12,
    "jharkhand_tirupur": 12,
    "bihar_coimbatore": 12,
    "jharkhand_coimbatore": 12,
    "bihar_cochin": 14,
    "jharkhand_cochin": 14,
    "bihar_kerala": 14,
    "jharkhand_kerala": 14,
    "bihar_indore": 10,
    "jharkhand_indore": 10,
    "bihar_madhya pradesh": 10,
    "jharkhand_madhya pradesh": 10,
    "bihar_nagpur": 9,
    "jharkhand_nagpur": 9,
    "bihar_raipur": 9,
    "jharkhand_raipur": 9,
    "bihar_chhattisgarh": 9,
    "jharkhand_chhattisgarh": 9,
    "bihar_vidarbha": 9,
    "jharkhand_vidarbha": 9,
    "bihar_akola": 9,
    "jharkhand_akola": 9,
    "bihar_amravati": 9,
    "jharkhand_amravati": 9,
    "bihar_chandrapur": 9,
    "jharkhand_chandrapur": 9,
    "bihar_kolkata": 8,
    "jharkhand_kolkata": 8,
    "bihar_west bengal": 8,
    "jharkhand_west bengal": 8,
    "bihar_odisha": 8,
    "jharkhand_odisha": 8,
    "bihar_guwahati": 16,
    "jharkhand_guwahati": 16,
    "bihar_lucknow": 9,
    "jharkhand_lucknow": 9,
    "bihar_allahabad": 9,
    "jharkhand_allahabad": 9,
    "bihar_kanpur": 9,
    "jharkhand_kanpur": 9,
    "bihar_varanasi": 9,
    "jharkhand_varanasi": 9,
    "bihar_agra": 9,
    "jharkhand_agra": 9,
    "bihar_mathura": 9,
    "jharkhand_mathura": 9,
    "bihar_eastern uttarpradesh": 9,
    "jharkhand_eastern uttarpradesh": 9,
    "bihar_bihar": 7.5,
    "jharkhand_bihar": 7.5,
    "bihar_jharkhand": 7.5,
    "jharkhand_jharkhand": 7.5
};