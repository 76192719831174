import React, { useState } from 'react'
import Button from '../common/Button';

const ProductBoxForms = ({ add }) => {
    const [name, setName] = useState();
    const [length, setLength] = useState(0);
    const [breadth, setBreadth] = useState(0);
    const [height, setHeight] = useState(0);
    const [hsn, setHSN] = useState();
    const [sku, setSKU] = useState();
    const [boxes, setBoxes] = useState(0);
    const handleAdd = () => {
        add({
            name,
            length,
            breadth,
            height,
            hsn,
            sku,
            boxes
        })
    }
  return (
    <div className='signup'>
        <div className='heading'>
            <h1>Product Box Details</h1>
        </div>
        <div className='inputs'>
            <input
                type='text'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='name'
            />
            <input
                type='number'
                name='length'
                value={length}
                onChange={(e) => setLength(e.target.value)}
                placeholder='length'
            />
            <input
                type='number'
                name='breadth'
                value={breadth}
                onChange={(e) => setBreadth(e.target.value)}
                placeholder='breadth'
            />
            <input
                type='number'
                name='height'
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                placeholder='height'
            />
            <input
                type='text'
                name='hsn'
                value={hsn}
                onChange={(e) => setHSN(e.target.value)}
                placeholder='hsn'
            />
            <input
                type='text'
                name='sku'
                value={sku}
                onChange={(e) => setSKU(e.target.value)}
                placeholder='sku'
            />
            <input
                type='number'
                name='no of boxes'
                value={boxes}
                onChange={(e) => setBoxes(e.target.value)}
                placeholder='no of boxes'
            />
        </div>
        <div className='buttons'>
            <Button onClick={handleAdd}>
                Add Box
            </Button>
        </div>
    </div>
  )
}

export default ProductBoxForms