import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useGetCurrUserQuery } from '../../store';
import { getCookie, setCookie } from '../../helpers/cookies';
import RechargeWallet from '../customers/RechargeWallet';

const SideBar = ({ setProgress }) => {
    const jwt = getCookie("delivaryUserJWT");
    const [parent, enable] = useAutoAnimate({ duration: 350 });
    const { data, isFetching } = useGetCurrUserQuery(jwt);
    const [content, setContent] = useState();

    useEffect(() => {
        if(data){
            if(data.role === "admin") {
                setContent(
                    <div className='user'>
                        <div className='user-circle'>{data.username[0]}</div>
                        <div className='user-name'>{data.username}</div>
                    </div>
                )
            }
            else {
                setCookie("delivaryUserName", data.username);
                setCookie("delivaryUserEmail", data.email);
                setCookie("delivaryUserPhone", data.phone);
                setCookie("delivaryUserWallet", data.wallet);
                setCookie("delivaryUserOrders", data.no_of_orders);
                setContent(
                    <div className='user'>
                        <div className='wallet'>
                            <img src="https://sr-sidebar.shiprocket.in/assets/svg/wallet.svg" alt="wallet" /> Rs. {data.wallet}
                        </div>
                        <RechargeWallet setProgress={setProgress} />
                        <div className='user-circle'>{data.username[0]}</div>
                        <div className='user-name'>{data.username}</div>
                    </div>
                )
            }
        }
        else if(isFetching){
            setContent(
                <div className="skeleton">
                    <div className='user-circle' />
                    <div className='user-name' />
                </div>
            )
        }
        else {
            setContent();
        }
    }, [data, isFetching]);

    useEffect(() => {
        enable(true);
    }, [parent, enable]);

    const menuArray = ["Log In", "Sign Up"];
    const menuItems = {
        "Log In": '/auth/login',
        "Sign Up": '/auth/signup'
    };
    return (
        <div className='sidebar'>
            <div className='company'>
                <div className='logo'>
                    <img src={window.location.origin + `/favicon.ico`} alt='company logo' />
                </div>
            </div>
            <div className="to-menus">
                <NavLink to="/">
                    Home
                </NavLink>
                <NavLink to="/track-order">
                    Track Order
                </NavLink>
                { jwt && (
                    data && (
                        <NavLink to={ data.role === "admin" ? "/admin/": "/customer/" }>
                            My Dashboard
                        </NavLink>
                    )
                )}
            </div>
            {!jwt && (<div className='menu-items-login'>
                {menuArray.map((menuItem) => {
                    return <div key={menuItem} className='link'>
                        <NavLink to={menuItems[menuItem]}>
                            {menuItem}
                        </NavLink>
                    </div>;
                })}
            </div> )}
            {jwt && content}
        </div>
    )
}

export default SideBar