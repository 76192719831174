import React from 'react'

const Issues = () => {
  return (
    <div>
        <h1>Site is under Maintanance.</h1>
    </div>
  )
}

export default Issues